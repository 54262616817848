// src/components/ShimmerImage.jsx

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './ShimmerImage.css'; // Import the CSS for shimmer effect

const ShimmerImage = ({ src, alt, className, style, fallbackSrc }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    // Create a new Image object to track loading
    const img = new Image();
    img.src = src;
    img.onload = () => setIsLoaded(true);
    img.onerror = () => {
      setIsLoaded(true);
      setHasError(true);
    };
  }, [src]);

  return (
    <div className={`shimmer-image-container ${className}`} style={style}>
      {!isLoaded && <div className="shimmer"></div>}
      {isLoaded && !hasError && <img src={src} alt={alt} className="shimmer-image" />}
      {isLoaded && hasError && fallbackSrc && (
        <img src={fallbackSrc} alt="Fallback" className="shimmer-image" />
      )}
    </div>
  );
};

ShimmerImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  fallbackSrc: PropTypes.string,
};

ShimmerImage.defaultProps = {
  alt: '',
  className: '',
  style: {},
  fallbackSrc: '',
};

export default ShimmerImage;