// src/pages/users/UsersDashboard.jsx

import React, { useEffect, useState } from "react";
import MainWrapper from "../components/wrapper/MainWrapper";
import { useNavigate } from "react-router-dom";
import { ProtocolList } from "../components/ProtocolList"; // Ensure this path is correct
import {
  Alert,
  CircularProgress,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Button,
  TextField,
  Menu,
  MenuItem,
} from "@mui/material"; // Import necessary components from MUI
import useAuthorizedHttp from "../hooks/use-authorized-http"; // Import the custom authorized HTTP hook
import SupportModal from "../components/supportModal/SupportModal";
import ShimmerImage from '../components/ShimmerImage'; // Import ShimmerImage
import { useTheme } from '@mui/material/styles';

const UsersDashboard = () => {
  const navigate = useNavigate();

  const theme = useTheme();

  // State variables for projects
  const [allProjects, setAllProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [isProjectsLoading, setIsProjectsLoading] = useState(false);
  const [error, setError] = useState(null);

  // State variables for search and sorting
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOption, setSortOption] = useState("score"); // Example sort option

  // State variables for menu handling
  const [anchorEl, setAnchorEl] = useState(null);

  // Initialize the custom authorized HTTP hook
  const makeRequest = useAuthorizedHttp();

  // Function to open the sort menu
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to close the sort menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);

 

  // Debounce search input to improve performance
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleFilterAndSort(searchTerm, sortOption);
    }, 300); // 300ms delay

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, sortOption, allProjects]);

  // Function to filter and sort projects
  const handleFilterAndSort = (searchValue, sortOption) => {
    console.log("Filtering and Sorting with:", searchValue, sortOption); // Debugging
    let filtered = allProjects;

    if (searchValue.trim() !== "") {
      filtered = allProjects.filter((project) =>
        project.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    // Implement sorting based on sortOption
    if (sortOption === "score") {
      filtered.sort((a, b) => b.score - a.score); // Example sorting by score
    } else if (sortOption === "name") {
      filtered.sort((a, b) => a.name.localeCompare(b.name)); // Sort by name
    }

    setFilteredProjects(filtered);
  };

  // Function to handle project actions (view, edit, duplicate, delete)
  const handleProjectAction = (action, projectId) => {
    switch (action) {
      case "view":
        navigate(`/projects/${projectId}`);
        break;
      case "edit":
        alert(`Edit project with ID: ${projectId}`); // Replace with real edit functionality
        break;
      case "duplicate":
        alert(`Duplicate project with ID: ${projectId}`); // Replace with real duplicate functionality
        break;
      case "delete":
        alert(`Delete project with ID: ${projectId}`); // Replace with real delete functionality
        break;
      default:
        break;
    }
    handleMenuClose();
  };

  return (
    <MainWrapper>
      <Box sx={{ padding: { xs: 2, sm: 3, md: 4 },background: theme.palette.background.grey }}>
        {/* Dashboard Header Section */}
        <Box

          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: { xs: "column", sm: "row" },
            marginTop: 4,
            marginBottom: 3,
            
          }}
        >
          <Box sx={{ marginBottom: { xs: 2, sm: 0 } }}>
            <Typography
              variant="h6"
              sx={{
                fontSize: { xs: "16px", sm: "18px" },
               
              }}
            >
              Synthetic Satellite Imagery
            </Typography>
          </Box>

          {/* See all DGUs Button */}
         
        </Box>

        {/* Error Alert */}
        {error && (
          <Alert severity="error" sx={{ marginBottom: 3 }}>
            {error}
          </Alert>
        )}

        {/* Loading Indicator */}
        {isProjectsLoading && (
          <Box sx={{ display: "flex", justifyContent: "center", margin: "20px 0" }}>
            <CircularProgress />
          </Box>
        )}



        {/* Released DGUs */}
        <Box sx={{ marginBottom: 5 }}>
          <Typography
            variant="t2_grey"
            sx={{
              fontSize: { xs: "16px", sm: "18px" },
              
              marginBottom: "15px",
            }}
          >
            Shed light on satellite imagery's black box with explainable, synthetic data generators.
          </Typography>
          <Grid container spacing={3}>
            {ProtocolList.filter((protocol) => protocol.release === "Yes").map(
              (protocol) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={protocol.id}>
                  <Card variant="blackBox"
                    sx={{
                      borderRadius: "12px",
                      overflow: "hidden",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      position: "relative",
                      transition: "transform 0.2s",
                      "&:hover": {
                        transform: "scale(1.02)",
                      },
                    }}
                  >
                    {/* GIF Section with ShimmerImage */}
                    <CardMedia>
                      <Box
                        sx={{
                          position: "relative",
                          width: "100%",
                          paddingTop: "56.25%", // 16:9 Aspect Ratio
                          overflow: "hidden",
                        }}
                      >
                        <ShimmerImage
                          src={protocol.gif}
                          alt={`${protocol.name} GIF`}
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                        {/* Overlay Text */}
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: "10px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            color: "#fff",
                            padding: "5px 10px",
                            borderRadius: "8px",
                            fontSize: "12px",
                            fontWeight: "500",
                            textAlign: "center",
                            width: "200px",
                            maxWidth: "80%",
                          }}
                        >
                          {protocol.tags[0]}
                        </Box>
                      </Box>
                    </CardMedia>

                    {/* Card Content */}
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: 2,
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: { xs: "15px", sm: "16px" },
                            
                          }}
                        >
                          {protocol.name}
                        </Typography>
                        {/* Release Tag */}
                        {protocol.release_tag && (
                          <Box variant="tag"
                          sx={{fontSize: '12px',
                            fontWeight: 500,
                            color: '#fff',
                            backgroundColor: '#444',
                            padding: '2px 8px',
                            borderRadius: '6px',}}
                            
                          >
                            {protocol.release_tag}
                          </Box>
                        )}
                      </Box>

                      <Typography
                        variant="t3_grey"
                        sx={{
                          
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {protocol.description}
                      </Typography>
                    </CardContent>

                    {/* Card Actions */}
                    <CardActions
                      sx={{
                        justifyContent: "flex-end",
                        padding: 2,
                      }}
                    >
                      <Button
                        variant="custom"
                        size="small"
                        sx={{
                          fontSize: { xs: "12px", sm: "14px" },
                          fontWeight: "600",
                          borderRadius: "6px",
                          padding: { xs: "6px 8px", sm: "6px 10px" },
                          textTransform: "none",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (protocol.button === "Contact Sales") {
                            setIsSupportModalOpen(true); // Open the modal
                          } else {
                            navigate(protocol.link_playground); // Default behavior
                          }
                        }}
                        aria-label={protocol.button || "Explore"}
                      >
                        {protocol.button || "Explore"}
                        <Box component="span" sx={{ fontSize: "14px", marginLeft: 1 }}>
                          &#8594;
                        </Box>
                      </Button>
                    </CardActions>

                    {/* Support Modal */}
                    <SupportModal
                      openModal={isSupportModalOpen}
                      setOpenModal={setIsSupportModalOpen}
                    />
                  </Card>
                </Grid>
              )
            )}
          </Grid>
        </Box>

        {/* Coming Soon */}
        <Box>
          <Typography
            variant="h6_grey"
            sx={{
              
              marginBottom: "15px",
            }}
          >
            Coming Soon
          </Typography>
          <Grid container spacing={3}>
            {ProtocolList.filter(
              (protocol) => protocol.release !== "Yes" || !protocol.release
            ).map((protocol) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={protocol.id}>
                <Card variant="blackBox"
                  sx={{
                    borderRadius: "12px",
                    overflow: "hidden",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    transition: "transform 0.2s",
                    "&:hover": {
                      transform: "scale(1.02)",
                    },
                  }}
                >
                  {/* GIF Section with ShimmerImage */}
                  <CardMedia>
                    <Box variant="blackbox"
                      sx={{
                        position: "relative",
                        width: "100%",
                        paddingTop: "56.25%", // 16:9 Aspect Ratio
                        overflow: "hidden",
                      }}
                    >
                      <ShimmerImage
                        src={protocol.gif}
                        alt={`${protocol.name} GIF`}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                      {/* Overlay Text */}
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: "10px",
                          left: "50%",
                          transform: "translateX(-50%)",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          color: "#fff",
                          padding: "5px 10px",
                          borderRadius: "8px",
                          fontSize: "12px",
                          fontWeight: "500",
                          textAlign: "center",
                          width: "200px",
                          maxWidth: "80%",
                        }}
                      >
                        {protocol.tags[0]}
                      </Box>
                    </Box>
                  </CardMedia>

                  {/* Card Content */}
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 2,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: { xs: "16px", sm: "18px" },
                          
                        }}
                      >
                        {protocol.name}
                      </Typography>
                      {/* Release Tag */}
                      {protocol.release_tag && (
                        <Box variant="tag"
                        sx={{fontSize: '12px',
                          fontWeight: 500,
                          color: '#fff',
                          backgroundColor: '#444',
                          padding: '2px 8px',
                          borderRadius: '6px',}}
                         
                        >
                          {protocol.release_tag}
                        </Box>
                      )}
                    </Box>

                    <Typography
                      variant="t3_grey"
                      sx={{
                        
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {protocol.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </MainWrapper>
  );
};

// Custom CSS for the shimmer effect with a light grey shade
const shimmerStyle = `
  .shimmer {
    background: linear-gradient(to right, #f2f2f2 0%, #e0e0e0 20%, #f2f2f2 40%, #f2f2f2 100%);
    background-size: 200% auto;
    animation: shimmer 1.5s linear infinite;
    color: transparent !important;
    border: none;
    padding: 10px 20px;
    cursor: default;
  }

  @keyframes shimmer {
    0% {
      background-position: 200% center;
    }
    100% {
      background-position: -200% center;
    }
  }
`;

// Append the shimmer styles to the document head
const styleElement = document.createElement('style');
styleElement.innerHTML = shimmerStyle;
document.head.appendChild(styleElement);

export default UsersDashboard;
