// src/components/UserProfile.js
import React, { useState, useEffect } from 'react';
import axios from "axios"; // Ensure axios is imported

import MainWrapper from "../../components/wrapper/MainWrapper";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Modal,
  TextField,
  Typography,
  Stack,
  Card,
  CardContent,
  Divider,
  Autocomplete,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import {
  BusinessCenter as BusinessCenterIcon,
  DeleteOutline as DeleteOutlineIcon,
  CurrencyExchange as CurrencyExchangeIcon,
  Business as BusinessIcon,
  Home as HomeIcon,
  Language as LanguageIcon,
  AccountBalance as AccountBalanceIcon,
  Badge as BadgeIcon,
  Public as PublicIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from '../../context/app-slice';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles'; // Import useTheme to access theme properties

const UserProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appCtx = useSelector((state) => state.app);
  const theme = useTheme(); // Access the theme

  // Initialize userData from Redux state or localStorage
  const [userData, setUserData] = useState({
    userId: appCtx.userDetails?.userId || localStorage.getItem("userId") || '',
    firstName: appCtx.userDetails?.firstName || localStorage.getItem("firstName") || '',
    lastName: appCtx.userDetails?.lastName || localStorage.getItem("lastName") || '',
    userName: appCtx.userDetails?.userName || localStorage.getItem("userName") || '',
    email: appCtx.userDetails?.email || localStorage.getItem("email") || '',
    businessDetails: appCtx.userDetails?.businessDetails || localStorage.getItem("businessDetails") || '',
    businessName: appCtx.userDetails?.businessName || localStorage.getItem("businessName") || '',
    businessAddress: appCtx.userDetails?.businessAddress || localStorage.getItem("businessAddress") || '',
    currency: appCtx.userDetails?.currency || localStorage.getItem("currency") || '',
    taxIdName: appCtx.userDetails?.taxIdName || localStorage.getItem("taxIdName") || '',
    taxIdValue: appCtx.userDetails?.taxIdValue || localStorage.getItem("taxIdValue") || '',
    website: appCtx.userDetails?.website || localStorage.getItem("website") || '',
    countryOfResidence: appCtx.userDetails?.countryOfResidence || localStorage.getItem("countryOfResidence") || '',
    countryOfBilling: appCtx.userDetails?.countryOfBilling || localStorage.getItem("countryOfBilling") || '',
    profilePhoto: localStorage.getItem("profilePhoto") || 'https://via.placeholder.com/150',
  });

  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteConfirmationText, setDeleteConfirmationText] = useState('');
  const [countryOptions, setCountryOptions] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [taxIdOptions, setTaxIdOptions] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  // Static data for Autocomplete options
  const countryList = ['India', 'USA', 'UK', 'Australia', 'Canada'];
  const currencyList = [
    { value: 'USD', label: 'USD - US Dollar' },
    { value: 'EUR', label: 'EUR - Euro' },
    { value: 'GBP', label: 'GBP - British Pound' },
    { value: 'INR', label: 'INR - Indian Rupee' },
    { value: 'JPY', label: 'JPY - Japanese Yen' },
    { value: 'AUD', label: 'AUD - Australian Dollar' },
  ];

  const taxIdOptionsData = {
    India: [
      { value: 'GST', label: 'GST - Goods and Services Tax' },
      { value: 'CIN', label: 'CIN - Corporate Identification Number' },
    ],
    USA: [
      { value: 'EIN', label: 'EIN - Employer Identification Number' },
      { value: 'TIN', label: 'TIN - Taxpayer Identification Number' },
    ],
    Default: [{ value: 'TAX', label: 'Tax ID' }],
  };

  // Initialize country and currency options
  useEffect(() => {
    setCountryOptions(countryList);
    setCurrencyOptions(currencyList);
  }, []);

  // Update Tax ID options whenever countryOfBilling changes
  useEffect(() => {
    fetchTaxIdOptions(userData.countryOfBilling);
  }, [userData.countryOfBilling]);

  // Function to set Tax ID options based on selected country
  const fetchTaxIdOptions = (country) => {
    setTaxIdOptions(taxIdOptionsData[country] || taxIdOptionsData['Default']);
  };

  // Handle input changes for TextField and Autocomplete
  const handleInputChange = (prop) => (event) => {
    const value = event.target.value;
    setUserData({ ...userData, [prop]: value });
    localStorage.setItem(prop, value);
  };
  
  

  // Function to handle updating user details
  const handleUpdateDetails = async () => {
    setLoading(true);
    const payload = {
      userId: userData.userId,
      firstName: userData.firstName,
      lastName: userData.lastName,
      userName: userData.userName,
      businessDetails: userData.businessDetails,
      businessName: userData.businessName,
      businessAddress: userData.businessAddress,
      currency: userData.currency,
      taxIdName: userData.taxIdName,
      taxIdValue: userData.taxIdValue,
      website: userData.website,
      country: userData.countryOfResidence,
      countryBilling: userData.countryOfBilling,
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_NFTVERSE_DEV_API}/user/profile/update`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": appCtx.authToken, // Assuming you have an auth token in context
          },
        }
      );

      // Update Redux state and localStorage with the new user details
      dispatch(appActions.updateUserDetails(response.data));
      Object.keys(payload).forEach((key) => {
        localStorage.setItem(key, response.data[key] || payload[key]);
      });

      setSnackbar({
        open: true,
        message: "User details updated successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error updating user details:", error);
      setSnackbar({
        open: true,
        message: "Error updating user details",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  // Function to handle account deletion
  const handleDeleteAccount = () => {
    setLoading(true);
    try {
      // Clear all user-related data from localStorage
      const keysToRemove = [
        'userId',
        'firstName',
        'lastName',
        'userName',
        'email',
        'businessDetails',
        'businessName',
        'businessAddress',
        'currency',
        'taxIdName',
        'taxIdValue',
        'website',
        'countryOfResidence',
        'countryOfBilling',
        'profilePhoto',
        'authToken', // Assuming you store authToken here
      ];
      keysToRemove.forEach((key) => localStorage.removeItem(key));

      // Clear Redux state
      dispatch(appActions.logout());

      setSnackbar({ open: true, message: 'Account deleted successfully', severity: 'success' });
      setShowDeleteModal(false);
      setDeleteConfirmationText('');

      // Navigate to login page
      navigate('/login');
      setLoading(false);
    } catch (error) {
      console.error('Error deleting account locally:', error);
      setSnackbar({ open: true, message: 'Error deleting account', severity: 'error' });
      setLoading(false);
    }
  };

  // Function to handle profile photo change
  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLoading(true);
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result.split(',')[1]; // Remove the data URL part
        const dataURL = `data:image/png;base64,${base64Data}`;
        try {
          // Update local state
          setUserData((prevState) => ({
            ...prevState,
            profilePhoto: dataURL,
          }));

          // Update localStorage
          localStorage.setItem('profilePhoto', dataURL);

          // Update Redux state if necessary
          dispatch(appActions.updateUserDetails({ ...userData, profilePhoto: dataURL }));

          setSnackbar({ open: true, message: 'Photo updated successfully', severity: 'success' });
          setLoading(false);
        } catch (error) {
          console.error('Error updating photo locally:', error);
          setSnackbar({ open: true, message: 'Error uploading photo', severity: 'error' });
          setLoading(false);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <MainWrapper>
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Card
          elevation={3}
          sx={{
            p: 3,
            m: 'auto',
            maxWidth: 900,
            borderRadius: 3,
            backgroundColor: theme.palette.background.grey, // Use theme's background
          }}
        >
          <CardContent>
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              sx={{ textAlign: 'center', fontWeight: 'bold' }}
            >
              Profile & Business Details
            </Typography>
            <Divider sx={{ mb: 3 }} />
            {loading && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                <CircularProgress />
              </Box>
            )}
            {/* Uncomment and adjust Avatar section if needed
            <Stack spacing={3} alignItems="center">
              <Avatar
                src={userData.profilePhoto}
                key={userData.profilePhoto}
                sx={{ width: 120, height: 120 }}
              />
              <Button variant="contained" component="label" color="primary">
                Change Photo
                <input type="file" hidden onChange={handlePhotoChange} accept="image/*" />
              </Button>
            </Stack>
            */}
            <Box sx={{ mt: 4 }}>
              <Grid container spacing={3}>
                {/* First Name */}
                <Grid item xs={12} md={6}>
                  <TextField
                    label="First Name"
                    fullWidth
                    variant="outlined"
                    value={userData.firstName}
                    onChange={handleInputChange('firstName')}
                    margin="normal"
                  />
                </Grid>
                {/* Last Name */}
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Last Name"
                    fullWidth
                    variant="outlined"
                    value={userData.lastName}
                    onChange={handleInputChange('lastName')}
                    margin="normal"
                  />
                </Grid>
                {/* Username */}
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Username"
                    fullWidth
                    variant="outlined"
                    value={userData.userName}
                    onChange={handleInputChange('userName')}
                    margin="normal"
                  />
                </Grid>
                {/* Email */}
                {/* Email */}
{/* Email */}
<Grid item xs={12} md={6}>
  <TextField
    label="Email"
    fullWidth
    variant="outlined"
    value={userData.email}
    disabled
    margin="normal"
    InputProps={{
      style: {
        color: '#aaaaaa', // Light grey text color
        backgroundColor: '#212121', // Dark grey background color
        borderRadius: '8px', // Rounded corners
      },
    }}
    InputLabelProps={{
      style: {
        color: '#aaaaaa', // Light grey label color
      },
    }}
    sx={{
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#aaaaaa', // Light grey border color
        },
        '&:hover fieldset': {
          borderColor: '#aaaaaa', // Light grey on hover (same as default for consistency)
        },
        '&.Mui-disabled fieldset': {
          borderColor: '#aaaaaa', // Light grey for disabled state
        },
      },
      '& .Mui-disabled': {
        WebkitTextFillColor: '#aaaaaa', // Light grey for disabled text
      },
    }}
  />
</Grid>



                {/* Country of Residence */}
                <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel id="country-residence-label">Country of Residence</InputLabel>
                  <Select
                    labelId="country-residence-label"
                    id="country-residence-select"
                    value={userData.countryOfResidence}
                    onChange={handleInputChange('countryOfResidence')}
                    label="Country of Residence"
                  >
                    {countryOptions.map((country) => (
                      <MenuItem key={country} value={country}>
                        {country}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>


                </Grid>
                {/* Country of Billing */}
                <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel id="country-billing-label">Country of Billing</InputLabel>
                  <Select
                    labelId="country-billing-label"
                    id="country-billing-select"
                    value={userData.countryOfBilling}
                    onChange={handleInputChange('countryOfBilling')}
                    label="Country of Billing"
                  >
                    {countryOptions.map((country) => (
                      <MenuItem key={country} value={country}>
                        {country}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>


                </Grid>
              </Grid>
              <Accordion variant="blackBox" sx={{ mt: 3 }}>
  <AccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ color: '#aaaaaa' }} />} // Light grey expand icon
    aria-controls="business-details-content"
    id="business-details-header"
  >
    <Typography variant="h6">Business Details</Typography>
  </AccordionSummary>
  <AccordionDetails>
    <Grid container spacing={3}>
      {/* Business Name */}
      <Grid item xs={12} md={6}>
        <TextField
          label="Business Name"
          fullWidth
          variant="outlined"
          value={userData.businessName}
          onChange={handleInputChange('businessName')}
          margin="normal"
          InputProps={{
            startAdornment: <BusinessIcon sx={{ mr: 1, color: '#aaaaaa' }} />, // Light grey icon
          }}
        />
      </Grid>
      {/* Business Address */}
      <Grid item xs={12} md={6}>
        <TextField
          label="Business Address"
          fullWidth
          variant="outlined"
          value={userData.businessAddress}
          onChange={handleInputChange('businessAddress')}
          margin="normal"
          InputProps={{
            startAdornment: <HomeIcon sx={{ mr: 1, color: '#aaaaaa' }} />, // Light grey icon
          }}
        />
      </Grid>
                    {/* Website */}
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Website"
                        fullWidth
                        variant="outlined"
                        value={userData.website}
                        onChange={handleInputChange('website')}
                        margin="normal"
                        InputProps={{
                          startAdornment: <LanguageIcon sx={{ mr: 1 }} />,
                        }}
                      />
                    </Grid>
                    {/* Tax ID Name */}
                    <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel id="tax-id-name-label">Tax ID Name</InputLabel>
                      <Select
                        labelId="tax-id-name-label"
                        id="tax-id-name-select"
                        value={userData.taxIdName}
                        onChange={handleInputChange('taxIdName')}
                        label="Tax ID Name"
                      >
                        {taxIdOptions.map((taxId) => (
                          <MenuItem key={taxId.value} value={taxId.value}>
                            {taxId.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>


                    </Grid>
                    {/* Tax ID Value */}
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Tax ID Value"
                        fullWidth
                        variant="outlined"
                        value={userData.taxIdValue}
                        onChange={handleInputChange('taxIdValue')}
                        margin="normal"
                        InputProps={{
                          startAdornment: <AccountBalanceIcon sx={{ mr: 1 }} />,
                        }}
                      />
                    </Grid>
                    {/* Business Details */}
                    <Grid item xs={12}>
                      <TextField
                        label="Business Details"
                        fullWidth
                        multiline
                        maxRows={4}
                        value={userData.businessDetails}
                        onChange={handleInputChange('businessDetails')}
                        margin="normal"
                      />
                    </Grid>
                    {/* Currency */}
                    <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel id="currency-label">Currency</InputLabel>
                      <Select
                        labelId="currency-label"
                        id="currency-select"
                        value={userData.currency}
                        onChange={handleInputChange('currency')}
                        label="Currency"
                      >
                        {currencyOptions.map((currency) => (
                          <MenuItem key={currency.value} value={currency.value}>
                            {currency.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>


                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
            {/* Action Buttons */}
            <Box
              sx={{
                mt: 4,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Button
                startIcon={<DeleteOutlineIcon />}
                variant="custom" // Use custom variant from theme
                color="error" // Utilize theme's error color
                onClick={() => setShowDeleteModal(true)}
              >
                Delete Account
              </Button>
              <Button
                startIcon={<BusinessCenterIcon />}
                variant="actionButton" // Use actionButton variant from theme
                onClick={handleUpdateDetails}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Update Details'}
              </Button>
            </Box>
          </CardContent>
        </Card>

        {/* Delete Confirmation Modal */}
        <Modal
          open={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
            setDeleteConfirmationText('');
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: theme.palette.background.grey, // Use theme's background
              boxShadow: 24,
              p: 4,
              borderRadius: 3,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Confirm Account Deletion
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to delete your account? This action is irreversible.
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Please type <strong>DELETE</strong> to confirm:
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              value={deleteConfirmationText}
              onChange={(e) => setDeleteConfirmationText(e.target.value)}
            />
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={() => {
                  setShowDeleteModal(false);
                  setDeleteConfirmationText('');
                }}
                sx={{ mr: 2 }}
                variant="transparent" // Use transparent variant from theme
              >
                Cancel
              </Button>
              <Button
                onClick={handleDeleteAccount}
                variant="contained" // Uses the overridden 'contained' variant (orange)
                color="error" // Utilize theme's error color if needed
                disabled={deleteConfirmationText !== 'DELETE' || loading}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Modal>

        {/* Snackbar for Notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </MainWrapper>
  );
};

export default UserProfile;
