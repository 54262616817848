// DGUPlayground.jsx
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Card,
  IconButton,
  Fade,
  Chip,
  Tooltip,
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
  Tabs,
  Tab,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Container,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import {
  Download as DownloadIcon,
  Visibility as VisibilityIcon,
  PictureAsPdf as PdfIcon,
  Send as SendIcon,
  Delete as DeleteIcon,
  ContentCopy as CopyIcon,
  ClearAll as ClearAllIcon,
  Remove as RemoveIcon,
} from '@mui/icons-material';
import MainWrapper from '../wrapper/MainWrapper';
import { ProtocolList } from '../ProtocolList';
import { TransitionGroup } from 'react-transition-group';
import { styled, keyframes } from '@mui/system';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import SupportModal from '../supportModal/SupportModal';

// -------------------------------------------
// Configuration Variables
// -------------------------------------------
const TYPING_SPEED = 30; // Adjust this to slow down or speed up the agent's typing

// -------------------------------------------
// Keyframe Animations
// -------------------------------------------

// Background gradient animation
const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

// Fade-in-out animation for the processing image
const fadeInOut = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.4; }
`;

// Splash animation for send button click
const splashAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
`;

// -------------------------------------------
// Styled Components
// -------------------------------------------

// Full-screen animated background
const BackgroundContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  background: theme.palette.background.default,
  backgroundSize: '400% 400%',
  zIndex: -1,
  animation: `${gradientAnimation} 15s ease infinite`,
}));

// Centered container with a max-width
const CenteredContainer = styled(Container)(({ theme }) => ({
  maxWidth: '1200px',
  // We remove any fixed height constraints, so the entire page can scroll
  margin: '0 auto',
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

// Message container
const MessageContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'role',
})(({ theme, role }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: role === 'user' ? 'flex-end' : 'flex-start',
  marginBottom: theme.spacing(2.5),
}));

// Message bubble
const MessageBubble = styled(Box)(({ theme, role }) => ({
  backgroundColor: 'transparent',
  color: role === 'user' ? '#e36c04' : '#e0e0e0',
  border: role === 'user' ? '0.5px solid #e36c04' : '0.5px solid #e0e0e0',
  borderRadius: '16px',
  padding: '12px',
  maxWidth: '70%',
  wordWrap: 'break-word',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
  transition: 'box-shadow 0.3s ease, transform 0.3s ease',
  '&:hover': {
    boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.5)',
    transform: 'scale(1.02)',
  },
  '& .MuiTypography-root': {
    color: 'inherit'
  }
}));

// Timestamp styling
const Timestamp = styled(Typography)(({ theme }) => ({
  fontSize: '0.625rem',
  opacity: 0.7,
  marginTop: theme.spacing(0.5),
}));

// Role label (User/Agent)
const RoleLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: 'bold',
  marginBottom: theme.spacing(0.5),
  opacity: 0.9,
  color: theme.palette.text.secondary,
}));

// Data point chips
const TagChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  transition: 'background-color 0.3s ease, transform 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    transform: 'scale(1.05)',
  },
}));

// Button row for actions like view/download
const ButtonRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  marginTop: theme.spacing(1),
  flexWrap: 'wrap',
  padding: theme.spacing(1),
  justifyContent: 'center',
}));

// JSON payload input wrapper
const JSONInputWrapper = styled(Box)(({ theme, isDragging }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  background: isDragging ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.7)',
  boxShadow: theme.shadows[4],
  width: '100%',
  margin: '0 auto',
  border: isDragging ? '2px dashed #42d392' : 'none',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },
}));

// Text area style
const JSONTextField = styled(TextField)(({ theme }) => ({
  flex: 1,
  backgroundColor: 'transparent',
  borderRadius: theme.shape.borderRadius,
  overflowY: 'auto', // Enables vertical scrolling
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.grey[400],
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.dark,
    },
  },
  '& .MuiOutlinedInput-input': {
    resize: 'none', // Disables resizing
    fontFamily: 'Monaco, Menlo, Consolas, "Courier New", monospace',
    fontSize: '0.875rem',
    lineHeight: 1.5,
    padding: theme.spacing(1),
  },
}));

// Send button (round with splash and hover animation)
const SendButton = styled(IconButton)(({ theme }) => ({
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.primary.contrastText,
  position: 'relative',
  overflow: 'hidden',
  transition: theme.transitions.create(['transform', 'box-shadow', 'background'], {
    duration: 300,
    easing: 'ease',
  }),
  '&:hover': {
    background: 'linear-gradient(135deg, #0288d1, #42d392)',
    transform: 'scale(1.1)',
    boxShadow: theme.shadows[4],
  },
  '&:active': {
    animation: `${splashAnimation} 0.6s ease-out`,
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
    boxShadow: 'none',
    transform: 'none',
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100px',
    height: '100px',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    borderRadius: '50%',
    transform: 'translate(-50%, -50%) scale(0)',
    opacity: 0,
    transition: theme.transitions.create(['transform', 'opacity'], {
      duration: 500,
      easing: 'ease-out',
    }),
  },
  '&:active:after': {
    transform: 'translate(-50%, -50%) scale(4)',
    opacity: 1,
  },
}));

// Processing image (for synthesis loader)
const ProcessingImage = styled('img')(({ theme }) => ({
  width: 180,
  height: 120,
  borderRadius: theme.shape.borderRadius,
  animation: `${fadeInOut} 2s infinite`,
  objectFit: 'cover',
}));

// JSON File Preview
const JSONFilePreview = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  backgroundColor: '#f5f5f5',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  width: '100%',
}));

// Clear Chat Wrapper
const ClearChatWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(1),
}));

// -------------------------------------------
// Component
// -------------------------------------------

const DGUPlayground = () => {
  const { model: modelFromParams } = useParams();
  const theme = useTheme();

  // For Support Modal
  const [openSupportModal, setOpenSupportModal] = useState(false);

  // Tab state
  const [activeTab, setActiveTab] = useState('chat');

  // States
  const [model, setModel] = useState(modelFromParams || '');
  const [selectedModel, setSelectedModel] = useState(null);
  const [apiResponse, setApiResponse] = useState(null);
  const [dataPoints, setDataPoints] = useState([]);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [userPayloadText, setUserPayloadText] = useState('');
  const [uploadedJSON, setUploadedJSON] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Clear chat confirmation dialog
  const [clearChatDialogOpen, setClearChatDialogOpen] = useState(false);

  const messagesEndRef = useRef(null);
  const conversationRef = useRef(null);

  // API details
  const apiUrl = 'https://lbvm.vortx.ai/tile/tileformer';
  const apiKey = process.env.REACT_APP_FOOD_KEY; // If needed

  // Find model from param
  useEffect(() => {
    const foundModel = ProtocolList.find((protocol) => protocol.model === model);
    setSelectedModel(foundModel);
  }, [model]);

  // Scroll to bottom helper
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Typing simulation for agent messages
  const typeAgentMessage = async (content) => {
    return new Promise((resolve) => {
      const newMessage = {
        role: 'agent',
        contentType: 'text',
        content: '',
        timestamp: new Date(),
      };
      setMessages((prev) => [...prev, newMessage]);

      let currentIndex = 0;
      const interval = setInterval(() => {
        currentIndex++;
        setMessages((prev) => {
          const updated = [...prev];
          updated[updated.length - 1].content = content.slice(0, currentIndex);
          return updated;
        });
        if (currentIndex === content.length) {
          clearInterval(interval);
          resolve();
        }
      }, TYPING_SPEED);
    });
  };

  // Generic message adder
  const addMessage = async (role, contentType, content) => {
    if (role === 'agent' && contentType === 'text') {
      await typeAgentMessage(content);
    } else {
      const newMessage = {
        role,
        contentType,
        content,
        timestamp: new Date(),
      };
      setMessages((prev) => [...prev, newMessage]);
    }
  };

  // Initial welcome messages
  useEffect(() => {
    (async () => {
      await addMessage(
        'agent',
        'text',
        "Hello there! I am your data synthesis companion. You can upload a JSON payload file or enter JSON text to begin."
      );
    })();
    // eslint-disable-next-line
  }, []);

  // Helper to detect media type from URL
  const detectMediaType = (url) => {
    const lowerUrl = url.toLowerCase();
    if (/\.(jpg|jpeg|png|gif|webp|svg)$/i.test(lowerUrl)) {
      return 'image';
    }
    if (/\.(mp4|webm|ogg)$/i.test(lowerUrl)) {
      return 'video';
    }
    if (/\.(mp3|wav|ogg)$/i.test(lowerUrl)) {
      return 'audio';
    }
    return 'unknown';
  };

  // Parse API response into chat messages
  const parseApiResponse = async (apiData) => {
    // Show raw JSON
    const prettyJSON = JSON.stringify(apiData, null, 2);
    await addMessage('agent', 'text', "Here's the raw response from the API:");
    await addMessage('agent', 'json', prettyJSON);

    // data_points as chips
    if (apiData.data_points && Array.isArray(apiData.data_points)) {
      await addMessage('agent', 'text', 'Data points found:');
      setMessages((prev) => [
        ...prev,
        {
          role: 'agent',
          contentType: 'dataPoints',
          content: apiData.data_points,
          timestamp: new Date(),
        },
      ]);
      setDataPoints(apiData.data_points);
    }

    // Check for _url or base64 images
    for (const [key, value] of Object.entries(apiData)) {
      if (key.endsWith('_url') && typeof value === 'string') {
        const mediaType = detectMediaType(value);
        if (mediaType === 'image') {
          await addMessage('agent', 'text', `Detected an image: ${key}`);
          setMessages((prev) => [
            ...prev,
            {
              role: 'agent',
              contentType: 'image',
              content: value,
              timestamp: new Date(),
            },
          ]);
        } else if (mediaType === 'video') {
          await addMessage('agent', 'text', `Detected a video: ${key}`);
          setMessages((prev) => [
            ...prev,
            {
              role: 'agent',
              contentType: 'video',
              content: value,
              timestamp: new Date(),
            },
          ]);
        } else if (mediaType === 'audio') {
          await addMessage('agent', 'text', `Detected an audio file: ${key}`);
          setMessages((prev) => [
            ...prev,
            {
              role: 'agent',
              contentType: 'audio',
              content: value,
              timestamp: new Date(),
            },
          ]);
        }
      } else if (typeof value === 'string') {
        // base64 images
        const base64Prefixes = ['data:image/png;base64,', 'data:image/jpeg;base64,'];
        if (base64Prefixes.some((prefix) => value.startsWith(prefix))) {
          await addMessage('agent', 'text', `Detected a base64 image: ${key}`);
          setMessages((prev) => [
            ...prev,
            {
              role: 'agent',
              contentType: 'image',
              content: value,
              timestamp: new Date(),
            },
          ]);
        }
      }
    }

    // PDF prompt
    await addMessage('agent', 'pdf', 'You may download the entire conversation as a PDF.');
  };

  // Tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Synthesize
  const handleSynthesize = async (payload) => {
    setLoading(true);
    setErrorMessage('');
    const startTime = performance.now();

    try {
      await addMessage('agent', 'text', 'Got it! Let me process your request...');
      const thinkingMessage = {
        role: 'agent',
        contentType: 'loader',
        content: 'Thinking...',
        timestamp: new Date(),
      };
      setMessages((prev) => [...prev, thinkingMessage]);

      const response = await axios.post(apiUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
          Accept: '*/*',
        },
        responseType: 'blob',
      });

      // remove loader
      setMessages((prev) => prev.filter((m) => !(m.role === 'agent' && m.contentType === 'loader')));

      const contentType = response.headers['content-type'] || '';
      const endTime = performance.now();
      const durationSeconds = ((endTime - startTime) / 1000).toFixed(2);

      await addMessage(
        'agent',
        'text',
        `The request took ${durationSeconds} seconds to complete.`
      );

      if (contentType.includes('application/json')) {
        // JSON response
        const reader = new FileReader();
        reader.onload = async (e) => {
          try {
            const apiData = JSON.parse(e.target.result);
            await parseApiResponse(apiData);
          } catch (err) {
            console.error('Error parsing JSON:', err);
            throw new Error('Unable to parse JSON response from the API.');
          }
        };
        reader.readAsText(response.data);
      } else if (contentType.startsWith('image/')) {
        // Image response
        const imageUrl = URL.createObjectURL(response.data);
        await addMessage('agent', 'text', 'Here is the synthesized image:');
        setMessages((prev) => [
          ...prev,
          { role: 'agent', contentType: 'image', content: imageUrl, timestamp: new Date() },
        ]);
        setApiResponse(imageUrl);
        // PDF prompt
        await addMessage('agent', 'pdf', 'You may download the entire conversation as a PDF.');
      } else {
        throw new Error(`Unrecognized response type: ${contentType}`);
      }
    } catch (error) {
      const errorMsg = error.message || 'Something went wrong. Please try again.';
      setErrorMessage(errorMsg);
      console.error('API Error:', error);
      // remove loader
      setMessages((prev) => prev.filter((m) => !(m.role === 'agent' && m.contentType === 'loader')));
      // Add error
      await addMessage('agent', 'text', errorMsg);
    } finally {
      setLoading(false);
    }
  };

  // Send button
  const handleSend = async () => {
    const text = userPayloadText.trim();
    if (!text) return;

    // User's JSON text
    await addMessage('user', 'text', text);

    let payload;
    try {
      payload = JSON.parse(text);
    } catch (e) {
      addMessage('agent', 'text', 'Invalid JSON. Please check your payload and try again.');
      return;
    }

    handleSynthesize(payload);
    setUserPayloadText('');
  };

  // Clear chat
  const handleOpenClearChatDialog = () => setClearChatDialogOpen(true);
  const handleCloseClearChatDialog = () => setClearChatDialogOpen(false);
  const handleConfirmClearChat = () => {
    setMessages([]);
    setUploadedJSON(null);
    setUserPayloadText('');
    setClearChatDialogOpen(false);
  };

  // PDF download
  const handleDownloadPdf = async () => {
    if (!conversationRef.current) return;
    const canvas = await html2canvas(conversationRef.current, { scale: 2 });
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'pt', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imageProps = pdf.getImageProperties(imgData);
    const ratio = imageProps.width / imageProps.height;
    let w = pdfWidth - 40;
    let h = (pdfWidth - 40) / ratio;
    if (h > pdfHeight - 40) {
      h = pdfHeight - 40;
      w = h * ratio;
    }
    pdf.addImage(imgData, 'PNG', 20, 20, w, h);
    pdf.save('conversation.pdf');
  };

  // Timestamp
  const formatTime = (timestamp) =>
    new Intl.DateTimeFormat('default', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }).format(timestamp);

  // Drag & Drop
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };
  const handleDragLeave = () => setIsDragging(false);
  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file && file.type === 'application/json') {
      setUploadedJSON(file);
      setSuccessMessage(`Uploaded: ${file.name}`);
      const reader = new FileReader();
      reader.onload = (ev) => {
        setUserPayloadText(ev.target.result);
      };
      reader.readAsText(file);
    } else {
      setErrorMessage('Please drop a valid JSON file.');
    }
  };

  // Copy JSON
  const handleCopyJSON = (jsonContent) => {
    navigator.clipboard.writeText(jsonContent).then(() => {
      setSuccessMessage('JSON copied to clipboard!');
    });
  };

  // Disable send if no JSON or text
  const sendDisabled = (!uploadedJSON && userPayloadText.trim() === '') || loading;

  // Custom scrollbar
  const customScroll = {
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.primary.dark,
    },
  };

  return (
    <MainWrapper>
      {/* Animated Background */}
      <BackgroundContainer />

      <CenteredContainer>
        {/* Header */}
        <Paper
          variant="blackBox"
          sx={{
            width: '100%',
            mb: 2,
            p: 2,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            boxShadow: '0px 4px 8px rgba(0,0,0,0.2)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'space-between',
              alignItems: { xs: 'flex-start', md: 'center' },
              gap: 2,
            }}
          >
            <Box>
              <Typography variant="h4">Tileformer</Typography>
              <Typography variant="subtitle1" sx={{ mt: 1 }}>
                Upload JSON payloads to visualize terrain in 3D and track historical
                changes for better spatial analysis and insights.
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
                <Chip variant="outlined" label="Tiled image" />
                <Chip variant="outlined" label="GPU acceleration" />
              </Box>
            </Box>

            {/* "Contact for Deployment" button that opens Support Modal */}
            <Button
              variant="contained"
              color="secondary"
              sx={{ padding: '8px 24px', textTransform: 'none' }}
              onClick={() => setOpenSupportModal(true)}
            >
              Contact for Deployment
            </Button>
          </Box>
        </Paper>

        {/* Support Modal */}
        <SupportModal
          openModal={openSupportModal}
          setOpenModal={setOpenSupportModal}
        />

        {/* Content */}
        <Paper
          variant="blackBox"
          sx={{
            width: '100%',
            p: 2,
            backgroundColor: 'rgba(0,0,0,0.7)',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {/* Tabs */}
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}
            indicatorColor="secondary"
            textColor="inherit"
          >
            <Tab value="chat" label="Chat" />
            <Tab value="apiReference" label="API Reference" />
          </Tabs>

          {/* Chat Tab */}
          {activeTab === 'chat' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                // No fixed height -> entire page scrolls
                transition: 'all 0.3s ease',
                ...customScroll,
                backgroundColor: 'transparent',
                backdropFilter: 'blur(5px)',
              }}
            >
              {/* Clear Chat */}
              <ClearChatWrapper>
                <Tooltip title="Clear Chat">
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<ClearAllIcon />}
                    onClick={handleOpenClearChatDialog}
                  >
                    Clear
                  </Button>
                </Tooltip>
              </ClearChatWrapper>

              <Divider
                sx={{
                  my: 2,
                  borderColor: 'rgba(255, 255, 255, 0.1)',
                }}
              />

              {/* Conversation */}
              <Box sx={{ width: '100%', overflow: 'auto' }} ref={conversationRef}>
                {messages.length > 0 ? (
                  <TransitionGroup>
                    {messages.map((msg, index) => (
                      <Fade key={index} in timeout={500}>
                        <MessageContainer role={msg.role}>
                          <RoleLabel variant="caption">
                            {msg.role === 'user' ? 'You' : 'Agent'}
                          </RoleLabel>
                          <MessageBubble role={msg.role}>
                            {/* Text */}
                            {msg.contentType === 'text' && (
                              <Typography
                                variant="body1"
                                sx={{ whiteSpace: 'pre-wrap', lineHeight: 1.5 }}
                              >
                                {msg.content}
                              </Typography>
                            )}

                            {/* JSON File */}
                            {msg.contentType === 'jsonFile' && (
                              <JSONFilePreview>
                                <Typography variant="body2">{msg.content}</Typography>
                                <Tooltip title="Download JSON">
                                  <IconButton
                                    onClick={() => {
                                      const link = document.createElement('a');
                                      link.href = URL.createObjectURL(
                                        new Blob([msg.content], {
                                          type: 'application/json',
                                        })
                                      );
                                      link.download = msg.content;
                                      document.body.appendChild(link);
                                      link.click();
                                      document.body.removeChild(link);
                                    }}
                                  >
                                    <DownloadIcon />
                                  </IconButton>
                                </Tooltip>
                              </JSONFilePreview>
                            )}

                            {/* Data Points */}
                            {msg.contentType === 'dataPoints' && (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1 }}>
                                {msg.content.map((point, i) => (
                                  <Tooltip title="Data Point" key={i}>
                                    <TagChip label={point} />
                                  </Tooltip>
                                ))}
                              </Box>
                            )}

                            {/* Image / Video / Audio */}
                            {msg.contentType === 'image' && (
                              <Card
                                sx={{
                                  mt: 2,
                                  borderRadius: 2,
                                  overflow: 'hidden',
                                  boxShadow: theme.shadows[1],
                                  transition: theme.transitions.create(
                                    ['transform', 'box-shadow'],
                                    {
                                      duration: 300,
                                      easing: 'ease',
                                    }
                                  ),
                                  '&:hover': {
                                    transform: 'scale(1.02)',
                                    boxShadow: theme.shadows[3],
                                  },
                                }}
                              >
                                <img
                                  src={msg.content}
                                  alt="Synthesized"
                                  width="100%"
                                  style={{ objectFit: 'cover' }}
                                />
                                {msg.role === 'agent' && (
                                  <ButtonRow>
                                    <Tooltip title="View Full Image">
                                      <Button
                                        startIcon={<VisibilityIcon />}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => window.open(msg.content, '_blank')}
                                      >
                                        View
                                      </Button>
                                    </Tooltip>
                                    <Tooltip title="Download Image">
                                      <Button
                                        startIcon={<DownloadIcon />}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                          const link = document.createElement('a');
                                          link.href = msg.content;
                                          link.setAttribute('download', 'synthesized_image.jpg');
                                          document.body.appendChild(link);
                                          link.click();
                                          document.body.removeChild(link);
                                        }}
                                      >
                                        Download
                                      </Button>
                                    </Tooltip>
                                  </ButtonRow>
                                )}
                              </Card>
                            )}
                            {msg.contentType === 'video' && (
                              <Typography sx={{ mt: 1 }}>
                                Video detected. (No preview here.)
                              </Typography>
                            )}
                            {msg.contentType === 'audio' && (
                              <Typography sx={{ mt: 1 }}>
                                Audio detected. (No preview here.)
                              </Typography>
                            )}

                            {/* Loader */}
                            {msg.contentType === 'loader' && (
                              <Box sx={{ width: 180, height: 120, position: 'relative' }}>
                                <ProcessingImage
                                  src="/path-to-your-loader-image.gif"
                                  alt="Processing"
                                />
                              </Box>
                            )}

                            {/* PDF */}
                            {msg.contentType === 'pdf' && (
                              <Box>
                                <Typography variant="body2" sx={{ mb: 1 }}>
                                  {msg.content}
                                </Typography>
                                <ButtonRow>
                                  <Tooltip title="Download Conversation as PDF">
                                    <Button
                                      startIcon={<PdfIcon />}
                                      variant="contained"
                                      color="secondary"
                                      onClick={handleDownloadPdf}
                                    >
                                      Download PDF
                                    </Button>
                                  </Tooltip>
                                </ButtonRow>
                              </Box>
                            )}

                            {/* JSON (expandable/copyable) */}
                            {msg.contentType === 'json' && (
                              <Box
                                sx={{
                                  mt: 2,
                                  p: 2,
                                  backgroundColor: '#f5f5f5',
                                  borderRadius: '8px',
                                  position: 'relative',
                                }}
                              >
                                <Tooltip title="Copy JSON">
                                  <IconButton
                                    size="small"
                                    sx={{ position: 'absolute', top: 4, right: 4 }}
                                    onClick={() => handleCopyJSON(msg.content)}
                                  >
                                    <CopyIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                                <Typography
                                  variant="body2"
                                  component="pre"
                                  sx={{
                                    whiteSpace: 'pre-wrap',
                                    fontFamily: 'monospace',
                                    overflowX: 'auto',
                                    maxHeight: 300,
                                  }}
                                >
                                  {msg.content}
                                </Typography>
                              </Box>
                            )}
                          </MessageBubble>
                          <Timestamp variant="caption">{formatTime(msg.timestamp)}</Timestamp>
                        </MessageContainer>
                      </Fade>
                    ))}
                  </TransitionGroup>
                ) : (
                  <Typography
                    variant="body2"
                    sx={{ textAlign: 'center', color: theme.palette.text.secondary, mt: 4 }}
                  >
                    No messages yet. Start the conversation!
                  </Typography>
                )}
                <div ref={messagesEndRef} />
              </Box>

              {/* JSON Payload Input */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 2,
                  borderTop: `1px solid rgba(255, 255, 255, 0.1)`,
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  mt: 2,
                }}
              >
                <JSONInputWrapper
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                  isDragging={isDragging}
                  aria-label="JSON Payload Input Area"
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#fff' }}>
                      JSON Payload
                    </Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'wrap' }}>
                      <Button
                        variant="outlined"
                        component="label"
                        sx={{
                          borderColor: '#e36c09',
                          color: '#e36c09',
                          '&:hover': {
                            borderColor: '#cc5e08',
                            backgroundColor: 'rgba(227, 108, 9, 0.1)',
                          },
                        }}
                      >
                        Upload JSON File
                        <input
                          type="file"
                          accept="application/json"
                          hidden
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file && file.type === 'application/json') {
                              setUploadedJSON(file);
                              setSuccessMessage(`Uploaded: ${file.name}`);
                              const reader = new FileReader();
                              reader.onload = (ev) => {
                                setUserPayloadText(ev.target.result);
                              };
                              reader.readAsText(file);
                            } else {
                              setErrorMessage('Please select a valid JSON file.');
                            }
                            e.target.value = '';
                          }}
                        />
                      </Button>

                      {uploadedJSON && (
                        <JSONFilePreview>
                          <Typography variant="body2">{uploadedJSON.name}</Typography>
                          <Tooltip title="Remove File">
                            <IconButton onClick={() => setUploadedJSON(null)}>
                              <DeleteIcon color="error" />
                            </IconButton>
                          </Tooltip>
                        </JSONFilePreview>
                      )}
                    </Box>

                    <JSONTextField
                      id="json-input"
                      multiline
                      minRows={6} // Adjust as needed
                      maxRows={12}
                      variant="outlined"
                      placeholder={`{
  "image_url_b04": "https://Your_band_4_tif.tif",
  "image_url_b08": "https://Your_band_8_tif.tif",
  "minx": 781025.1,
  "miny": 1437575.95027367,
  "maxx": 788035.13786,
  "maxy": 1445575.9502,
  "tile_size": 256,
  "algorithm": "transformer"
}`}
                      value={userPayloadText}
                      onChange={(e) => setUserPayloadText(e.target.value)}
                      fullWidth
                    />
                  </Box>

                  <SendButton onClick={handleSend} disabled={sendDisabled} aria-label="Send JSON">
                    {loading ? <CircularProgress size={24} color="inherit" /> : <SendIcon />}
                  </SendButton>
                </JSONInputWrapper>
              </Box>
            </Box>
          )}

          {/* API Reference Tab */}
          {activeTab === 'apiReference' && (
            <Box sx={{ p: 2 }}>
              
              {/* API Endpoint */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
                <Chip variant="outlined"
                  label="POST" 
                  
                />
                <Typography variant="t1">
                  https://lbvm.vortx.ai/plant/flow
                </Typography>
              </Box>

              {/* Headers */}
              <Box sx={{ mb: 4 }}>
                <Typography variant="t2_grey" sx={{ mb: 2 }}>Headers</Typography>
                
                <Paper 
                  variant="blackBox" 
                  sx={{ 
                    backgroundColor: theme.palette.background.black,
                    border: `1px solid ${theme.palette.background.grey}`
                  }}
                >
                  {/* Content-Type Header */}
                  <Box 
                    sx={{ 
                      p: 2,
                      display: 'flex',
                      alignItems: 'center',
                      borderBottom: `1px solid ${theme.palette.background.grey}`,
                      '&:hover': {
                        backgroundColor: 'rgba(255,255,255,0.03)'
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Typography variant="t1">Content-Type</Typography>
                      <Typography variant="t2_grey">multipart/form-data</Typography>
                    </Box>
                  </Box>

                  {/* Secret-Key Header */}
                  <Box 
                    sx={{ 
                      p: 2,
                      display: 'flex',
                      alignItems: 'center',
                      '&:hover': {
                        backgroundColor: 'rgba(255,255,255,0.03)'
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Typography variant="t1">Secret-Key</Typography>
                      <Typography variant="t2_grey">[Your Secret Key]</Typography>
                      <Chip variant="filled"
                        label="required" 
                        size="small"
                        
                      />
                    </Box>
                  </Box>
                </Paper>
              </Box>

              {/* Body Parameters */}
              <Box sx={{ mb: 4 }}>
                <Typography variant="t2_grey" sx={{ mb: 2 }}>Body Params</Typography>
                
                <Paper 
                  variant="blackBox" 
                  sx={{ 
                    backgroundColor: theme.palette.background.black,
                    border: `1px solid ${theme.palette.background.grey}`
                  }}
                >
                  {/* Image Parameter */}
                  <Box 
                    sx={{ 
                      p: 2,
                      display: 'flex',
                      alignItems: 'center',
                      '&:hover': {
                        backgroundColor: 'rgba(255,255,255,0.03)'
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Typography variant="t1">image</Typography>
                      <Typography variant="t2_grey">file</Typography>
                      <Chip variant="filled"
                        label="required" 
                        size="small"
                        
                      />
                    </Box>
                  </Box>
                </Paper>
              </Box>

              {/* After Body Parameters section */}
              {/* Responses */}
              <Box sx={{ mb: 4 }}>
                <Typography variant="t2_grey" sx={{ mb: 2 }}>Responses</Typography>
                
                <Paper 
                  variant="blackBox" 
                  sx={{ 
                    backgroundColor: theme.palette.background.black,
                    border: `1px solid ${theme.palette.background.grey}`
                  }}
                >
                  {/* 200 Success */}
                  <Box 
                    sx={{ 
                      p: 2,
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      borderBottom: `1px solid ${theme.palette.background.grey}`,
                      '&:hover': {
                        backgroundColor: 'rgba(255,255,255,0.03)'
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Box 
                        sx={{ 
                          width: 8,
                          height: 8,
                          borderRadius: '50%',
                          backgroundColor: theme.palette.success.main 
                        }} 
                      />
                      <Typography variant="t1">200</Typography>
                      <Typography variant="t2_grey">Successful Response</Typography>
                    </Box>
                  </Box>

                  {/* 422 Validation Error */}
                  <Box 
                    sx={{ 
                      p: 2,
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      borderBottom: `1px solid ${theme.palette.background.grey}`,
                      '&:hover': {
                        backgroundColor: 'rgba(255,255,255,0.03)'
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Box 
                        sx={{ 
                          width: 8,
                          height: 8,
                          borderRadius: '50%',
                          backgroundColor: theme.palette.error.main 
                        }} 
                      />
                      <Typography variant="t1">400</Typography>
                      <Typography variant="t2_grey">Bad Request</Typography>
                    </Box>
                  </Box>

                  {/* 401 Unauthorized */}
                  <Box 
                    sx={{ 
                      p: 2,
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      borderBottom: `1px solid ${theme.palette.background.grey}`,
                      '&:hover': {
                        backgroundColor: 'rgba(255,255,255,0.03)'
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Box 
                        sx={{ 
                          width: 8,
                          height: 8,
                          borderRadius: '50%',
                          backgroundColor: theme.palette.error.main 
                        }} 
                      />
                      <Typography variant="t1">401</Typography>
                      <Typography variant="t2_grey">Unauthorized</Typography>
                    </Box>
                  </Box>

                  {/* 500 Internal Server Error */}
                  <Box 
                    sx={{ 
                      p: 2,
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      borderBottom: `1px solid ${theme.palette.background.grey}`,
                      '&:hover': {
                        backgroundColor: 'rgba(255,255,255,0.03)'
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Box 
                        sx={{ 
                          width: 8,
                          height: 8,
                          borderRadius: '50%',
                          backgroundColor: theme.palette.error.main 
                        }} 
                      />
                      <Typography variant="t1">500</Typography>
                      <Typography variant="t2_grey">Internal Server Error</Typography>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>
          )}
        </Paper>
      </CenteredContainer>

      {/* Clear Chat Confirmation Dialog */}
      <Dialog open={clearChatDialogOpen} onClose={handleCloseClearChatDialog}>
        <DialogTitle>Clear Chat</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to clear the conversation?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClearChatDialog}>Cancel</Button>
          <Button onClick={handleConfirmClearChat} color="error">
            Clear
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Success and Error */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={4000}
        onClose={() => setSuccessMessage('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </MainWrapper>
  );
};

export default DGUPlayground;
