import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  Button,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Snackbar,
  Tooltip,
} from '@mui/material';
import {
  Add as AddIcon,
  CloudUpload as CloudUploadIcon,
  Visibility as VisibilityIcon,
  Delete as DeleteIcon,
  CloudDownload as CloudDownloadIcon,
  Close as CloseIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import SatelliteIcon from '@mui/icons-material/Satellite';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ArchiveIcon from '@mui/icons-material/Archive';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import TableChartIcon from '@mui/icons-material/TableChart';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import ArchitectureIcon from '@mui/icons-material/Architecture';

import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  '&:hover': {
    boxShadow: theme.shadows[6],
    transition: 'box-shadow 0.3s',
  },
}));

const artifactTypes = [
  { value: 'satellite_image', label: 'Satellite Image', icon: <SatelliteIcon /> },
  { value: 'drone_image', label: 'Drone Image', icon: <AirplanemodeActiveIcon /> },
  { value: 'mobile_image', label: 'Mobile Image', icon: <PhotoCameraIcon /> },
  { value: 'zip', label: 'ZIP Archive', icon: <ArchiveIcon /> },
  { value: 'pdf', label: 'PDF', icon: <PictureAsPdfIcon /> },
  { value: 'word_document', label: 'Word Document', icon: <DescriptionIcon /> },
  { value: 'excel_file', label: 'Excel File', icon: <TableChartIcon /> },
  { value: 'csv', label: 'CSV File', icon: <InsertDriveFileIcon /> },
  { value: 'video_cctv', label: 'CCTV Video', icon: <VideocamIcon /> },
  { value: 'video_mobile', label: 'Mobile Video', icon: <VideoLibraryIcon /> },
  { value: 'design_file', label: 'Design File (CAD, 3D)', icon: <ArchitectureIcon /> },
  { value: 'other', label: 'Other', icon: <InsertDriveFileIcon /> },
];

const authToken = localStorage.getItem("authToken");

const ArtifactsTab = () => {
  const { projectId: projectIdParam } = useParams();
  const projectId = Number(projectIdParam);

  const [artifacts, setArtifacts] = useState([]);
  const [artifactSearchQuery, setArtifactSearchQuery] = useState('');
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [currentArtifact, setCurrentArtifact] = useState(null);
  const [newArtifact, setNewArtifact] = useState({
    projectId: projectId,
    size: 0,
    timestamp: new Date().toISOString(),
    url: '',
    type: '',
    description: '',
    name: '',
  });
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    const fetchArtifacts = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://backend.vortx.ai/${projectId}/artifacts`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': authToken,
          },
        });
        const data = await response.json();
        setArtifacts(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error('Error fetching artifacts:', error);
        setSnackbarMessage('Error fetching artifacts.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };
    if (projectId) {
      fetchArtifacts();
    }
  }, [projectId]);

  const handleAddArtifact = () => {
    setUploadDialogOpen(true);
    setNewArtifact({
      projectId: projectId,
      size: 0,
      timestamp: new Date().toISOString(),
      url: '',
      type: '',
      description: '',
      name: '',
    });
  };

  const handleFileUpload = async (file) => {
    setUploading(true);
    setUploadSuccess(null);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch('https://us-central1-peak-sorter-432307-p8.cloudfunctions.net/file_upload', {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      if (data && data.url) {
        setNewArtifact((prev) => ({ ...prev, url: data.url }));
        setUploadSuccess(true);
      } else {
        throw new Error('File upload did not return a URL');
      }
    } catch (error) {
      console.error('File upload error:', error);
      setUploadSuccess(false);
    } finally {
      setUploading(false);
    }
  };

  const handleSaveArtifact = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://backend.vortx.ai/${projectId}/artifacts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': authToken,
        },
        body: JSON.stringify(newArtifact),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to save artifact.');
      }

      const saveResponse = await response.json();
      setArtifacts((prevArtifacts) => [...prevArtifacts, saveResponse]);
      setSnackbarMessage('Artifact added successfully!');
      setSnackbarSeverity('success');
      setUploadDialogOpen(false);
    } catch (error) {
      console.error('Failed to save artifact', error);
      setSnackbarMessage(error.message || 'Failed to add artifact. Please try again.');
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
      setLoading(false);
    }
  };

  const handleDeleteArtifact = async (artifactId) => {
    if (window.confirm('Are you sure you want to delete this artifact?')) {
      try {
        const response = await fetch(`https://backend.vortx.ai/artifacts/${artifactId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': authToken,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to delete artifact.');
        }
        setArtifacts((prevArtifacts) => prevArtifacts.filter((artifact) => artifact.id !== artifactId));
        setSnackbarMessage('Artifact deleted successfully.');
        setSnackbarSeverity('success');
      } catch (error) {
        console.error('Failed to delete artifact:', error);
        setSnackbarMessage('Failed to delete artifact.');
        setSnackbarSeverity('error');
      } finally {
        setSnackbarOpen(true);
      }
    }
  };

  const handleDownloadArtifact = (url) => {
    window.open(url, '_blank');
  };

  const handleViewArtifact = (artifact) => {
    setCurrentArtifact(artifact);
    setViewDialogOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const filteredArtifacts = artifacts.filter((artifact) =>
    artifact.name.toLowerCase().includes(artifactSearchQuery.toLowerCase())
  );

  const columns = [
    { field: 'name', headerName: 'Name', flex: 2 },
    { field: 'type', headerName: 'Type', flex: 1 },
    { field: 'size', headerName: 'Size', flex: 1 },
    { field: 'timestamp', headerName: 'Timestamp', flex: 1.5, renderCell: (params) => new Date(params.value).toLocaleString() },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1.5,
      renderCell: (params) => (
        <>
          <Tooltip title="View">
            <IconButton color="primary" onClick={() => handleViewArtifact(params.row)}>
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download">
            <IconButton color="primary" onClick={() => handleDownloadArtifact(params.row.url)}>
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton color="error" onClick={() => handleDeleteArtifact(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <StyledCard variant="blackBox">
        <Typography variant="h6" gutterBottom>
          Artifacts
        </Typography>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search artifacts..."
              value={artifactSearchQuery}
              onChange={(e) => setArtifactSearchQuery(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddArtifact}>
              Add Artifact
            </Button>
          </Grid>
        </Grid>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <div style={{ height: 500, width: '100%', marginTop: '16px' }}>
            <DataGrid rows={filteredArtifacts} columns={columns} pageSize={10} rowsPerPageOptions={[5, 10, 25]} />
          </div>
        )}

        {/* View Artifact Dialog */}
        <Dialog open={viewDialogOpen} onClose={() => setViewDialogOpen(false)} fullWidth maxWidth="sm">
          <DialogTitle>View Artifact</DialogTitle>
          <DialogContent>
            {currentArtifact && (
              <Box>
                <Typography variant="h5">{currentArtifact.name}</Typography>
                <Typography variant="body1">Type: {currentArtifact.type}</Typography>
                <Typography variant="body1">Size: {currentArtifact.size}</Typography>
                <Typography variant="body1">Uploaded At: {new Date(currentArtifact.timestamp).toLocaleString()}</Typography>
                <Typography variant="body1" sx={{ mt: 2 }}>
                  <strong>Description:</strong> {currentArtifact.description}
                </Typography>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setViewDialogOpen(false)} color="secondary" startIcon={<CloseIcon />}>
              Close
            </Button>
            <Button variant="contained" color="primary" startIcon={<CloudDownloadIcon />} href={currentArtifact?.url} target="_blank" rel="noopener noreferrer">
              Download
            </Button>
          </DialogActions>
        </Dialog>

        {/* Upload Artifact Dialog */}
        <Dialog open={uploadDialogOpen} onClose={() => setUploadDialogOpen(false)} fullWidth maxWidth="sm">
          <DialogTitle>Add Artifact</DialogTitle>
          <DialogContent>
            <TextField
              label="Artifact Name"
              variant="outlined"
              fullWidth
              required
              value={newArtifact.name}
              onChange={(e) => setNewArtifact({ ...newArtifact, name: e.target.value })}
              sx={{ mb: 2 }}
            />
            <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
              <InputLabel>Artifact Type</InputLabel>
              <Select
                value={newArtifact.type}
                onChange={(e) => setNewArtifact({ ...newArtifact, type: e.target.value })}
                label="Artifact Type"
              >
                {artifactTypes.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {option.icon}
                      <Typography sx={{ ml: 1 }}>{option.label}</Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              value={newArtifact.description}
              onChange={(e) => setNewArtifact({ ...newArtifact, description: e.target.value })}
              sx={{ mb: 2 }}
            />
            <Button variant="outlined" component="label" startIcon={<CloudUploadIcon />}>
              Upload File
              <input type="file" hidden onChange={(e) => handleFileUpload(e.target.files[0])} />
              {uploading && <CircularProgress size={24} sx={{ ml: 2 }} />}
              {uploadSuccess && !uploading && <CloudDownloadIcon color="success" sx={{ ml: 2 }} />}
              {uploadSuccess === false && !uploading && <CloseIcon color="error" sx={{ ml: 2 }} />}
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setUploadDialogOpen(false)} color="secondary" startIcon={<CloseIcon />}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleSaveArtifact}
              disabled={!newArtifact.url || !newArtifact.name || !newArtifact.type || loading}
            >
              Add Artifact
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          ContentProps={{
            sx: {
              backgroundColor: snackbarSeverity === 'success' ? 'green' : 'red',
            },
          }}
        />
      </StyledCard>
    </Box>
  );
};

export default ArtifactsTab;
