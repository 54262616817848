export const ProtocolList = [

    {
      id: 1,
      name: "Track Natural Origin",
      title: "Trace Natural Origin History of Things",
      description: "Trace the location history of natural things like food, water, & minerals.",
      image: "/images/food-donation.gif",
      link: "/generate-data/location-history-v1",
      link_playground: "/playground/dgu-playground1",
      tags: ["Image → GeoHeritage "],
      model: "location-history-v1",
      icon: "/icons/food-history-icon.png",
      no_code: "yes",
      level: "images",
      scoring: "95",
      video_url: "https://youtu.be/example1",
      detailed_desc1: "Our model allows for tracking the origin and journey of natural resources like food, water, and minerals, filling in critical location gaps.",
      detailed_desc2: "Upload related inputs to analyze their journey and receive detailed location histories, optimizing supply chain efficiency.",
      dataTypes: ["Food", "Water", "Elements"],
      button:"Explore",
      release:"Yes",
      gif:"/images/DGU/DG002.gif",
      release_tag:"Live"
    },
    {
      id: 16,
      name: "Tileformer",
      title: "tileformer",
      description: "Instantly create shareable tiles from STAC files.",
      image: "/images/food-donation.gif",
      link: "/generate-data/food-tracker-v1", // Updated link
      link_playground: "/playground/dgu-playground", // New playground link
      tags: [ "STAC → Layer"],
      model: "food-tracker-v1",
      icon: "/icons/food-history-icon.png",
      no_code: "yes",
      level: "image&video",
      scoring: "99",
      video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
      detailed_desc1: "Generate 3D DEM models from GeoJSON inputs and analyze changes over time using satellite image data.",
      detailed_desc2: "Upload GeoJSON files to visualize terrain in 3D and track historical changes for better spatial analysis and insights.",
      dataTypes: ["System Prompts", "Mobile Image", "Time-series"] ,
      release:"Yes",
      gif:"/images/DGU/DG005.gif",
      release_tag:"Live",
      button:"Explore"
    },
    {
      id: 2,
      name: "Sub-Pixel World",
      title: "Sub-pixel Inpainting in Satellite Images",
      description: "Construct world views within pixels. For explainable EO.",
      image: "/images/food-donation.gif",
      link: "/generate-data/subpixel-inpainting-v1",
      link_playground: "/playground/subpixel-inpainting-v1",
      tags: ["Pixel → SubPixel"],
      model: "subpixel-inpainting-v1",
      icon: "/icons/food-history-icon.png",
      no_code: "yes",
      level: "geospatial",
      scoring: "97",
      video_url: "https://youtu.be/example2",
      detailed_desc1: "Our solution enables precise sub-pixel inpainting in satellite images, restoring details lost due to data gaps or resolution issues.",
      detailed_desc2: "Test the model by uploading satellite images with missing pixels to experience seamless inpainting and enhanced visuals.",
      dataTypes: ["Satellite RGB Image", "MSI", "HSI"],
      release:"Yes",
      gif:"/images/DGU/DG001.gif",
      release_tag:"Beta",
      button:"Contact Sales"
    },
    {
      id: 3,
      name: "Aircraft Eraser",
      title: "Detect and Inpaint Aircraft Pixels",
      description: "Detect, remove, and inpaint aircraft pixels from satellite images.",
      image: "/images/food-donation.gif",
      link: "/generate-data/aircraft-inpainting-v1",
      link_playground: "/playground/aircraft-inpainting-v1",
      tags: ["Noisy → Clean"],
      model: "aircraft-inpainting-v1",
      icon: "/icons/food-history-icon.png",
      no_code: "yes",
      level: "geospatial",
      scoring: "98",
      video_url: "https://youtu.be/example3",
      detailed_desc1: "Automatically detect and remove aircraft pixels from satellite images, ensuring clean, distraction-free data for analysis.",
      detailed_desc2: "Upload satellite images containing aircraft and watch as our model seamlessly detects, removes, and reconstructs the visuals.",
      dataTypes: ["Satellite RGB Image", "Sentinel-2", "Sentinel-1"],
      release:"Yes",
      gif:"/images/DGU/DG003.gif",
      release_tag:"Beta",
      button:"Contact Sales"
    },

    {
      id: 4,
      name: "World View",
      title: "World View",
      description: "Convert high-res satellite images to synthetic world views.",
      image: "/images/food-donation.gif",
      link: "/generate-data/food-tracker-v1", // Updated link
      link_playground: "/playground/food-tracker-v1", // New playground link
      tags: [ "Pixels → Landscape"],
      model: "food-tracker-v1",
      icon: "/icons/food-history-icon.png",
      no_code: "yes",
      level: "image&video",
      scoring: "99",
      video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
      detailed_desc1: "Generate 3D DEM models from GeoJSON inputs and analyze changes over time using satellite image data.",
      detailed_desc2: "Upload GeoJSON files to visualize terrain in 3D and track historical changes for better spatial analysis and insights.",
      dataTypes: ["System Prompts", "Mobile Image", "Time-series"] ,
      release:"Yes",
      gif:"/images/DGU/DG004.gif",
      release_tag:"Beta",
      button:"Contact Sales"
    },

  {
    id: 5,
    name: "Synthesize from Images",
    title: "Synthesize from Images",
    description: "e.g. Input Mobile Images of Apples to get {Output: 'Food, Apples, 4, Honeycrisp' , 'Grown in shade, on Hills, with plenty water' , 'India, HP, Shimla'} ",
    image: "/images/food-donation.gif",
    link: "/generate-data/food-tracker-v1", // Updated link
    link_playground: "/playground/food-tracker-v1", // New playground link
    tags: [ "Coming Soon"],
    model: "food-tracker-v1",
    icon: "/icons/food-history-icon.png",
    gif:"/images/icons/coming-soon.gif",
    no_code: "yes",
    level: "image&video",
    scoring: "99",
    video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
    detailed_desc1: "Gaps in supply chain tracking can result in inefficiencies and lost revenue. Our solution fills in missing location data to optimize operations.",
    detailed_desc2: "To test our model, upload images of fruits and vegetables to predict their origin, track their movement, and gain insights into their journey across the supply chain.",
    dataTypes: ["System Prompts", "Mobile Image", "Time-series"] ,




  },
  {
    id: 6,
    name: "Synthesize from Audio",
    title: "Synthesize from Audio",
    description: "e.g. Connect Customer Calls to get {Output : 'Urban Indian, Young Female, Educated, potentially from Odisha' , 'Evening, Cold, Happy'}   ",
    image: "/images/coming-soon.gif",
    link: "/generate-data/rgb-1m-v2",
    link_playground: "/playground/rgb-1m-v2",
    tags: ["Coming Soon", "Accuracy:low"],
    model: "rgb-1m-v2",
    icon: "/icons/rgb-icon.png",
    gif:"/images/icons/coming-soon.gif",
    no_code: "no",
    level: "Audio",
    scoring: "98",
    video_url: "https://youtu.be/cSqOarZdMQg",
    detailed_desc1: "Daily imagery at 1m resolution is perfect for infrastructure and urban planning.",
    detailed_desc2: "High-definition RGB imagery captures every detail with precision.",
    dataTypes: ["Satellite Image", "RGB Image"],


    },
  {
    id: 7,
    name: "Synthesize from Prompts.",
    title: "Synthesize from Prompts.",
    description: "e.g. Connect LLM Prompts to get {Output : 'Direct : Louvre Museum, Paris, 48.860131, 2.338459 ' , 'Inferred : Indian Restaurant, walkable distance'}",
    image: "/images/coming-soon.gif",
    link: "/generate-data/rgb-3m-v1", // Updated link
    link_playground: "/playground/rgb-3m-v1", // New playground link
    tags: ["Coming Soon", "Accuracy:medium"],
    model: "rgb-3m-v1",
    icon: "/icons/rgb-icon.png",
    gif:"/images/icons/coming-soon.gif",
    no_code: "no",
    level: "Text",
    scoring: "97",
    video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
    detailed_desc1: "Ideal for capturing large-scale environments like forests or cityscapes.",
    detailed_desc2: "This resolution balances detail with coverage for versatile applications.",
    dataTypes: ["Satellite Image", "RGB Image", "Landscape Data"] ,


  },
  {
    id: 8,
    name: "Synthesize from IoT",
    title: "Synthesize from IoT",
    description: "e.g. Connect Sensor Mesh to get periodic {Output : 'temperature_map, surge_map'}",
    image: "/images/coming-soon.gif",
    link: "/generate-data/rgb-nir-5m-v1", // Updated link
    link_playground: "/playground/rgb-nir-5m-v1", // New playground link
    tags: ["Coming Soon", "Accuracy:low"],
    model: "rgb-nir-5m-v1",
    icon: "/icons/nir-icon.png",
    no_code: "no",
    level: "Text",
    scoring: "38",
    video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
    detailed_desc1: "Use AI to analyze audio conversations and identify accents, vocabulary, and linguistic features to determine caller location.",
    detailed_desc2: "Useful for customer service optimization, fraud detection, and regional insights..",
    dataTypes: ["Audio Data", "Linguistic Data", "Location Data"],
    release:"No",
    gif:"/images/icons/coming-soon.gif",
  },

  {
    id: 9,
    name: "Synthesize from Location",
    title: "Synthesize from Location",
    description: "e.g. Connect GPS to get {Output : 'Satellite_map, Street_view'}",
    image: "/images/coming-soon.gif",
    link: "/generate-data/satellite-draw-v1", // Updated link
    link_playground: "/playground/satellite-draw-v1", // New playground link
    tags: ["Coming Soon", "Accuracy:good"],
    model: "satellite-draw-v1",
    icon: "/icons/drawing-icon.png",
    no_code: "no",
    level: "Text",
    scoring: "34",
    video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
    detailed_desc1: "Turn simple drawings into satellite-grade images.",
    detailed_desc2: "Perfect for concept development and creative applications.",
    gif:"/images/icons/coming-soon.gif",
    dataTypes: ["Drawing", "Satellite Image", "AI-generated Data"] // Added data types
  },
  {
    id: 10,
    name: "Synthesize from Texts",
    title: "Synthesize from Texts",
    description: "e.g. Connect Texts to get {Output : Urban Indian, Young Female, Educated, potentially from Odisha' , 'Evening, Cold, Happy'}",
    image: "/images/coming-soon.gif",
    link: "/generate-data/custom-start-v1", // Updated link
    link_playground: "/playground/custom-start-v1", // New playground link
    tags: ["Coming Soon", "Accuracy:low"],
    model: "custom-start-v1",
    icon: "/icons/start-icon.png",
    no_code: "no",
    level: "Text",
    scoring: "29",
    gif:"/images/icons/coming-soon.gif",
    video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
    detailed_desc1: "Choose from custom or pre-built satellite imaging models.",
    detailed_desc2: "Tailor your project to your specific needs.",
    dataTypes: ["Satellite Image", "Custom Data"] // Added data types
  },
  {
    id: 11,
    name: "Geojson World",
    title: "Geojson World",
    description: "See a world within geojson.",
    image: "/images/food-donation.gif",
    link: "/generate-data/food-tracker-v1", // Updated link
    link_playground: "/playground/food-tracker-v1", // New playground link
    tags: [ "Pixels → Landscape"],
    model: "food-tracker-v1",
    icon: "/icons/food-history-icon.png",
    no_code: "yes",
    level: "image&video",
    scoring: "99",
    video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
    detailed_desc1: "Generate 3D DEM models from GeoJSON inputs and analyze changes over time using satellite image data.",
    detailed_desc2: "Upload GeoJSON files to visualize terrain in 3D and track historical changes for better spatial analysis and insights.",
    dataTypes: ["System Prompts", "Mobile Image", "Time-series"] ,
    release:"no",
    gif:"/images/coming-soon.gif",
    release_tag:"Beta",
    button:"Contact Sales"
  },
  {
    id: 12,
    name: "Time-shared Cartesian",
    title: "Time-shared Cartesian",
    description: "PII removal through time limited access on a new plane.",
    image: "/images/food-donation.gif",
    link: "/generate-data/food-tracker-v1", // Updated link
    link_playground: "/playground/food-tracker-v1", // New playground link
    tags: [ "Pixels → Landscape"],
    model: "food-tracker-v1",
    icon: "/icons/food-history-icon.png",
    no_code: "yes",
    level: "image&video",
    scoring: "99",
    video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
    detailed_desc1: "Generate 3D DEM models from GeoJSON inputs and analyze changes over time using satellite image data.",
    detailed_desc2: "Upload GeoJSON files to visualize terrain in 3D and track historical changes for better spatial analysis and insights.",
    dataTypes: ["System Prompts", "Mobile Image", "Time-series"] ,
    release:"no",
    gif:"/images/coming-soon.gif",
    release_tag:"Beta",
    button:"Contact Sales"
  },
  {
    id: 13,
    name: "Warp",
    title: "Warp",
    description: "Warp Raster.",
    image: "/images/food-donation.gif",
    link: "/generate-data/food-tracker-v1", // Updated link
    link_playground: "/playground/food-tracker-v1", // New playground link
    tags: [ "Pixels → Landscape"],
    model: "food-tracker-v1",
    icon: "/icons/food-history-icon.png",
    no_code: "yes",
    level: "image&video",
    scoring: "99",
    video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
    detailed_desc1: "Generate 3D DEM models from GeoJSON inputs and analyze changes over time using satellite image data.",
    detailed_desc2: "Upload GeoJSON files to visualize terrain in 3D and track historical changes for better spatial analysis and insights.",
    dataTypes: ["System Prompts", "Mobile Image", "Time-series"] ,
    release:"no",
    gif:"/images/coming-soon.gif",
    release_tag:"Beta",
    button:"Contact Sales"
  },
  {
    id: 14,
    name: "gdal_polygonize",
    title: "gdal_polygonize",
    description: "Convert raster to polygon.",
    image: "/images/food-donation.gif",
    link: "/generate-data/food-tracker-v1", // Updated link
    link_playground: "/playground/food-tracker-v1", // New playground link
    tags: [ "Pixels → Landscape"],
    model: "food-tracker-v1",
    icon: "/icons/food-history-icon.png",
    no_code: "yes",
    level: "image&video",
    scoring: "99",
    video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
    detailed_desc1: "Generate 3D DEM models from GeoJSON inputs and analyze changes over time using satellite image data.",
    detailed_desc2: "Upload GeoJSON files to visualize terrain in 3D and track historical changes for better spatial analysis and insights.",
    dataTypes: ["System Prompts", "Mobile Image", "Time-series"] ,
    release:"no",
    gif:"/images/coming-soon.gif",
    release_tag:"Beta",
    button:"Contact Sales"
  },
  {
    id: 15,
    name: "gdal_merge",
    title: "gdal_merge",
    description: "Merge multiple spatial files.",
    image: "/images/food-donation.gif",
    link: "/generate-data/food-tracker-v1", // Updated link
    link_playground: "/playground/food-tracker-v1", // New playground link
    tags: [ "Pixels → Landscape"],
    model: "food-tracker-v1",
    icon: "/icons/food-history-icon.png",
    no_code: "yes",
    level: "image&video",
    scoring: "99",
    video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
    detailed_desc1: "Generate 3D DEM models from GeoJSON inputs and analyze changes over time using satellite image data.",
    detailed_desc2: "Upload GeoJSON files to visualize terrain in 3D and track historical changes for better spatial analysis and insights.",
    dataTypes: ["System Prompts", "Mobile Image", "Time-series"] ,
    release:"no",
    gif:"/images/coming-soon.gif",
    release_tag:"Beta",
    button:"Contact Sales"
  },
  
  {
    id: 17,
    name: "10m RGB daily",
    title: "10m RGB daily",
    description: "Get 10m*10m resolution RGB daily.",
    image: "/images/food-donation.gif",
    link: "/generate-data/food-tracker-v1", // Updated link
    link_playground: "/playground/food-tracker-v1", // New playground link
    tags: [ "Pixels → Landscape"],
    model: "food-tracker-v1",
    icon: "/icons/food-history-icon.png",
    no_code: "yes",
    level: "image&video",
    scoring: "99",
    video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
    detailed_desc1: "Generate 3D DEM models from GeoJSON inputs and analyze changes over time using satellite image data.",
    detailed_desc2: "Upload GeoJSON files to visualize terrain in 3D and track historical changes for better spatial analysis and insights.",
    dataTypes: ["System Prompts", "Mobile Image", "Time-series"] ,
    release:"no",
    gif:"/images/coming-soon.gif",
    release_tag:"Beta",
    button:"Contact Sales"
  },
  {
    id: 18,
    name: "5m RGB daily",
    title: "5m RGB daily",
    description: "Get 5m*5m resolution RGB daily.",
    image: "/images/food-donation.gif",
    link: "/generate-data/food-tracker-v1", // Updated link
    link_playground: "/playground/food-tracker-v1", // New playground link
    tags: [ "Pixels → Landscape"],
    model: "food-tracker-v1",
    icon: "/icons/food-history-icon.png",
    no_code: "yes",
    level: "image&video",
    scoring: "99",
    video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
    detailed_desc1: "Generate 3D DEM models from GeoJSON inputs and analyze changes over time using satellite image data.",
    detailed_desc2: "Upload GeoJSON files to visualize terrain in 3D and track historical changes for better spatial analysis and insights.",
    dataTypes: ["System Prompts", "Mobile Image", "Time-series"] ,
    release:"no",
    gif:"/images/coming-soon.gif",
    release_tag:"Beta",
    button:"Contact Sales"
  },
  {
    id: 19,
    name: "1m RGB daily",
    title: "1m RGB daily",
    description: "Get 1m*1m resolution RGB daily.",
    image: "/images/food-donation.gif",
    link: "/generate-data/food-tracker-v1", // Updated link
    link_playground: "/playground/food-tracker-v1", // New playground link
    tags: [ "Pixels → Landscape"],
    model: "food-tracker-v1",
    icon: "/icons/food-history-icon.png",
    no_code: "yes",
    level: "image&video",
    scoring: "99",
    video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
    detailed_desc1: "Generate 3D DEM models from GeoJSON inputs and analyze changes over time using satellite image data.",
    detailed_desc2: "Upload GeoJSON files to visualize terrain in 3D and track historical changes for better spatial analysis and insights.",
    dataTypes: ["System Prompts", "Mobile Image", "Time-series"] ,
    release:"no",
    gif:"/images/coming-soon.gif",
    release_tag:"Beta",
    button:"Contact Sales"
  },
  {
    id: 20,
    name: "10m MSI daily",
    title: "10m MSI daily",
    description: "Get 10m*10m resolution MSI daily.",
    image: "/images/food-donation.gif",
    link: "/generate-data/food-tracker-v1", // Updated link
    link_playground: "/playground/food-tracker-v1", // New playground link
    tags: [ "Pixels → Landscape"],
    model: "food-tracker-v1",
    icon: "/icons/food-history-icon.png",
    no_code: "yes",
    level: "image&video",
    scoring: "99",
    video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
    detailed_desc1: "Generate 3D DEM models from GeoJSON inputs and analyze changes over time using satellite image data.",
    detailed_desc2: "Upload GeoJSON files to visualize terrain in 3D and track historical changes for better spatial analysis and insights.",
    dataTypes: ["System Prompts", "Mobile Image", "Time-series"] ,
    release:"no",
    gif:"/images/coming-soon.gif",
    release_tag:"Beta",
    button:"Contact Sales"
  },
  {
    id: 21,
    name: "5m SAR daily",
    title: "5m SAR daily",
    description: "Get 5m resolution SAR daily.",
    image: "/images/food-donation.gif",
    link: "/generate-data/food-tracker-v1", // Updated link
    link_playground: "/playground/food-tracker-v1", // New playground link
    tags: [ "Pixels → Landscape"],
    model: "food-tracker-v1",
    icon: "/icons/food-history-icon.png",
    no_code: "yes",
    level: "image&video",
    scoring: "99",
    video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
    detailed_desc1: "Generate 3D DEM models from GeoJSON inputs and analyze changes over time using satellite image data.",
    detailed_desc2: "Upload GeoJSON files to visualize terrain in 3D and track historical changes for better spatial analysis and insights.",
    dataTypes: ["System Prompts", "Mobile Image", "Time-series"] ,
    release:"no",
    gif:"/images/coming-soon.gif",
    release_tag:"Beta",
    button:"Contact Sales"
  },
  {
    id: 22,
    name: "10m SAR daily",
    title: "10m SAR daily",
    description: "Get 10m resolution SAR daily.",
    image: "/images/food-donation.gif",
    link: "/generate-data/food-tracker-v1", // Updated link
    link_playground: "/playground/food-tracker-v1", // New playground link
    tags: [ "Pixels → Landscape"],
    model: "food-tracker-v1",
    icon: "/icons/food-history-icon.png",
    no_code: "yes",
    level: "image&video",
    scoring: "99",
    video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
    detailed_desc1: "Generate 3D DEM models from GeoJSON inputs and analyze changes over time using satellite image data.",
    detailed_desc2: "Upload GeoJSON files to visualize terrain in 3D and track historical changes for better spatial analysis and insights.",
    dataTypes: ["System Prompts", "Mobile Image", "Time-series"] ,
    release:"no",
    gif:"/images/coming-soon.gif",
    release_tag:"Beta",
    button:"Contact Sales"
  },
  {
    id: 23,
    name: "30m RGB daily",
    title: "30m RGB daily",
    description: "Get 30m*30m resolution RGB daily.",
    image: "/images/food-donation.gif",
    link: "/generate-data/food-tracker-v1", // Updated link
    link_playground: "/playground/food-tracker-v1", // New playground link
    tags: [ "Pixels → Landscape"],
    model: "food-tracker-v1",
    icon: "/icons/food-history-icon.png",
    no_code: "yes",
    level: "image&video",
    scoring: "99",
    video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
    detailed_desc1: "Generate 3D DEM models from GeoJSON inputs and analyze changes over time using satellite image data.",
    detailed_desc2: "Upload GeoJSON files to visualize terrain in 3D and track historical changes for better spatial analysis and insights.",
    dataTypes: ["System Prompts", "Mobile Image", "Time-series"] ,
    release:"no",
    gif:"/images/coming-soon.gif",
    release_tag:"Beta",
    button:"Contact Sales"
  },
  {
    id: 24,
    name: "30m MSI daily",
    title: "30m MSI daily",
    description: "Get 30m*30m resolution MSI daily",
    image: "/images/food-donation.gif",
    link: "/generate-data/food-tracker-v1", // Updated link
    link_playground: "/playground/food-tracker-v1", // New playground link
    tags: [ "Pixels → Landscape"],
    model: "food-tracker-v1",
    icon: "/icons/food-history-icon.png",
    no_code: "yes",
    level: "image&video",
    scoring: "99",
    video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
    detailed_desc1: "Generate 3D DEM models from GeoJSON inputs and analyze changes over time using satellite image data.",
    detailed_desc2: "Upload GeoJSON files to visualize terrain in 3D and track historical changes for better spatial analysis and insights.",
    dataTypes: ["System Prompts", "Mobile Image", "Time-series"] ,
    release:"no",
    gif:"/images/coming-soon.gif",
    release_tag:"Beta",
    button:"Contact Sales"
  },
  {
    id: 25,
    name: "SAR to RGB",
    title: "SAR to RGB",
    description: "Convert SAR images to RGB Images.",
    image: "/images/food-donation.gif",
    link: "/generate-data/food-tracker-v1", // Updated link
    link_playground: "/playground/food-tracker-v1", // New playground link
    tags: [ "Pixels → Landscape"],
    model: "food-tracker-v1",
    icon: "/icons/food-history-icon.png",
    no_code: "yes",
    level: "image&video",
    scoring: "99",
    video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
    detailed_desc1: "Generate 3D DEM models from GeoJSON inputs and analyze changes over time using satellite image data.",
    detailed_desc2: "Upload GeoJSON files to visualize terrain in 3D and track historical changes for better spatial analysis and insights.",
    dataTypes: ["System Prompts", "Mobile Image", "Time-series"] ,
    release:"no",
    gif:"/images/coming-soon.gif",
    release_tag:"Beta",
    button:"Contact Sales"
  },
  {
    id: 26,
    name: "GCP Correction",
    title: "GCP Correction",
    description: "Correct Satellite Images using CCTV Ground Points.",
    image: "/images/food-donation.gif",
    link: "/generate-data/food-tracker-v1", // Updated link
    link_playground: "/playground/food-tracker-v1", // New playground link
    tags: [ "Pixels → Landscape"],
    model: "food-tracker-v1",
    icon: "/icons/food-history-icon.png",
    no_code: "yes",
    level: "image&video",
    scoring: "99",
    video_url: "https://youtu.be/cSqOarZdMQg", // Added video URL
    detailed_desc1: "Generate 3D DEM models from GeoJSON inputs and analyze changes over time using satellite image data.",
    detailed_desc2: "Upload GeoJSON files to visualize terrain in 3D and track historical changes for better spatial analysis and insights.",
    dataTypes: ["System Prompts", "Mobile Image", "Time-series"] ,
    release:"no",
    gif:"/images/coming-soon.gif",
    release_tag:"Beta",
    button:"Contact Sales"
  },
];
