// src/pages/projects/ConnectionsTab.jsx

import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  Button,
  Grid,
  TextField,
  Typography,
  Snackbar,
  Tabs,
  Tab,
  IconButton,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  CircularProgress,
  AppBar,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  Storage as StorageIcon,
  Cloud as CloudIcon,
  Http as HttpIcon,
  Code as CodeIcon,
  CloudUpload as CloudUploadIcon,
  Close as CloseIcon,
  Save as SaveIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import axios from 'axios'; // Import Axios directly
import { useParams } from 'react-router-dom';
import { useTheme } from "@mui/material/styles"; // Import useTheme

// Styled Card with hover effect
const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  '&:hover': {
    boxShadow: theme.shadows[6],
    transition: 'box-shadow 0.3s',
  },
}));

// Define connection types with icons
const connectionTypes = [
  // Databases
  { value: 'PostgreSQL', label: 'PostgreSQL', category: 'Databases', icon: <StorageIcon /> },
  { value: 'MongoDB', label: 'MongoDB', category: 'Databases', icon: <StorageIcon /> },
  { value: 'MySQL', label: 'MySQL', category: 'Databases', icon: <StorageIcon /> },
  // Cloud Storage
  { value: 'AWS S3', label: 'AWS S3', category: 'Cloud Storage', icon: <CloudIcon /> },
  { value: 'Azure Blob', label: 'Azure Blob', category: 'Cloud Storage', icon: <CloudIcon /> },
  { value: 'Google Cloud Storage', label: 'Google Cloud Storage', category: 'Cloud Storage', icon: <CloudIcon /> },
  // Messaging Queues
  { value: 'Kafka', label: 'Kafka', category: 'Messaging Queues', icon: <CloudIcon /> },
  { value: 'RabbitMQ', label: 'RabbitMQ', category: 'Messaging Queues', icon: <CloudIcon /> },
  // APIs
  { value: 'Webhook', label: 'Webhook', category: 'APIs', icon: <HttpIcon /> },
  { value: 'REST API', label: 'REST API', category: 'APIs', icon: <CodeIcon /> },
  // File Storage
  { value: 'Google Drive', label: 'Google Drive', category: 'File Storage', icon: <CloudIcon /> },
  { value: 'Dropbox', label: 'Dropbox', category: 'File Storage', icon: <CloudIcon /> },
  // Monitoring Tools
  { value: 'ElasticSearch', label: 'ElasticSearch', category: 'Monitoring Tools', icon: <StorageIcon /> },
  { value: 'InfluxDB', label: 'InfluxDB', category: 'Monitoring Tools', icon: <StorageIcon /> },
  // Other
  { value: 'Custom', label: 'Custom', category: 'Other', icon: <CloudIcon /> },
];

// Define status options with icons
const statusOptions = [
  { value: 'Active', label: 'Active', icon: <CheckCircleIcon color="success" /> },
  { value: 'Inactive', label: 'Inactive', icon: <ErrorIcon color="error" /> },
];

// Define dynamic fields based on connection type
const connectionFields = {
  PostgreSQL: [
    { label: 'Host', key: 'host', required: true },
    { label: 'Port', key: 'port', required: true },
    { label: 'Database Name', key: 'databaseName', required: true },
    { label: 'Username', key: 'username', required: true },
    { label: 'Password', key: 'password', required: true, type: 'password' },
  ],
  // Add other connection types' fields as needed
  // ...
};

const ConnectionsTab = () => {
  const { projectId } = useParams();
  const theme = useTheme(); // Access the theme

  const [connections, setConnections] = useState([]);
  const [filteredConnections, setFilteredConnections] = useState([]);
  const [connectionSearchQuery, setConnectionSearchQuery] = useState('');
  const [connectionModalOpen, setConnectionModalOpen] = useState(false);
  const [isEditingConnection, setIsEditingConnection] = useState(false);
  const [currentConnection, setCurrentConnection] = useState(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchConnections = async () => {
      setLoading(true);
      try {
        const url = `${process.env.REACT_APP_VORTX_URL1}/${projectId}/connections`;
        console.log('Fetching connections from URL:', url);
        console.log('Using Auth Token:', authToken);

        const response = await axios.get(url, {
          headers: {
            "X-Auth-Token": authToken,
            "Content-Type": "application/json",
          },
        });

        console.log('Fetch Connections Raw Response:', response.data);

        if (response.data && Array.isArray(response.data)) {
          setConnections(response.data);
          setFilteredConnections(response.data);
          console.log('Connections successfully set:', response.data);
        } else {
          console.error('Expected an array but received:', response.data);
          setError('Unexpected response format from the server.');
        }
      } catch (err) {
        console.error('Fetch Connections Error:', err);
        setError('Failed to fetch connections. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    if (projectId) {
      fetchConnections();
    } else {
      console.error('Project ID is undefined.');
      setError('Project ID is not defined.');
    }
  }, [projectId, authToken]);

  useEffect(() => {
    let filtered = [...connections];
    if (connectionSearchQuery.trim() !== '') {
      filtered = filtered.filter((conn) =>
        conn.name.toLowerCase().includes(connectionSearchQuery.toLowerCase())
      );
    }
    setFilteredConnections(filtered);
  }, [connectionSearchQuery, connections]);

  const handleAddConnection = () => {
    setIsEditingConnection(false);
    setCurrentConnection({
      name: '',
      type: '',
      status: 'Active',
      details: '',
    });
    setConnectionModalOpen(true);
  };

  const handleEditConnection = (connection) => {
    setIsEditingConnection(true);
    setCurrentConnection(connection);
    setConnectionModalOpen(true);
  };

  const handleSaveConnection = async (e) => {
    e.preventDefault();

    // Prepare the connection data to save
    const connectionToSave = {
      ...currentConnection,
      connectionType: currentConnection.type,
      projectId: projectId,
    };
    delete connectionToSave.type;

    // Log the data being sent and the API URL for debugging
    console.log('Data to save:', connectionToSave);
    console.log('API URL:', `${process.env.REACT_APP_VORTX_URL1}/connections`);

    if (isEditingConnection) {
      // Handle updating an existing connection
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_VORTX_URL1}/connections/${connectionToSave.id}`,
          connectionToSave,
          {
            headers: {
              "X-Auth-Token": authToken,
              "Content-Type": "application/json",
            },
          }
        );

        console.log('Update Connection Response:', response.data);

        setConnections((prevConnections) =>
          prevConnections.map((conn) =>
            conn.id === connectionToSave.id ? response.data : conn
          )
        );
        setFilteredConnections((prevConnections) =>
          prevConnections.map((conn) =>
            conn.id === connectionToSave.id ? response.data : conn
          )
        );
        setSuccessMessage('Connection updated successfully.');
        setError(null);
      } catch (error) {
        console.error('Update Connection API Error:', error);
        setError('Failed to update connection. Please try again.');
        setSuccessMessage(null);
      }
    } else {
      // Handle creating a new connection
      try {
        // Make the POST request to create a new connection
        const newConnection = await axios.post(
          `${process.env.REACT_APP_VORTX_URL1}/${projectId}/connections`,
          connectionToSave,
          {
            headers: {
              "X-Auth-Token": authToken,
              "Content-Type": "application/json",
            },
          }
        );

        // Log the response from the API
        console.log("API Base URL:", process.env.REACT_APP_VORTX_URL1);
        console.log('Create Connection Response:', newConnection.data);

        // Check if the newConnection has an id and update state accordingly
        if (newConnection.data && newConnection.data.id) {
          setConnections((prevConnections) => [...prevConnections, newConnection.data]);
          setFilteredConnections((prevConnections) => [...prevConnections, newConnection.data]);
          setSuccessMessage('Connection created successfully.');
          setError(null);
        } else {
          console.error('Unexpected API response:', newConnection.data);
          setError('Failed to create connection. Unexpected response data.');
        }
      } catch (error) {
        // Handle any errors that occur during the API call
        console.error('Create Connection API Error:', error);
        setError('Failed to create connection. Please try again.');
        setSuccessMessage(null);
      }
    }

    // Close the connection modal after attempting to save
    setConnectionModalOpen(false);
  };

  const handleDeleteConnection = async (connectionId) => {
    if (
      window.confirm(
        'Are you sure you want to delete this connection? This action cannot be undone.'
      )
    ) {
      try {
        await axios.delete(`${process.env.REACT_APP_VORTX_URL1}/connections/${connectionId}`, {
          headers: {
            "X-Auth-Token": authToken,
            "Content-Type": "application/json",
          },
        });

        setConnections((prevConnections) =>
          prevConnections.filter((conn) => conn.id !== connectionId)
        );
        setFilteredConnections((prevConnections) =>
          prevConnections.filter((conn) => conn.id !== connectionId)
        );
        setSuccessMessage('Connection deleted successfully.');
        setError(null);
      } catch (error) {
        console.error('Delete Connection API Error:', error);
        setError('Failed to delete connection. Please try again.');
        setSuccessMessage(null);
      }
    }
  };

  useEffect(() => {
    if (error) {
      setSnackbarMessage(error);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else if (successMessage) {
      setSnackbarMessage(successMessage);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    }
  }, [error, successMessage]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setError(null);
    setSuccessMessage(null);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setConnectionSearchQuery('');
  };

  const getFilteredConnectionsByTab = () => {
    // Ensure `filteredConnections` is an array
    let filteredByTab = Array.isArray(filteredConnections) ? [...filteredConnections] : [];

    const categories = [
      'All',
      'Databases',
      'Cloud Storage',
      'Messaging Queues',
      'APIs',
      'File Storage',
      'Monitoring Tools',
      'Other',
    ];

    if (tabValue !== 0) {
      const category = categories[tabValue];
      filteredByTab = filteredByTab.filter((conn) => {
        const type = conn && conn.connectionType
          ? connectionTypes.find((t) => t.value === conn.connectionType)
          : null;
        return type && type.category === category;
      });
    }

    return filteredByTab
      .filter((conn) => conn && conn.id) // Ensure each connection has an id
      .map((conn) => ({
        id: conn.id,
        ...conn,
      }));
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'connectionType',
      headerName: 'Type',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const type = connectionTypes.find((t) => t.value === params.value);
        return (
          <Box display="flex" alignItems="center">
            {type?.icon}
            <Typography sx={{ ml: 1 }}>{params.value}</Typography>
          </Box>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const status = statusOptions.find((s) => s.value === params.value);
        return (
          <Box display="flex" alignItems="center">
            {status?.icon}
            <Typography sx={{ ml: 1 }}>{status?.label}</Typography>
          </Box>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <Box display="flex">
          <IconButton
            color="primary"
            onClick={() => handleEditConnection(params.row)}
            size="small"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={() => handleDeleteConnection(params.row.id)}
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ width: '100%' }} variant="blackBox">
      <StyledCard variant="blackBox">
        <Typography variant="h6" gutterBottom>
          Connections
        </Typography>
        <Typography variant="t2_grey" gutterBottom>
          Manage your connections to databases, cloud buckets, webhooks, APIs, and more.
        </Typography>

        {/* Search and Add Connection Section */}
        <Grid container spacing={2} sx={{ mb: 2, mt: 2 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search connections..."
              value={connectionSearchQuery}
              onChange={(e) => setConnectionSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <IconButton edge="start">
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddConnection}
            >
              Add Connection
            </Button>
          </Grid>
        </Grid>

        {/* Tabs */}
        <Box sx={{ mt: 2 }}>
          <AppBar position="static" sx={{ backgroundColor: theme.palette.background.black }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="Connection Tabs"
              sx={{
                // Remove the default indicator (underline)
                "& .MuiTabs-indicator": {
                  display: "none",
                },
                // Style for each Tab
                "& .MuiTab-root": {
                  color: theme.palette.text.primary, // White text for non-selected tabs
                  textTransform: "none", // Keep text as is
                  fontWeight: 500,
                  padding: theme.spacing(1, 2),
                  "&.Mui-selected": {
                    color: theme.palette.secondary.main, // Orange text for selected tab
                    borderBottom: `2px solid ${theme.palette.secondary.main}`, // Orange bottom border
                  },
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.main, // Orange background on hover
                    color: theme.palette.text.active, // Black text on hover
                  },
                },
                backgroundColor: theme.palette.background.black, // Black background for Tabs container
              }}
            >
              <Tab label="All" />
              <Tab label="Databases" icon={<StorageIcon />} />
              <Tab label="Cloud Storage" icon={<CloudUploadIcon />} />
              <Tab label="Messaging Queues" icon={<CloudIcon />} />
              <Tab label="APIs" icon={<CodeIcon />} />
              <Tab label="File Storage" icon={<CloudIcon />} />
              <Tab label="Monitoring Tools" icon={<StorageIcon />} />
              <Tab label="Other" icon={<CloudIcon />} />
            </Tabs>
          </AppBar>
        </Box>

        {/* DataGrid or Loading Indicator */}
        {loading ? (
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <CircularProgress />
            <Typography variant="body1" sx={{ mt: 2 }}>
              Loading connections...
            </Typography>
          </Box>
        ) : (
          <div style={{ height: 500, width: '100%', marginTop: '16px' }}>
            <DataGrid
              rows={getFilteredConnectionsByTab()}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5, 10, 25]}
              pagination
              sx={{
                '& .MuiDataGrid-row:hover': {
                  backgroundColor: '#f5f5f5',
                },
              }}
            />
          </div>
        )}
      </StyledCard>

      {/* Connection Modal */}
      <Dialog
        open={connectionModalOpen}
        onClose={() => setConnectionModalOpen(false)}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Slide}
      >
        <DialogTitle>
          {isEditingConnection ? 'Edit Connection' : 'Add Connection'}
        </DialogTitle>
        <DialogContent>
          <Box component="form" onSubmit={handleSaveConnection}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Connection Name"
                  variant="outlined"
                  fullWidth
                  required
                  value={currentConnection ? currentConnection.name : ''}
                  onChange={(e) =>
                    setCurrentConnection({
                      ...currentConnection,
                      name: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="connection-type-label">Connection Type</InputLabel>
                  <Select
                    labelId="connection-type-label"
                    label="Connection Type"
                    value={currentConnection ? currentConnection.type : ''}
                    onChange={(e) =>
                      setCurrentConnection({
                        ...currentConnection,
                        type: e.target.value,
                      })
                    }
                    required
                  >
                    {connectionTypes.map((option) => (
                      <MenuItem value={option.value} key={option.value}>
                        <Box display="flex" alignItems="center">
                          {option.icon}
                          <Typography sx={{ ml: 1 }}>{option.label}</Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Dynamic Fields Based on Connection Type */}
              {currentConnection &&
                currentConnection.type &&
                connectionFields[currentConnection.type] &&
                connectionFields[currentConnection.type].map((field, index) => (
                  <Grid item xs={12} key={index}>
                    {field.type === 'select' ? (
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id={`${field.key}-label`}>{field.label}</InputLabel>
                        <Select
                          labelId={`${field.key}-label`}
                          label={field.label}
                          value={currentConnection[field.key] || ''}
                          onChange={(e) =>
                            setCurrentConnection({
                              ...currentConnection,
                              [field.key]: e.target.value,
                            })
                          }
                          required={field.required}
                        >
                          {field.options.map((option) => (
                            <MenuItem value={option} key={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : field.type === 'password' ? (
                      <TextField
                        label={field.label}
                        variant="outlined"
                        fullWidth
                        required={field.required}
                        type={currentConnection[`show_${field.key}`] ? 'text' : 'password'}
                        value={currentConnection[field.key] || ''}
                        onChange={(e) =>
                          setCurrentConnection({
                            ...currentConnection,
                            [field.key]: e.target.value,
                          })
                        }
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              aria-label={`toggle ${field.key} visibility`}
                              edge="end"
                              onClick={() =>
                                setCurrentConnection({
                                  ...currentConnection,
                                  [`show_${field.key}`]: !currentConnection[`show_${field.key}`],
                                })
                              }
                            >
                              {currentConnection[`show_${field.key}`] ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          ),
                        }}
                      />
                    ) : field.type === 'json' ? (
                      <TextField
                        label={field.label}
                        variant="outlined"
                        fullWidth
                        required={field.required}
                        multiline
                        rows={3}
                        value={currentConnection[field.key] || ''}
                        onChange={(e) =>
                          setCurrentConnection({
                            ...currentConnection,
                            [field.key]: e.target.value,
                          })
                        }
                      />
                    ) : (
                      <TextField
                        label={field.label}
                        variant="outlined"
                        fullWidth
                        required={field.required}
                        value={currentConnection[field.key] || ''}
                        onChange={(e) =>
                          setCurrentConnection({
                            ...currentConnection,
                            [field.key]: e.target.value,
                          })
                        }
                      />
                    )}
                  </Grid>
                ))}

              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="connection-status-label">Status</InputLabel>
                  <Select
                    labelId="connection-status-label"
                    label="Status"
                    value={currentConnection ? currentConnection.status : ''}
                    onChange={(e) =>
                      setCurrentConnection({
                        ...currentConnection,
                        status: e.target.value,
                      })
                    }
                    required
                  >
                    {statusOptions.map((option) => (
                      <MenuItem value={option.value} key={option.value}>
                        <Box display="flex" alignItems="center">
                          {option.icon}
                          <Typography sx={{ ml: 1 }}>{option.label}</Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Details"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                  value={currentConnection ? currentConnection.details : ''}
                  onChange={(e) =>
                    setCurrentConnection({
                      ...currentConnection,
                      details: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
            <DialogActions sx={{ mt: 2 }}>
              <Button
                onClick={() => setConnectionModalOpen(false)}
                color="secondary"
                startIcon={<CloseIcon />}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                startIcon={<SaveIcon />}
              >
                {isEditingConnection ? 'Save Changes' : 'Add Connection'}
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Snackbar for feedback messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        ContentProps={{
          sx: {
            backgroundColor: snackbarSeverity === 'success' ? theme.palette.success.main : theme.palette.error.main,
            color: '#fff',
          },
        }}
      />
    </Box>
  );
};

export default ConnectionsTab;
