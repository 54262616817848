import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import useAuthorizedHttp from "../../../hooks/use-authorized-http";
import AddDataFileUpload from "../addDataFileUpload";
import AddBurnerTimeline from "../addBurnerTimeline/AddBurnerTimeline";
import { CircularProgress } from "@mui/material";
function AddBurnerModal({ openModal, setOpenModal }) {
  const [loading, setLoading] = useState(false);
  const [minAmount, setMinAmount] = useState("");
  const appCtx = useSelector((state) => state.app);
  const [logoPreview, setLogoPreview] = useState();
  const [collectionLogo, setCollectionLogo] = useState();
  const [logoUrl, setLogoUrl] = useState("");
  const [contractAddress, setContractAddress] = useState("");
  const [tokenShortName, setTokenShortName] = useState("");
  const [tokenName, setTokenName] = useState("");
  const [decimals, setDecimals] = useState(null);
  const [website, setWebsite] = useState(null);
  const [whitepaper, setWhitepaper] = useState(null);
  const [twitter, setTwitter] = useState(null);
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [standard, setStandard] = useState(null);
  const [blockchain, setBlockchain] = useState(appCtx.blockchain);
  const [symbol, setSymbol] = useState("");
  const [slug, setSlug] = useState("");
 
  const [burnerLoading, setBurnerLoading] = useState(false);
  const [burners, setBurners] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const navigate = useNavigate();
  const fileRef = useRef();
  const makeRequest = useAuthorizedHttp();

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   setLoading(true);

  //   const data = {
  //     contractAddress,
  //     tokenShortName: tokenDetails?.symbol,
  //     tokenName: tokenDetails?.name,
  //     tokenIcon: logoUrl,
  //     decimals: tokenDetails?.decimal,
  //     website,
  //     whitepaper,
  //     twitter,
  //     category,
  //     description,
  //     standard,
  //     blockchain,
  //     symbol: tokenDetails?.symbol,
  //     slug,
  //   };

  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token`,
  //       data,
  //       {
  //         headers: {
  //           "X-Auth-Token": appCtx.authToken,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     console.log(response.data);
  //     if (response.data) {
  //       toast.success("Token added successfully");

  //       setLoading(false);
  //       closeModal();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };

  const width = window.screen.width;
  let isMobileDevice = width < 600;

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: 9999,
      backgroundColor: "#000000",
      border: "1px solid  #000000",
      color: "black",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      width: `${isMobileDevice ? "95%" : "auto"}`,
    },
  };
  function closeModal() {
    setOpenModal(false);
  }

  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    setBurnerLoading(true);
    makeRequest(
      {
        url: `${process.env.REACT_APP_VORTX_URL}/burner/master/list`,
      },
      (data) => {
        setBurners(data);
        setBurnerLoading(false);
      },
      (error) => {
        setBurnerLoading(false);
      }
    );
  }, []);

  const items = [
    { id: 1, label: "Factory" },
    { id: 2, label: "Logistic" },
    { id: 3, label: "Travel" },
    { id: 4, label: "Electricity" },
    { id: 5, label: "Water" },
    { id: 6, label: "Packaging" },
    { id: 7, label: "Others" },
  ];

  const handleRowClick = (item) => {
    setSelectedItem(item.id);
  };

  return (
    <div>
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div
          className="w-100  position-relative"
          style={{
            backgroundColor: "#000000",
            minWidth: "400px",
          }}
        >
          <div className="d-flex justify-content-end  position-relative">
            <button
              onClick={closeModal}
              className="btn btn-link position-absolute top-1 right-2"
            >
              <i className="fa fa-times"></i>
            </button>
          </div>

          <div className="p-3">
            <h4 className="text-center mt-2">Upload Burner</h4>
            <AddBurnerTimeline currentIndex={currentIndex} />
            {currentIndex === 0 && (
              <div className="mb-2">
                <div className="row">
                  <div className="col-xl-12 col-sm-12">
                    <div className="table-responsive dataTabletrade">
                      <table
                        id="example-2"
                        className="table display orderbookTable"
                        style={{ minWidth: "845px" }}
                      >
                        <thead>
                          <tr>
                            <th className="text-center">S no.</th>
                            <th className="text-center">Burner Type</th>
                            <th className="text-center">Select</th>
                          </tr>
                        </thead>
                        <tbody>
                          {burnerLoading ? (
                            <tr>
                              <td colSpan="3" className="w-100">
                                <div className="d-flex justify-content-center align-items-center py-5">
                                  <CircularProgress
                                    style={{
                                      color: "#BBFF00",
                                      fontSize: "10px",
                                      width: "25px",
                                      height: "25px",
                                    }}
                                    className="ml-2"
                                    fontSize="small"
                                  />
                                </div>
                              </td>
                            </tr>
                          ) : !burnerLoading && burners?.length === 0 ? (
                            <tr>
                              <td colSpan="5" className="w-100">
                                <div className="d-flex justify-content-center align-items-center text-center py-5">
                                  Burner's List is not available right now
                                </div>
                              </td>
                            </tr>
                          ) : (
                            burners?.map((item, index) => (
                              <tr
                                key={item.id}
                                style={{ cursor: "pointer" }}
                                onClick={() => handleRowClick(item)}
                                className={
                                  selectedItem === item.id
                                    ? "table-primary"
                                    : ""
                                }
                              >
                                <td className="text-center">{index + 1}</td>
                                <td className="text-center">{item.label}</td>
                                <td className="text-center">
                                  <div className="form-check d-flex justify-content-end align-items-center pe-2">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="sequesterSelection"
                                      id={`sequesterSelection-${item.id}`}
                                      checked={selectedItem === item.id}
                                      onChange={() => handleRowClick(item)}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                        <tbody></tbody>
                      </table>
                    </div>
                    <div className="w-100 d-flex justify-content-center my-4">
                      <button
                        disabled={!selectedItem}
                        onClick={() => {
                          setCurrentIndex(1);
                        }}
                        className="btn btn-upload px-5 py-2"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {currentIndex === 1 && (
              <div className="">
                <div>
                  <div className=" justify-content-center">
                    <h6 className="text-center">Choose a File to Upload</h6>
                    <p className="mb-0 text-center mt-2">
                      Choose and upload a doc, photo video in PDF, Excel,
                      CSV,JSON, PNG, JPG, SVG, AVI, MP4, WMV OR MOV formate
                    </p>
                    <div className="w-75 mx-auto mt-2">
                      <AddDataFileUpload setCurrentIndex={setCurrentIndex} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AddBurnerModal;
