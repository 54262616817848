import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { BiChevronDown } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import Modal from "react-modal";
import TokenSelectionDropdown from "../../tokenSelectionDropdown/TokenSelectionDropdown";
import { useWalletView } from "../../../hooks/useWalletView";
import { nativeToken } from "../../../config";

function CustodialSwap({
  openModal,
  setOpenModal,
  allCoin,
  allCoinPrice,
  usersToken,
  setOpenTransferSuccessModal,
  setTransactionData,
  closeSwapModal,
  symbol,
  isTokenDetailsPage,
}) {
  const [tokenInChain, setTokenInChain] = useState([]);
  const [sendToken, setSendToken] = useState("");
  const [sendTokenId, setSendTokenId] = useState("");
  const [sendTokenPrice, setSendTokenPrice] = useState("");
  const [sendTokenUserBalance, setSendTokenUserBalance] = useState(0);
  const [receiveTokenUserBalance, setReceiveTokenUserBalance] = useState(0);
  const [receiveTokenPrice, setReceiveTokenPrice] = useState("");
  const [sendAmount, setSendAmount] = useState("");
  const [receiveToken, setReceiveToken] = useState("");
  const [receiveTokenId, setReceiveTokenId] = useState("");
  const [receiveAmount, setReceiveAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [sendTokenName, setsendTokenName] = useState(null);
  const [receiveTokenName, setReceiveTokenName] = useState(null);
  const [sendTokenData, setSendTokenData] = useState({});
  const [sendTokenPriceLoading, setsendTokenPriceLoading] = useState(false);

  const [nativeTokenPriceLoading, setNativeTokenPriceLoading] = useState(false);
  const [nativeTokenData, setNativeTokenData] = useState({});

  const appCtx = useSelector((state) => state.app);
  const componentRef = useRef(null);
  const { getSingleTokenBalance } = useWalletView();

  function closeModal() {
    setOpenModal(false);
    // setTokenInChain([]);
    setSendToken(null);
    setSendTokenId(null);
    setSendTokenPrice(null);
    setSendTokenUserBalance(0);
    setReceiveTokenUserBalance(null);
    setReceiveTokenPrice(null);
    setSendAmount(null);
    setReceiveToken(null);
    setReceiveTokenId(null);
    setReceiveAmount(null);
    setSuccess(false);
  }

    const isWalletsRoute = window.location.pathname === "/wallet";
    let blockchain = isWalletsRoute
      ? appCtx?.walletBlockchain
      : appCtx?.blockchain;

  useEffect(() => {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/search?blockchain=${blockchain}`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        setTokenInChain(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [blockchain]);

  const width = window.screen.width;
  let isMobileDevice = width < 600;

  const usersWalletAddress = appCtx?.walletAddress?.find(
    (addr) => addr?.blockchain === blockchain
  )?.address;

  useEffect(() => {
    const sendTokenPrice = allCoinPrice[sendToken]?.price;
    const receiveTokenPrice = allCoinPrice[receiveToken]?.price;
    if (sendTokenPrice && receiveTokenPrice) {
      const receiveAmount = (
        (parseFloat(sendAmount) * parseFloat(sendTokenPrice)) /
        parseFloat(receiveTokenPrice)
      )?.toFixed(4);

      console.log("receiveAmount", receiveAmount);
      setReceiveAmount(receiveAmount);
    } else {
      setReceiveAmount("");
    }
  }, [receiveToken, sendAmount, allCoinPrice, sendToken]);

  useEffect(() => {
    console.log("logging allcoin from custodial swap", allCoin);
    console.log("logging sendToken", sendToken);
    const send = allCoin.find((token) => token?.symbol === sendToken);
    const sendprice = allCoinPrice[sendToken]?.price;
    setSendTokenPrice(sendprice);
    console.log("logging send", send);
    setSendTokenId(send?.tokenId);

    setSendTokenUserBalance(sendTokenData?.balance);
    const receive = allCoin.find((token) => token?.symbol === receiveToken);

    console.log("logging receive", receive);
    const receiveprice = allCoinPrice[receiveToken]?.price;
    setReceiveTokenPrice(receiveprice);
    setReceiveTokenId(receive?.tokenId);
    setReceiveTokenUserBalance(receive?.balance);
  }, [allCoin, sendToken, receiveToken, sendTokenData]);

  console.log("logging receivetokenid", receiveTokenId);

  const handleSendAmountChange = (event) => {
    setSendAmount(event.target.value);
  };

  const handleMaxButtonClick = () => {
    setSendAmount(sendTokenUserBalance);
  };

  const handleSubmit = async () => {
    if (nativeTokenData?.balance < 0.0003) {
      toast.error(
        `You don't have enough ${
          nativeToken[blockchain]
        } to pay the gas fee`
      );
      return;
    }
    if (sendAmount > sendTokenUserBalance) {
      toast.error("Insufficient Balance");
      return;
    }

    setLoading(true);

    let data = JSON.stringify({
      fromTokenId: parseInt(sendTokenId),
      toTokenId: parseInt(receiveTokenId),
      amount: parseFloat(sendAmount),
      blockchain: blockchain,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/evm/erc20/token/swap`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
        "Content-Type": "application/json",
        "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);

          setOpenTransferSuccessModal(true);
          closeModal();
          setTransactionData(response.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Token swap failed!");
      });
  };

  console.log("usersToken", usersToken);

  const [sendInputValue, setSendInputValue] = useState("");
  const [sendOpen, setSendOpen] = useState(false);
  const [receiveInputValue, setReceiveInputValue] = useState("");
  const [receiveOpen, setReceiveOpen] = useState(false);
  const handleClick = (event) => {
    event.preventDefault();
    // Check if the clicked element has the 'button-id' ID
    if (event.target.id === "sendButton") {
      setSendOpen(true);
    } else if (
      event.target.id === "searchbar" ||
      event.target.id === "searchIcon"
    ) {
    } else if (event.target.id === "receiveButton") {
      setReceiveOpen(true);
    } else {
      setSendOpen(false);
      setReceiveOpen(false);
    }
  };

  useEffect(() => {
    if (sendTokenId) {
      getSingleTokenBalance(
        usersWalletAddress,
        blockchain,
        sendTokenId,
        setSendTokenData,
        setsendTokenPriceLoading
      );
    }
  }, [sendTokenId]);
  // get native token balance
  useEffect(() => {
    let nativeTokenId = allCoin.find(
      (token) => token?.symbol === nativeToken[blockchain]
    )?.tokenId;

    if (nativeTokenId) {
      getSingleTokenBalance(
        usersWalletAddress,
        blockchain,
        nativeTokenId,
        setNativeTokenData,
        setNativeTokenPriceLoading
      );
    }
  }, [blockchain, allCoin]);

  // initial token for token details swap
  useEffect(() => {
    if (isTokenDetailsPage) {
      let initialSelecteToken =
        allCoin && allCoin?.find((token) => token?.symbol === symbol);

      setSendToken(initialSelecteToken?.symbol);
      setsendTokenName(initialSelecteToken?.tokenName);
    }
  }, [blockchain, allCoin]);

  return (
    <div className="p-4 position-relative">
      <div className="w-100 d-flex justify-content-end m-2 position-relative">
        {!success && (
          <div
            onClick={closeModal}
            className="btn btn-link position-absolute top-0 right-2"
            style={{ cursor: "pointer" }}
          >
            <i className="fa fa-times" style={{ fontSize: "20px" }}></i>
          </div>
        )}
      </div>
      <div className="w-100" ref={componentRef} onClick={handleClick}>
        {!success && (
          <>
            <h3 class="text-center mb-3">Swap Tokens</h3>
            <form class="d-flex flex-column align-items-center">
              <div class="w-100 max-w-md my-2">
                <h5 class="">Select Token</h5>
                {sendToken && (
                  <p>
                    I have {sendTokenUserBalance?.toFixed(6)} {sendToken}
                  </p>
                )}

                <div class="d-flex justify-content-between align-items-start">
                  <div class="flex-grow  position-relative">
                    {/* dropdown  */}

                    <div className="position-relative">
                      <div
                        onClick={() => setSendOpen(!sendOpen)}
                        className={`form-select`}
                        style={{
                          width: `${isMobileDevice ? "150px" : "210px"}`,
                          border: `${
                            appCtx?.isDarkMode ? "1 px solid #F6F6F6" : "1 px solid #F6F6F6"
                          }`,
                          borderRadius: "5px",
                          backgroundColor: `${
                            appCtx.isDarkMode ? "#000000" : "#000000"
                          }`,
                          color: `${appCtx.isDarkMode ? "#717579" : "#717579"}`,
                          cursor: "pointer",
                        }}
                      >
                        <p
                          className="mb-0"
                          style={{ paddingTop: "2px", paddingBottom: "2px" }}
                          id="sendButton"
                        >
                          {sendTokenName
                            ? sendTokenName?.length > 15
                              ? sendTokenName?.substring(0, 15) + "..."
                              : sendTokenName
                            : "Select token"}
                        </p>
                      </div>
                      {sendOpen && (
                        <TokenSelectionDropdown
                          searchTerm={sendInputValue}
                          setSearchTerm={setSendInputValue}
                          allCoin={allCoin}
                          isDrodownOpen={sendOpen}
                          setIsDropdownOpen={setSendOpen}
                          setToken={setSendToken}
                          setTokenName={setsendTokenName}
                          additionalStyles={{ top: "-60px", left: "0px" }}
                        />
                      )}
                    </div>

                    {sendTokenPrice && (
                      <label htmlFor="">${sendTokenPrice?.toFixed(5)}</label>
                    )}
                  </div>
                  <div class="position-relative d-flex">
                    <div class="position-relative d-flex align-items-center">
                      <input
                        id="sendAmount"
                        placeholder="Enter amount"
                        name="sendAmount"
                        type="number"
                        min="0"
                        step="any"
                        value={sendAmount}
                        onChange={handleSendAmountChange}
                        class="form-control"
                        style={{
                          width: `${isMobileDevice ? "180px" : "210px"}`,
                        }}
                      />
                      <button
                        type="button"
                        class="position-absolute text-sm text-gray-700 bg-transparent border border-gray-400 rounded px-2 py-1"
                        onClick={handleMaxButtonClick}
                        style={{
                          top: "50%",
                          transform: "translateY(-50%)",
                          right: "5px",
                        }}
                      >
                        <h6 class="font-s12 font-w400 mb-0">max</h6>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center my-3 w-100">
                  <img src="/images/doublearrow.svg" alt="" />
                </div>
                <div>
                  {receiveToken && <p>I want {receiveToken}</p>}

                  <div class="d-flex justify-content-between w-100 rounded-lg">
                    <div class="flex-grow  position-relative">
                      {/* receive dropdown  */}

                      <div className="position-relative">
                        <div
                          onClick={() => setReceiveOpen(!receiveOpen)}
                          className={`form-select`}
                          style={{
                            width: `${isMobileDevice ? "150px" : "210px"}`,
                            border: `${
                              appCtx?.isDarkMode ? "1 px solid #F6F6F6" : "1 px solid #F6F6F6"
                            }`,
                            borderRadius: "5px",
                            backgroundColor: `${
                              appCtx.isDarkMode ? "#000000" : "#000000"
                            }`,
                            color: `${
                              appCtx.isDarkMode ? "#717579" : "#717579"
                            }`,
                            cursor: "pointer",
                          }}
                        >
                          <p
                            className="mb-0"
                            style={{ paddingTop: "2px", paddingBottom: "2px" }}
                            id="receiveButton"
                          >
                            {receiveTokenName
                              ? receiveTokenName?.length > 15
                                ? receiveTokenName?.substring(0, 15) + "..."
                                : receiveTokenName
                              : "Select token"}
                          </p>
                        </div>
                        {receiveOpen && (
                          <TokenSelectionDropdown
                            searchTerm={receiveInputValue}
                            setSearchTerm={setReceiveInputValue}
                            allCoin={allCoin}
                            isDrodownOpen={receiveOpen}
                            setIsDropdownOpen={setReceiveOpen}
                            setToken={setReceiveToken}
                            setTokenName={setReceiveTokenName}
                            additionalStyles={{ top: "-150px", left: "0px" }}
                          />
                        )}
                      </div>

                      {receiveTokenPrice && (
                        <label htmlFor="">
                          ${receiveTokenPrice?.toFixed(5)}
                        </label>
                      )}
                    </div>
                    <div class="position-relative d-flex">
                      <input
                        id="receiveAmount"
                        placeholder="Amount you will get"
                        name="receiveAmount"
                        type="number"
                        min="0"
                        step="any"
                        value={receiveAmount}
                        disabled
                        class="form-control"
                        style={{
                          width: `${isMobileDevice ? "180px" : "210px"}`,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-4 w-100">
                  <button
                    onClick={handleSubmit}
                    className="btn btn-primary px-5 d-flex align-items-center gap-2  "
                  >
                    <p className="mb-0">Swap</p>
                    <div>
                      {loading && (
                        <CircularProgress size={20} className="ml-[5px]" />
                      )}
                    </div>
                  </button>
                </div>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
}

export default CustodialSwap;
