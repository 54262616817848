import React, { useEffect } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getBlockchainTransactionUrl } from "../../../config";
import useAuthorizedHttp from "../../../hooks/use-authorized-http";

const SuccessModal = ({
  openModal,
  setOpenModal,
  amount,
  option,
  address,
  setPreviousOpen,
  setConfirmOpen,
  transactionHash,
  fundId,
  isCryptoFundTransfer,
}) => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const makeRequest = useAuthorizedHttp();

  let usersWalletAddress = appCtx?.walletAddress?.find(
    (addr) => addr?.blockchain === appCtx?.blockchain
  )?.address;
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: 9999,
      backgroundColor: "#000000",
      border: "1px solid  #000000",
      color: "black",

      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "20px",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
    },
  };
  function closeModal() {
    setOpenModal(false);
    setPreviousOpen(false);
    setConfirmOpen(false);
    navigate("/");
  }

  useEffect(() => {
    if (isCryptoFundTransfer) {
      const data = {
        transactionId: transactionHash,
        userAddress: usersWalletAddress,
        usdtDecimal: 6, //decimal of usdt
        currency: "USDT",
        amount: amount,
        fundId: fundId,
      };

      makeRequest(
        {
          url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/fund/${fundId}/verifyDepositAndIssueToken`,
          data,
          method: "post",
        },
        (data) => {
          // setLoader(false);
        },
        (error) => {},
        () => {}
      );
    }
  }, []);

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="mt-4 font-w400">
        <h4 className=" text-2xl">
          {isCryptoFundTransfer
            ? "Transaction submitted to blockchain"
            : "Transaction Successful"}
        </h4>

        <p className="mt-4 mb-3 d-flex gap-2 text-sm">
          <span className="font-w600">Send Amount :</span> {amount} {option}
        </p>
        <p className="mb-3 d-flex gap-2 text-sm">
          <span className="font-w600">from :</span> {usersWalletAddress}
        </p>
        <p className="d-flex gap-2 text-sm">
          <span className="font-w600">to :</span>
          {address}
        </p>
        <p className="d-flex gap-2 text-sm">
          <span className="font-w600">Transaction Hash :</span>
          {transactionHash}
        </p>

        <div class="w-100 mt-4 d-flex gap-4">
          <div class="w-50">
            <button
              onClick={() => {
                closeModal();
              }}
              class="mb-3 py-2 text-md font-weight-bold text-white bg-success border border-success rounded active:text-secondary focus:outline-none focus:ring w-100"
            >
              Close
            </button>
          </div>
          <div class="w-50">
            <button
              onClick={() => {
                const blockchain = appCtx?.blockchain;
                const blockchainLink = getBlockchainTransactionUrl(
                  blockchain,
                  transactionHash
                );
                if (blockchainLink !== "") {
                  window.open(blockchainLink);
                }
              }}
              class="mb-3 py-2 text-md font-weight-bold text-white bg-primary border border-primary rounded active:text-secondary hover:bg-transparent hover:text-primary focus:outline-none focus:ring w-100"
            >
              View transaction on chain
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SuccessModal;
