import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import Market from "../../pages/Market";
import Login from "../../pages/Login";

import CropBasket from "../../pages/cryptobasket/CropBasket";
import CryptoFundDetails from "../../pages/cryptoFund/CryptoFundDetails";
import Tokens from "../../pages/Tokens/Tokens";
import { TokenDetailsPage } from "../../pages/Tokens/TokenDetailsPage";
import Wallets from "../../pages/Wallets/Wallets";
import UsersDashboard from "../../pages/UsersDashboard";
import GenerateDataPage from "../../components/dashboardcard/GenerateDataPage"; // Import the new page
import Billing from "../../components/dashboardcard/Billing"
import ApiKey from "../../components/dashboardcard/apiKey"

import UsersCryptoFund from "../../pages/cryptoFund/UsersCryptoFund";
import PortFolio from "../../pages/portfolio/PortFolio";
import CryptolensComponet from "../../pages/cryptoLens/CryptolensComponet";
import Airdrop from "../Airdrop/Airdrop";
import AirdropDetails from "../Airdrop/AirdropDetails";
import KycVerification from "../KYC_verification/KycVerificationScreen";
import { Thankyou } from "../KYC_verification/Thankyou";
import { KycSelectCountry } from "../KYC_verification/KycSelectCountry";
import { KycUploadDocument } from "../KYC_verification/KycDocumentUpload";
import { FrontAndBackDocument } from "../KYC_verification/FrontAndBackDocument";
import { UploadSelfie } from "../KYC_verification/UploadSelfie";
import { KycStatus } from "../KYC_verification/KycStatus";
import Projects from "../../pages/projects/Projects";
import TermsAndCompliance from "../../pages/terms/terms";
import Privacy from "../../pages/terms/privacy";
import Help from "../../pages/Help";
import EsgReportMaker from "../../pages/esgReportMaker/EsgReportMaker";
import GimmiAiPage from "../../pages/gimmiAi/GimmiAiPage";
import EsgPools from "../../pages/esgPools/EsgPools";
import EsgPoolsDetails from "../../pages/esgPools/EsgPoolsDetails";
import BusinessDetails from "../../pages/business/BusinessDetails";
import Transactions from "../../pages/History/Transactions";
import ProjectsDetails from "../../pages/projects/ProjectsDetails";
import CropBasketDetails from "../../pages/cryptobasket/CropBasketDetails";

import DGUPlayground from "../../components/Playground/DGUPlayground";
import DGUPlayground1 from "../../components/Playground/DGUPlayground1";
import DGUPlayground2 from "../../components/Playground/DGUPlayground2";


export const RouterComponent = () => {
  const appCtx = useSelector((state) => state.app);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "shortcut icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = "/images/talelogo.png";
  }, []);

  console.log("appCtx.isLoggedIn", appCtx.isLoggedIn);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            !appCtx.isLoggedIn ? <Navigate to={"/login"} /> : <UsersDashboard />
          }
        />
        <Route
          path="/crypto-fund"
          element={
            !appCtx.isLoggedIn ? (
              <Navigate to={"/login"} />
            ) : (
              <UsersCryptoFund />
            )
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Login />} />

        {/* Market Route */}
        <Route
          path="/market"
          element={!appCtx.isLoggedIn ? <Navigate to="/login" /> : <Market />}
        />

        {/* Crypto Basket Route */}
        <Route
          path="/dgu-basket"
          element={
            !appCtx.isLoggedIn ? <Navigate to="/login" /> : <CropBasket />
          }
        />
        
        <Route path="/playground/dgu-playground" element={<DGUPlayground />} />
        <Route path="/playground/dgu-playground1" element={<DGUPlayground1 />} />
        <Route path="/playground/dgu-playground2" element={<DGUPlayground2 />} />




        {/* Portfolio Route */}
        <Route
          path="/portfolio"
          element={
            !appCtx.isLoggedIn ? <Navigate to="/login" /> : <PortFolio />
          }
        />

        {/* Transactions History Route */}
        {/* <Route
          path="/transactions"
          element={!appCtx.isLoggedIn ? <Navigate to="/login" /> : <History />}
        /> */}
        <Route
          path="/transactions"
          element={
            !appCtx.isLoggedIn ? <Navigate to="/login" /> : <Transactions />
          }
        />

        {/* Crypto Basket Details Route with parameters */}
        <Route
          path="/dgu-basket/:id"
          element={
            !appCtx.isLoggedIn ? (
              <Navigate to="/login" />
            ) : (
              <CropBasketDetails />
            )
          }
        />
        <Route path="/generate-data/:model" element={<GenerateDataPage />} />

        <Route path="/generate-data" element={<GenerateDataPage />} /> {/* New route */}
        <Route path="/billing" element={<Billing />} />
        <Route path="/api-key" element={<ApiKey />} />

        {/* Crypto Fund Details Route with parameters */}
        <Route
          path="/crypto-fund/:id"
          element={
            !appCtx.isLoggedIn ? (
              <Navigate to="/login" />
            ) : (
              <CryptoFundDetails />
            )
          }
        />

        {/* Cryptolens Route (probably intended path) */}
        <Route
          path="/cryptoLens"
          element={
            !appCtx.isLoggedIn ? (
              <Navigate to="/login" />
            ) : (
              <CryptolensComponet />
            )
          }
        />

        {/* Airdrop Route */}
        <Route
          path="/airdrop"
          element={!appCtx.isLoggedIn ? <Navigate to="/login" /> : <Airdrop />}
        />

        {/* Airdrop Details with parameters */}
        <Route
          path="/airdrop/:id"
          element={
            !appCtx.isLoggedIn ? <Navigate to="/login" /> : <AirdropDetails />
          }
        />
        <Route
          path="/tokens"
          element={!appCtx.isLoggedIn ? <Navigate to={"/login"} /> : <Tokens />}
        ></Route>
        <Route
          path="/token/:tokenid/:tokenname"
          element={
            !appCtx.isLoggedIn ? (
              <Navigate to={"/login"} />
            ) : (
              <TokenDetailsPage />
            )
          }
        ></Route>

        <Route
          path="kyc/verification"
          element={
            !appCtx.isLoggedIn ? <Navigate to={"/"} /> : <KycVerification />
          }
        />
        <Route
          path="/kyc/selectcountry"
          element={
            !appCtx.isLoggedIn ? <Navigate to={"/"} /> : <KycSelectCountry />
          }
        />
        <Route
          path="/kyc/upload"
          element={
            !appCtx.isLoggedIn ? <Navigate to={"/"} /> : <KycUploadDocument />
          }
        />
        <Route
          path="/kyc/upload/document"
          element={
            !appCtx.isLoggedIn ? (
              <Navigate to={"/"} />
            ) : (
              <FrontAndBackDocument />
            )
          }
        />
        <Route
          path="/kyc/upload/selfie"
          element={
            !appCtx.isLoggedIn ? <Navigate to={"/"} /> : <UploadSelfie />
          }
        />
        <Route
          path="/kyc/verification/status"
          element={!appCtx.isLoggedIn ? <Navigate to={"/"} /> : <KycStatus />}
        />
        <Route
          path="/kyc/information"
          element={!appCtx.isLoggedIn ? <Navigate to={"/"} /> : <Thankyou />}
        />
        <Route
          path="/terms-and-compliance"
          element={
            !appCtx.isLoggedIn ? <Navigate to={"/"} /> : <TermsAndCompliance />
          }
        />
        <Route
          path="/privacy"
          element={
            !appCtx.isLoggedIn ? <Navigate to={"/"} /> : <Privacy />
          }
        />
        <Route
          path="/business-details"
          element={
            !appCtx.isLoggedIn ? <Navigate to={"/"} /> : <BusinessDetails />
          }
        />
        <Route
          path="/projects"
          element={!appCtx.isLoggedIn ? <Navigate to={"/"} /> : <Projects />}
        />
        <Route
          path="/help"
          element={!appCtx.isLoggedIn ? <Navigate to={"/"} /> : <Help />}
        />

        <Route
          path="/projects"
          element={!appCtx.isLoggedIn ? <Navigate to={"/"} /> : <Projects />}
        />
        <Route
          path="/projects/:projectId"
          element={!appCtx.isLoggedIn ? <Navigate to="/" /> : <ProjectsDetails />}
        />
        <Route
          path="/gimmy-ai"
          element={!appCtx.isLoggedIn ? <Navigate to={"/"} /> : <GimmiAiPage />}
        />
        <Route
          path="/financials"
          element={!appCtx.isLoggedIn ? <Navigate to="/login" /> : <Wallets />}
        />

        <Route
          path="/esg-pools"
          element={!appCtx.isLoggedIn ? <Navigate to={"/"} /> : <EsgPools />}
        />

        <Route
          path="/esg-pool/:id"
          element={
            !appCtx.isLoggedIn ? <Navigate to={"/"} /> : <EsgPoolsDetails />
          }
        />

        <Route
          path="/esg-report-maker"
          element={
            !appCtx.isLoggedIn ? <Navigate to={"/"} /> : <EsgReportMaker />
          }
        />
        <Route
          path="/esg-lens"
          element={
            !appCtx.isLoggedIn ? (
              <Navigate to="/login" />
            ) : (
              <CryptolensComponet />
            )
          }
        />
      </Routes>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme={appCtx.isDarkMode ? "light" : "light"}
        toastStyle={
          appCtx.isDarkMode
            ? { backgroundColor: "#F8FAFC", color: "#1F2937" }
            : { backgroundColor: "#F8FAFC", color: "#1F2937" }
        }
      />
    </>
  );
};
