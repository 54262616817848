import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Table, Badge, Modal, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHistory,
  faCreditCard,
  faDownload,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import MuiCard from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import axios from "axios";
import MainWrapper from "../wrapper/MainWrapper";
import {
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

const Billing = () => {
  const theme = useTheme(); // Access theme object
  const [activeTab, setActiveTab] = useState("billingHistory");
  const [showPayModal, setShowPayModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [billingHistory, setBillingHistory] = useState([]);
  const [totalAmountDue, setTotalAmountDue] = useState(0);

  // Fetch user ID from local storage
  const userId = localStorage.getItem("userId");

  // Fetch billing history from API
  const fetchBillingHistory = async () => {
    try {
      const response = await axios.get(`https://backend.vortx.ai/user/${userId}/billing`, {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": process.env.REACT_APP_AUTH_TOKEN || "46eba9763c91dc8241437f1a307eab0a",
        },
      });
      setBillingHistory(response.data);

      // Calculate total unpaid amount
      const unpaidTotal = response.data
        .filter((invoice) => invoice.status !== "Paid")
        .reduce((acc, invoice) => acc + parseFloat(invoice.amount), 0);
      setTotalAmountDue(unpaidTotal);
    } catch (error) {
      console.error("Error fetching billing history:", error);
    }
  };

  useEffect(() => {
    if (userId) fetchBillingHistory();
  }, [userId]);

  const handlePayInvoice = (invoice) => {
    setSelectedInvoice(invoice);
    setShowPayModal(true);
  };

  const processPayment = () => {
    console.log("Payment initiated"); // Replace with Razorpay or other payment integration logic
    setShowPayModal(false);
  };

  const renderContent = () => {
    if (activeTab === "billingHistory") {
      return (
        <div>
          <MuiCard variant="blackBox" sx={{ mb: 4 }}>
            <CardContent>
              <Typography variant="h6" color={theme.palette.text.primary}>
                Total Amount Due
              </Typography>
              <Typography variant="h4" color={theme.palette.secondary.main}>
                ${totalAmountDue.toFixed(2)}
              </Typography>
            </CardContent>
          </MuiCard>
          <Typography variant="h6" sx={{ mb: 4 }}>
            <FontAwesomeIcon icon={faHistory} className="me-2" />
            Billing History
          </Typography>
          <MuiCard variant="blackBox" >
            <CardContent>
            <TableContainer>
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Date</TableCell>
        <TableCell>Invoice Number</TableCell>
        <TableCell>Amount</TableCell>
        <TableCell>Status</TableCell>
        <TableCell align="right">Actions</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {billingHistory.map((invoice) => (
        <TableRow key={invoice.id}>
          <TableCell>{invoice.date}</TableCell>
          <TableCell>{invoice.invoiceNumber}</TableCell>
          <TableCell>${invoice.amount}</TableCell>
          <TableCell>
            <Badge
              sx={{
                backgroundColor:
                  invoice.status === "Paid"
                    ? theme.palette.success.main
                    : theme.palette.warning.main,
                color:
                  invoice.status === "Paid"
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary,
                px: 2,
                py: 1,
                borderRadius: "4px",
              }}
            >
              {invoice.status}
            </Badge>
          </TableCell>
          <TableCell align="right">
            <Button
              size="small"
              sx={{ color: theme.palette.primary.main }}
              onClick={() => alert(`Viewing invoice ${invoice.invoiceNumber}`)}
            >
              <FontAwesomeIcon icon={faEye} /> View
            </Button>
            <Button
              size="small"
              sx={{ color: theme.palette.primary.main }}
              onClick={() => alert(`Downloading invoice ${invoice.invoiceNumber}`)}
            >
              <FontAwesomeIcon icon={faDownload} /> Download
            </Button>
            {invoice.status !== "Paid" && (
              <Button
                size="small"
                sx={{ color: theme.palette.secondary.main }}
                onClick={() => handlePayInvoice(invoice)}
              >
                <FontAwesomeIcon icon={faCreditCard} /> Pay
              </Button>
            )}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>;
            </CardContent>
          </MuiCard>
        </div>
      );
    }
    return null;
  };

  return (
    <MainWrapper>
      <div className="container-fluid" style={{ paddingBottom: "70px" ,paddingTop:"50px"}}>
        {/* Page Header */}
        <Row className="mb-4">
          <Col xs={12}>
            <Typography variant="h4" >  
              Billing Dashboard
            </Typography>
          </Col>
        </Row>

        {/* Navigation Tabs */}
        <Row className="mb-4">
          <Col xs={12}>
            <ul className="nav nav-pills">
              <li className="nav-item">
                <Button
                  sx={{
                    textTransform: "none",
                    color: activeTab === "billingHistory" ? theme.palette.secondary.main : theme.palette.text.secondary,
                  }}
                  onClick={() => setActiveTab("billingHistory")}
                >
                  <FontAwesomeIcon icon={faHistory} className="me-2" />
                  Monthly Billing Cycle
                </Button>
              </li>
            </ul>
          </Col>
        </Row>

        {/* Main Content */}
        <Row>{renderContent()}</Row>

        {/* Pay Invoice Modal */}
        <Modal show={showPayModal} onHide={() => setShowPayModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Pay Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedInvoice && (
              <div>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>Invoice Number:</strong> {selectedInvoice.invoiceNumber}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>Amount Due:</strong> ${selectedInvoice.amount}
                </Typography>
                <Form>
                  <Form.Group controlId="paymentMethod" className="mb-3">
                    <Form.Label>Select Payment Method</Form.Label>
                    <Form.Select>
                      <option value="card">Credit/Debit Card</option>
                      <option value="paypal">PayPal</option>
                      <option value="crypto">Crypto Wallet</option>
                    </Form.Select>
                  </Form.Group>
                  <Button
                    sx={{
                      backgroundColor: theme.palette.secondary.main,
                      color: theme.palette.text.primary,
                      "&:hover": {
                        backgroundColor: theme.palette.secondary.dark,
                      },
                    }}
                    onClick={processPayment}
                  >
                    Pay Now
                  </Button>
                </Form>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </MainWrapper>
  );
};

export default Billing;
