import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import CreateBasketModal from "./CreateCryptoFundModal";
import { useSelector } from "react-redux";
import CryptoFundCard from "./CryptoFundCard";
import { useCryptoFund } from "./useCryptoFund";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import UsersCryptoFundCard from "./UsersCryptoFundCard";
import MainWrapper from "../../components/wrapper/MainWrapper";
import { CircularProgress } from "@mui/material";
import AllCryptoFundCard from "./AllCryptoFundCard";

const UsersCryptoFund = () => {
   const {
     fetchUsersCryptoFund,
     usersCryptoFund,
     usersCryptoFundLoading,
     allCryptoFund,
     setAllCryptoFund,
     fetchAllCryptoFund,
     allCryptoFundLoading,
     fetchAllCryptoFundsStats,
     allFundsStats,
     allCryptoFundsStatsLoading,
   } = useCryptoFund();
   const makeRequest = useAuthorizedHttp();

   const appCtx = useSelector((state) => state.app);

   useEffect(() => {
     fetchUsersCryptoFund();
     fetchAllCryptoFund();
   }, []);

   useEffect(() => {
     let fundIds = allCryptoFund?.map((item) => item.fundId);
     if (fundIds?.length !== 0) {
       fetchAllCryptoFundsStats(fundIds);
     }
   }, [allCryptoFund?.length]);
   console.log("allFundsStats", allFundsStats);

   return (
     <MainWrapper>
       <div className="">
         <div className="container-fluid">
           <div className="row">
             {/* my fund  */}
             <div className="col-12 my-order-ile">
               {!usersCryptoFundLoading && usersCryptoFund?.length === 0 ? (
                 <img
                   src="/images/cryptofundlong.png"
                   alt=""
                   style={{ width: "100%" }}
                   className="my-2"
                 />
               ) : (
                 <div className="card">
                   <div className="card-header border-0 pb-3">
                     <h4 className="card-title">My Investments</h4>
                   </div>
                   <div className="card-body px-0 pt-0">
                     {usersCryptoFundLoading ? (
                       <div
                         className="d-flex justify-content-center align-items-center"
                         style={{ minHeight: "100px" }}
                       >
                         <CircularProgress
                           style={{
                             fontSize: "10px",
                             width: "25px",
                             height: "25px",
                           }}
                           className="py-10"
                         />
                       </div>
                     ) : (
                       <>
                         {usersCryptoFund?.map((item) => (
                           <UsersCryptoFundCard item={item} />
                         ))}
                       </>
                     )}
                   </div>
                 </div>
               )}
             </div>
             {/* all fund  */}
             <div class="col-12 my-order-ile">
               <div class="card">
                 <div class="card-header border-0 pb-3">
                   <h4 class="card-title">All Crypto Funds</h4>
                 </div>
                 <div class="card-body px-0 pt-0 ">
                   {allCryptoFundLoading ? (
                     <div
                       class="d-flex justify-content-center align-items-center "
                       style={{ minHeight: "100px" }}
                     >
                       <CircularProgress
                         sryle={{ fontSize: "10px" }}
                         className="py-10"
                         fontSize="small"
                         style={{ width: "25px", height: "25px" }}
                       />
                     </div>
                   ) : !allCryptoFundLoading && allCryptoFund?.length === 0 ? (
                     <div
                       class="alert alert-danger alert-dismissible fade show"
                       style={{
                         width: "60%",
                         margin: "50px auto",
                         textAlign: "center",
                       }}
                     >
                       <svg
                         viewBox="0 0 24 24"
                         width="24"
                         height="24"
                         stroke="currentColor"
                         stroke-width="2"
                         fill="none"
                         stroke-linecap="round"
                         stroke-linejoin="round"
                         class="me-2 alert-icon"
                       >
                         <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                         <line x1="15" y1="9" x2="9" y2="15"></line>
                         <line x1="9" y1="9" x2="15" y2="15"></line>
                       </svg>
                       No Crypto Fund Found
                       <button
                         type="button"
                         class="btn-close"
                         data-bs-dismiss="alert"
                         aria-label="btn-close"
                       ></button>
                     </div>
                   ) : (
                     <>
                       {!allCryptoFundLoading &&
                         allCryptoFund?.map((item) => (
                           <AllCryptoFundCard
                             item={item}
                             allFundsStats={allFundsStats}
                           />
                         ))}
                     </>
                   )}
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </MainWrapper>
   );
};

export default UsersCryptoFund;
