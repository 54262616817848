import React, { useEffect, useState } from "react";
import "./App.css";
import "@fontsource/poppins";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouterComponent } from "./components/common/RouterComponent"; // Ensure the correct import type
//import "./css/style.css";
import Preloader from "./components/preloader/preloader"; // Import the Preloader component
import { GoogleOAuthProvider } from "@react-oauth/google"; // Import GoogleOAuthProvider
import ReactGA from "react-ga4"; // Import ReactGA for Google Analytics

// Initialize Google Analytics
const TRACKING_ID = "G-Z81FB5M3NK"; // Replace with your Google Analytics Tracking ID
ReactGA.initialize(TRACKING_ID);

// Analytics Tracker Component
const AnalyticsTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // Track page views on route changes
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null;
};

function AppContent() {
  const [loading, setLoading] = useState(true); // State to manage loading

  useEffect(() => {
    // Simulate a loading delay for the preloader (2 seconds)
    const timer = setTimeout(() => {
      setLoading(false); // Set loading to false after 2 seconds
    }, 2000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  return (
    <>
      {loading && <Preloader />} {/* Show preloader while loading is true */}
      {!loading && <RouterComponent />} {/* Once loading is complete, show the app */}
    </>
  );
}

export default function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router>
        {/* Analytics Tracker to track route changes */}
        <AnalyticsTracker />
        {/* Main App Content */}
        <AppContent />
      </Router>
    </GoogleOAuthProvider>
  );
}
