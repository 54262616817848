// src/pages/Login.jsx

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"; // For accessing Redux store
import {
  Button,
  TextField,
  Box,
  Typography,
  Paper,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
import { appActions } from "../context/app-slice";
import useAuthorizedHttp from "../hooks/use-authorized-http";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
//import { useGoogleLogin } from "@react-oauth/google"; // Import useGoogleLogin
import {jwtDecode} from 'jwt-decode'; // Corrected import
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from '@mui/material/styles';
import './googlebutton.css'; // Ensure the path is correct

import {
  handleSendOtp,
  handleCheckOtp,
  formatTime,
  handleSubmitDetails,
  sendOtp,
  verifyOtp,
  blockchainLogin,
  blockchainWalletVerify,
  googleSignup,
  updateUserProfile,
} from "../components/api/services";

import{googleSigninApi,
  blockchainLoginApi,
  blockchainWalletVerifyApi,
  googleSignupApi,
  updateUserProfileApi} from "../components/api/api";
//
//import MetaMaskLoginButton from 'react-metamask-login-button';


const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize navigate
  const makeRequest = useAuthorizedHttp();

  const theme = useTheme();

  // Redux State Selectors
  const authToken = useSelector((state) => state.app.authToken);
  const userId = useSelector((state) => state.app.userDetails.userId);
  const isLoggedIn = useSelector((state) => state.app.isLoggedIn);

  const [userData, setUserData] = useState({ email: "", otp: "" });
  const [clickedOnLoginOtp, setClickedOnLoginOtp] = useState(false);
  const [loader, setLoader] = useState(false);
  const [timer, setTimer] = useState(300); // 5 minutes in seconds

  // Snackbar state
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // MetaMask related states
  const [isMetaMaskRequesting, setIsMetaMaskRequesting] = useState(false);
  const [nonCustodialLoader, setNonCustodialLoader] = useState(false);
  const [metamaskClicked, setMetamaskClicked] = useState(false);
  const [loginMethod, setLoginMethod] = useState(null); // To track login method
  const [showDetailsForm, setShowDetailsForm] = useState(false);
  const [additionalDetails, setAdditionalDetails] = useState({
    firstName: "",
    lastName: "",
    //mobileNumber: "",
  });

  // Password visibility state (if applicable)
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  // Handle Snackbar close
  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Handle input changes for login form
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevValue) => ({ ...prevValue, [name]: value }));
  };

  // Handle input changes for additional details form
  const handleDetailsChange = (e) => {
    const { name, value } = e.target;
    setAdditionalDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Toggle password visibility (if applicable)
  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  // Define handleGoogleLogin BEFORE using it in useGoogleLogin
  const handleGoogleLogin = async (credentialResponse) => {
    if (!credentialResponse?.credential) {
      setSnackbar({
        open: true,
        message: "Invalid Google credentials.",
        severity: "error",
      });
      return;
    }

    try {
      // Decode the JWT token to get user information
      const decoded = jwtDecode(credentialResponse.credential);
      
      // Call the backend API to verify and register/login the user
      const response = await googleSigninApi({
        email: decoded.email,
        firstName: decoded.given_name,
        lastName: decoded.family_name,
        googleId: decoded.sub,
        picture: decoded.picture,
        name: decoded.name,
        loginType: "GOOGLE",
        //credential: credentialResponse.credential,
        // Add all required fields with values from Google response
        mobile: "",  // These optional fields can be updated later if needed
        country: "",
        website: "",
        country_billing: "",
        business_name: "",
        business_address: "",
        tax_id: "",
        currency: "",
        userName: decoded.name
      });

      // Get user data from response
      const userData = response.data;

      // Store all user details in localStorage
      const fieldsToStore = {
        userId: userData.userId,
        authToken: userData.authToken,
        firstName: userData.firstName || decoded.given_name, // Fallback to Google data
        lastName: userData.lastName || decoded.family_name,  // Fallback to Google data
        userName: userData.userName || decoded.name,         // Fallback to Google data
        mobile: userData.mobile,
        email: userData.email || decoded.email,             // Fallback to Google data
        country: userData.country,
        website: userData.website,
        country_billing: userData.country_billing,
        business_name: userData.business_name,
        business_address: userData.business_address,
        taxIdName: userData.tax_id,
        currency: userData.currency
      };

      // Store each field in localStorage if it exists
      Object.entries(fieldsToStore).forEach(([key, value]) => {
        if (value) {
          window.localStorage.setItem(key, value);
        }
      });

      // Update Redux state
      dispatch(appActions.updateUserDetails(userData));
      dispatch(appActions.updateAuthToken(userData.authToken));
      dispatch(appActions.login(true));

      // Show success message and navigate
      setSnackbar({
        open: true,
        message: "Login Successful!",
        severity: "success",
      });
      navigate("/");

    } catch (error) {
      console.error("Google Login Error:", error);
      setSnackbar({
        open: true,
        message: error.response?.data?.message || "Google Sign-In failed. Please try again.",
        severity: "error",
      });
    }
  };

  // Initialize Google Login AFTER defining handleGoogleLogin
  /*const login = useGoogleLogin({
    onSuccess: handleGoogleLogin,
    onError: () => {
      setSnackbar({
        open: true,
        message: "Google Sign-In was unsuccessful. Please try again.",
        severity: "error",
      });
    },
  });*/

  // Handle MetaMask Sign-in
  const handleMetamaskSignin = async () => {
    if (isMetaMaskRequesting) {
      setSnackbar({
        open: true,
        message:
          "MetaMask request is already in progress. Please complete the current request.",
        severity: "warning",
      });
      return;
    }

    if (!window.ethereum) {
      setSnackbar({
        open: true,
        message: "MetaMask not detected. Please install MetaMask extension.",
        severity: "error",
      });
      return;
    }

    setIsMetaMaskRequesting(true);
    setMetamaskClicked(true);

    try {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      await handleNonCustodialSignin(accounts[0]);
    } catch (error) {
      if (error.code === -32002) {
        setSnackbar({
          open: true,
          message:
            "A MetaMask request is already pending. Please check your MetaMask extension.",
          severity: "error",
        });
      } else {
        console.error(error);
        setSnackbar({
          open: true,
          message: "An error occurred while connecting to MetaMask.",
          severity: "error",
        });
      }
    } finally {
      setIsMetaMaskRequesting(false);
      setMetamaskClicked(false);
    }
  };

  // Handle Non-Custodial Sign-in (MetaMask)
  const handleNonCustodialSignin = async (walletAddress) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
        },
      };

      if (authToken) {
        config.headers["X-Auth-Token"] = authToken;
      }

      setNonCustodialLoader(true); // Show loading spinner

      // Use "ETHEREUM" as the blockchain
      const blockchain = "ETHEREUM";
      const wallet = "TALEWALLET";
      const product = "TALE_WALLET";

      // API call to initiate login
      const response = await axios.post(
        `${process.env.REACT_APP_NFTVERSE_DEV_API}/user/blockchain/crypto/account/login`,
        {
          blockchain,
          blockchainAddress: walletAddress,
          marketplaceAddress: "0",
          wallet,
          product,
        },
        config
      );
      console.log("Account Login API Response:", response.data);

      const message = response.data.secretKey;

      // Encrypt the secret key
      const keyEncrypt = CryptoJS.SHA256(
        JSON.stringify(response.data.secretKey)
      ).toString();
      dispatch(appActions.setEvmEncrypt(keyEncrypt));

      // Request signature from MetaMask
      const signature = await window.ethereum.request({
        method: "personal_sign",
        params: [message, walletAddress],
      });

      // Verify the signature
      const verifyResponse = await axios.post(
        `${process.env.REACT_APP_NFTVERSE_DEV_API}/user/blockchain/crypto/wallet/verify`,
        {
          blockchain,
          blockchainAddress: walletAddress,
          marketplaceAddress: "0", // Ensure this is included
          signedSecretKey: signature,
          userId: response.data.userId,
        },
        config
      );
      console.log("Verification Response:", verifyResponse.data);

      // Handle the verification response
      if (verifyResponse.data.role) {
        dispatch(appActions.setRole(verifyResponse.data.role));
      } else {
        dispatch(appActions.setRole("user"));
      }

      dispatch(appActions.updateAuthToken(verifyResponse.data.authToken));
      dispatch(
        appActions.updateUserDetails({
          ...verifyResponse.data,
          userId: verifyResponse.data.userId,
          firstName: verifyResponse.data.firstName,
          lastName: verifyResponse.data.lastName,
        })
      );

      // Store user details in localStorage
      window.localStorage.setItem("userId", verifyResponse.data.userId);
      window.localStorage.setItem("authToken", verifyResponse.data.authToken);
      window.localStorage.setItem("firstName", verifyResponse.data.firstName);
      window.localStorage.setItem("lastName", verifyResponse.data.lastName);
      if (verifyResponse.data.userName) {
        window.localStorage.setItem("userName", verifyResponse.data.userName);
      }

      dispatch(appActions.setBlockChain(blockchain));
      dispatch(appActions.setCustodial(false));

      // Check if essential user details are missing
      if (!verifyResponse.data.firstName || !verifyResponse.data.lastName) {
        // Show the additional details form
        setLoginMethod("metamask");
        setShowDetailsForm(true);
      } else {
        // Complete the login process
        dispatch(appActions.login(true));
        setSnackbar({
          open: true,
          message: "Wallet connected successfully",
          severity: "success",
        });
        console.log("MetaMask Login Response:", verifyResponse.data);
        navigate("/");
      }
    } catch (error) {
      console.error("Error connecting...", error);
      if (error.response) {
        console.error("API error response:", error.response.data);
        setSnackbar({
          open: true,
          message: `Error: ${
            error.response.data.message || "Failed to connect with MetaMask."
          }`,
          severity: "error",
        });
      } else {
        setSnackbar({
          open: true,
          message: "Failed to connect with MetaMask.",
          severity: "error",
        });
      }
    } finally {
      setNonCustodialLoader(false); // Hide loading spinner
      setIsMetaMaskRequesting(false);
      setMetamaskClicked(false);
    }
  };
  // Timer for OTP expiration
  useEffect(() => {
    if (clickedOnLoginOtp && timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [clickedOnLoginOtp, timer]);

  // Reset states on component unmount to prevent state leakage
  useEffect(() => {
    return () => {
      setShowDetailsForm(false);
      setLoginMethod(null);
      setUserData({
        email: "",
        otp: "",
      });
      setAdditionalDetails({
        firstName: "",
        lastName: "",
        // mobileNumber: "", // Uncomment if used
      });
      // ... reset other states if necessary
    };
  }, []);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: theme.palette.background.gradient,
        padding: 2,
        position: "relative",
      }}
    >
      {/* Logo */}
      <Box
        variant="logo"
        sx={{
          position: "absolute",
          top: 20,
          left: 20,
        }}
      >
        <a
          style={{
            ...theme.typography.logo,
            textDecoration: 'none',
          }}
          href="https://vortx.ai"
          target="_blank"
          rel="noopener noreferrer"
        >
          vortx.ai
        </a>
      </Box>

      {/* LinkedIn Link */}
      <Box
        variant="social_logo"
        sx={{
          position: "absolute",
          bottom: 20,
          left: 20,
          display: "flex",
          alignItems: "center",
        }}
      >
        <a
          href="https://www.linkedin.com/company/vortxai/"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            ...theme.typography.social_logo,
            textDecoration: 'none',
            display: "flex",
            alignItems: "center",
          }}
        >
          <LinkedInIcon style={{ marginRight: 10 }} />
          LinkedIn
        </a>
      </Box>

      {/* Centered Form */}
      <Paper variant="blackBox" elevation={3} sx={{ padding: 4, width: "100%", maxWidth: "400px" }}>
        {!showDetailsForm ? (
          <>
            {/* Email/OTP Login Section */}
            <Typography align="center" style={theme.typography.h6_grey} gutterBottom>
              Login with Email
            </Typography>

            <Box component="form" noValidate>
              {!clickedOnLoginOtp ? (
                <TextField
                  label="Email Address"
                  name="email"
                  value={userData.email}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    sx: { fontSize: 12, color: "grey.600" },
                  }}
                />
              ) : (
                <TextField
                  label="OTP"
                  name="otp"
                  value={userData.otp}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                  InputLabelProps={{
                    sx: { fontSize: 14, color: "grey.600" },
                  }}
                  type={isPasswordVisible ? "text" : "password"} // Optional: Toggle OTP visibility
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={togglePasswordVisibility} edge="end">
                          <FontAwesomeIcon
                            icon={isPasswordVisible ? faEye : faEyeSlash}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              <Button
                variant="dynamic" // Use the dynamic button variant
                className={userData.email || userData.otp ? "filled" : ""} // Add the 'filled' class dynamically
                fullWidth
                onClick={
                  !clickedOnLoginOtp
                    ? () => handleSendOtp(userData.email, setSnackbar, setLoader, setClickedOnLoginOtp, setTimer)
                    : () => handleCheckOtp(userData.email, userData.otp, dispatch, navigate, setSnackbar, setLoader, setShowDetailsForm, setLoginMethod)
                }
                disabled={!userData.email && !userData.otp} // Disable button if both email and OTP fields are empty
                endIcon={loader ? <CircularProgress size={20} color="inherit" /> : null}
                sx={{ marginTop: 2 }}
              >
                {!clickedOnLoginOtp ? "Send OTP" : "Verify OTP"}
              </Button>

              {clickedOnLoginOtp && (
                <Typography
                  variant="t2"
                  align="center"
                  sx={{ marginTop: 2 }}
                >
                  OTP expires in {formatTime(timer)}
                </Typography>
              )}
            </Box>

            {/* Divider */}
            {!showDetailsForm && (
              <Box sx={{ textAlign: "center", my: 2 }}>
                <Typography variant="t4_grey">
                  _____________________________
                </Typography>
              </Box>
            )}

            {/* MetaMask and Google Login Section */}
            {!showDetailsForm && (
              <Box
                component="form"
                noValidate
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {/* "Login with Other Providers" Text */}
                <Typography
                  variant="t3_grey"
                  align="center"
                  gutterBottom
                  //sx={{ color: "grey.600", fontSize: "0.875rem", mb: 1 }}
                >
                  Login with Other Providers
                </Typography>

                {/* Buttons Row */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row", // Align buttons horizontally
                    justifyContent: "center", // Center buttons horizontally
                    alignItems: "center", // Align buttons vertically
                    gap: 2, // Space between buttons
                    width: "100%", // Occupy full width
                    flexWrap: "nowrap", // Prevent wrapping to keep buttons on the same line
                  }}
                >
                  {/* MetaMask Button */}
                  <Button
                    onClick={handleMetamaskSignin}
                    variant="outlined"
                    sx={{
                      backgroundColor: "transparent",
                      color: "#FFFFFF",
                      textTransform: "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "8px 8px",
                      border: "1px solid rgba(255, 255, 255, 0.5)",
                      borderRadius: "8px",
                      gap: "10px",
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                        border: "1px solid #FFFFFF",
                      },
                      flex: 1, // Allow buttons to grow equally
                      minWidth: "140px", // Set a reasonable minimum width
                    }}
                  >
                    <img
                      src="/icons/metamask.png"
                      alt="MetaMask Icon"
                      style={{ width: "24px", height: "24px" }}
                    />
                    MetaMask
                  </Button>

                  {/* Custom Google Sign-In Button */}
                  <GoogleLogin
  onSuccess={handleGoogleLogin}
  onError={() =>
    setSnackbar({
      open: true,
      message: "Google Sign-In failed. Please try again.",
      severity: "error",
    })
  }
  useOneTap={false}
  text="signin_with"
  className="custom-google-button"
/>


                  {/*<Button
                    onClick={() => login()} // Trigger Google Sign-In
                    variant="outlined"
                    sx={{
                      backgroundColor: "transparent",
                      color: "#FFFFFF",
                      textTransform: "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      //padding: "10px 20px",
                      border: "1px solid rgba(255, 255, 255, 0.5)",
                      borderRadius: "8px",
                      gap: "10px",
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                        border: "1px solid #FFFFFF",
                      },
                      flex: 1, // Allow buttons to grow equally
                      minWidth: "140px", // Set a reasonable minimum width
                    }}
                  >
                    <img
                      src="/icons/google-icon.png" // Path to your Google icon in the public/icons folder
                      alt="Google"
                      style={{
                        height: "24px", // Icon height
                        marginRight: "5px", // Space between icon and text
                        border: "none", // Ensure the icon itself has no border
                      }}
                    />
                    <Typography
                      variant="t3"
                      sx={{
                        fontWeight: 500,
                        fontSize: "14px", // Font size for button text
                      }}
                    >
                       Google Login
                    </Typography>
                  </Button>*/}
                </Box>
              </Box>
            )}
          </>
        ) : (
          // Additional Details Form
          <Box component="form" noValidate>
            <Typography variant="h6" gutterBottom>
              Complete Profile
            </Typography>
            <TextField
              label="First Name"
              name="firstName"
              value={additionalDetails.firstName}
              onChange={handleDetailsChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Last Name"
              name="lastName"
              value={additionalDetails.lastName}
              onChange={handleDetailsChange}
              fullWidth
              margin="normal"
            />
            {/* Only show Mobile Number field if not using MetaMask */}
            {/* Uncomment if mobile number is required
            {!loginMethod === "metamask" && (
              <TextField
                label="Mobile Number"
                name="mobileNumber"
                value={additionalDetails.mobileNumber}
                onChange={handleDetailsChange}
                fullWidth
                margin="normal"
              />
            )}
            */}

<Button
  variant="contained"
  style={theme.components.MuiButtonForm}
  onClick={() =>
    handleSubmitDetails(
      additionalDetails, // Object containing firstName and lastName
      userId, // User ID from Redux state or localStorage
      dispatch, // Redux dispatch function
      setSnackbar, // Snackbar state setter
      navigate, // React Router navigate function
      setLoader // Loader state setter
    )
  }
  fullWidth
  disabled={loader}
  endIcon={loader ? <CircularProgress size={20} color="inherit" /> : null}
  sx={{ marginTop: 2 }}
>
  Submit
</Button>

          </Box>
        )}
      </Paper>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Login;
