import { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import styles from "./AddAirdropModal.module.css";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUpload, faDollarSign } from "@fortawesome/free-solid-svg-icons";
function AddAirdropModal({ openModal, setOpenModal }) {
  const [data, setData] = useState({
    tokenName: "",
    tokenSymbol: "",
    description: "",
    project: "",
    totalAirdrop: "",
    totalAirdropWorth: "",
    icon: "",
    title: "",
    projectUrl: "",
  });
  const [addAirDropLoading, setAddAirDropLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadbutton, setUploadbutton] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [cid, setCid] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [fileName, setFileName] = useState("");
    const [logoPreview, setLogoPreview] = useState();
      const [collectionLogo, setCollectionLogo] = useState();
        const [imageUploadLoading, setImageUploadLoading] = useState(false);
          const [logoUrl, setLogoUrl] = useState("");

  const appCtx = useSelector((state) => state.app);

  const fileRef = useRef(null);

  const navigate = useNavigate();

  const width = window.screen.width;

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: 9999,
      backgroundColor: "#000000",
      border: "1px solid  #000000",
      color: "black",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "inherit",
    },
  };
  function closeModal() {
    setOpenModal(false);
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleAddAirDrop(data);
  };

  const handleAddAirDrop = (data) => {
    setAddAirDropLoading(true);

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/airdrop`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.status === 200) {
          toast.success("New Airdrop Added Successfully");
          closeModal();
        }
        setAddAirDropLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setAddAirDropLoading(false);
      });
  };

  const fileChange = useCallback((file) => {
    if (file && file?.type?.split("/")[0] === "image") {
      const objectUrl = URL.createObjectURL(file);

      const data = new FormData();
      data.append("file", file);
      data.append("fileName", `airdrop`);
      data.append("fileUrl", objectUrl);
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/kyc/document/upload`,
        headers: {
          "Content-Type": "multipart/form-data",
          "X-Auth-Token": appCtx.authToken,
          "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
        },
        data: data,
      };
      toast.promise(
        () =>
          axios(config)
            .then(function (response) {
              console.log("logging image response", response);
              toast.success("Image Uploaded Successfully");
              setFileName(response?.data?.fileName);

              setData((prevData) => ({
                ...prevData,
                ["icon"]: response?.data?.fileUrl,
              }));
            })
            .catch(function (error) {
              toast.error("Uploading  failed!");
            }),
        {
          pending: "uploading",
          // success: 'Settings updated successfully!',
          // error: 'Uh-oh! Something went wrong'
        }
      );

      return () => URL.revokeObjectURL(objectUrl);
    } else {
      toast.error("Documents uploaded should be an Image");
    }
  }, []);

   const handleLogoChange = useCallback((file) => {
     if (file) {
       setCollectionLogo(file);
       const objectUrl = URL.createObjectURL(file);
       setLogoPreview(objectUrl);
       return () => URL.revokeObjectURL(objectUrl);
     }
   }, []);

     const handleUploadBanner = () => {
       setImageUploadLoading(true);
       const data = new FormData();
        data.append("file", collectionLogo);
       let config = {
         method: "post",
         url: `${process.env.REACT_APP_FILE_UPLOAD}/marketplace/${appCtx.marketplaceId}/web2/file/upload?type=collection-banner`,
         headers: {
           "Content-Type": "multipart/form-data",
           "X-Auth-Token": appCtx.authToken,
         },
         data: data,
       };

       const uploadPromise = axios(config)
         .then(function (response) {
            setLogoUrl(response.data.fileUrl);
           setImageUploadLoading(false);
              setData((prevData) => ({
                ...prevData,
                icon: response?.data?.fileUrl,
              }));
         })
         .catch(function (error) {
           setImageUploadLoading(false);
         });

       toast.promise(uploadPromise, {
         pending: "Uploading image...",
         success: "Image uploaded successfully!",
         error: "Image upload failed.",
       });
     };

       useEffect(() => {
         if (collectionLogo) {
           handleUploadBanner();
         }
       }, [collectionLogo]);

  return (
    <div>
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        className={`${styles.popup}  `}
      >
        <div className="d-flex justify-content-end m-4 position-relative">
          <button
            onClick={closeModal}
            className="btn btn-link position-absolute top-0 right-2"
          >
            <i className="fa fa-times" style={{ fontSize: "20px" }}></i>
          </button>

          <div className="p-3">
            <form onSubmit={handleSubmit} className="">
              <h5 className="text-center mt-2">Submit a new airdrop</h5>
              <div className="mb-3">
                <label htmlFor="fundName" className="form-label">
                  Title
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="title"
                  value={data.title}
                  onChange={handleInputChange}
                  placeholder="Enter project title"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Description
                </label>
                <textarea
                  style={{ height: "200px" }}
                  type="text"
                  className="form-control"
                  name="description"
                  value={data.description}
                  onChange={handleInputChange}
                  placeholder="Enter Description"
                  required
                />
              </div>
              {/* --------------------------- */}

              <div className="d-flex gap-3">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Token Name
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="tokenName"
                    value={data.tokenName}
                    onChange={handleInputChange}
                    placeholder="Enter token name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Token Symbol
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="tokenSymbol"
                    value={data.tokenSymbol}
                    onChange={handleInputChange}
                    placeholder="Enter token symbol"
                    required
                  />
                </div>
              </div>
              <div className="d-flex gap-3">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Project
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="project"
                    value={data.project}
                    onChange={handleInputChange}
                    placeholder="Enter project name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Project Url
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="projectUrl"
                    value={data.projectUrl}
                    onChange={handleInputChange}
                    placeholder="Enter project url"
                    required
                  />
                </div>
              </div>
              <div className="d-flex gap-3">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Total Airdrop
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    name="totalAirdrop"
                    value={data.totalAirdrop}
                    onChange={handleInputChange}
                    placeholder="Enter number of airdrop"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Total Airdrop Worth
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    name="totalAirdropWorth"
                    value={data.totalAirdropWorth}
                    onChange={handleInputChange}
                    placeholder="Enter worth of airdrop"
                    required
                  />
                </div>
              </div>

              <div className="d-flex flex-column gap-2">
                <label htmlFor="icon" className="form-label">
                  Airdrop Image
                </label>
                <div className="d-flex gap-2 align-items-center">
                  <div
                    className="d-flex flex-column items-center justify-center"
                    style={{
                      border: `${
                        appCtx?.isDarkMode ? "1 px solid #F6F6F6" : "1 px solid #F6F6F6"
                      }`,
                      borderRadius: "5px",
                      backgroundColor: `${
                        appCtx.isDarkMode ? "#212121" : "#212121"
                      }`,
                      color: `${appCtx.isDarkMode ? "#717579" : "#717579"}`,
                      width: "150px",
                      height: "120px",
                      cursor: "pointer",
                      position: "relative",
                    }}
                    onClick={() => fileRef.current.click()}
                  >
                    <p className="text-center my-auto">Browse for icon</p>
                    {logoPreview && (
                      <img
                        src={logoPreview}
                        alt="upload"
                        style={{
                          width: "150px",
                          height: "120px",
                          borderRadius: "5%",
                          position: "absolute",
                          // marginBottom: "-120px",
                          overflow: "hidden",
                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                </div>
                <input
                  hidden={true}
                  type="file"
                  ref={fileRef}
                  onChange={(e) => {
                    handleLogoChange(e.target.files[0]);
                  }}
                />
              </div>

              <div className="d-flex justify-content-center w-full my-2">
                <button className="btn btn-primary px-5 d-flex gap-2 align-items-center" type="submit">
                  Submit{" "}
                  {addAirDropLoading && (
                    <CircularProgress size={20} className="" />
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AddAirdropModal;
