import React from "react";
import { Box, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
const Footer = () => {
  const theme = useTheme();
  return (
    <Box
      component="footer"
      sx={{
        borderTop: "1px solid #000",
        backgroundColor: "#000",
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        zIndex: 1000,
        boxShadow: "0 -2px 5px rgba(0,0,0,0.1)", // Subtle shadow
        height: 32, // Adjust height as needed
        display: { xs: "none", md: "flex" }, // Hide on small screens
        justifyContent: "flex-end",
        alignItems: "center",
        px: 2,
      }}
    >
      <Typography variant="t4_grey"
        sx={{
          fontSize: "11px",
          color: "#aaaaaa",
          display: "flex",
          alignItems: "center",
          gap: 2, // Spacing between links
        }}
      >
        <RouterLink
          to="/terms-and-compliance"
          style={{
            textDecoration: "none",
            color: "#aaaaaa",
            fontSize: "11px",
          }}
        >
          Terms & Conditions
        </RouterLink>

        <RouterLink
          to="/privacy"
          style={{
            textDecoration: "none",
            color: "#aaaaaa",
            fontSize: "11px",
          }}
        >
          Privacy
        </RouterLink>

        <a
          href="https://vortx.statuspage.io/"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "none",
            color: "#aaaaaa",
            fontSize: "11px",
            display: "flex",
            alignItems: "center",
          }}
        >
          API Status
          <Box
            component="span"
            sx={{
              color: "green",
              ml: 0.5, // Margin-left for spacing
              fontSize: "11px",
            }}
          >
            ●
          </Box>
        </a>
      </Typography>
    </Box>
  );
};

export default Footer;
