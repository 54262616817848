// src/components/addProjectModal/AddProjectModal.jsx

import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
} from "@mui/material";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from '@mui/material/styles';
//import "./AddProjectModal.css"; // Ensure this CSS file is updated as needed

function AddProjectModal({ openModal, setOpenModal }) {
  const [loading, setLoading] = useState(false);
  const appCtx = useSelector((state) => state.app);
  const [projectName, setProjectName] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [noEndDate, setNoEndDate] = useState(false); // Added state for No End Date

  const theme = useTheme(); // Access the theme

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!projectName || !startDate || (!endDate && !noEndDate)) {
      toast.error("Please fill in all required fields.");
      return;
    }

    setLoading(true);

    const data = {
      description: description || "", // Use empty string if description is not provided
      endDate: noEndDate ? null : endDate, // Handle "No End Date" option
      name: projectName,
      startDate,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_VORTX_URL}/project`,
        data,
        {
          headers: {
            "X-Auth-Token": appCtx.authToken,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data) {
        toast.success("Project added successfully");
        setLoading(false);
        closeModal();
        // Reset form fields
        setProjectName("");
        setDescription("");
        setStartDate("");
        setEndDate("");
        setNoEndDate(false);
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to add project. Please try again.");
      setLoading(false);
    }
  };

  function closeModal() {
    setOpenModal(false);
  }

  return (
    <Dialog
      variant="blackBox" // Uses the custom 'blackBox' variant from theme
      open={openModal}
      onClose={closeModal}
      fullWidth
      maxWidth="sm"
      aria-labelledby="add-project-dialog-title"
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "000",
          background: "000",

        }}
        id="add-project-dialog-title"
      >
        <Typography variant="h6">Add a New Project</Typography>
        <IconButton onClick={closeModal} aria-label="close" size="large">
          <CloseIcon sx={{ color: theme.palette.text.primary }} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box component="form" onSubmit={handleSubmit} id="add-project-form">
          <Box className="form-group" mb={2}>
            <TextField
              label="Project Name"
              fullWidth
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              margin="normal"
              required
              variant="outlined"
              // Utilize theme's TextField styles
            />
          </Box>
          <Box className="form-group" mb={2}>
            <TextField
              label="Description"
              fullWidth
              multiline
              rows={2}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              margin="normal"
              variant="outlined"
              // Utilize theme's TextField styles
            />
          </Box>
          <Box className="form-group" mb={2}>
            <Typography variant="t1_grey" gutterBottom>
              Duration
            </Typography>
            <Box display="flex" gap={3}>
              <TextField
                label="Start Date"
                type="date"
                fullWidth
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    min: new Date().toISOString().split("T")[0], // Only allow today or future dates
                  },
                }}
                required
                variant="outlined"
              />
              <TextField
                label="End Date"
                type="date"
                fullWidth
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    min: startDate || new Date().toISOString().split("T")[0], // Only allow dates after the start date or today
                    disabled: noEndDate, // Disable input when No End Date is checked
                  },
                }}
                variant="outlined"
              />
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={noEndDate}
                  onChange={(e) => {
                    setNoEndDate(e.target.checked);
                    if (e.target.checked) {
                      setEndDate(""); // Clear the end date if "No End Date" is selected
                    }
                  }}
                  color="primary" // Use theme's primary color
                />
              }
              label="No End Date"
              sx={{ mt: 1 }} // Adjust spacing if necessary
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeModal}
          color="secondary"
          variant="custom" // Use the custom button variant from theme
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          form="add-project-form"
          variant="contained" // Use the 'contained' variant which is styled as orange in theme
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Add Project"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddProjectModal;
