import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCopy } from "@fortawesome/free-solid-svg-icons";
import { getBlockchainTransactionUrl } from "../../config";
import axios from "axios";
import { CircularProgress } from "@mui/material";

const MetamaskSwapSuccessModal = ({
  openModal,
  setOpenModal,
  isMetamaskSwapPending,
  isMetamaskSwapConfirming,
  isMetamaskSwapConfirmed,
  metmaskTransactionHash,
  setopenSwapModal,
  sendTokenId,
  receiveTokenId,
}) => {
  const [singleTokenPriceLoading, setSingleTokenPriceLoading] = useState(false);
  const [sendBalanceObject, setSendBalanceObject] = useState(false);
  const [receiveBalanceObject, setReceiveBalanceObject] = useState(false);
  const [isAddressCopied, setIsAddressCopied] = useState(false);
  const [timer, setTimer] = useState(20);
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const makeAuthorizedRequest = useAuthorizedHttp();

  const defaultHeaders = {
    "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
    "Content-Type": "application/json",
  };

  if (appCtx.authToken !== "") {
    defaultHeaders["X-Auth-Token"] = appCtx.authToken;
  }
  const width = window.screen.width;
  let isMobileDevice = width < 600;
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      top: "53%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      backgroundColor: "#000000",
      border: "1px solid  #000000",
      color: "black",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "inherit",
      zIndex: 9999,
      width: isMobileDevice ? "400px" : "700px",
    },
  };

  const themedColor = {
    color: "black",
  };
  function closeModal() {
    setOpenModal(false);
    setopenSwapModal(false);
  }

  const usersWalletAddress = appCtx?.walletAddress?.find(
    (addr) => addr?.blockchain === appCtx?.blockchain
  )?.address;

  const handleCopyAddress = (value) => {
    setIsAddressCopied(!isAddressCopied);
    navigator.clipboard.writeText(value);
  };
  useEffect(() => {
    if (isMetamaskSwapConfirmed) {
      getTokenBalance(setSendBalanceObject, usersWalletAddress, sendTokenId);
      getTokenBalance(
        setReceiveBalanceObject,
        usersWalletAddress,
        receiveTokenId
      );
    }
  }, [isMetamaskSwapConfirmed]);

  const getTokenBalance = (setBalanceObjectstate, address, tokenId) => {
    setSingleTokenPriceLoading(true);

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/wallet/${address}/token/${tokenId}/balance?blockchain=${appCtx?.blockchain}`,
      headers: defaultHeaders,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data) {
          setBalanceObjectstate(response?.data);
        }
        setSingleTokenPriceLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSingleTokenPriceLoading(false);
      });
  };

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="w-100 d-flex justify-content-end m-2 position-relative">
        <div
          onClick={closeModal}
          className="btn btn-link position-absolute top-0 right-0 me-3"
          style={{ cursor: "pointer" }}
        >
          <i className="fa fa-times text-20px" style={themedColor}></i>
        </div>
      </div>
      <div
        className="p-5"
        style={{
          minHeight: "400px",
        }}
      >
        <div className="">
          {/* failed  */}
          {!isMetamaskSwapConfirming &&
            !metmaskTransactionHash &&
            !isMetamaskSwapPending && (
              <div
                className="d-flex flex-column gap-3 justify-content-center align-items-center "
                style={{
                  minHeight: "400px",
                }}
              >
                <img
                  src="/images/failicon.jpg"
                  alt=""
                  style={{ width: "50px", height: "50px" }}
                />
                <h6 className="">Transaction Failed</h6>
              </div>
            )}

          {/* pending  */}
          {(isMetamaskSwapConfirming || isMetamaskSwapPending) && (
            <div
              className="d-flex flex-column gap-3 justify-content-center align-items-center"
              style={{
                minHeight: "400px",
              }}
            >
              <img src="/images/Spinner.gif" alt="" />
              {isMetamaskSwapPending && <h6 className="mb-0">Trade Pending</h6>}
              {isMetamaskSwapConfirming && (
                <h6 className="mb-0">Trade Confirming</h6>
              )}

              {/* {transactionData?.action === "BUY" ? (
                <p className="">
                  from {transactionData?.tokenQuantity} USDT to{" "}
                  {transactionData?.buyTokenQuantity} {transactionData?.token}
                </p>
              ) : (
                <p className="">
                  from {transactionData?.tokenQuantity} {transactionData?.token}{" "}
                  to {transactionData?.buyTokenQuantity} USDT
                </p>
              )} */}
            </div>
          )}

          {/* success  */}
          {isMetamaskSwapConfirmed && (
            <>
              {singleTokenPriceLoading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <CircularProgress size={20} />
                </div>
              ) : (
                <div className="w-100 d-flex flex-column gap-3 justify-content-center align-items-center">
                  <img
                    src="/images/successicon.png"
                    alt=""
                    style={{ width: "50px", height: "50px" }}
                  />

                  <h6 className="mb-0">Trade Executed Successfully</h6>

                  <div className="text-center">
                    <h6 className="mb-1">Your New Balance</h6>
                    <p className="mb-0">
                      {sendBalanceObject?.token}{" "}
                      <span className="font-w400">
                        {sendBalanceObject?.balance}
                      </span>
                    </p>
                    <p className="mb-0">
                      {receiveBalanceObject?.token}{" "}
                      <span className="font-w400">
                        {receiveBalanceObject?.balance}
                      </span>
                    </p>
                  </div>
                  <div className="text-center">
                    <h6 className="mb-0">Transaction receipt</h6>
                    <div class="d-flex gap-1 align-items-center px-2">
                      <div
                        onClick={() => {
                          const blockchain = appCtx?.blockchain;
                          const blockchainLink = getBlockchainTransactionUrl(
                            blockchain,
                            metmaskTransactionHash
                          );

                          if (blockchainLink !== "") {
                            window.open(blockchainLink);
                          }
                        }}
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                      >
                        {metmaskTransactionHash}
                      </div>
                      <button
                        className="btn btn-link"
                        onClick={() =>
                          handleCopyAddress(metmaskTransactionHash)
                        }
                      >
                        {isAddressCopied ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            className=""
                            style={themedColor}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faCopy}
                            className=""
                            style={themedColor}
                          />
                        )}
                      </button>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center w-100">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        closeModal();
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default MetamaskSwapSuccessModal;
