import React from "react";
import { Container, Typography, Box } from "@mui/material";
import MainWrapper from "../../components/wrapper/MainWrapper";
import theme from "../../components/theme/theme";

const PrivacyPolicy = () => {
  return (
    <MainWrapper>
      <Box
        sx={{
          background: theme.palette.background.grey,
          p: 3,
          //mt: { xs: 8, sm: 10 }, // Add top margin to avoid clipping
        }}
      >
        <Container maxWidth="lg">
          <Typography variant="h6" gutterBottom>
            Privacy Policy
          </Typography>
          <Typography variant="t2_grey" gutterBottom>
            Last updated on Dec 6th, 2024
          </Typography>
          <Typography variant="t2_grey" paragraph>
            This privacy policy sets out how Vortx AI Private Limited uses and protects any information that you give Vortx AI Private Limited when you visit their website and/or agree to purchase from them.
          </Typography>
          <Typography variant="t2_grey" paragraph>
            Vortx AI Private Limited is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, you can be assured that it will only be used in accordance with this privacy statement.
          </Typography>
          <Typography variant="t2_grey" paragraph>
            Vortx AI Private Limited may change this policy from time to time by updating this page. You should check this page periodically to ensure you adhere to these changes.
          </Typography>

          <Box variant="blackb=Box"
            
          >
            <Typography variant="h6" gutterBottom>
              Information We Collect
            </Typography>
            <ul>
              <li>
                <Typography variant="t2_grey">Name</Typography>
              </li>
              <li>
                <Typography variant="t2_grey">Contact information including email address</Typography>
              </li>
              <li>
                <Typography variant="t2_grey">
                  Demographic information such as postcode, preferences, and interests
                </Typography>
              </li>
              <li>
                <Typography variant="t2_grey">Other information relevant to customer surveys and/or offers</Typography>
              </li>
            </ul>

            <Typography variant="h6" gutterBottom>
              How We Use the Information
            </Typography>
            <ul>
              <li>
                <Typography variant="t2_grey">Internal record keeping</Typography>
              </li>
              <li>
                <Typography variant="t2_grey">Improving our products and services</Typography>
              </li>
              <li>
                <Typography variant="t2_grey">
                  Sending promotional emails about new products, special offers, or other information
                </Typography>
              </li>
              <li>
                <Typography variant="t2_grey">Contacting you for market research purposes</Typography>
              </li>
            </ul>

            <Typography variant="h6" gutterBottom>
              Security
            </Typography>
            <Typography variant="t2_grey" paragraph>
              We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure, we have put in suitable measures.
            </Typography>

            <Typography variant="h6" gutterBottom>
              How We Use Cookies
            </Typography>
            <Typography variant="t2_grey" paragraph>
              A cookie is a small file placed on your computer's hard drive. Cookies allow web applications to respond to you as an individual and tailor their operations to your needs.
            </Typography>
            <Typography variant="t2_grey" paragraph>
              You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Controlling Your Personal Information
            </Typography>
            <Typography variant="t2_grey" paragraph>
              You may choose to restrict the collection or use of your personal information in the following ways:
            </Typography>
            <ul>
              <li>
                <Typography variant="t2_grey">
                  Look for the box to indicate you do not want the information used for direct marketing purposes.
                </Typography>
              </li>
              <li>
                <Typography variant="t2_grey">
                  Contact us if you previously agreed to us using your personal information and wish to change your preference.
                </Typography>
              </li>
            </ul>
            <Typography variant="t2_grey" paragraph>
              If you believe that any information we are holding on you is incorrect or incomplete, please contact us at{" "}
              <strong>avijeet@vortx.ai</strong> or write to us at:
            </Typography>
            <Typography variant="t2_grey" component="address">
              G1, Abhishek Tower, Lake Road, Upper Bazaar, Ranchi GPO, Ranchi, Jharkhand, 834001.
            </Typography>
            <Typography variant="t2_grey" paragraph>
              We will promptly correct any information found to be incorrect.
            </Typography>
          </Box>
        </Container>
      </Box>
    </MainWrapper>
  );
};

export default PrivacyPolicy;
