import React from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

var CryptoJS = require("crypto-js");

function PermissionModal({ openModal, setOpenModal, setPermissionGranted }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const width = window.screen.width;
  const appCtx = useSelector((state) => state.app);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: 9999,
      backgroundColor: "#000000",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
      width: "400px",
    },
  };

  function closeModal() {
    setOpenModal(false);
  }
  return (
    <Modal
      isOpen={openModal}
      onRequestClose={closeModal}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="d-flex flex-column py-4">
        <div className="p-4 d-flex flex-column justify-content-center align-items-center w-100 gap-4">
          <div className="text-20px lg-text-30px text-purple-600 font-weight-bold text-center">
            Do you want to unsubscribe cryptolens?
          </div>

          <div className="d-flex flex-row justify-content-center align-items-center gap-5 w-100">
            <button
             className="btn btn-primary"
              style={{ width: "45%" }}
              onClick={() => {
                closeModal();
              }}
            >
              No
            </button>
            <button
             className="btn btn-danger"
              style={{ width: "45%" }}
              onClick={() => {
                setPermissionGranted(true);
                closeModal();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default PermissionModal;
