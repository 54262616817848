// DGUPlayground2.js

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Card,
  IconButton,
  Fade,
  Chip,
  Tooltip,
  Snackbar,
  Alert,
} from '@mui/material';
import {
  InfoOutlined as InfoIcon,
  Download as DownloadIcon,
  Visibility as VisibilityIcon,
  PictureAsPdf as PdfIcon,
  Image as ImageIcon,
  Send as SendIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import MainWrapper from '../wrapper/MainWrapper';
import { ProtocolList } from '../ProtocolList';
import { TransitionGroup } from 'react-transition-group';
import { styled, keyframes } from '@mui/system';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

// -------------------------------------------
// Configuration Variables
// -------------------------------------------
const TYPING_SPEED = 30; // Adjust this to slow down or speed up the agent's typing

// -------------------------------------------
// Keyframe Animations
// -------------------------------------------

// Background gradient animation
const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  
  50% {
    background-position: 100% 50%;
  }
  
  100% {
    background-position: 0% 50%;
  }
`;

// Fade-in-out animation for the processing image
const fadeInOut = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.4; }
`;

// Splash animation for send button click
const splashAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
`;

// -------------------------------------------
// Styled Components
// -------------------------------------------

// Full-screen animated background
const BackgroundContainer = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  background: 'transparent',
  backgroundSize: '400% 400%',
  zIndex: -1,
  animation: `${gradientAnimation} 15s ease infinite`,
});

// Glass-like header
const GlassHeader = styled(Box)({
  position: 'sticky',
  top: 0,
  width: '100%',
  padding: '16px',
  backgroundColor: 'rgba(255,255,255,0.5)',
  backdropFilter: 'blur(10px)',
  zIndex: 10,
  boxShadow: '0 2px 6px rgba(0,0,0,0.1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

// Info icon with hover effect
const InfoIconButton = styled(IconButton)({
  color: '#ffffff',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.1)',
    color: '#0EA5E9',
  },
});

// Message container
const MessageContainer = styled(Box)(({ role }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: role === 'user' ? 'flex-end' : 'flex-start',
  marginBottom: '20px',
}));

// Message bubble
const MessageBubble = styled(Box)(({ role }) => ({
  maxWidth: '70%',
  borderRadius: '16px',
  padding: '12px',
  color: role === 'user' ? '#fff' : '#333',
  background: role === 'user'
    ? 'linear-gradient(135deg, #0EA5E9 0%, #42d392 100%)'
    : 'rgba(255,255,255,0.85)',
  backdropFilter: role === 'user' ? 'none' : 'blur(10px)',
  boxShadow: '0px 4px 20px rgba(0,0,0,0.1)',
  position: 'relative',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0px 8px 25px rgba(0,0,0,0.15)',
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    [role === 'user' ? 'right' : 'left']: '-8px',
    width: 0,
    height: 0,
    border: '8px solid transparent',
    borderTopColor: role === 'user'
      ? '#0EA5E9'
      : 'rgba(255,255,255,0.85)',
  },
}));

// Timestamp styling
const Timestamp = styled(Typography)({
  fontSize: '10px',
  opacity: 0.7,
  marginTop: '4px',
});

// Role label (User/Agent)
const RoleLabel = styled(Typography)({
  fontSize: '12px',
  fontWeight: 'bold',
  marginBottom: '4px',
  opacity: 0.9,
});

// Data point chips
const TagChip = styled(Chip)({
  margin: '4px',
  backgroundColor: '#0EA5E9',
  color: '#fff',
  transition: 'background-color 0.3s ease, transform 0.3s ease',
  '&:hover': {
    backgroundColor: '#0C4A6E',
    transform: 'scale(1.05)',
  },
});

// Button row for actions like view/download
const ButtonRow = styled(Box)({
  display: 'flex',
  gap: '8px',
  marginTop: '8px',
  flexWrap: 'wrap',
  padding: '8px',
  justifyContent: 'center',
});

// Chat Input Wrapper
const ChatInputWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  padding: '0.5rem',
  borderRadius: '8px',
  background: 'transparent',
  margin: theme.spacing(1, 'auto', 5),
  maxWidth: '320px',
  width: '60%',
  justifyContent: 'center',
  position: 'sticky',
  bottom: '16px',
  zIndex: 11,
}));

// Image upload area with enhanced styling
const ImageUploadArea = styled(Box)(({ isDragging }) => ({
  flex: '1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: isDragging ? '3px dashed #0EA5E9' : '3px dashed #42d392',
  borderRadius: '12px',
  padding: '1rem',
  backgroundColor: isDragging ? '#e3f2fd' : '#fafafa',
  cursor: 'pointer',
  position: 'relative',
  boxShadow: '0 0 10px #42d392, 0 0 20px #42d392',
  transition: 'box-shadow 0.3s ease, border-color 0.3s ease, transform 0.3s ease',
  overflow: 'hidden',
  '&:hover': {
    borderColor: '#0EA5E9',
    boxShadow: '0 0 15px #0EA5E9, 0 0 30px #0EA5E9',
    transform: 'scale(1.02)',
  },
}));

// Image preview styling
const ImagePreview = styled('img')({
  width: '100%',
  height: 'auto',
  objectFit: 'cover',
  borderRadius: '8px',
  maxHeight: '100%',
});

// Send button (round with splash and hover animation)
const SendButton = styled(IconButton)(({ theme }) => ({
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  backgroundColor: '#03218c',
  color: '#fff',
  position: 'relative',
  overflow: 'hidden',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(135deg, #0288d1, #42d392)',
    transform: 'scale(1.1)',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)',
  },
  '&:active': {
    animation: `${splashAnimation} 0.6s ease-out`,
  },
  '&.Mui-disabled': {
    backgroundColor: '#bdbdbd',
    color: '#757575',
    boxShadow: 'none',
    transform: 'none',
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100px',
    height: '100px',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    borderRadius: '50%',
    transform: 'translate(-50%, -50%) scale(0)',
    opacity: 0,
    transition: 'transform 0.5s ease-out, opacity 0.5s ease-out',
  },
  '&:active:after': {
    transform: 'translate(-50%, -50%) scale(4)',
    opacity: 1,
  },
}));

// Processing image (for synthesis loader)
const ProcessingImage = styled('img')({
  width: 180,
  height: 120,
  borderRadius: '16px',
  animation: `${fadeInOut} 2s infinite`,
  objectFit: 'cover',
});

// -------------------------------------------
// Component
// -------------------------------------------

const DGUPlayground2 = () => {
  const { model: modelFromParams } = useParams();

  // States
  const [model, setModel] = useState(modelFromParams || '');
  const [selectedModel, setSelectedModel] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [apiResponse, setApiResponse] = useState(null);
  const [dataPoints, setDataPoints] = useState([]);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const messagesEndRef = useRef(null);
  const conversationRef = useRef(null);

  const apiUrl = process.env.REACT_APP_FOOD_API;
  const apiKey = process.env.REACT_APP_FOOD_KEY;

  useEffect(() => {
    const foundModel = ProtocolList.find((protocol) => protocol.model === model);
    setSelectedModel(foundModel);
  }, [model]);

  // Handle image preview URL and cleanup
  useEffect(() => {
    if (uploadedImage) {
      const url = URL.createObjectURL(uploadedImage);
      setImagePreviewUrl(url);
      return () => URL.revokeObjectURL(url);
    } else {
      setImagePreviewUrl(null);
    }
  }, [uploadedImage]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // -------------------------------------------
  // Typing simulation for agent messages
  // -------------------------------------------
  const typeAgentMessage = async (content) => {
    return new Promise((resolve) => {
      // Create a new message object
      const newMessage = {
        role: 'agent',
        contentType: 'text',
        content: '',
        timestamp: new Date(),
      };

      // Add the empty message first
      setMessages((prev) => [...prev, newMessage]);

      let currentIndex = 0;
      const interval = setInterval(() => {
        currentIndex++;
        setMessages((prev) => {
          const updated = [...prev];
          updated[updated.length - 1].content = content.slice(0, currentIndex);
          return updated;
        });
        if (currentIndex === content.length) {
          clearInterval(interval);
          resolve();
        }
      }, TYPING_SPEED);
    });
  };

  const addMessage = async (role, contentType, content) => {
    if (role === 'agent' && contentType === 'text') {
      // Use typing simulation for agent text
      await typeAgentMessage(content);
    } else {
      // Directly add messages for user or non-text agent content
      const newMessage = {
        role,
        contentType,
        content,
        timestamp: new Date(),
      };
      setMessages((prev) => [...prev, newMessage]);
    }
  };

  // Initial welcome messages
  useEffect(() => {
    (async () => {
      await addMessage(
        'agent',
        'text',
        'Hello there! I am your image analysis companion. I can help you synthesize historical and contextual insights from satellite images and more.'
      );
      await addMessage(
        'agent',
        'text',
        'Please upload an image to begin. No text input is required initially.'
      );
      await addMessage(
        'agent',
        'text',
        '↓↓ Upload your image below to proceed ↓↓'
      );
      await addMessage('agent', 'text', '🠗');
    })();
  }, []);

  // Handle synthesis
  const handleSynthesize = async (imageFile, optionalText) => {
    setLoading(true);

    let userMessage =
      'After this message I have uploaded an image, please synthesize its history.';
    if (optionalText && optionalText.trim()) {
      userMessage += '\n' + optionalText.trim();
    }

    // User text message
    setMessages((prev) => [
      ...prev,
      {
        role: 'user',
        contentType: 'text',
        content: userMessage,
        timestamp: new Date(),
      },
    ]);
    // User image message
    setMessages((prev) => [
      ...prev,
      {
        role: 'user',
        contentType: 'image',
        content: imagePreviewUrl,
        timestamp: new Date(),
      },
    ]);

    await addMessage('agent', 'text', 'Let me analyze the image you provided...');
    await addMessage(
      'agent',
      'text',
      'Synthesizing historical satellite data, hold on...'
    );

    // Add a placeholder processing message using user's image
    setMessages((prev) => [
      ...prev,
      {
        role: 'agent',
        contentType: 'placeholder',
        content: imagePreviewUrl,
        timestamp: new Date(),
      },
    ]);

    const fullApiUrl = `${apiUrl}?Secret-Key=${apiKey}`;
    const formData = new FormData();
    formData.append('image', imageFile, imageFile.name);

    try {
      const response = await axios.post(fullApiUrl, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Remove placeholder message
      setMessages((prev) =>
        prev.filter(
          (m) => !(m.role === 'agent' && m.contentType === 'placeholder')
        )
      );

      if (response.data?.satellite_simulation) {
        const synthesizedImage = response.data.satellite_simulation;
        const points = response.data?.data_points || [];

        await addMessage(
          'agent',
          'text',
          'Here are the insights derived from the image:'
        );
        if (points.length > 0) {
          setMessages((prev) => [
            ...prev,
            {
              role: 'agent',
              contentType: 'dataPoints',
              content: points,
              timestamp: new Date(),
            },
          ]);
        }
        setMessages((prev) => [
          ...prev,
          {
            role: 'agent',
            contentType: 'image',
            content: synthesizedImage,
            timestamp: new Date(),
          },
          {
            role: 'agent',
            contentType: 'pdf',
            content: 'You may download the entire conversation as a PDF.',
            timestamp: new Date(),
          },
        ]);

        setApiResponse(synthesizedImage);
        setDataPoints(points);
      } else {
        await addMessage(
          'agent',
          'text',
          'Sorry, I am not sure how to handle this image. Please try another.'
        );
      }
    } catch (error) {
      setMessages((prev) =>
        prev.filter(
          (m) => !(m.role === 'agent' && m.contentType === 'placeholder')
        )
      );
      setErrorMessage(`API Error: ${error.response?.data || error.message}`);
      await addMessage('agent', 'text', 'I encountered an error. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Handle send
  const handleSend = () => {
    const text = userInput.trim();
    if (!uploadedImage && !text) {
      return; // Nothing to send
    }

    if (uploadedImage) {
      handleSynthesize(uploadedImage, text);
    } else {
      // Just user text without image
      const userMsg = {
        role: 'user',
        contentType: 'text',
        content: text,
        timestamp: new Date(),
      };
      setMessages((prev) => [...prev, userMsg]);

      const lowercaseText = text.toLowerCase();
      const knownTriggers = ['help', 'history', 'synthesize', 'image'];
      const isKnownRequest = knownTriggers.some((trigger) =>
        lowercaseText.includes(trigger)
      );

      if (!isKnownRequest) {
        addMessage(
          'agent',
          'text',
          "I'm not sure how to help with that. Could you try asking differently or provide an image?"
        );
      }
    }

    // Reset fields
    setUserInput('');
    setUploadedImage(null);
    const inputField = document.getElementById('image-input');
    if (inputField) inputField.value = '';
  };

  const handleViewImage = () => {
    if (apiResponse) {
      window.open(apiResponse, '_blank');
    }
  };

  const handleDownloadImage = () => {
    if (apiResponse) {
      const link = document.createElement('a');
      link.href = apiResponse;
      link.setAttribute('download', 'synthesized_image.jpg');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleDownloadPdf = async () => {
    const conversationElement = conversationRef.current;
    if (!conversationElement) return;

    const canvas = await html2canvas(conversationElement, {
      scale: 2,
    });
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'pt', 'a4');

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imageProps = pdf.getImageProperties(imgData);
    const ratio = imageProps.width / imageProps.height;
    let w = pdfWidth - 40;
    let h = (pdfWidth - 40) / ratio;
    if (h > pdfHeight - 40) {
      h = pdfHeight - 40;
      w = h * ratio;
    }

    pdf.addImage(imgData, 'PNG', 20, 20, w, h);
    pdf.save('conversation.pdf');
  };

  const formatTime = (timestamp) => {
    return new Intl.DateTimeFormat('default', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }).format(timestamp);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedImage(file);
      e.target.value = '';
    }
  };

  const handleRemoveImage = () => {
    setUploadedImage(null);
    const inputField = document.getElementById('image-input');
    if (inputField) inputField.value = '';
  };

  // Drag & Drop handlers
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => setIsDragging(false);

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      setUploadedImage(file);
      const inputField = document.getElementById('image-input');
      if (inputField) inputField.value = '';
    }
  };

  const sendDisabled = !uploadedImage && userInput.trim() === '';

  // Custom scrollbar - track is transparent to remove vertical line
  const customScroll = {
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#0EA5E9',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#0288d1',
    },
  };

  return (
    <MainWrapper>
      <BackgroundContainer />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          color: '#333333',
        }}
      >
        {/* Header */}
        <GlassHeader>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* Agent avatar placeholder */}
            <Box
              component="img"
              src="https://via.placeholder.com/40"
              alt="Agent Avatar"
              sx={{
                width: 40,
                height: 40,
                borderRadius: '50%',
                mr: 1,
                boxShadow: '0 2px 6px rgba(0,0,0,0.1)',
              }}
            />
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                color: '#ffffff',
                letterSpacing: '0.5px',
                textShadow: '0px 1px 2px rgba(0,0,0,0.2)',
              }}
            >
              {selectedModel?.name?.toUpperCase() || 'VORTX SIMULATION'}
            </Typography>
          </Box>
          {selectedModel?.link && (
            <Tooltip title="More Info">
              <InfoIconButton
                aria-label="More Info"
                onClick={() => window.open(selectedModel.link, '_blank')}
              >
                <InfoIcon />
              </InfoIconButton>
            </Tooltip>
          )}
        </GlassHeader>

        {/* Content Area: Chat UI */}
        <Box
          sx={{
            flex: 1,
            overflowY: 'auto',
            p: { xs: 2, md: 4 },
            transition: 'all 0.3s ease',
            ...customScroll,
            backgroundColor: 'transparent',
            backdropFilter: 'blur(5px)',
          }}
          ref={conversationRef}
        >
          <TransitionGroup>
            {messages.map((msg, index) => (
              <Fade key={index} in timeout={500}>
                <MessageContainer role={msg.role}>
                  <RoleLabel variant="body2">
                    {msg.role === 'user' ? 'You' : 'Agent'}
                  </RoleLabel>
                  <MessageBubble role={msg.role}>
                    {/* Text Message */}
                    {msg.contentType === 'text' && (
                      <Typography
                        variant="body1"
                        sx={{ whiteSpace: 'pre-wrap', lineHeight: 1.5 }}
                      >
                        {msg.content}
                      </Typography>
                    )}
                    {/* Data Points */}
                    {msg.contentType === 'dataPoints' && (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1 }}>
                        {msg.content.map((point, i) => (
                          <Tooltip title="Data Point" key={i}>
                            <TagChip label={point} />
                          </Tooltip>
                        ))}
                      </Box>
                    )}
                    {/* Image Message */}
                    {msg.contentType === 'image' && (
                      <Card
                        sx={{
                          mt: 2,
                          borderRadius: 2,
                          overflow: 'hidden',
                          boxShadow: '0px 4px 20px rgba(0,0,0,0.1)',
                          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                          '&:hover': {
                            transform: 'scale(1.02)',
                            boxShadow: '0px 8px 25px rgba(0,0,0,0.15)',
                          },
                        }}
                      >
                        <img
                          src={msg.content}
                          alt="Synthesized"
                          width="100%"
                          style={{ objectFit: 'cover' }}
                        />
                        {msg.role === 'agent' && (
                          <ButtonRow>
                            <Tooltip title="View Full Image">
                              <Button
                                startIcon={<VisibilityIcon />}
                                variant="text"
                                onClick={handleViewImage}
                                sx={{
                                  color: '#333',
                                  '&:hover': { color: '#0EA5E9' },
                                }}
                                aria-label="View Full Image"
                              >
                                View
                              </Button>
                            </Tooltip>
                            <Tooltip title="Download Image">
                              <Button
                                startIcon={<DownloadIcon />}
                                variant="text"
                                onClick={handleDownloadImage}
                                sx={{
                                  color: '#333',
                                  '&:hover': { color: '#0EA5E9' },
                                }}
                                aria-label="Download Image"
                              >
                                Download
                              </Button>
                            </Tooltip>
                          </ButtonRow>
                        )}
                      </Card>
                    )}
                    {/* Placeholder Message */}
                    {msg.contentType === 'placeholder' && (
                      <Box sx={{ width: 180, height: 120, position: 'relative' }}>
                        <ProcessingImage src={msg.content} alt="Processing" />
                      </Box>
                    )}
                    {/* PDF Message */}
                    {msg.contentType === 'pdf' && (
                      <Box>
                        <Typography variant="body1" sx={{ mb: 1 }}>
                          {msg.content}
                        </Typography>
                        <ButtonRow>
                          <Tooltip title="Download Conversation as PDF">
                            <Button
                              startIcon={<PdfIcon />}
                              variant="text"
                              onClick={handleDownloadPdf}
                              sx={{
                                color: '#333',
                                '&:hover': { color: '#0EA5E9' },
                              }}
                              aria-label="Download PDF"
                            >
                              Download PDF
                            </Button>
                          </Tooltip>
                        </ButtonRow>
                      </Box>
                    )}
                  </MessageBubble>
                  <Timestamp variant="caption">
                    {formatTime(msg.timestamp)}
                  </Timestamp>
                </MessageContainer>
              </Fade>
            ))}
          </TransitionGroup>
          <div ref={messagesEndRef} />
        </Box>

        {/* Chat Input with Drag & Drop */}
        <ChatInputWrapper
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <ImageUploadArea
            isDragging={isDragging}
            onClick={() => document.getElementById('image-input').click()}
            aria-label="Image Upload Area"
          >
            <input
              type="file"
              accept="image/*"
              id="image-input"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            {uploadedImage && imagePreviewUrl ? (
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ImagePreview src={imagePreviewUrl} alt="Preview" />
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    backgroundColor: 'rgba(255,255,255,0.8)',
                    '&:hover': {
                      backgroundColor: 'rgba(255,255,255,1)',
                    },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveImage();
                  }}
                  aria-label="Remove Uploaded Image"
                >
                  <DeleteIcon color="error" />
                </IconButton>
              </Box>
            ) : (
              <Box sx={{ textAlign: 'center', px: 2 }}>
                <ImageIcon sx={{ fontSize: 48, color: '#0EA5E9', mb: 1 }} />
                <Typography variant="body2" sx={{ color: '#333' }}>
                  Drag & Drop or Click to Upload an Image
                </Typography>
              </Box>
            )}
          </ImageUploadArea>

          {/* Round send button if image is uploaded */}
          {uploadedImage && (
            <SendButton
              onClick={handleSend}
              disabled={sendDisabled || loading}
              aria-label="Send Message"
            >
              {loading ? '...' : <SendIcon style={{ fontSize: '24px' }} />}
            </SendButton>
          )}
        </ChatInputWrapper>

        {/* Snackbar for Error Messages */}
        <Snackbar
          open={Boolean(errorMessage)}
          autoHideDuration={6000}
          onClose={() => setErrorMessage('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
      </Box>
    </MainWrapper>
  );
};

export default DGUPlayground2;
