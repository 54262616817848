// src/pages/projects/ProjectsDetails.jsx

import React, { useState, useEffect } from "react";
import MainWrapper from "../../components/wrapper/MainWrapper";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Avatar,
  Button,
  Tabs,
  Tab,
  Autocomplete,
  TextField,
  Chip,
  IconButton,
} from "@mui/material";
import {
  Assignment as AssignmentIcon,
  Storage as StorageIcon,
  Devices as DevicesIcon,
  GpsFixed as GpsFixedIcon,
  CalendarToday as CalendarTodayIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import SummaryTab from "./SummaryTab";
import WorkflowTab from "./WorkflowTab";
import ConnectionsTab from "./ConnectionsTab";
import ArtifactsTab from "./ArtifactsTab";
import TransactionsTab from "./TransactionsTab";
import SettingsTab from "./SettingsTab";
import AddDataModal from "./addDataModal/AddDataModal";
import AddSequesterModal from "./addSequesterModal/AddSequesterModal";
import AddBurnerModal from "./addBurnerModal/AddBurnerModal";
import AoITab from "./AoITab";
import CalendarTab from "./CalendarTab";
import { useTheme } from "@mui/material/styles";

const ProjectsDetails = () => {
  const { projectId } = useParams(); // Get projectId from URL parameters
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme(); // Access the theme

  const [selectedProject, setSelectedProject] = useState(
    location.state?.selectedProject || null
  );
  const [allProjects, setAllProjects] = useState(
    location.state?.allProjects || []
  );
  const [selectedTab, setSelectedTab] = useState("workflow");
  const [openAddDataModal, setOpenAddDataModal] = useState(false);
  const [openAddSequesterModal, setOpenAddSequesterModal] = useState(false);
  const [openAddBurnerModal, setOpenAddBurnerModal] = useState(false);
  const [notifications, setNotifications] = useState(3);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    if (!selectedProject) {
      // Fallback in case the project is not in state (e.g., user directly accesses the URL)
      const project = allProjects.find((p) => p.id === Number(projectId));
      setSelectedProject(project);
    }
  }, [projectId, selectedProject, allProjects]);

  const handleSelectProject = (project) => {
    // Update selected project
    setSelectedProject(project);
    // Update the URL to reflect the new project selection
    navigate(`/projects/${project.id}`, {
      state: {
        selectedProject: project,
        allProjects,
      },
    });
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    // Implement theme switching logic here if necessary
  };

  return (
    <MainWrapper>
      <Box sx={{ padding: 2 }}>
        {/* Top App Bar */}
        <Box
          variant="blackBox" // Uses the custom 'blackBox' variant from theme
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          sx={{
            padding: 2,
            backgroundColor: theme.palette.background.gradient, // Example usage
          }}
        >
          <Typography variant="h5_grey">Project Details</Typography>
          <Box display="flex" alignItems="center">
            <IconButton onClick={toggleDarkMode} aria-label="toggle dark mode">
             
            </IconButton>
          </Box>
        </Box>

        {/* Top Section with Project Information */}
        <Paper variant="blackBox" sx={{ mb: 2 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={2}
            flexWrap="wrap"
          >
            <Box display="flex" alignItems="center">
              
              <Box>
                <Box display="flex" alignItems="center" variant="blackBox">
                  <Typography variant="h6" component="div" sx={{ mr: 2 }}>
                    {selectedProject ? selectedProject.name : "Select a Project"}
                  </Typography>
                  {selectedProject && (
                    <Chip
                      label="Active"
                      color="success"
                      size="small"
                      sx={{ borderRadius: "8px" }}
                    />
                  )}
                </Box>
                <Typography variant="t2" color="text.secondary">
                  {selectedProject?.description}
                </Typography>
                {/* Project Stats can be added here */}
              </Box>
            </Box>
            <Box display="flex" gap={2} alignItems="center" flexWrap="wrap">
              <Autocomplete
                options={allProjects}
                getOptionLabel={(option) => option.name}
                sx={{ width: 300 }}
                value={selectedProject || null}
                onChange={(event, newValue) => {
                  // Check if newValue is null or undefined before calling handleSelectProject
                  if (newValue) {
                    handleSelectProject(newValue);
                  } else {
                    console.error("No project selected or value is null");
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Project"
                    variant="outlined"
                  />
                )}
              />
            </Box>
          </Box>
        </Paper>

        {/* Tab Navigation Bar */}
        <Box sx={{ mt: 4 }} variant="blackBox">
  <Tabs
    value={selectedTab}
    onChange={(e, newValue) => setSelectedTab(newValue)}
    variant="scrollable"
    scrollButtons="auto"
    sx={{
      backgroundColor: theme.palette.background.black, // Background for the Tabs container
      borderRadius: "8px",
      "& .MuiTabs-indicator": {
        display: "none", // Remove the underline indicator
      },
      "& .MuiTab-root": {
        minWidth: 120,
        textTransform: "none", // Keep text as it is
        fontWeight: 500,
        borderRadius: "8px",
        margin: "4px",
        transition: "all 0.3s ease", // Smooth transitions
        "&:hover": {
          backgroundColor: theme.palette.secondary.hoverBackground, // Orange on hover
          color: theme.palette.text.active, // Black text on hover
        },
      },
    }}
  >
    <Tab
      value="workflow"
      label={
        <Box display="flex" alignItems="center">
          <AssignmentIcon
            sx={{
              mr: 1,
              color: selectedTab === "workflow" ? theme.palette.text.active : theme.palette.text.primary,
            }}
          />
          Workflow
        </Box>
      }
      sx={{
        backgroundColor: selectedTab === "workflow" ? theme.palette.secondary.main : theme.palette.background.black,
        color: selectedTab === "workflow" ? theme.palette.text.active : theme.palette.text.primary,
      }}
    />
    <Tab
      value="connections"
      label={
        <Box display="flex" alignItems="center">
          <DevicesIcon
            sx={{
              mr: 1,
              color: selectedTab === "connections" ? theme.palette.text.active : theme.palette.text.primary,
            }}
          />
          Connections
        </Box>
      }
      sx={{
        backgroundColor: selectedTab === "connections" ? theme.palette.secondary.main : theme.palette.background.black,
        color: selectedTab === "connections" ? theme.palette.text.active : theme.palette.text.primary,
      }}
    />
    <Tab
      value="artifacts"
      label={
        <Box display="flex" alignItems="center">
          <StorageIcon
            sx={{
              mr: 1,
              color: selectedTab === "artifacts" ? theme.palette.text.active : theme.palette.text.primary,
            }}
          />
          Artifacts
        </Box>
      }
      sx={{
        backgroundColor: selectedTab === "artifacts" ? theme.palette.secondary.main : theme.palette.background.black,
        color: selectedTab === "artifacts" ? theme.palette.text.active : theme.palette.text.primary,
      }}
    />
    <Tab
      value="aoi"
      label={
        <Box display="flex" alignItems="center">
          <GpsFixedIcon
            sx={{
              mr: 1,
              color: selectedTab === "aoi" ? theme.palette.text.active : theme.palette.text.primary,
            }}
          />
          Geo Coverage
        </Box>
      }
      sx={{
        backgroundColor: selectedTab === "aoi" ? theme.palette.secondary.main : theme.palette.background.black,
        color: selectedTab === "aoi" ? theme.palette.text.active : theme.palette.text.primary,
      }}
    />
    <Tab
      value="calendar"
      label={
        <Box display="flex" alignItems="center">
          <CalendarTodayIcon
            sx={{
              mr: 1,
              color: selectedTab === "calendar" ? theme.palette.text.active : theme.palette.text.primary,
            }}
          />
          Calendar
        </Box>
      }
      sx={{
        backgroundColor: selectedTab === "calendar" ? theme.palette.secondary.main : theme.palette.background.black,
        color: selectedTab === "calendar" ? theme.palette.text.active : theme.palette.text.primary,
      }}
    />
    <Tab
      value="settings"
      label={
        <Box display="flex" alignItems="center">
          <SettingsIcon
            sx={{
              mr: 1,
              color: selectedTab === "settings" ? theme.palette.text.active : theme.palette.text.primary,
            }}
          />
          Settings
        </Box>
      }
      sx={{
        backgroundColor: selectedTab === "settings" ? theme.palette.secondary.main : theme.palette.background.black,
        color: selectedTab === "settings" ? theme.palette.text.active : theme.palette.text.primary,
      }}
    />
  </Tabs>


          <Box mt={2}>
            {selectedTab === "workflow" && (
              <WorkflowTab projectId={selectedProject?.id} />
            )}
            {selectedTab === "connections" && (
              <ConnectionsTab projectId={selectedProject?.id} />
            )}
            {selectedTab === "artifacts" && (
              <ArtifactsTab projectId={selectedProject?.id} />
            )}
            {selectedTab === "aoi" && <AoITab projectId={selectedProject?.id} />}
            {selectedTab === "calendar" && (
              <CalendarTab projectId={selectedProject?.id} />
            )}
            {selectedTab === "settings" && (
              <SettingsTab projectId={selectedProject?.id} />
            )}
          </Box>
        </Box>
      </Box>

      {/* Modals */}
      {openAddDataModal && (
        <AddDataModal
          openModal={openAddDataModal}
          setOpenModal={setOpenAddDataModal}
          projectId={selectedProject?.id}
        />
      )}
      {openAddSequesterModal && (
        <AddSequesterModal
          openModal={openAddSequesterModal}
          setOpenModal={setOpenAddSequesterModal}
          projectId={selectedProject?.id}
        />
      )}
      {openAddBurnerModal && (
        <AddBurnerModal
          openModal={openAddBurnerModal}
          setOpenModal={setOpenAddBurnerModal}
          projectId={selectedProject?.id}
        />
      )}
    </MainWrapper>
  );
};

export default ProjectsDetails;
