export const nativeToken = {
    ETHEREUM: "ETH",
    POLYGON: "MATIC",
    BSC: "BNB",
    BASE: "BASE",
    AVALANCHE: "AVAX",
    ALL: "",
  };
  
  export const getImageUrl = (key) => {
    switch (key) {
      case "ALGORAND":
        return "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQk46xK0rqOLKpXI1ExMlc2bKa0uasNE-4GgvQEcLK7JA&s";
      case "ETHEREUM":
        return "https://talewallet-testnet.s3.ap-southeast-1.amazonaws.com/coin-icon/eth.png";
      case "POLYGON":
        return "https://talewallet-testnet.s3.ap-southeast-1.amazonaws.com/coin-icon/matic.png";
      case "BSC":
        return "https://talewallet-testnet.s3.ap-southeast-1.amazonaws.com/coin-icon/bnb.png";
      case "SOLANA":
        return "https://talewallet-assets.s3.ap-southeast-1.amazonaws.com/token-icon/Solana.png";
      case "BASE":
        return "https://images.mirror-media.xyz/publication-images/cgqxxPdUFBDjgKna_dDir.png";
      case "AVALANCHE":
        return "https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png";
      case "NORDEK":
        return "https://s2.coinmarketcap.com/static/img/coins/64x64/24638.png";
      case "ALL":
        return "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoaAqFzhkWggqDyTQK-CE_nawzAiB2MQku-A&s";
      default:
        return "";
    }
  };
  export const getChainId = (key) => {
    switch (key) {
      case "ALGORAND":
        return "";
      case "ETHEREUM":
        return 1;
      case "POLYGON":
        return 137;
      case "BSC":
        return 56;
      case "SOLANA":
        return 101;
      default:
        return "";
    }
  };
  export const nativeTokensTokenId = (key) => {
    switch (key) {
      case "ALGORAND":
        return "";
      case "ETHEREUM":
        return 1;
      case "POLYGON":
        return 137;
      case "BSC":
        return 56;
      case "SOLANA":
        return 101;
      default:
        return "";
    }
  };
  
  export function getPriceInDecimal(price) {
    let tokenprice;
    if (price >= 1000) {
      tokenprice = price?.toFixed(0);
    } else if (price >= 1) {
      tokenprice = price?.toFixed(2);
    } else if (price >= 0.1) {
      tokenprice = price?.toFixed(3);
    } else if (price >= 0.01) {
      tokenprice = price?.toFixed(4);
    } else if (price >= 0.001) {
      tokenprice = price?.toFixed(5);
    } else if (price >= 0.0001) {
      tokenprice = price?.toFixed(6);
    } else if (price >= 0.00001) {
      tokenprice = price?.toFixed(7);
    } else {
      tokenprice = price?.toFixed(8);
    }
  
    return tokenprice;
  }
  export function getBlockchainTransactionUrl(blockchain, transactionHash) {
    let url =
      blockchain === "FLOW"
        ? `${process.env.REACT_APP_GATEWAY_FLOWSCAN}${transactionHash}`
        : blockchain === "ALGORAND"
        ? `${process.env.REACT_APP_GATEWAY_ALGORAND}/tx/${transactionHash}`
        : blockchain === "ETHEREUM"
        ? `${process.env.REACT_APP_GATEWAY_ETHEREUM}/tx/${transactionHash}`
        : blockchain === "POLYGON"
        ? `${process.env.REACT_APP_GATEWAY_POLYGON}/tx/${transactionHash}`
        : blockchain === "BSC"
        ? `${process.env.REACT_APP_GATEWAY_BSC}/tx/${transactionHash}`
        : blockchain === "SOLANA"
        ? `${process.env.REACT_APP_GATEWAY_SOLANA}/${transactionHash}`
        : blockchain === "AVALANCHE"
        ? `${process.env.REACT_APP_GATEWAY_AVALANCHE}/blockchain/${blockchain}/tx/${transactionHash}`
        : blockchain === "NORDEK"
        ? `${process.env.REACT_APP_GATEWAY_NORDEK}/tx/${transactionHash}`
        : "";
  
    return url;
  }
  export function getBlockchainAddressUrl(blockchain, address) {
    let url =
      blockchain === "FLOW"
        ? `${process.env.REACT_APP_GATEWAY_FLOWSCAN}${address}`
        : blockchain === "ALGORAND"
        ? `${process.env.REACT_APP_GATEWAY_ALGORAND}/address/${address}`
        : blockchain === "ETHEREUM"
        ? `${process.env.REACT_APP_GATEWAY_ETHEREUM}/address/${address}`
        : blockchain === "POLYGON"
        ? `${process.env.REACT_APP_GATEWAY_POLYGON}/address/${address}`
        : blockchain === "BSC"
        ? `${process.env.REACT_APP_GATEWAY_BSC}/address/${address}`
        : blockchain === "SOLANA"
        ? `${process.env.REACT_APP_GATEWAY_SOLANA}/${address}`
        : blockchain === "AVALANCHE"
        ? `${process.env.REACT_APP_GATEWAY_AVALANCHE}/blockchain/${blockchain}/address/${address}`
        : blockchain === "NORDEK"
        ? `${process.env.REACT_APP_GATEWAY_NORDEK}/address/${address}`
        : "";
  
    return url;
  }
  
  // config for project details page 
   export const selectedAssetPoolDistributionKeys = [
     "1x",
     "10x",
     "20x",
     "30x",
     "50x",
     "70x",
     "100x",
   ];
   export const selectedAssetPoolListKeys = [
     "Category",
     "Sub-category",
     "Total-XCO2",
     "Purchased-XCO2",
     "Asset-detail",
     "Asset-source",
   ];
  
  //  config for esg pools details page 
   export const EsgPoolsCompaniesData = [
     {
       key: "total_lifetime_credits",
       display_name: "Total lifetime credits",
       value: 100,
     },
     {
       key: "current_credits_in_pool",
       display_name: "Current credits in pool",
       value: 50,
     },
     {
       key: "total_credits_sold",
       display_name: "Total credits sold",
       value: 200,
     },
     {
       key: "total_earning_of_pool",
       display_name: "Total earning of pool",
       value: 5000,
     },
     {
       key: "current_price_of_carbon_credit",
       display_name: "Current price of carbon credit",
       value: 10,
     },
     {
       key: "operational_status_of_pool",
       display_name: "Operational Status of Pool",
       value: "Active",
     },
     {
       key: "exchange_level_compliances",
       display_name: "Exchange level compliances",
       value: 5,
     },
     {
       key: "exchange_compliance_score",
       display_name: "Exchange compliance score",
       value: 8,
     },
     {
       key: "elemental_sustainability_score",
       display_name: "Elemental sustainability Score",
       value: 7,
     },
     {
       key: "research_whitepapers_used",
       display_name: "Research whitepapers used in pooling",
       value: ["Paper A"],
     },
     {
       key: "project_certifications",
       display_name: "Project certifications",
       value: ["Certification X"],
     },
     {
       key: "total_acceptance_in_pool",
       display_name: "Total Acceptance in Pool",
       value: 20,
     },
     {
       key: "acceptance_percentage_in_pool",
       display_name: "Acceptance % in pool",
       value: "85%",
     },
     {
       key: "warning_disclaimers_dispute_resolutions",
       display_name: "Warning, disclaimers, dispute resolutions",
       value: "See terms & conditions",
     },
   ];