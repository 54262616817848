import React, { useState, useEffect } from 'react';
import './preloader.css';  // Import the preloader styles

const Preloader = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay of 2 seconds
    const timer = setTimeout(() => {
      setLoading(false);
      document.body.style.overflow = 'auto'; // Re-enable scrolling
    }, 2000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  if (!loading) return null; // Don't render the preloader once loading is done

  return (
    <div id="preloader">
      <div className="loader-text">vortx.ai</div>
    </div>
  );
};

export default Preloader;
