import axios from "axios";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { appActions } from "../context/app-slice";
import { persistor } from "../context/store";

const useAuthorizedHttp = (token) => {
  const appCtx = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let config = {};
  if (appCtx.authToken) {
    config = {
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token ? token : appCtx.authToken,
      },
    };
  } else {
    config = {
      headers: {
        "Content-Type": "application/json",
        "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
      },
    };
  }
  const handleLogout = async () => {
    try {
      // Dispatch logout actions
      dispatch(appActions.logout(undefined));
      dispatch(appActions.updateAuthToken(undefined));
      // Uncomment and modify if you need to reset wallet address
      // dispatch(appActions.setWalletAddress([{ address: "" }]));
      dispatch(
        appActions.updateUserDetails({
          userId: "",
          email: "",
          name: "",
        })
      );

      // Purge persisted store
      await persistor.purge();

      // Navigate to the login page after successful purge
      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
      // Optionally, display an error message to the user
    }
  };

  return useCallback(
    async (
      requestOptions,
      successCallback,
      errorCallback,
      completeCallback
    ) => {
      try {
        const response = await axios({
          method: requestOptions.method
            ? requestOptions.method.toLowerCase()
            : "GET",
          url: requestOptions.url,
          headers: config.headers,
          data: requestOptions.data
            ? JSON.stringify(requestOptions.data)
            : null,
        });

        successCallback && successCallback(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // For a 401 error, we provide a generic error message and initiate logout
          toast.error("Session expired. Logging you out...");
          handleLogout();
        } else {
          // Only log the error internally, do not show it to the user
          console.error("An error occurred:", error);
        }

        errorCallback && errorCallback(error);
      } finally {
        completeCallback && completeCallback();
      }
    },
    [appCtx.authToken] // include handleLogout in the dependencies array
  );
};

export default useAuthorizedHttp;
