import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCryptobasket } from "./useCryptobasket";
import { CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronDown, faX } from "@fortawesome/free-solid-svg-icons";

import styles from "./CreateBasketModal.module.css";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { nativeToken } from "../../config";
import { useWalletView } from "../../hooks/useWalletView";
import "./CreateBasketModal.module.css";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { useEsgPools } from "../../hooks/useEsgPools";
function CreateBasketModal({
  openModal,
  setOpenModal,

  closeClicked,
  setCloseClicked,
}) {
  const [confirmClicked, setConfirmClicked] = useState(false);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [pool, setPool] = useState("");
  const [poolPercent, setPoolPercent] = useState("");
  const [basketPools, setBasketPools] = useState([]);
  const [volume, setVolume] = useState("");
  const appCtx = useSelector((state) => state.app);
  const [blockchain, setBlockchain] = useState(appCtx.blockchain);

  const navigate = useNavigate();
  const makeRequest = useAuthorizedHttp();

  const { getAllCoin, allCoin, isCoinLoading } = useWalletView();
  const { poolsLoading, poolsList, getEsgPoolsList } = useEsgPools();
  const themedColor = "black";

  const chains = Object.keys(nativeToken)
    .filter((key) => key !== "ALGORAND")
    .reduce((obj, key) => {
      obj[key] = nativeToken[key];
      return obj;
    }, {});

  useEffect(() => {
    // getAllCoin(0, 60, blockchain);
    getEsgPoolsList();
  }, [appCtx.blockchain, blockchain]);

  const handleAddPool = () => {
    // Check if the selected pool already exists in the array
    const existingTokenIndex = basketPools?.findIndex(
      (t) => t.esgPoolId === pool[0]
    );

    if (existingTokenIndex !== -1) {
      // If the pool exists, update the poolPercent value
      const updatedTokens = [...basketPools];
      updatedTokens[existingTokenIndex].poolPercent = poolPercent;
      setBasketPools(updatedTokens);
    } else {
      // If the pool doesn't exist, add it to the array
      setBasketPools([
        ...basketPools,
        {
          esgPoolId: pool[0],
          poolName: pool[1],
          icon: pool[2],
          poolPercent: poolPercent,
        },
      ]);
    }

    // Reset the input fields
    setPool([]);
    setPoolPercent("");
  };

  const handleRemovePool = (poolToRemove) => {
    setBasketPools(
      basketPools.filter((pool) => pool?.poolName !== poolToRemove)
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    const tokenPercentages = basketPools?.map((pool) =>
      parseInt(pool?.poolPercent)
    );

    const sum = tokenPercentages.reduce(
      (total, percentage) => total + percentage
    );

    if (sum !== 100) {
      toast.warning("Summation of all pool percentage should be 100");
      setLoading(false);
      return;
    }

    const modifiedCrops = basketPools?.map((pool) => ({
      esgPoolId: pool.esgPoolId,
      pool: pool.poolName,
      poolPercent: pool.poolPercent,
    }));

    const data = {
      name,
      description,
      volume,
      blockchain,
      basketPoolDomains: modifiedCrops,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_VORTX_URL}/crop/basket`,
        data,
        {
          headers: {
            "X-Auth-Token": appCtx.authToken,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data) {
        // navigate(`/crypto-basket/${response.data.basketId}`);
        toast.success("basket added successfully");
        setLoading(false);
        closeModal();
      }
    } catch (error) {
      toast.error("Failed to create basket");
      setLoading(false);
    }
  };

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      top: "53%",
      backgroundColor: "#000000",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
    },
  };
  function closeModal() {
    setOpenModal(false);
  }
  return (
    <div>
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        className={`${styles.popup}  `}
      >
        <div
          className="d-flex justify-content-end m-2 position-relative"
          style={{ maxHeight: "500px", overflow: "auto" }}
        >
          <div
            onClick={closeModal}
            className="btn btn-link position-absolute top-0 right-2"
            style={{ cursor: "pointer" }}
          >
            <i className="fa fa-times" style={{ fontSize: "20px" }}></i>
          </div>
          <div className="p-3">
            <form className="">
              <h5 className="text-center">Create Basket</h5>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name of Basket
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Enter Basket Name"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Description
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  placeholder="Enter description"
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Blockchain
                </label>

                <div className="d-flex flex-direction-row  gap-3">
                  <select
                    className="p-1 ms-2"
                    style={{
                      border:  "1 px solid #F6F6F6"
                      ,
                      borderRadius: "5px",
                      backgroundColor:  "#000000"
                      ,
                      color:  "#717579",
                      padding: "5px",
                    }}
                    id="blockchain"
                    value={blockchain}
                    onChange={(event) => setBlockchain(event.target.value)}
                  >
                    {Object.keys(chains)?.map((chain) => (
                      <option key={chain} value={chain.toUpperCase()}>
                        {chain.toUpperCase()}
                      </option>
                    ))}
                    <span>
                      <FontAwesomeIcon icon={faCircleChevronDown} />
                    </span>
                  </select>
                </div>
              </div>

              <div className="mb-4">
                <label htmlFor="name" className="form-label">
                  Pools
                </label>
                <div className="d-flex flex-direction-row  gap-3 ">
                  <select
                    className="p-1 ms-2"
                    style={{
                      border: `${
                        appCtx?.isDarkMode ? "1 px solid #F6F6F6" : "1 px solid #F6F6F6"
                      }`,
                      borderRadius: "5px",
                      backgroundColor: `${
                        appCtx.isDarkMode ? "#000000F" : "#000000"
                      }`,
                      color: `${appCtx.isDarkMode ? "#717579" : "#717579"}`,
                    }}
                    id="pool"
                    value={JSON.stringify(pool)}
                    onChange={(event) =>
                      setPool(JSON.parse(event.target.value))
                    }
                  >
                    <option value="">
                      <span> Select Pools</span>
                      <span>
                        <FontAwesomeIcon icon={faCircleChevronDown} />
                      </span>
                    </option>
                    {poolsList?.map((pool, index) => (
                      <option
                        key={index}
                        value={JSON.stringify([
                          pool?.esgPoolId,
                          pool?.poolName,
                          pool?.icon,
                        ])}
                      >
                        {pool?.poolName}
                      </option>
                    ))}
                  </select>
                  <input
                    className="form-control"
                    id="poolPercent"
                    type="number"
                    placeholder="pool percentage"
                    value={poolPercent}
                    onChange={(event) => setPoolPercent(event.target.value)}
                  />
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleAddPool}
                  >
                    Add
                  </button>
                </div>
              </div>

              {basketPools.length > 0 && (
                <div className="mb-4">
                  <label htmlFor="name" className="form-label">
                    Selected Pools
                  </label>
                  <ul className="d-flex  justify-content-start align-items-center gap-5">
                    {basketPools?.map((pool, index) => {
                      return (
                        <li
                          key={index}
                          className="list-none flex flex-col items-center justify-between"
                          onMouseEnter={(e) =>
                            e.currentTarget.firstChild.classList.remove(
                              "invisible"
                            )
                          }
                          onMouseLeave={(e) =>
                            e.currentTarget.firstChild.classList.add(
                              "invisible"
                            )
                          }
                        >
                          <div className="text-gray-400 invisible rounded-md px-1 mb-n12 ml-3 bg-purple-700 shadow-sm">
                            <FontAwesomeIcon
                              className=""
                              style={{ width: "8px", color: themedColor }}
                              onClick={() =>
                                handleRemovePool(pool?.poolName)
                              }
                              icon={faX}
                            />
                          </div>
                          {pool && (
                            <div className="flex flex-col items-center z-10">
                              <img
                                src={pool.icon}
                                alt={pool.poolName}
                                className=""
                                style={{ width: "30px" }}
                              />
                              <p>{pool?.poolPercent}%</p>
                            </div>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
              <div className="mb-4">
                <label htmlFor="name" className="form-label">
                  Volume
                </label>
                <input
                  className="form-control"
                  id="volume"
                  type="number"
                  placeholder="Enter Volume"
                  value={volume}
                  onChange={(event) => setVolume(event.target.value)}
                />
              </div>
              <div className="d-flex justify-content-center ">
                <button
                  className="btn btn-primary  "
                  type="submit"
                  onClick={handleSubmit}
                >
                  Continue{" "}
                  <span>
                    {loading && (
                      <CircularProgress size={20} className="ml-[5px]" />
                    )}
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CreateBasketModal;
