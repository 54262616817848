// DGUPlayground1.js

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Card,
  IconButton,
  Fade,
  Chip,
  Tooltip,
  Tabs,
  Tab,
  Paper,
  TextField,
  Divider,
  Container,
} from '@mui/material';
import {
  Download as DownloadIcon,
  Visibility as VisibilityIcon,
  PictureAsPdf as PdfIcon,
  Send as SendIcon,
  Delete as DeleteIcon,
  AttachFile as AttachFileIcon,
  ClearAll as ClearAllIcon,
} from '@mui/icons-material';
import { TransitionGroup } from 'react-transition-group';
import { styled, keyframes } from '@mui/system';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import MainWrapper from '../wrapper/MainWrapper';
import { ProtocolList } from '../ProtocolList';
import { useTheme } from '@mui/material/styles';

// If your SupportModal is in the same directory, adjust the path accordingly
import SupportModal from '../supportModal/SupportModal';

// -------------------------------------------
// Configuration Variables
// -------------------------------------------
const TYPING_SPEED = 30; // Adjust to slow/speed up typed messages

// -------------------------------------------
// Keyframe Animations
// -------------------------------------------
const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const fadeInOut = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.4; }
`;

const splashAnimation = keyframes`
  0%   { transform: scale(1); }
  50%  { transform: scale(1.2); }
  100% { transform: scale(1); }
`;

// -------------------------------------------
// Styled Components
// -------------------------------------------

const BackgroundContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  background: theme.palette.background.default,
  backgroundSize: '400% 400%',
  zIndex: -1,
  animation: `${gradientAnimation} 15s ease infinite`,
}));

const CenteredContainer = styled(Container)(({ theme }) => ({
  maxWidth: '1200px',
  margin: '0 auto',
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

const HeaderPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: 'rgba(0,0,0,0.7)',
  boxShadow: '0px 4px 8px rgba(0,0,0,0.2)',
}));

const ContentPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: 'rgba(0,0,0,0.7)',
  boxShadow: '0px 4px 8px rgba(0,0,0,0.2)',
}));

const MessageContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'role',
})(({ theme, role }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: role === 'user' ? 'flex-end' : 'flex-start',
  marginBottom: theme.spacing(2.5),
}));

const MessageBubble = styled(Box)(({ theme, role }) => ({
  backgroundColor: 'transparent',
  color: role === 'user' ? '#e36c04' : '#fff',
  border: role === 'user' ? '0.5px solid #e36c04' : '0.5px solid #fff',
  borderRadius: '16px',
  padding: '12px',
  maxWidth: '70%',
  wordWrap: 'break-word',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
  transition: 'box-shadow 0.3s ease, transform 0.3s ease',
  '&:hover': {
    boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.5)',
    transform: 'scale(1.02)',
  },
}));

const Timestamp = styled(Typography)(({ theme }) => ({
  fontSize: '0.625rem',
  opacity: 0.7,
  marginTop: theme.spacing(0.5),
}));

const RoleLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: 'bold',
  marginBottom: theme.spacing(0.5),
  opacity: 0.9,
  color: theme.palette.text.secondary,
}));

const TagChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  transition: 'background-color 0.3s ease, transform 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    transform: 'scale(1.05)',
  },
}));

const ButtonRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  marginTop: theme.spacing(1),
  flexWrap: 'wrap',
  padding: theme.spacing(1),
  justifyContent: 'center',
}));

const SendButton = styled(IconButton)(({ theme }) => ({
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.primary.contrastText,
  transition: theme.transitions.create(['transform', 'box-shadow', 'background'], {
    duration: 300,
    easing: 'ease',
  }),
  '&:hover': {
    background: 'linear-gradient(135deg, #0288d1, #42d392)',
    transform: 'scale(1.1)',
    boxShadow: theme.shadows[4],
  },
  '&:active': {
    animation: `${splashAnimation} 0.6s ease-out`,
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
    boxShadow: 'none',
    transform: 'none',
  },
}));

const ProcessingImage = styled('img')(({ theme }) => ({
  width: 180,
  height: 120,
  borderRadius: theme.shape.borderRadius,
  animation: `${fadeInOut} 2s infinite`,
  objectFit: 'cover',
}));

const FooterWrapper = styled(Box)(({ theme, isDragging }) => ({
  borderTop: `1px solid rgba(255, 255, 255, 0.1)`,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  flexWrap: 'wrap',
  transition: 'all 0.3s ease',
  ...(isDragging && {
    outline: '2px dashed #42d392',
    backgroundColor: 'rgba(66, 211, 146, 0.1)',
  }),
}));

const ImageThumbnail = styled('img')(({ theme }) => ({
  width: 60,
  height: 60,
  objectFit: 'cover',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
}));

// -------------------------------------------
// Main Component
// -------------------------------------------

const DGUPlayground1 = () => {
  const theme = useTheme();
  const { model: modelFromParams } = useParams();

  // For the support modal
  const [openSupportModal, setOpenSupportModal] = useState(false);

  // Tabs
  const [activeTab, setActiveTab] = useState('chat');
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // States
  const [model, setModel] = useState(modelFromParams || '');
  const [selectedModel, setSelectedModel] = useState(null);

  // Chat states
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  // For plant-flow
  const [uploadedImage, setUploadedImage] = useState(null);
  const [userInput, setUserInput] = useState('');
  const [apiResponse, setApiResponse] = useState(null);
  const [dataPoints, setDataPoints] = useState([]);

  // Drag & drop
  const [isDragging, setIsDragging] = useState(false);

  // Refs
  const conversationRef = useRef(null);
  const messagesEndRef = useRef(null);

  // Endpoint
  const fullApiUrl =
    'https://lbvm.vortx.ai/plant/flow?Secret-Key=ab413f4703b0781bb9096b86fd7c73bd25b8cb5f1731cf4b2660f51e3ff6a6f7';

  // Find model if needed
  useEffect(() => {
    const foundModel = ProtocolList.find((protocol) => protocol.model === model);
    setSelectedModel(foundModel);
  }, [model]);

  // Scroll to bottom
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Typing simulation
  const typeAgentMessage = async (content) => {
    return new Promise((resolve) => {
      const newMsg = {
        role: 'agent',
        contentType: 'text',
        content: '',
        timestamp: new Date(),
      };
      setMessages((prev) => [...prev, newMsg]);

      let idx = 0;
      const interval = setInterval(() => {
        idx++;
        setMessages((prev) => {
          const updated = [...prev];
          updated[updated.length - 1].content = content.slice(0, idx);
          return updated;
        });
        if (idx === content.length) {
          clearInterval(interval);
          resolve();
        }
      }, TYPING_SPEED);
    });
  };

  const addMessage = async (role, contentType, content) => {
    if (role === 'agent' && contentType === 'text') {
      await typeAgentMessage(content);
    } else {
      const newMsg = {
        role,
        contentType,
        content,
        timestamp: new Date(),
      };
      setMessages((prev) => [...prev, newMsg]);
    }
  };

  // On open of the support modal, also add a line of agent text to the chat
  const handleOpenSupportModal = async () => {
    setOpenSupportModal(true);
    // Add line from the agent to mimic DGU playground behavior
    await addMessage(
      'agent',
      'text',
      'Sure! Let me connect you to our deployment support. Please fill out your request.'
    );
  };

  // Initial greeting
  useEffect(() => {
    (async () => {
      await addMessage(
        'agent',
        'text',
        "Hello there! I'm your Natural origin analysis companion. Upload an image and I'll generate insights."
      );
      
      await addMessage('agent', 'text', '↓↓ Upload your image below to begin ↓↓');
    })();
    // eslint-disable-next-line
  }, []);

  // The main synergy
  const handleSynthesize = async (imageFile, optionalText) => {
    setLoading(true);

    // If user typed something, show it as user text
    if (optionalText?.trim()) {
      setMessages((prev) => [
        ...prev,
        {
          role: 'user',
          contentType: 'text',
          content: optionalText.trim(),
          timestamp: new Date(),
        },
      ]);
    }

    // Show user's image
    setMessages((prev) => [
      ...prev,
      {
        role: 'user',
        contentType: 'image',
        content: URL.createObjectURL(imageFile),
        timestamp: new Date(),
      },
    ]);

    // Agent: Analyzing...
    await addMessage('agent', 'text', 'Analyzing your plant image...');
    // Placeholder loader
    setMessages((prev) => [
      ...prev,
      {
        role: 'agent',
        contentType: 'placeholder',
        content: URL.createObjectURL(imageFile),
        timestamp: new Date(),
      },
    ]);

    // Prepare form data
    const formData = new FormData();
    formData.append('image', imageFile, imageFile.name);

    try {
      const response = await axios.post(fullApiUrl, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Remove placeholder
      setMessages((prev) =>
        prev.filter((m) => !(m.role === 'agent' && m.contentType === 'placeholder'))
      );

      if (response.data?.image) {
        const imageUrl = response.data.image;
        const points = response.data?.data_points || [];

        await addMessage('agent', 'text', 'Here are the insights derived from the plant image:');

        if (points.length > 0) {
          setMessages((prev) => [
            ...prev,
            {
              role: 'agent',
              contentType: 'dataPoints',
              content: points,
              timestamp: new Date(),
            },
          ]);
          setDataPoints(points);
        }

        // Show final image + PDF prompt
        setMessages((prev) => [
          ...prev,
          {
            role: 'agent',
            contentType: 'image',
            content: imageUrl,
            timestamp: new Date(),
          },
          {
            role: 'agent',
            contentType: 'pdf',
            content: 'You may download the entire conversation as a PDF.',
            timestamp: new Date(),
          },
        ]);
        setApiResponse(imageUrl);
      } else {
        await addMessage(
          'agent',
          'text',
          'It appears the API did not return an image. Please try again.'
        );
      }
    } catch (error) {
      // Remove placeholder if error
      setMessages((prev) =>
        prev.filter((m) => !(m.role === 'agent' && m.contentType === 'placeholder'))
      );
      console.error('API Error:', error);
      await addMessage('agent', 'text', 'An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Send button
  const handleSend = () => {
    const text = userInput.trim();
    if (!text && !uploadedImage) return;

    // If image
    if (uploadedImage) {
      handleSynthesize(uploadedImage, text);
    } else {
      // Just text
      setMessages((prev) => [
        ...prev,
        {
          role: 'user',
          contentType: 'text',
          content: text,
          timestamp: new Date(),
        },
      ]);
      addMessage('agent', 'text', "Please upload a plant image if you'd like me to analyze it.");
    }

    setUserInput('');
    setUploadedImage(null);
    const fileInput = document.getElementById('image-input');
    if (fileInput) fileInput.value = '';
  };

  // PDF
  const handleDownloadPdf = async () => {
    if (!conversationRef.current) return;
    const canvas = await html2canvas(conversationRef.current, { scale: 2 });
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'pt', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imageProps = pdf.getImageProperties(imgData);
    const ratio = imageProps.width / imageProps.height;
    let w = pdfWidth - 40;
    let h = (pdfWidth - 40) / ratio;
    if (h > pdfHeight - 40) {
      h = pdfHeight - 40;
      w = h * ratio;
    }
    pdf.addImage(imgData, 'PNG', 20, 20, w, h);
    pdf.save('conversation.pdf');
  };

  // Format chat timestamp
  const formatTime = (timestamp) => {
    return new Intl.DateTimeFormat('default', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }).format(timestamp);
  };

  // File input
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) setUploadedImage(file);
    e.target.value = '';
  };

  // Remove image
  const handleRemoveImage = () => {
    setUploadedImage(null);
    const fileInput = document.getElementById('image-input');
    if (fileInput) fileInput.value = '';
  };

  // View / Download
  const handleViewImage = () => {
    if (apiResponse) window.open(apiResponse, '_blank');
  };
  const handleDownloadImage = () => {
    if (apiResponse) {
      const link = document.createElement('a');
      link.href = apiResponse;
      link.setAttribute('download', 'plant_analysis.jpg');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // Drag & Drop
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };
  const handleDragLeave = () => setIsDragging(false);
  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) setUploadedImage(file);
    const fileInput = document.getElementById('image-input');
    if (fileInput) fileInput.value = '';
  };

  // Clear Chat
  const handleClearChat = () => {
    setMessages([]);
    setUserInput('');
    setUploadedImage(null);
    setDataPoints([]);
    setApiResponse(null);
    const fileInput = document.getElementById('image-input');
    if (fileInput) fileInput.value = '';
  };

  // Disabled if no input
  const sendDisabled = !uploadedImage && !userInput.trim();

  // Custom scroll style
  const customScroll = {
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.primary.dark,
    },
  };

  return (
    <MainWrapper>
      {/* Gradient background */}
      <BackgroundContainer />

      <CenteredContainer>
        {/* Header Section */}
        <HeaderPaper variant="blackBox">
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'space-between',
              alignItems: { xs: 'flex-start', md: 'center' },
              gap: 2,
            }}
          >
            <Box>
              <Typography variant="h4">Track Natural Origin</Typography>
              <Typography variant="subtitle1" sx={{ mt: 1 }}>
                Tracking the origin and journey of natural resources filling in critical location gaps
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
                <Chip variant="outlined" label="Food" />
                <Chip variant="outlined" label="Water" />
                <Chip variant="outlined" label="Elements" />
              </Box>
            </Box>

            {/* On click -> open support modal + add agent line */}
            <Button
              variant="contained"
              color="secondary"
              sx={{ padding: '8px 24px', textTransform: 'none' }}
              onClick={handleOpenSupportModal}
            >
              Contact for Deployment
            </Button>
          </Box>
        </HeaderPaper>

        {/* The SupportModal (like in DGUPlayground) */}
        <SupportModal openModal={openSupportModal} setOpenModal={setOpenSupportModal} />

        {/* Main content area */}
        <ContentPaper variant="blackBox">
          {/* Tabs for Chat / API Reference */}
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}
            indicatorColor="secondary"
            textColor="inherit"
          >
            <Tab value="chat" label="Chat" />
            <Tab value="apiReference" label="API Reference" />
          </Tabs>

          {/* Chat Tab */}
          {activeTab === 'chat' && (
            <Box sx={{ ...customScroll, display: 'flex', flexDirection: 'column' }}>
              {/* Clear Chat button */}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<ClearAllIcon />}
                  onClick={handleClearChat}
                >
                  Clear
                </Button>
              </Box>
              <Divider sx={{ mb: 2, borderColor: 'rgba(255, 255, 255, 0.1)' }} />

              {/* Conversation */}
              <Box sx={{ width: '100%' }} ref={conversationRef}>
                {messages.length > 0 ? (
                  <TransitionGroup>
                    {messages.map((msg, index) => (
                      <Fade key={index} in timeout={500}>
                        <MessageContainer role={msg.role}>
                          <RoleLabel variant="caption">
                            {msg.role === 'user' ? 'You' : 'Agent'}
                          </RoleLabel>
                          <MessageBubble role={msg.role}>
                            {/* text */}
                            {msg.contentType === 'text' && (
                              <Typography
                                variant="body1"
                                sx={{ whiteSpace: 'pre-wrap', lineHeight: 1.5 }}
                              >
                                {msg.content}
                              </Typography>
                            )}

                            {/* data points */}
                            {msg.contentType === 'dataPoints' && (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1 }}>
                                {msg.content.map((point, i) => (
                                  <Tooltip key={i} title="Data Point">
                                    <TagChip label={point} />
                                  </Tooltip>
                                ))}
                              </Box>
                            )}

                            {/* image message */}
                            {msg.contentType === 'image' && (
                              <Card
                                sx={{
                                  mt: 2,
                                  borderRadius: 2,
                                  overflow: 'hidden',
                                  boxShadow: theme.shadows[1],
                                  transition: theme.transitions.create(
                                    ['transform', 'box-shadow'],
                                    {
                                      duration: 300,
                                      easing: 'ease',
                                    }
                                  ),
                                  '&:hover': {
                                    transform: 'scale(1.02)',
                                    boxShadow: theme.shadows[3],
                                  },
                                }}
                              >
                                <img
                                  src={msg.content}
                                  alt="Synthesized"
                                  width="100%"
                                  style={{ objectFit: 'cover' }}
                                />
                                {msg.role === 'agent' && (
                                  <ButtonRow>
                                    <Tooltip title="View Full Image">
                                      <Button
                                        startIcon={<VisibilityIcon />}
                                        variant="contained"
                                        color="primary"
                                        onClick={handleViewImage}
                                      >
                                        View
                                      </Button>
                                    </Tooltip>
                                    <Tooltip title="Download Image">
                                      <Button
                                        startIcon={<DownloadIcon />}
                                        variant="contained"
                                        color="primary"
                                        onClick={handleDownloadImage}
                                      >
                                        Download
                                      </Button>
                                    </Tooltip>
                                  </ButtonRow>
                                )}
                              </Card>
                            )}

                            {/* placeholder / loader */}
                            {msg.contentType === 'placeholder' && (
                              <Box sx={{ width: 180, height: 120, position: 'relative' }}>
                                <ProcessingImage src={msg.content} alt="Processing" />
                              </Box>
                            )}

                            {/* PDF prompt */}
                            {msg.contentType === 'pdf' && (
                              <Box>
                                <Typography variant="body2" sx={{ mb: 1 }}>
                                  {msg.content}
                                </Typography>
                                <ButtonRow>
                                  <Tooltip title="Download Conversation as PDF">
                                    <Button
                                      startIcon={<PdfIcon />}
                                      variant="contained"
                                      color="secondary"
                                      onClick={handleDownloadPdf}
                                    >
                                      Download PDF
                                    </Button>
                                  </Tooltip>
                                </ButtonRow>
                              </Box>
                            )}
                          </MessageBubble>
                          <Timestamp variant="caption">
                            {msg.timestamp && formatTime(msg.timestamp)}
                          </Timestamp>
                        </MessageContainer>
                      </Fade>
                    ))}
                  </TransitionGroup>
                ) : (
                  <Typography
                    variant="body2"
                    sx={{ textAlign: 'center', color: '#aaa', mt: 3 }}
                  >
                    No messages yet. Start by uploading an image or typing something below.
                  </Typography>
                )}
                <div ref={messagesEndRef} />
              </Box>

              {/* Footer for image + text + send */}
              <FooterWrapper
                isDragging={isDragging}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <input
                  type="file"
                  accept="image/*"
                  id="image-input"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
                {/* Attach button */}
                <Tooltip title="Attach Image">
                  <Button
                    variant="outlined"
                    color="inherit"
                    startIcon={<AttachFileIcon />}
                    onClick={() => document.getElementById('image-input').click()}
                    sx={{
                      borderColor: '#e36c09',
                      color: '#e36c09',
                      '&:hover': {
                        borderColor: '#cc5e08',
                        backgroundColor: 'rgba(227,108,9,0.1)',
                      },
                    }}
                  >
                    Upload Image
                  </Button>
                </Tooltip>

                {/* Optional image preview */}
                {uploadedImage && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <ImageThumbnail src={URL.createObjectURL(uploadedImage)} alt="Preview" />
                    <IconButton onClick={handleRemoveImage} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}

                {/* If you'd like a text field for optional notes, you can uncomment below: */}
                {/*
                <TextField
                  placeholder="Enter optional notes..."
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  sx={{
                    backgroundColor: 'rgba(0,0,0,0.2)',
                    borderRadius: 1,
                    input: { color: '#fff' },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': { borderColor: 'rgba(255,255,255,0.3)' },
                      '&:hover fieldset': { borderColor: '#fff' },
                      '&.Mui-focused fieldset': { borderColor: '#fff' },
                    },
                  }}
                />
                */}

                {/* Send button */}
                <SendButton onClick={handleSend} disabled={sendDisabled || loading}>
                  {loading ? '...' : <SendIcon style={{ fontSize: '24px' }} />}
                </SendButton>
              </FooterWrapper>
            </Box>
          )}

          {/* API Reference Tab */}
          {activeTab === 'apiReference' && (
            <Box sx={{ p: 2 }}>
              {/* API Endpoint */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
                <Chip variant="outlined"
                  label="POST" 
                />
                <Typography variant="t1">
                  https://lbvm.vortx.ai/plant/flow
                </Typography>
              </Box>

              {/* Headers */}
              <Box sx={{ mb: 4 }}>
                <Typography variant="t2_grey" sx={{ mb: 2 }}>Headers</Typography>
                
                <Paper 
                  variant="blackBox" 
                  sx={{ 
                    backgroundColor: theme.palette.background.black,
                    border: `1px solid ${theme.palette.background.grey}`
                  }}
                >
                  {/* Content-Type Header */}
                  <Box 
                    sx={{ 
                      p: 2,
                      display: 'flex',
                      alignItems: 'center',
                      borderBottom: `1px solid ${theme.palette.background.grey}`,
                      '&:hover': {
                        backgroundColor: 'rgba(255,255,255,0.03)'
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Typography variant="t1">Content-Type</Typography>
                      <Typography variant="t2_grey">multipart/form-data</Typography>
                    </Box>
                  </Box>

                  {/* Secret-Key Header */}
                  <Box 
                    sx={{ 
                      p: 2,
                      display: 'flex',
                      alignItems: 'center',
                      '&:hover': {
                        backgroundColor: 'rgba(255,255,255,0.03)'
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Typography variant="t1">Secret-Key</Typography>
                      <Typography variant="t2_grey">[Your Secret Key]</Typography>
                      <Chip variant="filled"
                        label="required" 
                        size="small"
                      />
                    </Box>
                  </Box>
                </Paper>
              </Box>

              {/* Body Parameters */}
              <Box sx={{ mb: 4 }}>
                <Typography variant="t2_grey" sx={{ mb: 2 }}>Body Params</Typography>
                
                <Paper 
                  variant="blackBox" 
                  sx={{ 
                    backgroundColor: theme.palette.background.black,
                    border: `1px solid ${theme.palette.background.grey}`
                  }}
                >
                  {/* Image Parameter */}
                  <Box 
                    sx={{ 
                      p: 2,
                      display: 'flex',
                      alignItems: 'center',
                      '&:hover': {
                        backgroundColor: 'rgba(255,255,255,0.03)'
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Typography variant="t1">image</Typography>
                      <Typography variant="t2_grey">file</Typography>
                      <Chip variant="filled"
                        label="required" 
                        size="small"
                      />
                    </Box>
                  </Box>
                </Paper>
              </Box>

              {/* Responses */}
              <Box sx={{ mb: 4 }}>
                <Typography variant="t2_grey" sx={{ mb: 2 }}>Responses</Typography>
                
                <Paper 
                  variant="blackBox" 
                  sx={{ 
                    backgroundColor: theme.palette.background.black,
                    border: `1px solid ${theme.palette.background.grey}`
                  }}
                >
                  {/* 200 Success */}
                  <Box 
                    sx={{ 
                      p: 2,
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      borderBottom: `1px solid ${theme.palette.background.grey}`,
                      '&:hover': {
                        backgroundColor: 'rgba(255,255,255,0.03)'
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Box 
                        sx={{ 
                          width: 8,
                          height: 8,
                          borderRadius: '50%',
                          backgroundColor: theme.palette.success.main 
                        }} 
                      />
                      <Typography variant="t1">200</Typography>
                      <Typography variant="t2_grey">Success</Typography>
                    </Box>
                  </Box>

                  {/* 400 Bad Request */}
                  <Box 
                    sx={{ 
                      p: 2,
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      borderBottom: `1px solid ${theme.palette.background.grey}`,
                      '&:hover': {
                        backgroundColor: 'rgba(255,255,255,0.03)'
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Box 
                        sx={{ 
                          width: 8,
                          height: 8,
                          borderRadius: '50%',
                          backgroundColor: theme.palette.error.main 
                        }} 
                      />
                      <Typography variant="t1">400</Typography>
                      <Typography variant="t2_grey">Bad Request</Typography>
                    </Box>
                  </Box>

                  {/* 401 Unauthorized */}
                  <Box 
                    sx={{ 
                      p: 2,
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      borderBottom: `1px solid ${theme.palette.background.grey}`,
                      '&:hover': {
                        backgroundColor: 'rgba(255,255,255,0.03)'
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Box 
                        sx={{ 
                          width: 8,
                          height: 8,
                          borderRadius: '50%',
                          backgroundColor: theme.palette.error.main 
                        }} 
                      />
                      <Typography variant="t1">401</Typography>
                      <Typography variant="t2_grey">Unauthorized</Typography>
                    </Box>
                  </Box>

                  {/* 500 Internal Server Error */}
                  <Box 
                    sx={{ 
                      p: 2,
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      borderBottom: `1px solid ${theme.palette.background.grey}`,
                      '&:hover': {
                        backgroundColor: 'rgba(255,255,255,0.03)'
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Box 
                        sx={{ 
                          width: 8,
                          height: 8,
                          borderRadius: '50%',
                          backgroundColor: theme.palette.error.main 
                        }} 
                      />
                      <Typography variant="t1">500</Typography>
                      <Typography variant="t2_grey">Internal Server Error</Typography>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>
          )}
        </ContentPaper>
      </CenteredContainer>
    </MainWrapper>
  );
};

export default DGUPlayground1;
