import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import styles from "./SwapModal.module.css";
// import { WagmiConfig, createConfig, useAccount } from "wagmi";
// import {
//   ConnectKitProvider,
//   ConnectKitButton,
//   getDefaultConfig,
// } from "connectkit";
import NonCustodialSwap from "./NonCustodialSwap/NonCustodialSwap";
import CustodialSwap from "./CustodialSwap/CustodialSwap";
import SwapSuccessModal from "./SwapSuccessModal";
import { useWalletView } from "../../hooks/useWalletView";

// const config = createConfig(
//   getDefaultConfig({
//     // Required API Keys
//     alchemyId: "MzUaa0A87yexjd8UKcHm8HIr1f4aghxT",
//     walletConnectProjectId: "a8024e8262cb4e7102941a3577b5a5c0",

//     // Required
//     appName: "0x React.js Demo App",

//     // Optional
//     appDescription: "A React.js demo app for 0x Swap API and ConnectKit",
//   })
// );

function SwapModal({
  openModal,
  setOpenModal,
  allCoinPrice,
  usersToken,
  symbol,
  isTokenDetailsPage,
  setTransactionData,
  setOpenTransferSuccessModal,
  isMetamaskSwapPending,
  setIsMetamaskSwapPending,
  isMetamaskSwapConfirming,
  setIsMetamaskSwapConfirming,
  isMetamaskSwapConfirmed,
  setIsMetamaskSwapConfirmed,
  metmaskTransactionHash,
  setMetmaskTransactionHash,
  setOpenMetamaskSwapSuccessModal,
  sendTokenId,
setSendTokenId,
receiveTokenId,
setReceiveTokenId
}) {
  const [finalize, setFinalize] = useState(false);

  // const { address } = useAccount();

  const appCtx = useSelector((state) => state.app);
  const width = window.screen.width;
  let isMobileDevice = width < 600;

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9990,
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: 9991,
      backgroundColor: "#000000",
      border: "1px solid  #000000",
      color: "black",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      // overflow: "hidden",
      width: `${isMobileDevice ? "95%" : "500px"}`,
    },
  };
  function closeModal() {
    setOpenModal(false);
  }
  const { getAllCoin, allCoin } = useWalletView();
  useEffect(() => {
    getAllCoin(0, 60);
  }, [appCtx.blockchain]);

  return (
    <>
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        {appCtx.custodial ? (
          <>
            {openModal && (
              <CustodialSwap
                openModal={openModal}
                setOpenModal={setOpenModal}
                allCoin={allCoin}
                allCoinPrice={allCoinPrice}
                usersToken={usersToken}
                setOpenTransferSuccessModal={setOpenTransferSuccessModal}
                setTransactionData={setTransactionData}
                closeSwapModal={closeModal}
                symbol={symbol}
                isTokenDetailsPage={isTokenDetailsPage}
              />
            )}
          </>
        ) : (
          <>
            {openModal && (
              <NonCustodialSwap
                openModal={openModal}
                setOpenModal={setOpenModal}
                allCoin={allCoin}
                allCoinPrice={allCoinPrice}
                usersToken={usersToken}
                setOpenTransferSuccessModal={setOpenTransferSuccessModal}
                setTransactionData={setTransactionData}
                closeSwapModal={closeModal}
                symbol={symbol}
                isMetamaskSwapPending={isMetamaskSwapPending}
                setIsMetamaskSwapPending={setIsMetamaskSwapPending}
                isMetamaskSwapConfirming={isMetamaskSwapConfirming}
                setIsMetamaskSwapConfirming={setIsMetamaskSwapConfirming}
                isMetamaskSwapConfirmed={isMetamaskSwapConfirmed}
                setIsMetamaskSwapConfirmed={setIsMetamaskSwapConfirmed}
                metmaskTransactionHash={metmaskTransactionHash}
                setMetmaskTransactionHash={setMetmaskTransactionHash}
                setOpenMetamaskSwapSuccessModal={
                  setOpenMetamaskSwapSuccessModal
                }
                sendTokenId={sendTokenId}
                setSendTokenId={setSendTokenId}
                receiveTokenId={receiveTokenId}
                setReceiveTokenId={setReceiveTokenId}
                isTokenDetailsPage={isTokenDetailsPage}
              />
            )}
          </>
        )}
      </Modal>
    </>
  );
}

export default SwapModal;
