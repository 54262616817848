import { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronDown, faX } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import { toast } from "react-toastify";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { useWalletView } from "../../hooks/useWalletView";
import { nativeToken } from "../../config";
function AddTokenModal({ openModal, setOpenModal }) {
  const [loading, setLoading] = useState(false);
  const [minAmount, setMinAmount] = useState("");
  const appCtx = useSelector((state) => state.app);
  const [logoPreview, setLogoPreview] = useState();
  const [collectionLogo, setCollectionLogo] = useState();
  const [logoUrl, setLogoUrl] = useState("");
  const [contractAddress, setContractAddress] = useState("");
  const [tokenShortName, setTokenShortName] = useState("");
  const [tokenName, setTokenName] = useState("");
  const [decimals, setDecimals] = useState(null);
  const [website, setWebsite] = useState(null);
  const [whitepaper, setWhitepaper] = useState(null);
  const [twitter, setTwitter] = useState(null);
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [standard, setStandard] = useState(null);
  const [blockchain, setBlockchain] = useState(appCtx.blockchain);
  const [symbol, setSymbol] = useState("");
  const [slug, setSlug] = useState("");
    const [currentComponent, setCurrentComponent] = useState(1);
    const [tokenDetailsloading, setTokenDetailsloading] = useState(false);
    const [tokenDetails, setTokenDetails] = useState({});

    const navigate = useNavigate();
    const fileRef = useRef();
    const makeRequest = useAuthorizedHttp();

    const chains = Object.keys(nativeToken)
      .filter((key) => key !== "ALGORAND")
      .reduce((obj, key) => {
        obj[key] = nativeToken[key];
        return obj;
      }, {});

    const handleSubmit = async (event) => {
      event.preventDefault();

      setLoading(true);

      const data = {
        contractAddress,
        tokenShortName: tokenDetails?.symbol,
        tokenName: tokenDetails?.name,
        tokenIcon: logoUrl,
        decimals: tokenDetails?.decimal,
        website,
        whitepaper,
        twitter,
        category,
        description,
        standard,
        blockchain,
        symbol: tokenDetails?.symbol,
        slug,
      };
      

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token`,
          data,
          {
            headers: {
              "X-Auth-Token": appCtx.authToken,
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response.data);
        if (response.data) {
          toast.success("Token added successfully");

          setLoading(false);
          closeModal();
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    const getTokenDetails = async (event) => {
      event.preventDefault();

      setTokenDetailsloading(true);
      makeRequest(
        {
          url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/${contractAddress}/detail?blockchain=${blockchain}`,

          method: "get",
          headers: {
            "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
            "Content-Type": "application/json",
          },
        },
        (data) => {
          setTokenDetails(data);
          setCurrentComponent(2);
          setTokenDetailsloading(false);
        },
        () => {
          setTokenDetailsloading(false);
        },
        (error) => {
          setTokenDetailsloading(false);
        }
      );
    };

    const customStyles = {
      overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        zIndex: 9998,
      },
      content: {
        position: "fixed",
        top: "50%",
        left: "50%",
        zIndex: 9999,
        backgroundColor: "#000000",
        border: "1px solid  #000000",
        color: "black",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        padding: "0",
        borderRadius: "10px",
        transform: "translate(-50%, -50%)",
        overflow: "hidden",
      },
    };
    function closeModal() {
      setOpenModal(false);
    }

    const handleLogoChange = useCallback((file) => {
      if (file) {
        setCollectionLogo(file);
        const objectUrl = URL.createObjectURL(file);
        setLogoPreview(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
      }
    }, []);

    const handleUploadBanner = (type) => {
      const data = new FormData();
      data.append("file", collectionLogo);

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_FILE_UPLOAD}/marketplace/${appCtx.marketplaceId}/web2/file/upload?type=collection-banner`,
        headers: {
          "Content-Type": "multipart/form-data",
          "X-Auth-Token": appCtx.authToken,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          setLogoUrl(response.data.fileUrl);

          setLogoUrl(response.data.fileUrl);
          toast.success("image upload successful");
        })
        .catch(function (error) {
          toast.error("image upload successful failed!");
          // setUploadingBanner(false);
        });
    };

    useEffect(() => {
      if (collectionLogo) {
        handleUploadBanner("logo");
      }
    }, [collectionLogo]);

    return (
      <div>
        <Modal
          isOpen={openModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div
            className="w-100  position-relative"
            style={{
              backgroundColor: "#000000",
              minWidth: "400px",
            }}
          >
            <div className="d-flex justify-content-end  position-relative">
              <button
                onClick={closeModal}
                className="btn btn-link position-absolute top-1 right-2"
              >
                <i className="fa fa-times"></i>
              </button>
            </div>

            <div className="p-3">
              <form className="">
                {currentComponent === 1 && (
                  <>
                    <h3 className="text-center mb-2">Add a Token</h3>
                    <div className="mb-4">
                      <label htmlFor="contractAddress" className="form-label">
                        Contract Address
                      </label>
                      <input
                        className="form-control"
                        id="contractAddress"
                        type="text"
                        placeholder="Enter Contract Address"
                        value={contractAddress || ""}
                        onChange={(event) =>
                          setContractAddress(event.target.value)
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Blockchain
                      </label>
                      <select
                        className="form-select"
                        style={{
                          borderRadius: "5px",
                          backgroundColor:  "#000000"
                          ,
                        }}
                        id="blockchain"
                        value={blockchain}
                        onChange={(event) => setBlockchain(event.target.value)}
                      >
                        {Object.keys(chains)?.map((chain) => (
                          <option key={chain} value={chain.toUpperCase()}>
                            {chain.toUpperCase()}
                          </option>
                        ))}
                        <span>
                          <FontAwesomeIcon icon={faCircleChevronDown} />
                        </span>
                      </select>
                    </div>
                    <div className="d-flex justify-content-center ">
                      <button
                        className="btn btn-primary"
                        onClick={getTokenDetails}
                      >
                        Continue{" "}
                        <span>
                          {tokenDetailsloading && (
                            <CircularProgress size={20} className="ml-[5px]" />
                          )}
                        </span>
                      </button>
                    </div>
                  </>
                )}
                {currentComponent === 2 && (
                  <>
                    <h3 className="text-center mb-2">Confirm Token Details</h3>
                    <div>
                      {Object.entries(tokenDetails).map(([key, value]) => (
                        <div key={key}>
                          <span>{key}: </span>
                          <span>{value}</span>
                        </div>
                      ))}
                    </div>
                    <div className="d-flex justify-content-center ">
                      <button
                        className="btn btn-primary"
                        onClick={() => setCurrentComponent(3)}
                      >
                        Continue
                      </button>
                    </div>
                  </>
                )}
                
                {currentComponent === 3 && (
                  <>
                    <h3 className="text-center mb-2">Add more token data</h3>
                    <div className="d-flex gap-2">
                      <div className="mb-3">
                        <label htmlFor="tokenName" className="form-label">
                          Token Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="tokenName"
                          placeholder="Enter Fund Name"
                          value={tokenDetails?.name}
                          readOnly
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="tokenShortName" className="form-label">
                          Token ShortName
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="tokenShortName"
                          placeholder="Enter Token Short Name"
                          value={tokenDetails?.symbol}
                          readOnly
                        />
                      </div>
                      <div className="mb-4">
                        <label htmlFor="name" className="form-label">
                          Decimal
                        </label>

                        <div className="d-flex flex-direction-row  gap-3 ">
                          <input
                            className="form-control"
                            id="decimals"
                            type="number"
                            placeholder="Enter Decimal Value"
                            value={tokenDetails?.decimal}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between gap-2">
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Description
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="description"
                          placeholder="Enter description"
                          value={description}
                          onChange={(event) =>
                            setDescription(event.target.value)
                          }
                        />
                      </div>
                      <div className="mb-4">
                        <label htmlFor="symbol" className="form-label">
                          Symbol
                        </label>
                        <input
                          className="form-control"
                          id="symbol"
                          type="text"
                          placeholder="Enter Symbol"
                          value={tokenDetails?.symbol}
                          readOnly
                        />
                      </div>
                      <div className="mb-4">
                        <label htmlFor="slug" className="form-label">
                          Slug
                        </label>
                        <input
                          className="form-control"
                          id="slug"
                          type="text"
                          placeholder="Enter Slug"
                          value={slug || ""}
                          onChange={(event) => setSlug(event.target.value)}
                        />
                      </div>
                    </div>

                    <div className="d-flex   justify-content-between gap-2 ">
                      <div className="mb-4">
                        <label htmlFor="twitter" className="form-label">
                          Twitter
                        </label>
                        <input
                          className="form-control"
                          id="twitter"
                          type="text"
                          placeholder="Enter Twitter Handle"
                          value={twitter || ""}
                          onChange={(event) => setTwitter(event.target.value)}
                        />
                      </div>

                      <div className="mb-4">
                        <label htmlFor="category" className="form-label">
                          Category
                        </label>
                        <input
                          className="form-control"
                          id="category"
                          type="text"
                          placeholder="Enter Category"
                          value={category || ""}
                          onChange={(event) => setCategory(event.target.value)}
                        />
                      </div>

                      <div className="mb-4">
                        <label htmlFor="standard" className="form-label">
                          Standard
                        </label>
                        <input
                          className="form-control"
                          id="standard"
                          type="text"
                          placeholder="Enter Standard"
                          value={standard || ""}
                          onChange={(event) => setStandard(event.target.value)}
                        />
                      </div>
                    </div>

                    <div className="d-flex gap-2">
                      <div className="mb-4">
                        <label htmlFor="website" className="form-label">
                          Website
                        </label>
                        <input
                          className="form-control"
                          id="website"
                          type="text"
                          placeholder="Enter Website URL"
                          value={website || ""}
                          onChange={(event) => setWebsite(event.target.value)}
                        />
                      </div>

                      <div className="mb-4">
                        <label htmlFor="whitepaper" className="form-label">
                          Whitepaper
                        </label>
                        <input
                          className="form-control"
                          id="whitepaper"
                          type="text"
                          placeholder="Enter Whitepaper URL"
                          value={whitepaper || ""}
                          onChange={(event) =>
                            setWhitepaper(event.target.value)
                          }
                        />
                      </div>
                      {/* upload section  */}
                      <div className="d-flex flex-row gap-4">
                        <div className="d-flex flex-column gap-2">
                          <label htmlFor="icon" className="form-label">
                            icon
                          </label>
                          <div className="d-flex gap-2 align-items-center">
                            <div
                              className="d-flex flex-column items-center justify-center"
                              style={{
                                backgroundColor: `${
                                  appCtx?.isDarkMode ? "#EAEAEA" : "#EAEAEA"
                                }`,
                                darkBackground: "#808080",
                                darkBackgroundZing: "#1F2937",
                                borderRadius: "5%",
                                width: "120px",
                                height: "120px",
                                cursor: "pointer",
                                position: "relative",
                              }}
                              onClick={() => fileRef.current.click()}
                            >
                              <p className="text-center my-auto">
                                Browse for icon
                              </p>
                              {logoPreview && (
                                <img
                                  src={logoPreview}
                                  alt="upload"
                                  style={{
                                    width: "120px",
                                    height: "120px",
                                    borderRadius: "5%",
                                    position: "absolute",
                                    // marginBottom: "-120px",
                                    overflow: "hidden",
                                    objectFit: "cover",
                                    cursor: "pointer",
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          <input
                            hidden={true}
                            type="file"
                            ref={fileRef}
                            onChange={(e) => {
                              handleLogoChange(e.target.files[0]);
                              // setShowUploadLogoButton(true);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="d-flex justify-content-center "
                      style={{
                        position: "absolute",
                        bottom: "6%",
                        left: "45%",
                      }}
                    >
                      <button
                        className="btn btn-primary  "
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Continue{" "}
                        <span>
                          {loading && (
                            <CircularProgress size={20} className="ml-[5px]" />
                          )}
                        </span>
                      </button>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </Modal>
      </div>
    );
}

export default AddTokenModal;
