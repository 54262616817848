import React from "react";
import ReactDOM from "react-dom/client"; // Updated import for ReactDOM
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider, CssBaseline } from "@mui/material"; // For theming
import store, { persistor } from "./context/store";
import App from "./App";
import theme from "./components/theme/theme"; // Import your centralized theme
import { SwapProviders } from "./components/swap/NonCustodialSwap/SwapProviders"; // Import SwapProviders
import "@rainbow-me/rainbowkit/styles.css"; // Import RainbowKit styles if required

const root = ReactDOM.createRoot(document.getElementById("root")); // Updated method

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}> {/* Wrap the app with ThemeProvider */}
          <CssBaseline /> {/* Normalize styles and apply baseline styling */}
          <SwapProviders> {/* Add SwapProviders */}
            <App />
          </SwapProviders>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
