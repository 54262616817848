import { useState } from "react";
import { useEffect } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import algosdk from "algosdk";
import { toast } from "react-toastify";
import { nativeToken } from "../../../config";
import PasswordModal from "./PasswordModal";
import web3 from "web3";
import SuccessModal from "./SuccessModal";
import SignupPageModal from "../../CustodialWalletSetup/SignupPageModal";
import { GetDecryptedData } from "../../GetDecryptedData/GetDecryptedData";
import AlgorandClient from "../../services/algorandsdk";
import useAuthorizedHttp from "../../../hooks/use-authorized-http";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCheck,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";

const { ETH_DATA_FORMAT, DEFAULT_RETURN_FORMAT } = require("web3");
var CryptoJS = require("crypto-js");

function ConfirmSendPaymentModal({
  openModal,
  setOpenModal,
  setPreviousOpen,
  address,
  amount,
  algos,
  setAlgos,
  showAssets,
  setInfo,
  option,
  allCoin,
  fundId,
  isCryptoFundTransfer,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const makeAuthorizedRequest = useAuthorizedHttp();
  const [loader, setLoader] = useState(false);
  const [openSignupPageModal, setOpenSignupPageModal] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [tokenAddress, setTokenAddress] = useState("");
  const [tokenDecimals, setTokenDecimals] = useState("");
  const [proceedToPay, setProceedToPay] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isAddressCopied, setIsAddressCopied] = useState(false);
  const [isReceiverAddressCopied, setIsReceiverAddressCopied] = useState(false);

  const appCtx = useSelector((state) => state.app);
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9996,
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: 9997,
      backgroundColor: "#000000",
      border: "1px solid  #000000",
      color: "black",

      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "20px",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
      width: "400px",
    },
  };

  function closeModal() {
    setOpenModal(false);
  }
  const { decryptedData } = GetDecryptedData();

  const handleCopyAddress = (value) => {
    setIsAddressCopied(!isAddressCopied);
    navigator.clipboard.writeText(value);
  };
  const handleCopyReceiverAddress = (value) => {
    setIsReceiverAddressCopied(!isReceiverAddressCopied);
    navigator.clipboard.writeText(value);
  };

  useEffect(() => {
    const send = allCoin.find((token) => token?.symbol === option);
    setTokenAddress(send?.contractAddress);
    setTokenDecimals(send?.decimals);
  }, [option]);

  const createAccount = function () {
    try {
      let myaccount = algosdk.mnemonicToSecretKey(decryptedData);
      console.log("myaccount:", myaccount);
      return myaccount;
    } catch (err) {
      toast.error("Transaction Failed ");
    }
  };

  const keypress = async () => {
    process.stdin.setRawMode(true);
    return new Promise((resolve) =>
      process.stdin.once("data", () => {
        process.stdin.setRawMode(false);
        resolve();
      })
    );
  };
  const assetTransfer = async () => {
    setLoader(true);
    try {
      const waitForConfirmation = async function (algodclient, txId) {
        let response = await algodclient.status().do();
        let lastround = response["last-round"];
        while (true) {
          const pendingInfo = await algodclient
            .pendingTransactionInformation(txId)
            .do();
          if (
            pendingInfo["confirmed-round"] !== null &&
            pendingInfo["confirmed-round"] > 0
          ) {
            //Got the completed Transaction
            // console.log("Transaction " + txId + " confirmed in round " + pendingInfo["confirmed-round"]);
            break;
          }
          lastround++;
          await algodclient.statusAfterBlock(lastround).do();
        }
      };
      let assetID = parseInt(process.env.REACT_APP_TAIL_COIN_TOKEN);
      // Transfer New Asset:
      // Now that account3 can recieve the new tokens
      // we can tranfer tokens in from the creator
      // to account3
      // First update changing transaction parameters
      // We will account for changing transaction parameters
      // before every transaction in this example

      let params = await AlgorandClient.getTransactionParams().do();
      //comment out the next two lines to use suggested fee
      params.fee = 1000;
      params.flatFee = true;
      const senderaddress = appCtx?.walletAddress?.find(
        (addr) => addr?.blockchain === appCtx?.blockchain
      )?.address;

      let sender = senderaddress;
      let recipient = address;
      let revocationTarget = undefined;
      let closeRemainderTo = undefined;
      const enc = new TextEncoder();
      const note = undefined;
      //Amount of the asset to transfer
      amount = parseFloat(algos) * 100;
      let recoveredAccount1 = await algosdk.mnemonicToSecretKey(decryptedData);

      // signing and sending "txn" will send "amount" assets from "sender" to "recipient"
      let xtxn = algosdk.makeAssetTransferTxnWithSuggestedParams(
        sender,
        recipient,
        closeRemainderTo,
        revocationTarget,
        amount,
        note,
        assetID,
        params
      );

      let rawSignedTxn = xtxn.signTxn(recoveredAccount1.sk);
      try {
        let xtx = await AlgorandClient.sendRawTransaction(rawSignedTxn).do();
        // console.log("Transaction : " + xtx.txId);

        await waitForConfirmation(AlgorandClient, xtx.txId);
        toast.success("Asset Transfer Success");
        showAssets();
        setLoader(false);
        setOpenModal(false);
        setPreviousOpen(false);
        setInfo(false);
        setAlgos(0);
      } catch {
        toast.error(
          "You cannot send Tale Coin to an account which has not opted in Tale Coin asset"
        );
        setLoader(false);
      }
    } catch {
      toast.error("Asset Transfer failed ");
      setLoader(false);
    }
  };

  function getWeb3Provider(blockchain) {
    console.log(blockchain);
    if (blockchain === "POLYGON") {
      return new web3(
        new web3.providers.HttpProvider(
          process.env.REACT_APP_POLYGON_RPC_ENDPOINT
        )
      );
    } else if (blockchain === "BSC") {
      return new web3(
        new web3.providers.HttpProvider(process.env.REACT_APP_BSC_RPC_ENDPOINT)
      );
    } else if (blockchain === "ETHEREUM") {
      return new web3(
        new web3.providers.HttpProvider(
          process.env.REACT_APP_ETHEREUM_RPC_ENDPOINT
        )
      );
    }
  }

  const handleTransaction = async () => {
    setLoader(true);

    if (appCtx.blockchain === "ALGORAND") {
      try {
        let myAccount = createAccount();
        // console.log("Press any key when the account is funded");
        // await keypress();
        // Connect your client

        //Check your balance
        let accountInfo = await AlgorandClient.accountInformation(
          myAccount.addr
        ).do();

        // Construct the transaction
        let params = await AlgorandClient.getTransactionParams().do();
        // comment out the next two lines to use suggested fee
        params.fee = algosdk.ALGORAND_MIN_TX_FEE;
        params.flatFee = true;

        // receiver defined as TestNet faucet address
        const receiver = address;
        const enc = new TextEncoder();
        const note = enc.encode("Transfer Algos");
        let amount = parseFloat(algos) * 1000000;
        let sender = myAccount.addr;
        let txn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
          from: sender,
          to: receiver,
          amount: amount,
          note: note,
          suggestedParams: params,
        });

        // Sign the transaction
        let signedTxn = txn.signTxn(myAccount.sk);
        let txId = txn.txID().toString();

        // Submit the transaction
        await AlgorandClient.sendRawTransaction(signedTxn).do();

        // Wait for confirmation
        let confirmedTxn = await algosdk.waitForConfirmation(
          AlgorandClient,
          txId,
          4
        );
        //Get the completed Transaction
        // console.log("Transaction " + txId + " confirmed in round " + confirmedTxn["confirmed-round"]);
        let string = new TextDecoder().decode(confirmedTxn.txn.txn.note);
        // console.log("Note field: ", string);
        accountInfo = await AlgorandClient.accountInformation(
          myAccount.addr
        ).do();
        // console.log("Transaction Amount: %d microAlgos", confirmedTxn.txn.txn.amt);
        // console.log("Transaction Fee: %d microAlgos", confirmedTxn.txn.txn.fee);

        // console.log("Account balance: %d microAlgos", accountInfo.amount);
        toast.success("Algo Transfer Successfull");
        showAssets();
        setLoader(false);
        setOpenModal(false);
        setPreviousOpen(false);
        setInfo(false);
        setAlgos(0);
      } catch (err) {
        toast.error("Algo Transfer failed :(");
        setLoader(false);
      }
    }
    if (appCtx.blockchain !== "ALGORAND" && appCtx.blockchain !== "SOLANA") {
      // required code for metamsk send
      // .....................................................................
    }
  };

  const handleConfirm = () => {
    console.log("decryptedData from handle confirm", decryptedData);
    console.log("option from handle confirm", option);

    if (decryptedData) {
      if (option === "tale") {
        assetTransfer();
      } else {
        if (proceedToPay) {
          handleTransaction();
        } else {
          setOpenPasswordModal(true);
        }
      }
    } else {
      setOpenSignupPageModal(true);
    }
  };
  // useEffect(() => {
  //   if (proceedToPay) {
  //     handleConfirm();
  //   }
  // }, [proceedToPay]);

  //const themedColor = "black";
  const themedColor = "black";
  return (
    <Modal
      isOpen={openModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="">
        <div
          className="d-flex align-items-center gap-2"
          onClick={closeModal}
          style={{ cursor: "pointer" }}
        >
          <ArrowBackIosOutlinedIcon className="" style={{ width: "20px" }} />
          <h5 className="mb-0"> Edit</h5>
        </div>
      </div>

      <div
        className="d-flex flex-column justify-content-center align-items-center pb-2 gap-2 overflow-hidden mt-2"
        style={{
          color: themedColor,
        }}
      >
        <div class="d-flex justify-content-between align-items-center px-3 w-100 border-bottom border-top  py-2 ">
          <div class="text-sm d-flex align-items-center">
            {appCtx.walletAddress
              .find((addr) => addr.blockchain === appCtx.blockchain)
              ?.address?.substring(0, 10)}
            ...
            <div
              onClick={() =>
                handleCopyAddress(
                  appCtx.walletAddress.find(
                    (addr) => addr.blockchain === appCtx.blockchain
                  )?.address
                )
              }
              className="text-secondary px-3"
              style={{
                fontSize: "12px",
                cursor: "pointer",
              }}
            >
              {isAddressCopied ? (
                <FontAwesomeIcon
                  icon={faCheck}
                  className=""
                  style={{
                    color: themedColor,
                  }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCopy}
                  className=""
                  style={{
                    color: themedColor,
                  }}
                />
              )}
            </div>
          </div>
          <div>
            <FontAwesomeIcon
              icon={faArrowRight}
              className=""
              style={{
                color: themedColor,
              }}
            />
          </div>
          <div class="text-sm d-flex align-items-center">
            {address?.substring(0, 10)}...{" "}
            <div
              onClick={() => handleCopyReceiverAddress(address)}
              className="text-secondary px-3"
              style={{
                fontSize: "12px",
                cursor: "pointer",
              }}
            >
              {isReceiverAddressCopied ? (
                <FontAwesomeIcon
                  icon={faCheck}
                  className=""
                  style={{
                    color: themedColor,
                  }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCopy}
                  className=""
                  style={{
                    color: themedColor,
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div class="w-100">
          <div class="w-100 px-3  py-2 border  d-flex flex-column gap-1">
            <div class="w-150 d-flex justify-content-center p-1 rounded-lg border  text-dark">
              {appCtx.blockchain === "ALGORAND"
                ? option === "tale"
                  ? "SENDING TALE"
                  : "SENDING ALGOS"
                : `SENDING ${option}`}
            </div>
            <div class="text-20px font-weight-bold">
              {appCtx.blockchain === "ALGORAND"
                ? `${algos} ${option === "tale" ? "Tale" : "Algo"}`
                : `${algos} ${option}`}
            </div>
            {appCtx.blockchain === "ALGORAND" && option !== "tale" && (
              <div class="text-15px">${amount}</div>
            )}
          </div>
          <div class="w-100 px-3  py-2 border  d-flex flex-column gap-1">
            <div class="d-flex justify-content-between text-14px">
              <b>Estimated gas fee</b>
              <div class="font-weight-bold">
                0.001 {nativeToken[appCtx.blockchain]}
              </div>
            </div>
            <div class="d-flex justify-content-between text-14px">
              <div class="font-weight-bold">Total Transfer</div>
              {appCtx.blockchain === "ALGORAND" ? (
                option === "tale" ? (
                  <div>
                    <b class="font-weight-bold">{algos} Tale</b>
                  </div>
                ) : (
                  <div>
                    {amount}&nbsp;&nbsp;
                    <b class="font-weight-bold">{algos} Algo</b>
                  </div>
                )
              ) : (
                <div>
                  <b class="font-weight-bold">
                    {algos} {option}
                  </b>
                </div>
              )}
            </div>
            <div class="d-flex justify-content-between text-14px">
              {option !== "tale" && (
                <>
                  <div class="text-gray-700 text-12px">Amount + gas fee</div>
                  <div class="d-flex justify-content-between gap-2">
                    <b>Max amount:</b>
                    <div>
                      {parseFloat(algos) + 0.001} {option}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div class="w-100 d-flex justify-content-center mt-4 px-1 gap-3">
            <button
              primary={false}
              class="w-200 h-40 btn btn-secondary"
              onClick={() => {
                closeModal();
                setPreviousOpen(false);
                setAlgos(0);
              }}
            >
              Reject
            </button>
            <button
              primary={true}
              class="w-200 h-40 btn btn-primary d-flex justify-content-center align-items-center gap-2"
              onClick={handleConfirm}
            >
              Confirm
              {loader ? (
                <div
                  style="width: 30px; height: 30px;"
                  class="spinner-border"
                  role="status"
                ></div>
              ) : (
                ""
              )}
            </button>
          </div>
        </div>
        <SignupPageModal
          openModal={openSignupPageModal}
          setOpenModal={setOpenSignupPageModal}
          transfer={true}
          recieverAddress={address}
          option={option}
          amount={algos}
          setPreviousOpen={setPreviousOpen}
          setConfirmOpen={setOpenModal}
        />
        <PasswordModal
          openModal={openPasswordModal}
          setOpenModal={setOpenPasswordModal}
          setProceedToPay={setProceedToPay}
        />
        <SuccessModal
          openModal={openSuccessModal}
          setOpenModal={setOpenSuccessModal}
          amount={amount}
          option={option}
          address={address}
          setPreviousOpen={setPreviousOpen}
          setConfirmOpen={setOpenModal}
          fundId={fundId}
          isCryptoFundTransfer={isCryptoFundTransfer}
        />
      </div>
    </Modal>
  );
}

export default ConfirmSendPaymentModal;
