import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "vortx-app",
  initialState: {
    isLoggedIn: false,
    userDetails: {
      name: null,
      email: null,
      userId: null,
    },
    paymentData: {
      usdToEthereumPrice: "",
      marketId: "",
      nftSrc: "",
      collectionId: "",
      nftPrice: "",
      nftId: "",
      title: "",
      blockchainNftId: "",
      blockchain: "",
      type: "",
      ipfsHash: "",
      assetId: "",
      s3url: "",
      assetType: "",
      scale: "",
      price: "",
      role: "user",
    },
    authToken: null,
    isDarkMode: false,
  },
  mnemonic: '""',
    algomnemonic: "",
    evmmnemonic: "",
    solanamnemonic: "",
    isDarkMode: false,
    authToken: null,
    signInAndLoginImage: "/images/Signupwallet.png",
    waitlist: {},
    walletAddress: [{ address: "loading ..." }],
    metaData: {},
    country: "",
    page: "",
    frontSide: "",
    frontSideFile: "",
    backSide: "",
    backSideFile: "",
    picture: "",
    document: "",
    reward: {},
    verified: false,
    tailCoin: 0,
    password: "",
    rejection: null,
    lock: false,
    network: true,
    custodial: true,
    encrypt: "",
    evmncrypt: "",
    algoencrypt: "",
    solanaencrypt: "",
    marketplaceId: 0,
    blockchain: "BSC",
    productDetails: null,
    isSideBarOpen: true,
  reducers: {
    login(state) {
      state.isLoggedIn = true;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.userDetails = { name: null, email: null, userId: null };
      state.authToken = null;
    },
    updateUserDetails(state, action) {
      state.userDetails = action.payload;
    },
    updateAuthToken(state, action) {
      state.authToken = action.payload;
    },
    toggleDarkMode(state) {
      state.isDarkMode = !state.isDarkMode;
    },
    setIsSideBarOpen(state, action) {
      state.isSideBarOpen = action.payload;
    },
    setEvmEncrypt(state, action) {
      state.evmncrypt = action.payload;
    },
    setRole(state, action) {
      state.role = action.payload;
    },
    setBlockChain(state, action) {
      state.blockchain = action.payload;
    },
    setCustodial(state, action) {
      state.custodial = action.payload;
    },
    setIsSideBarOpen(state, action) {
      state.isSideBarOpen = action.payload;
    },
  },
});

const appActions = appSlice.actions;

export { appActions };
export default appSlice;
