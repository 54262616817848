import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { TableRow, TableCell, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { getBlockchainTransactionUrl } from "../../config";

const TransactionsTablesCard = ({ tran }) => {
  const theme = useTheme(); // Access theme object
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const handleBlockchainLinkClick = () => {
    const blockchain = tran?.blockchain;
    const transactionId = tran?.blockchainTransactionId;

    if (!blockchain || !transactionId) return;

    const blockchainLink = getBlockchainTransactionUrl(blockchain, transactionId);

    if (blockchainLink !== "") {
      window.open(blockchainLink, "_blank");
    }
  };

  return (
    <TableRow
      sx={{
        cursor: "pointer",
        "&:hover": {
          backgroundColor: theme.palette.background.grey, // Subtle hover effect
        },
      }}
    >
      {/* Blockchain Transaction ID */}
      <TableCell>
        {tran?.blockchainTransactionId ? (
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.primary.main,
              cursor: "pointer",
              textDecoration: "underline",
              "&:hover": { textDecoration: "none" },
            }}
            onClick={handleBlockchainLinkClick}
          >
            {`${tran?.blockchainTransactionId?.substring(0, 20)}...`}
          </Typography>
        ) : (
          <Typography variant="t2_grey" color={theme.palette.text.secondary}>
            --
          </Typography>
        )}
      </TableCell>

      {/* Action */}
      <TableCell>
        <Typography variant="t2_grey" color={theme.palette.text.primary}>
          {tran?.action || "--"}
        </Typography>
      </TableCell>

      {/* Placeholder */}
      <TableCell>
        <Typography variant="t2_grey" color={theme.palette.text.secondary}>
          --
        </Typography>
      </TableCell>

      {/* Blockchain */}
      <TableCell>
        <Typography variant="t2_grey" color={theme.palette.text.primary}>
          {tran?.blockchain || "--"}
        </Typography>
      </TableCell>

      {/* Address */}
      <TableCell>
        <Typography variant="t2_grey" color={theme.palette.text.secondary}>
          {tran?.address?.substring(0, 20) || "--"}...
        </Typography>
      </TableCell>

      {/* Other Address */}
      <TableCell>
        <Typography variant="t2_grey" color={theme.palette.text.secondary}>
          {tran?.otherAddress?.substring(0, 20) || "--"}...
        </Typography>
      </TableCell>

      {/* Amount */}
      <TableCell>
        <Typography variant="t2_grey" color={theme.palette.text.primary}>
          {tran?.amount || "--"}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default TransactionsTablesCard;
