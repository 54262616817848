// src/components/navbar/Navbar.jsx

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "../../context/app-slice";
import { Link, useNavigate } from "react-router-dom";
import SidebarMobile from "../sidebar/SidebarMobile";
//import { useWalletView } from "../../hooks/useWalletView";
import useNavbar from "./useNavbar";
//import CurrencyDropdown from "../currencyDropdown/CurrencyDropdown";
import axios from "axios";
import Networkmodal from "../Networkmodal/Networkmodal";
import SearchDropdown from "../searchDropdown/SearchDropdown";
import ProfileDrawer from "../profileDropdown/ProfileDrawer"; // Updated import
import SupportModal from "../supportModal/SupportModal";
//import AccountDetailsModal from "../accountDetailsModal/AccountDetailsModal";
//import PrivateKeysLoginModal from "../PrivateKeys/PrivateKeysLoginModal";
//import PrivateKeyPasswordModal from "../PrivateKeys/PrivateKeyPasswordModal";
import NotificationDropdown from "../notificationDropdown/NotificationDropdown";
import UserNameSetupModal from "../userNameSetupModal/UserNameSetupModal";
import { nativeToken } from "../../config";
import { useTheme } from '@mui/material/styles';

import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Badge,
  Avatar,
  Menu,
  MenuItem,
  Button,
  Tooltip,
} from "@mui/material";
import {
  Search as SearchIcon,
  NotificationsOutlined as NotificationsIcon,
  AccountCircle as AccountCircleIcon,
  ExpandMore as ExpandMoreIcon,
  Wallet as WalletIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";



const StyledToolbar = styled(Toolbar)({
  minHeight: "50px",
  display: "flex",
  justifyContent: "space-between",
});

const Navbar = () => {
  const theme = useTheme();
  const StyledAppBar = styled(AppBar)({
    backgroundColor: theme.palette.background.black,
    borderBottom: "1px solid #000",
  });
  const [isNavDropdownPopOpen, setIsNavDropdownPopOpen] = useState(false);
  const [wallet, setWallet] = useState(null);
  const [marketplace, setMarketplace] = useState(null);
  const [blockchainobject, setBlockchainobject] = useState({});
  const [openAccountModal, setopenAccountModal] = useState(false);
  const [openSupportModal, setOpenSupportModal] = useState(false);
  const [openAccountDetailsModal, setOpenAccountDetailsModal] = useState(false);
  const [openPrivateKeyLoginModal, setOpenPrivateKeyLoginModal] = useState(false);
  //const [openPrivateKeyPasswordModal, setOpenPrivateKeyPasswordModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [openUserNameSetupModal, setOpenUserNameSetupModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appCtx = useSelector((state) => state.app);
  const {
    collapsed,
    userId,
    visible,
    currencyvisible,
    open,
    handleVisible,
    handleCurrencyVisible,
    setVisible,
    setCurrencyVisible,
    searchVisible,
    setSearchVisible,
    mobileSearchVisible,
    setMobileSearchVisible,
    profileVisible,
    setProfileVisible,
    handleProfileVisible,
    notificationVisible,
    setNotificationVisible,
    handleNotificationVisible,
  } = useNavbar();

  /*const {
    getAllCoin,
    getAllCoinPrice,
    isCoinLoading,
    isCoinPriceLOading,
    allCoin,
    allCoinPrice,
    usersToken,
    getUsersToken,
  } = useWalletView();*/

  const width = window.screen.width;
  let isMobileDevice = width < 600;

  /*useEffect(() => {
    getAllCoin(0, 100);
    getAllCoinPrice();
    getUsersToken();
  }, [appCtx.blockchain]);*/

  useEffect(() => {
    if (isMobileDevice) {
      dispatch(appActions.setIsSideBarOpen(false));
    }
  }, [appCtx.blockchain, dispatch, isMobileDevice]);

  const toggle = () => dispatch(appActions.setIsSideBarOpen(!appCtx.isSideBarOpen));

  useEffect(() => {
    document.querySelector("body").setAttribute("data-theme-version", "light");
  }, []);

  //const walletAddress = appCtx.walletAddress.find(
  //  (addr) => addr.blockchain === appCtx.blockchain
  //)?.address;

  useEffect(() => {
    if (appCtx.authToken) {
      let config = {
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/blockchain/account?wallet=TALEWALLET`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": appCtx.authToken,
        },
      };
      axios(config)
        .then((response) => {
          let data = response.data;
          const blockchainObjects = data.reduce((obj, item) => {
            obj[item.blockchain] = true;
            return obj;
          }, {});

          const blockchainObjectsWithAllChain = {
            ...blockchainObjects,
            ALL: true,
          };

          setBlockchainobject(blockchainObjectsWithAllChain);
        })
        .catch((error) => {
          console.error("Error fetching blockchain accounts:", error);
          setBlockchainobject({});
        });
    }
  }, [appCtx.authToken, appCtx.blockchain]);

  const options = nativeToken;
  const filteredOptions = Object.keys(options).filter(
    (key) => !blockchainobject[key]
  );

  // Menu anchors
  const [anchorElNotifications, setAnchorElNotifications] = useState(null);
  const [anchorElCurrency, setAnchorElCurrency] = useState(null);
  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const [anchorElSearch, setAnchorElSearch] = useState(null);

  const handleNotificationsClick = (event) => {
    setAnchorElNotifications(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setAnchorElNotifications(null);
  };

  const handleCurrencyClick = (event) => {
    setAnchorElCurrency(event.currentTarget);
  };

  const handleCurrencyClose = () => {
    setAnchorElCurrency(null);
  };

  const handleProfileClick = (event) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorElProfile(null);
  };

  const handleSearchClick = (event) => {
    setAnchorElSearch(event.currentTarget);
  };

  const handleSearchClose = () => {
    setAnchorElSearch(null);
  };

  return (
    <StyledAppBar position="fixed">
      <StyledToolbar>
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* Logo */}
          <Link to="/" style={{ textDecoration: "none", color: "#ffffff" }}>
            <Typography
              variant="logo"
              style={theme.typography.logo}
            >
              vortx.ai
            </Typography>
          </Link>
          {appCtx?.isSideBarOpen && isMobileDevice && <SidebarMobile />}
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          {/* Mobile Search Icon */}
          {isMobileDevice && (
            <IconButton
              color="default"
              onClick={handleSearchClick}
              sx={{ marginRight: 1 }}
            >
              <SearchIcon />
            </IconButton>
          )}

          {/* Search Dropdown */}
          <Menu
            anchorEl={anchorElSearch}
            open={Boolean(anchorElSearch)}
            onClose={handleSearchClose}
            PaperProps={{
              style: {
                maxHeight: 300,
                width: "25ch",
              },
            }}
          >
            <SearchDropdown
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              //allCoin={allCoin}
            />
          </Menu>

          {/* Currency Selector */}
         {/*<Button
            variant="outlined"
            color="inherit"
            onClick={handleCurrencyClick}
            startIcon={<WalletIcon />}
            endIcon={<ExpandMoreIcon />}
            sx={{
              borderColor: "#000",
              borderRadius: "8px",
              textTransform: "none",
              marginRight: 1,
            }}
          >
            <Typography variant="body2" color="textPrimary">
              {appCtx.blockchain}
            </Typography>
          </Button> */}

          {/* Currency Menu */}
         {/* <Menu
            anchorEl={anchorElCurrency}
            open={Boolean(anchorElCurrency)}
            onClose={handleCurrencyClose}
            PaperProps={{
              style: {
                maxHeight: 300,
                width: "20ch",
              },
            }}
          >
            <CurrencyDropdown
              currencyvisible={currencyvisible}
              userId={userId}
              handleCurrencyVisible={handleCurrencyVisible}
              setCurrencyVisible={setCurrencyVisible}
              blockchainobject={blockchainobject}
              filteredOptions={filteredOptions}
              setopenAccountModal={setopenAccountModal}
              handleCurrencyClose={handleCurrencyClose}
            />
          </Menu>*/}

          {/* User Name */}
          {/* <Typography
            variant="body2"
            color="textPrimary"
            sx={{ marginLeft: 2, cursor: "pointer" }}
            onClick={() => setOpenUserNameSetupModal(true)}
          >
            {appCtx?.userDetails?.userName
              ? appCtx?.userDetails?.userName
              : "account1"}
          </Typography>*/}

          {/* Profile Drawer */}
          <ProfileDrawer
            setOpenSupportModal={setOpenSupportModal}
            //setOpenAccountDetailsModal={setOpenAccountDetailsModal}
            setOpenPrivateKeyLoginModal={setOpenPrivateKeyLoginModal}
           // setOpenPrivateKeyPasswordModal={setOpenPrivateKeyPasswordModal}
            triggerElement={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  marginLeft: 8,
                }}
              >
               <img
                  src={require("../../assets/icons/user.svg").default} // Path to the user.svg file
                  alt="User Icon"
                  style={{
                    width: 35,
                    height: 35,
                    borderRadius: "50%", // If you want to keep it circular, optional for SVG
                    cursor: "pointer",
                  }}
                />
                <ExpandMoreIcon />
              </div>
            }
          />
        </div>
      </StyledToolbar>

      {/* Modals */}
      <Networkmodal
        openModal={openAccountModal}
        setOpenModal={setopenAccountModal}
        filteredOptions={filteredOptions}
      />
      <SupportModal
        openModal={openSupportModal}
        setOpenModal={setOpenSupportModal}
      />
     {/*<AccountDetailsModal
        openModal={openAccountDetailsModal}
        setOpenModal={setOpenAccountDetailsModal}
      />*/}
      {/*<PrivateKeysLoginModal
        openModal={openPrivateKeyLoginModal}
        setOpenModal={setOpenPrivateKeyLoginModal}
      />*/}
      {/*<PrivateKeyPasswordModal
        openModal={openPrivateKeyPasswordModal}
        setOpenModal={setOpenPrivateKeyPasswordModal}
      />*/}
      <UserNameSetupModal
        openModal={openUserNameSetupModal}
        setOpenModal={setOpenUserNameSetupModal}
      />
    </StyledAppBar>
  );
};

export default Navbar;
