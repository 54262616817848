import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCryptobasket } from "./useCryptobasket";
import { CircularProgress, Skeleton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTrendUp, faArrowUp, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { appActions } from "../../context/app-slice";
import MainWrapper from "../../components/wrapper/MainWrapper";
import Pricechart from "../../components/Pricechart/Pricechart";
import Piechart from "../../components/Piechart/Piechart";
import CryptoBasketAreaChart from "../../components/areachart/CryptoBasketAreaChart";
import { Row, Col } from "react-bootstrap";
import RebalanceModal from "./RebalanceModal";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import BasketModal from "./BasketModal";
import { useCrops } from "../../hooks/useCrops";

const CropBasketDetails = () => {
  const [selectedChartTab, setSelectedChartTab] = useState(0);
  const [subscribed, setSubscribed] = useState(false);
  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const [portfolio, setPortfolio] = useState("");
  const [subscribedData, setSubscribedData] = useState("");
  const [fees, setFees] = useState("");
  const [distributionProgress, setDistributionProgress] = useState(false);
  const [openEditBasketModal, setOpenEditBasketModal] = useState(false);
  const [openFeeInfoModal, setOpenFeeInfoModal] = useState(false);
  const [status, setStatus] = useState("");
  const [subscribeId, setSubscribeId] = useState(null);
  const [selectedHistoryTab, setSelectedHistoryTab] = useState("week");

  // const [closeClicked, setCloseClicked] = useState(false);
  const appCtx = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { id } = useParams();

  const [openTalewalletModal, setOpenTalewalletModal] = useState(false);
  const [openHaveAnAccountOptionModal, setOpenHaveAnAccountOptionModal] =
    useState(false);
  const [openContinueInWebModal, setOpenContinueInWebModal] = useState(false);

  const {
    singleCropBasketListLoading,
    singleCropBasketData,
    getSingleCropBasketList,
  } = useCrops();

  const navigate = useNavigate();

  const Data = [
    {
      id: 0,
      value: "w",
      range: "week",
    },
    {
      id: 1,
      value: "m",
      range: "month",
    },
    {
      id: 2,
      value: "6m",
      range: "6month",
    },
    {
      id: 3,
      value: "y",
      range: "year",
    },
  ];

  useEffect(() => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/basket/${id}/fees`,
      headers: {
        ...(appCtx.authToken
          ? { "X-Auth-Token": appCtx.authToken }
          : { "X-App-Token": process.env.REACT_APP_X_APP_TOKEN }),
        "Content-Type": "application/json",
      },
    };

    const fetchData = async () => {
      try {
        const response = await axios.request(config);
        setFees(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    getSingleCropBasketList(id);
  }, []);

  return (
    <MainWrapper>
      <div class={ ""}>
        <div class="container-fluid ">
          {singleCropBasketListLoading ? (
            <div className="pt-[5%] w-75 mx-auto">
              {Array.from({ length: 12 }).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="text"
                  sx={{ fontSize: index === 11 ? "2rem" : "1rem" }}
                  className={
                    index === 2 || index === 9 || index === 10 || index === 11
                      ? "w-[60%] mx-auto mt-3"
                      : index === 3
                      ? "w-[70%] mx-auto mt-5"
                      : "w-[80%] mx-auto"
                  }
                  height={index === 3 ? 300 : undefined}
                />
              ))}
            </div>
          ) : (
            <div class="row ">
              <div class="col-xl-12">
                <button
                  className="btn btn-primary mb-2"
                  onClick={() => navigate("/dgu-basket")}
                >
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    style={{ fontSize: "12px" }}
                  />
                </button>
                <div>
                  <div>
                    <div class="row align-items-start  gx-3">
                      <div class="col-xl-9   ">
                        <div className="row">
                          <div className="col-xl-12">
                            <div class="card coin-content">
                              <div class="card-header border-0 flex-wrap pb-0">
                                <div class="mb-xl-0 mb-2">
                                  <h4 class="card-title">
                                    {singleCropBasketData?.name}
                                  </h4>
                                  {singleCropBasketData?.name && (
                                    <span class="fs-12">
                                      by {singleCropBasketData?.name}
                                    </span>
                                  )}
                                 
                                </div>

                                <button
                                  // onClick={() => {
                                  //   setopenBasketModal(true);
                                  // }}
                                  type="button"
                                  className="btn btn-success d-flex align-items-center justify-content-between"
                                >
                                  Invest
                                  <FontAwesomeIcon
                                    icon={faArrowTrendUp}
                                    style={{ transform: "rotate(90deg)" }}
                                  />
                                </button>
                              </div>
                              <div class="card-body">
                                <div class="d-flex align-items-center justify-content-between flex-wrap">
                                  <div class="d-flex align-items-end justify-content-between flex-wrap">
                                    <div class="price-content">
                                      <span class="d-block mb-2">
                                        Subscription Cost
                                      </span>
                                      <h4 class="fs-20 font-w600 mb-0">
                                        {singleCropBasketData?.subscriptionCost ===
                                          null ||
                                        singleCropBasketData?.subscriptionCost <=
                                          0
                                          ? "Free"
                                          : `${singleCropBasketData?.subscriptionCost} /month`}
                                        {
                                          singleCropBasketData?.subscriptionCostUnit
                                        }
                                      </h4>
                                    </div>
                                    <div class="price-content">
                                      <span class="fs-14 d-block mb-2">
                                        Min.Amount
                                      </span>
                                      <h4 class="font-w600 text-success mb-0">
                                        {singleCropBasketData?.minAmount}{" "}
                                        {
                                          singleCropBasketData?.minAmountCurrency
                                        }
                                        {/* <i class="fa-solid fa-caret-up ms-1 text-success"></i> */}
                                      </h4>
                                    </div>
                                    <div class="price-content">
                                      <span class="fs-14 d-block mb-2">
                                        Total Fee
                                      </span>
                                      <h4 class="font-w600 mb-0">
                                        {fees?.total?.toFixed(3)}
                                        {
                                          singleCropBasketData?.minAmountCurrency
                                        }
                                      </h4>
                                    </div>
                                    <div class="price-content">
                                      <span class="fs-14 d-block mb-2">
                                        Blockchain
                                      </span>
                                      <h4 class="font-w600 mb-0">
                                        {singleCropBasketData?.blockchain}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-3  col-sm-12 ">
                        <div class="card ">
                          <div class="card-header border-0 pb-3">
                            <h4 class="card-title">Token Allocation</h4>
                          </div>
                          {/* <div class="card-body px-0 pt-0 dlab-scroll">
                            {singleBasket?.cryptoBasketTokens?.map((token) => (
                              <div
                                key={token?.tokenId}
                                class="d-flex justify-content-between align-items-center market-preview"
                              >
                                <div class="d-flex align-items-center">
                                  <span>
                                    <img
                                      src={token?.icon}
                                      alt=""
                                      style={{ width: "36px", height: "36px" }}
                                      className="rounded-circle me-1"
                                    />
                                  </span>
                                  <div class="ms-3">
                                    <a href="javascript:void(0);">
                                      <h5 class="fs-14 font-w600 mb-0">
                                        {token?.token} ({token?.symbol})
                                      </h5>
                                    </a>
                                  </div>
                                </div>
                                <div class="d-flex align-items-center">
                                  <div class="ms-3">
                                    <span class="text-success">
                                      {token?.tokenPercent}%
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}

                            <div className="d-flex justify-content-center mt-3">
                              <button
                                onClick={() => {
                                  setopenBasketModal(true);
                                }}
                                type="button"
                                className="btn btn-success d-flex align-items-center justify-content-between"
                              >
                                Invest
                                <FontAwesomeIcon
                                  icon={faArrowTrendUp}
                                  style={{ transform: "rotate(90deg)" }}
                                />
                              </button>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* <BasketModal
        openModal={openBasketModal}
        setOpenModal={setopenBasketModal}
        closeClicked={closeClicked}
        setCloseClicked={setCloseClicked}
        singleBasket={singleBasket}
        tokenPercentArray={tokenPercentArray}
        tokenArray={tokenArray}
        successcloseClicked={successcloseClicked}
        setSuccessCloseClicked={setSuccessCloseClicked}
        openSuccessModal={openSuccessModal}
        setopenSuccessModal={setopenSuccessModal}
        transactionSuccess={transactionSuccess}
        setTransactionSuccess={setTransactionSuccess}
        fees={fees}
        setOpenContinueInWebModal={setOpenContinueInWebModal}
        subscribeId={subscribeId}
        status={status}
        basketId={id}
        basketBlockchain={singleBasket?.blockchain}
      /> */}
    </MainWrapper>
  );
};

export default CropBasketDetails;
