// src/components/Transactions/Transaction.jsx

import React, { useState, useEffect } from "react";
import {
  Skeleton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  MenuItem,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import { ContentCopy as ContentCopyIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import * as XLSX from "xlsx";
import MainWrapper from "../../components/wrapper/MainWrapper"; // Ensure the correct path
import { useTheme } from "@mui/material/styles";

// Helper function to determine status color based on theme
const getStatusColor = (status, theme) => {
  switch (status) {
    case "Completed":
      return theme.palette.success.main;
    case "Failed":
      return theme.palette.error.main;
    case "Pending":
      return theme.palette.warning.main;
    default:
      return theme.palette.text.primary;
  }
};

// Styled TextField using MUI's styled API
const InputTextField = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 200,
}));

const Transactions = () => {
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [sortMethod, setSortMethod] = useState("newestFirst");
  const [filter, setFilter] = useState("All");
  const [transactions, setTransactions] = useState([]);

  const theme = useTheme(); // Access theme object
  const authToken = localStorage.getItem("authToken");
  const userId = localStorage.getItem("userId"); // Get userId from local storage

  // Fetch transactions from backend API
  const fetchTransactions = async () => {
    setTransactionLoading(true);
    try {
      const response = await fetch(
        `https://backend.vortx.ai/users/${userId}/transactions`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": authToken,
          },
        }
      );
      if (!response.ok) throw new Error("Failed to fetch transactions");

      const data = await response.json();
      const formattedData = data.map((txn) => ({
        id: txn.id,
        model: txn.model,
        synthesizedOn: txn.synthesizedOn,
        synthesizedOnFormatted: new Date(txn.synthesizedOn).toLocaleString(),
        ranBy: txn.ranBy,
        jobHash: txn.jobHash,
        totalTokensUsed: txn.totalTokensUsed,
        totalCost: `${txn.totalCost} USDT`,
        totalCostValue: txn.totalCost,
        status: txn.status,
      }));
      setTransactions(formattedData);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    } finally {
      setTransactionLoading(false);
    }
  };

  useEffect(() => {
    if (userId) fetchTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  // Handle sorting and filtering
  const filteredTransactions = transactions.filter((txn) => {
    if (filter === "All") return true;
    return txn.status === filter;
  });

  const sortedTransactions = [...filteredTransactions].sort((a, b) => {
    switch (sortMethod) {
      case "newestFirst":
        return new Date(b.synthesizedOn) - new Date(a.synthesizedOn);
      case "oldestFirst":
        return new Date(a.synthesizedOn) - new Date(b.synthesizedOn);
      case "costliestToFree":
        return b.totalCostValue - a.totalCostValue;
      case "freeToCostliest":
        return a.totalCostValue - b.totalCostValue;
      default:
        return 0;
    }
  });

  // Download transactions as Excel
  const handleDownloadTransaction = () => {
    const data = transactions.map((txn) => ({
      "Job ID": txn.id,
      Model: txn.model,
      "Synthesized On": txn.synthesizedOnFormatted,
      "Ran By": txn.ranBy,
      "Job Hash": txn.jobHash,
      "Total Tokens Used": txn.totalTokensUsed,
      "Total Cost": txn.totalCost,
      Status: txn.status,
    }));

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Transactions");

    const filename = `transaction-history_${new Date()
      .toISOString()
      .replace(/[:.]/g, "-")}.xlsx`;
    XLSX.writeFile(wb, filename);
  };

  // Toggle Drawer
  const toggleDrawer = (transaction) => {
    setSelectedTransaction(transaction);
    setDrawerOpen(!drawerOpen);
  };

  // Copy to Clipboard
  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  // Handle Sort Change
  const handleSortChange = (event) => setSortMethod(event.target.value);

  // Handle Filter Change
  const handleFilterChange = (event) => setFilter(event.target.value);

  return (
    <MainWrapper>
      <Box
        sx={{
          paddingBottom: "70px",
          paddingTop: "50px",
          backgroundColor: theme.palette.background.default,
          minHeight: "100vh",
        }}
      >
        {transactionLoading ? (
          <SkeletonLoader />
        ) : (
          <Grid container spacing={3}>
            {/* Header and Download Button */}
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography variant="h4" sx={{ color: theme.palette.text.primary }}>
                  Transaction History: Synthetic, actuals post deployment on-chain / off-chain.
                </Typography>
                <Button
                  variant="orange" // Uses the 'orange' variant from theme.js
                  onClick={handleDownloadTransaction}
                >
                  Download
                </Button>
              </Box>
            </Grid>

            {/* Sort and Filter Controls */}
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  mb: 2,
                }}
              >
                <InputTextField
                  label="Sort By"
                  select
                  value={sortMethod}
                  onChange={handleSortChange}
                  helperText="Select sorting method"
                  variant="outlined"
                >
                  <MenuItem value="newestFirst">Newest to Oldest</MenuItem>
                  <MenuItem value="oldestFirst">Oldest to Newest</MenuItem>
                  <MenuItem value="costliestToFree">Costliest to Free</MenuItem>
                  <MenuItem value="freeToCostliest">Free to Costliest</MenuItem>
                </InputTextField>
                <InputTextField
                  label="Filter By"
                  select
                  value={filter}
                  onChange={handleFilterChange}
                  helperText="Select filter"
                  variant="outlined"
                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="Completed">Completed</MenuItem>
                  <MenuItem value="Failed">Failed</MenuItem>
                </InputTextField>
              </Box>
            </Grid>

            {/* Transaction Table */}
            <Grid item xs={12}>
              <TransactionTable
                transactions={sortedTransactions}
                toggleDrawer={toggleDrawer}
                handleCopyToClipboard={handleCopyToClipboard}
                theme={theme}
              />
            </Grid>

            {/* Optional Pagination */}
            {/* Uncomment and implement if pagination is required
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" mt={4}>
                <Pagination
                  count={Math.ceil(transactions.length / 10)}
                  page={page}
                  onChange={handleChange}
                  color="primary"
                />
              </Box>
            </Grid>
            */}
          </Grid>
        )}

        {/* Transaction Details Drawer */}
        <TransactionDrawer
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          selectedTransaction={selectedTransaction}
          handleCopyToClipboard={handleCopyToClipboard}
          theme={theme}
        />
      </Box>
    </MainWrapper>
  );
};

// Skeleton Loader Component
const SkeletonLoader = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: 2,
      mt: 5,
    }}
  >
    {Array.from({ length: 12 }).map((_, index) => (
      <Skeleton
        key={index}
        variant="rectangular"
        sx={{ width: "80%", height: 40 }}
      />
    ))}
  </Box>
);

// Transaction Table Component
const TransactionTable = ({
  transactions,
  toggleDrawer,
  handleCopyToClipboard,
  theme,
}) => (
  <TableContainer component={Paper} variant="blackBox" sx={{ borderRadius: 2, p: 2 }}>
    <Table sx={{ minWidth: 845 }} aria-label="transaction table">
      <TableHead>
        <TableRow>
          <TableCell align="center">Job ID</TableCell>
          <TableCell align="center">Model</TableCell>
          <TableCell align="center">Synthesized On</TableCell>
          <TableCell align="center">Ran By</TableCell>
          <TableCell align="center">Job Hash</TableCell>
          <TableCell align="center">Total Tokens Used</TableCell>
          <TableCell align="center">Total Cost</TableCell>
          <TableCell align="center">Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {transactions.map((txn) => (
          <TableRow
            key={txn.id}
            onClick={() => toggleDrawer(txn)}
            hover
            sx={{ cursor: "pointer" }}
          >
            <TableCell align="center">{txn.id}</TableCell>
            <TableCell align="center">{txn.model}</TableCell>
            <TableCell align="center">{txn.synthesizedOnFormatted}</TableCell>
            <TableCell align="center">{txn.ranBy}</TableCell>
            <TableCell align="center">
              {txn.jobHash}
              <Tooltip title="Copy Job Hash">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyToClipboard(txn.jobHash);
                  }}
                  size="small"
                  aria-label="copy job hash"
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell align="center">{txn.totalTokensUsed}</TableCell>
            <TableCell align="center">{txn.totalCost}</TableCell>
            <TableCell
              align="center"
              sx={{ color: getStatusColor(txn.status, theme) }}
            >
              {txn.status}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

// Transaction Drawer Component
const TransactionDrawer = ({
  drawerOpen,
  setDrawerOpen,
  selectedTransaction,
  handleCopyToClipboard,
  theme,
}) => (
  <Drawer
    anchor="right"
    open={drawerOpen}
    onClose={() => setDrawerOpen(false)}
    PaperProps={{
      sx: {
        backgroundColor: theme.palette.background.grey,
        padding: 3,
        width: 350,
      },
    }}
  >
    <Box sx={{ width: "100%" }}>
      <Typography variant="h6" gutterBottom>
        Transaction Details
      </Typography>
      {selectedTransaction ? (
        <List>
          <ListItem>
            <ListItemText primary="Job ID" secondary={selectedTransaction.id} />
            <Tooltip title="Copy Job ID">
              <IconButton
                onClick={() => handleCopyToClipboard(selectedTransaction.id)}
                aria-label="copy job id"
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </ListItem>
          <ListItem>
            <ListItemText primary="Model" secondary={selectedTransaction.model} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Synthesized On"
              secondary={selectedTransaction.synthesizedOnFormatted}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Ran By" secondary={selectedTransaction.ranBy} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Job Hash" secondary={selectedTransaction.jobHash} />
            <Tooltip title="Copy Job Hash">
              <IconButton
                onClick={() => handleCopyToClipboard(selectedTransaction.jobHash)}
                aria-label="copy job hash"
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Total Tokens Used"
              secondary={selectedTransaction.totalTokensUsed}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Total Cost" secondary={selectedTransaction.totalCost} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Status"
              secondary={selectedTransaction.status}
              sx={{ color: getStatusColor(selectedTransaction.status, theme) }}
            />
          </ListItem>
        </List>
      ) : (
        <Typography variant="body1">No transaction selected.</Typography>
      )}
    </Box>
  </Drawer>
);

export default Transactions;
