import React from "react";
import MainWrapper from "../components/wrapper/MainWrapper";
import { Accordion } from "react-bootstrap";

const Help = () => {
  return (
    <MainWrapper>
      <div>
        <div class="">
          {/* <!-- row --> */}
          <div class="container-fluid">
            {/* <!-- Row --> */}
            <div class="row">
              {/* portfolio card  */}
              <div className="col-12">
                <div className="card">
                  <div className="card-header border-0 pb-3">
                    <div className="">
                      <h3 className="">VortX Synthetic Satellite Imagery Guide</h3>
                      <h4 className="">Contact support for fast query resolution.</h4>
                      <p>
                        VortX is here to help you streamline your Data Synthesis goals. Detailed documentation is at docs.vortx.ai (coming soon).
                      </p>
                    </div>
                  </div>
                  <div className="card-body px-0 pt-0">
                    <div className="px-4">
                      <div className="row justify-content-center">
                        <div className="col-lg-12">
                          <div
                            className="accordion faqs rounded-3 shadow"
                            id="faqsAccordion"
                          >
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                What is Synthetic Data Powered by STAC Images, and why should I use it?
                                </Accordion.Header>
                                <Accordion.Body>
                                Synthetic data powered by STAC (SpatioTemporal Asset Catalog) images combines real-world imagery from seven sources—satellites, drones, CCTV & vehicles, mobiles, scanners, microscopes, and IoT devices—to generate highly realistic synthetic datasets. This allows you to simulate complex environments and scenarios where real-world data may be incomplete or expensive to obtain. It’s particularly useful for AI model training in industries like agriculture, security, healthcare, and urban planning, where diverse, high-quality data is critical.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                How do I start generating synthetic data using Vortx platform?
                                </Accordion.Header>
                                <Accordion.Body>
                                To begin generating synthetic data, follow these steps: Select the type of data source(s) you want to simulate (satellite, drone, CCTV, vehicle, mobile, scanner, microscope, or IoT), customize key parameters such as the environment, time of day, resolution, or specific object characteristics (e.g., crops, vehicles, or people), choose the relevant models (e.g., GPT-40, Llama 3) and any additional enhancements, such as real-time weather or geographic data, and click ‘Generate’ to create your synthetic dataset, powered by a combination of real-world and artificial data.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="2">
                                <Accordion.Header>
                                Can I customize the synthetic data based on my specific use case?
                                </Accordion.Header>
                                <Accordion.Body>
                                Yes! You can fully customize synthetic data based on your specific use case. For example, if you are working on agricultural AI, you can choose satellite and drone imagery combined with IoT data to simulate crop conditions. In security applications, CCTV and mobile data can be customized to create realistic surveillance scenarios. You can fine-tune parameters like object visibility, sensor type, and geographic location to ensure the data meets your precise needs.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="3">
                                <Accordion.Header>
                                What kind of data enhancers are available for synthetic data generation?
                                </Accordion.Header>
                                <Accordion.Body>
                                Our platform integrates real-time STAC imagery from sources like Sentinel-2, drones, IoT sensors, and CCTV, enabling you to generate hyper-realistic synthetic data. You can enhance the data with environmental factors such as weather, time of day, or even specific sensor settings. This makes the generated data not just realistic but also contextually relevant to your project, whether it’s monitoring agricultural fields, urban environments, or healthcare diagnostics.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="4">
                                <Accordion.Header>
                                How do I ensure that the synthetic data is realistic and useful for AI training?
                                </Accordion.Header>
                                <Accordion.Body>
                                Our platform uses advanced AI models like GPT-40 and Llama 3 to synthesize highly realistic data, augmented by real-world STAC images. By selecting relevant sources (e.g., satellite for environmental data, microscope for medical data), you can generate synthetic data that closely mimics real-world scenarios. Regularly testing the synthetic data in your AI models will ensure its accuracy and usefulness in training, analysis, or decision-making tasks.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="5">
                                <Accordion.Header>
                                How much does it cost to generate synthetic data, and how often can I generate new data?
                                </Accordion.Header>
                                <Accordion.Body>
                                Each synthetic data generation costs 1 credit per synthesis. You have the flexibility to generate synthetic data either as a one-time (zero-shot) event or set up recurring generations, depending on your project’s requirements. For continuous updates, such as monitoring crops or city traffic, you can schedule data generation at regular intervals, optimizing both cost and data relevance.
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                          {/*end accordion*/}
                        </div>
                        {/*end col*/}
                      </div>
                      {/*end row*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainWrapper>
  );
};

export default Help;