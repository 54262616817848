// src/components/api/services.js

import {
    sendOtpApi,
    verifyOtpApi,
    blockchainLoginApi,
    blockchainWalletVerifyApi,
    googleSignupApi,
    updateUserProfileApi,
  } from "./api";
  import CryptoJS from "crypto-js";
  import { appActions } from "../../context/app-slice";
  import { jwtDecode } from "jwt-decode"; // Default import for jwtDecode
  import axios from "axios"; // Ensure axios is imported
  import theme from "../theme/theme"; // Import the theme

  export const handleNonCustodialSignin = async (
    walletAddress,
    dispatch,
    setSnackbar,
    setNonCustodialLoader,
    setIsMetaMaskRequesting,
    setMetamaskClicked,
    setLoginMethod,
    setShowDetailsForm,
    navigate
  ) => {
    try {
      const authToken = localStorage.getItem("authToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
          ...(authToken && { "X-Auth-Token": authToken }),
        },
      };
  
      if (typeof setNonCustodialLoader === "function") {
        setNonCustodialLoader(true); // Show loading spinner
      } else {
        console.error("setNonCustodialLoader is not a function.");
      }
  
      const blockchain = "ETHEREUM";
      const wallet = "TALEWALLET";
      const product = "TALE_WALLET";
      
  
      const response = await axios.post(
        `${process.env.REACT_APP_NFTVERSE_DEV_API}/user/blockchain/crypto/account/login`,
        {
          blockchain,
          blockchainAddress: walletAddress,
          marketplaceAddress: "0",
          wallet,
          product,
        },
        config
      );
  
      console.log("Account Login API Response:", response.data);
  
      const message = response.data.secretKey;
  
      const keyEncrypt = CryptoJS.SHA256(
        JSON.stringify(response.data.secretKey)
      ).toString();
      dispatch(appActions.setEvmEncrypt(keyEncrypt));
  
      const signature = await window.ethereum.request({
        method: "personal_sign",
        params: [message, walletAddress],
      });

      const p1={
        blockchain,
        blockchainAddress: walletAddress,
        marketplaceAddress: "0",
        signedSecretKey: signature,
        userId: response.data.userId,
      };
  
      const verifyResponse = await blockchainWalletVerifyApi(p1);
      
      
  
      console.log("Verification Response:", verifyResponse.data);
  
      dispatch(appActions.updateAuthToken(verifyResponse.data.authToken));
      dispatch(appActions.updateUserDetails(verifyResponse.data));
  
      if (!verifyResponse.data.firstName || !verifyResponse.data.lastName) {
        setLoginMethod("metamask");
        setShowDetailsForm(true);
      } else {
        dispatch(appActions.login(true));
        setSnackbar({
          open: true,
          message: "Wallet connected successfully",
          severity: "success",
        });
        navigate("/");
      }
    } catch (error) {
      console.error("Error connecting...", error);
      if (error.response) {
        setSnackbar({
          open: true,
          message: `Error: ${
            error.response.data.message || "Failed to connect with MetaMask."
          }`,
          severity: "error",
        });
      } else {
        setSnackbar({
          open: true,
          message: "Failed to connect with MetaMask.",
          severity: "error",
        });
      }
    } finally {
      if (typeof setNonCustodialLoader === "function") {
        setNonCustodialLoader(false); // Hide loading spinner
      }
      if (typeof setIsMetaMaskRequesting === "function") {
        setIsMetaMaskRequesting(false);
      }
      if (typeof setMetamaskClicked === "function") {
        setMetamaskClicked(false);
      }
    }
  };
  
  
  // Format time in mm:ss
  export const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? `0${minutes}` : minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
  };
  
  // Encrypt Secret Key
  export const encryptSecretKey = (secretKey) =>
    CryptoJS.SHA256(JSON.stringify(secretKey)).toString();
  
  // Snackbar Helper Functions
  export const showSuccessSnackbar = (setSnackbar, messageKey, customMessage) => {
    setSnackbar({
      open: true,
      message: customMessage || theme.snackbar.messages[messageKey],
      severity: "success",
    });
  };
  
  export const showErrorSnackbar = (setSnackbar, messageKey, customMessage) => {
    setSnackbar({
      open: true,
      message: customMessage || theme.snackbar.messages[messageKey],
      severity: "error",
    });
  };

  
  
  // Send OTP
  export const handleSendOtp = async (
    email,
    setSnackbar,
    setLoader,
    setClickedOnLoginOtp,
    setTimer
  ) => {
    if (typeof email !== "string" || !email.trim()) {
      showErrorSnackbar(setSnackbar, "emailEmpty");
      return;
    }
  
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!emailRegex.test(email)) {
      showErrorSnackbar(setSnackbar, "emailInvalid");
      return;
    }
  
    try {
      setLoader(true);
      await sendOtpApi(email);
      showSuccessSnackbar(setSnackbar, "otpSent");
      setClickedOnLoginOtp(true);
      setTimer(300); // Reset timer to 5 minutes
    } catch (error) {
      showErrorSnackbar(setSnackbar, "otpFailed");
    } finally {
      setLoader(false);
    }
  };
  
  // Verify OTP
  export const handleCheckOtp = async (
    email,
    otp,
    dispatch,
    navigate,
    setSnackbar,
    setLoader,
    setShowDetailsForm,
    setLoginMethod
  ) => {
    if (!otp) {
      showErrorSnackbar(setSnackbar, "otpEmpty");
      return;
    }
  
    try {
      setLoader(true);
      const data = await verifyOtpApi(email, otp);
      //console.log("otp verify data:",data )
      //console.log("user id:",data.data.userId)
      window.localStorage.setItem("userId", data.data.userId);
      console.log("user_id:",data.data)
      //window.localStorage.setItem("lastName", data.lastName);
      dispatch(appActions.updateUserDetails(data.data));
      dispatch(appActions.updateAuthToken(data.data.authToken));

      window.localStorage.setItem("firstName",data.data.firstName);
      window.localStorage.setItem("lastName",data.data.lastName);
      window.localStorage.setItem("lastName",data.data.lastName);
      window.localStorage.setItem("userName",data.data.userName);
      window.localStorage.setItem("mobile",data.data.mobile);
      window.localStorage.setItem("email",data.data.email);
      window.localStorage.setItem("country",data.data.country);
      window.localStorage.setItem("website",data.data.website);
      window.localStorage.setItem("country_billing",data.data.country_billing);
      window.localStorage.setItem("business_name",data.data.business_name);
      window.localStorage.setItem("business_address",data.data.business_address);
      window.localStorage.setItem("taxIdName",data.data.tax_id);
      window.localStorage.setItem("currency",data.data.currency);

     
  
      if (!data.data.firstName || !data.data.lastName) {
        console.log("profile update page");
        setLoginMethod("otp");
        setShowDetailsForm(true);
      } else {
        dispatch(appActions.login(true));
        showSuccessSnackbar(setSnackbar, "otpVerified");
        navigate("/");
      }
    } catch (error) {
      showErrorSnackbar(setSnackbar, "otpVerificationFailed");
    } finally {
      setLoader(false);
    }
  };
  
  // Blockchain Login
  export const handleBlockchainLogin = async (
    walletAddress,
    dispatch,
    navigate,
    setSnackbar,
    setNonCustodialLoader,
    setShowDetailsForm,
    setLoginMethod,
    setIsMetaMaskRequesting,
    setMetamaskClicked
  ) => {
    try {
      setNonCustodialLoader(true);
      const blockchain = "ETHEREUM";
      const wallet = "TALEWALLET";
      const product = "TALE_WALLET";
  
      const loginResponse = await blockchainLoginApi({
        blockchain,
        blockchainAddress: walletAddress,
        marketplaceAddress: "0",
        wallet,
        product,
      });
  
      const secretKey = encryptSecretKey(loginResponse.secretKey);
      dispatch(appActions.setEvmEncrypt(secretKey));
  
      const signature = await window.ethereum.request({
        method: "personal_sign",
        params: [loginResponse.secretKey, walletAddress],
      });
  
      const verifyResponse = await blockchainWalletVerifyApi({
        blockchain,
        blockchainAddress: walletAddress,
        marketplaceAddress: "0",
        signedSecretKey: signature,
        userId: loginResponse.userId,
      });
  
      dispatch(appActions.updateAuthToken(verifyResponse.authToken));
      dispatch(appActions.updateUserDetails(verifyResponse));
  
      if (!verifyResponse.firstName || !verifyResponse.lastName) {
        setLoginMethod("metamask");
        setShowDetailsForm(true);
      } else {
        dispatch(appActions.login(true));
        showSuccessSnackbar(setSnackbar, "blockchainSuccess");
        navigate("/");
      }
    } catch (error) {
      showErrorSnackbar(setSnackbar, "blockchainFailed");
    } finally {
      setNonCustodialLoader(false);
      setIsMetaMaskRequesting(false);
      setMetamaskClicked(false);
    }
  };
  
  // Google Signup/Login
  export const handleGoogleLogin = async (
    credentialResponse,
    dispatch,
    navigate,
    setSnackbar
  ) => {
    if (!credentialResponse?.credential) {
      showErrorSnackbar(setSnackbar, "googleInvalid");
      return;
    }
  
    try {
      const decoded = jwtDecode(credentialResponse.credential);
      const { email, given_name, family_name, sub: googleId, picture } = decoded;
  
      const signupResponse = await googleSignupApi({
        email,
        firstName: given_name,
        lastName: family_name,
        password: googleId,
      });
  
      if (signupResponse.newUser) {
        const profileUpdate = {
          userId: signupResponse.userId,
          firstName: given_name,
          lastName: family_name,
          avatar: picture,
        };
        await updateUserProfileApi(profileUpdate);
      }
  
      dispatch(appActions.updateAuthToken(signupResponse.authToken));
      dispatch(appActions.login(true));
      showSuccessSnackbar(setSnackbar, "googleSignupSuccess", "Signup and login successful!");
      navigate("/");
    } catch (error) {
      showErrorSnackbar(setSnackbar, "googleSignupFailed");
    }
  };
  
  // Update User Profile
  export const handleSubmitDetails = async (
    additionalDetails, // Object containing firstName and lastName
    userId, // User ID from Redux or localStorage
    dispatch, // Redux dispatch function
    setSnackbar, // Snackbar state setter
    navigate, // React Router navigate function
    setLoader, // Loader state setter
    authToken // Optional: authToken from Redux or localStorage
  ) => {
    if (!additionalDetails.firstName || !additionalDetails.lastName) {
      // Show error if required fields are missing
      setSnackbar({
        open: true,
        message: "First name and last name are required!",
        severity: "error",
      });
      return;
    }

    else{
      dispatch(appActions.login(true));
      showSuccessSnackbar(setSnackbar, "Metamask Login Successful");
      navigate("/");
    }
    console.log("check details");
    console.log("first name:",additionalDetails.firstName);
    console.log("last name:",additionalDetails.lastName);
  
    setLoader(true); // Show loading spinner
    const payload_x = {
      firstName: additionalDetails.firstName,
      lastName: additionalDetails.lastName,
      userId: localStorage.getItem("userId"),
    };
    console.log("update details:",payload_x)
  
    try {
      // Make the profile update API call
      const response = await updateUserProfileApi(payload_x);
      
      
  
      // Handle successful response
      const data = response.data;
      console.log("update details response",data)
      dispatch(appActions.updateUserDetails(data)); // Update user details in Redux
      window.localStorage.setItem("firstName", data.firstName);
      window.localStorage.setItem("lastName", data.lastName);
  
      setSnackbar({
        open: true,
        message: "Profile updated successfully",
        severity: "success",
      });
      console.log("first name is:",data.firstName);
      dispatch(appActions.login(true)); // Log in the user
      navigate("/"); // Navigate to dashboard
    } catch (error) {
      console.error("Failed to update profile:", error);
      setSnackbar({
        open: true,
        message:
          "Failed to update details. Please check your internet connection or server logs for more info.",
        severity: "error",
      });
    } finally {
      setLoader(false); // Hide loading spinner
    }
  };
  

  export const handleMetamaskSignin = async (
    dispatch,
    setSnackbar,
    setIsMetaMaskRequesting,
    setMetamaskClicked,
    setNonCustodialLoader,
    handleNonCustodialSignin,
    navigate,
    setLoginMethod,
    setShowDetailsForm
  ) => {
    // Validate the passed functions
    if (
      typeof setIsMetaMaskRequesting !== "function" ||
      typeof setMetamaskClicked !== "function" ||
      typeof setSnackbar !== "function" ||
      typeof handleNonCustodialSignin !== "function"
    ) {
      console.error("Missing or invalid state management functions.");
      return;
    }
  
    // Check if MetaMask is installed
    if (!window.ethereum) {
      setSnackbar({
        open: true,
        message: "MetaMask not detected. Please install MetaMask extension.",
        severity: "error",
      });
      return;
    }
  
    // Set MetaMask request state
    setIsMetaMaskRequesting(true);
    setMetamaskClicked(true);
  
    try {
      // Request accounts from MetaMask
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
  
      // Call non-custodial sign-in handler with the wallet address
      await handleNonCustodialSignin(
        accounts[0],
        dispatch,
        setSnackbar,
        setNonCustodialLoader,
        setIsMetaMaskRequesting,
        setMetamaskClicked,
        setLoginMethod,
        setShowDetailsForm,
        navigate
      );
    } catch (error) {
      if (error.code === -32002) {
        setSnackbar({
          open: true,
          message:
            "A MetaMask request is already pending. Please check your MetaMask extension.",
          severity: "error",
        });
      } else {
        console.error("Error during MetaMask connection:", error);
        setSnackbar({
          open: true,
          message: "An error occurred while connecting to MetaMask.",
          severity: "error",
        });
      }
    } finally {
      // Reset state after completion
      setIsMetaMaskRequesting(false);
      setMetamaskClicked(false);
    }
  };
  
  
  
  

/*import {
    sendOtpApi,
    verifyOtpApi,
    blockchainLoginApi,
    blockchainWalletVerifyApi,
    googleSignupApi,
    updateUserProfileApi,
  } from "./api";
  import CryptoJS from "crypto-js";
  import { appActions } from "../../context/app-slice";
  import {jwtDecode} from "jwt-decode"; // Default import for jwtDecode
  import theme from "../theme/theme"; // Import the theme

  // Format time in mm:ss
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? `0${minutes}` : minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
  };
  
  // Encrypt Secret Key
  export const encryptSecretKey = (secretKey) =>
    CryptoJS.SHA256(JSON.stringify(secretKey)).toString();
  
  // Snackbar Helper Functions
  const showSuccessSnackbar = (setSnackbar, messageKey, customMessage) => {
    setSnackbar({
      open: true,
      message: customMessage || theme.snackbar.messages[messageKey],
      severity: "success",
    });
  };
  
  const showErrorSnackbar = (setSnackbar, messageKey, customMessage) => {
    setSnackbar({
      open: true,
      message: customMessage || theme.snackbar.messages[messageKey],
      severity: "error",
    });
  };
  
  // Send OTP
  export const handleSendOtp = async (
    email,
    setSnackbar,
    setLoader,
    setClickedOnLoginOtp,
    setTimer
  ) => {
    if (typeof email !== "string" || !email.trim()) {
      showErrorSnackbar(setSnackbar, "emailEmpty");
      return;
    }
  
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!emailRegex.test(email)) {
      showErrorSnackbar(setSnackbar, "emailInvalid");
      return;
    }
  
    try {
      setLoader(true);
      await sendOtpApi(email);
      showSuccessSnackbar(setSnackbar, "otpSent");
      setClickedOnLoginOtp(true);
      setTimer(300); // Reset timer to 5 minutes
    } catch (error) {
      showErrorSnackbar(setSnackbar, "otpFailed");
    } finally {
      setLoader(false);
    }
  };
  
  // Verify OTP
  export const handleCheckOtp = async (
    email,
    otp,
    dispatch,
    navigate,
    setSnackbar,
    setLoader,
    setShowDetailsForm,
    setLoginMethod
  ) => {
    if (!otp) {
      showErrorSnackbar(setSnackbar, "otpEmpty");
      return;
    }
  
    try {
      setLoader(true);
      const data = await verifyOtpApi(email, otp);
      dispatch(appActions.updateUserDetails(data));
      dispatch(appActions.updateAuthToken(data.authToken));
  
      if (!data.firstName || !data.lastName) {
        setLoginMethod("otp");
        setShowDetailsForm(true);
      } else {
        dispatch(appActions.login(true));
        showSuccessSnackbar(setSnackbar, "otpVerified");
        navigate("/");
      }
    } catch (error) {
      showErrorSnackbar(setSnackbar, "otpVerificationFailed");
    } finally {
      setLoader(false);
    }
  };
  
  // Blockchain Login
  export const handleBlockchainLogin = async (
    walletAddress,
    dispatch,
    navigate,
    setSnackbar,
    setNonCustodialLoader,
    setShowDetailsForm,
    setLoginMethod
  ) => {
    try {
      setNonCustodialLoader(true);
      const blockchain = "ETHEREUM";
      const wallet = "TALEWALLET";
      const product = "TALE_WALLET";
  
      const loginResponse = await blockchainLoginApi({
        blockchain,
        blockchainAddress: walletAddress,
        marketplaceAddress: "0",
        wallet,
        product,
      });
  
      const secretKey = encryptSecretKey(loginResponse.secretKey);
      dispatch(appActions.setEvmEncrypt(secretKey));
  
      const signature = await window.ethereum.request({
        method: "personal_sign",
        params: [loginResponse.secretKey, walletAddress],
      });
  
      const verifyResponse = await blockchainWalletVerifyApi({
        blockchain,
        blockchainAddress: walletAddress,
        marketplaceAddress: "0",
        signedSecretKey: signature,
        userId: loginResponse.userId,
      });
  
      dispatch(appActions.updateAuthToken(verifyResponse.authToken));
      dispatch(appActions.updateUserDetails(verifyResponse));
  
      if (!verifyResponse.firstName || !verifyResponse.lastName) {
        setLoginMethod("metamask");
        setShowDetailsForm(true);
      } else {
        dispatch(appActions.login(true));
        showSuccessSnackbar(setSnackbar, "blockchainSuccess");
        navigate("/");
      }
    } catch (error) {
      showErrorSnackbar(setSnackbar, "blockchainFailed");
    } finally {
      setNonCustodialLoader(false);
    }
  };
  
  // Google Signup/Login
  export const handleGoogleLogin = async (
    credentialResponse,
    dispatch,
    navigate,
    setSnackbar
  ) => {
    if (!credentialResponse?.credential) {
      showErrorSnackbar(setSnackbar, "googleInvalid");
      return;
    }
  
    try {
      const decoded = jwtDecode(credentialResponse.credential);
      const { email, given_name, family_name, sub: googleId, picture } = decoded;
  
      const signupResponse = await googleSignupApi({
        email,
        firstName: given_name,
        lastName: family_name,
        password: googleId,
      });
  
      if (signupResponse.newUser) {
        const profileUpdate = {
          userId: signupResponse.userId,
          firstName: given_name,
          lastName: family_name,
          avatar: picture,
        };
        await updateUserProfileApi(profileUpdate);
      }
  
      dispatch(appActions.updateAuthToken(signupResponse.authToken));
      dispatch(appActions.login(true));
      showSuccessSnackbar(setSnackbar, "googleSignupSuccess", "Signup and login successful!");
      navigate("/");
    } catch (error) {
      showErrorSnackbar(setSnackbar, "googleSignupFailed");
    }
  };
  
  // Update User Profile
  export const handleSubmitDetails = async (
    additionalDetails,
    userId,
    dispatch,
    setSnackbar,
    navigate,
    setLoader
  ) => {
    if (!additionalDetails.firstName || !additionalDetails.lastName) {
      showErrorSnackbar(setSnackbar, "profileUpdateFailed");
      return;
    }
  
    setLoader(true);
    const payload = {
      firstName: additionalDetails.firstName,
      lastName: additionalDetails.lastName,
      userId: userId || localStorage.getItem("userId"),
    };
  
    try {
      const data = await updateUserProfileApi(payload);
      dispatch(appActions.updateUserDetails(data));
      dispatch(appActions.login(true));
      showSuccessSnackbar(setSnackbar, "profileUpdated");
      navigate("/");
    } catch (error) {
      showErrorSnackbar(setSnackbar, "profileUpdateFailed");
    } finally {
      setLoader(false);
    }
  };

    // Handle MetaMask Sign-in
    const handleMetamaskSignin = async () => {
        if (isMetaMaskRequesting) {
          setSnackbar({
            open: true,
            message:
              "MetaMask request is already in progress. Please complete the current request.",
            severity: "warning",
          });
          return;
        }
    
        if (!window.ethereum) {
          setSnackbar({
            open: true,
            message: "MetaMask not detected. Please install MetaMask extension.",
            severity: "error",
          });
          return;
        }
    
        setIsMetaMaskRequesting(true);
        setMetamaskClicked(true);
    
        try {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
    
          await handleNonCustodialSignin(accounts[0]);
        } catch (error) {
          if (error.code === -32002) {
            setSnackbar({
              open: true,
              message:
                "A MetaMask request is already pending. Please check your MetaMask extension.",
              severity: "error",
            });
          } else {
            console.error(error);
            setSnackbar({
              open: true,
              message: "An error occurred while connecting to MetaMask.",
              severity: "error",
            });
          }
        } finally {
          setIsMetaMaskRequesting(false);
          setMetamaskClicked(false);
        }
      };

  // Handle Non-Custodial Sign-in (MetaMask)
  const handleNonCustodialSignin = async (walletAddress) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
        },
      };

      if (authToken) {
        config.headers["X-Auth-Token"] = authToken;
      }

      setNonCustodialLoader(true); // Show loading spinner

      // Use "ETHEREUM" as the blockchain
      const blockchain = "ETHEREUM";
      const wallet = "TALEWALLET";
      const product = "TALE_WALLET";

      // API call to initiate login
      const response = await axios.post(
        `${process.env.REACT_APP_NFTVERSE_DEV_API}/user/blockchain/crypto/account/login`,
        {
          blockchain,
          blockchainAddress: walletAddress,
          marketplaceAddress: "0",
          wallet,
          product,
        },
        config
      );
      console.log("Account Login API Response:", response.data);

      const message = response.data.secretKey;

      // Encrypt the secret key
      const keyEncrypt = CryptoJS.SHA256(
        JSON.stringify(response.data.secretKey)
      ).toString();
      dispatch(appActions.setEvmEncrypt(keyEncrypt));

      // Request signature from MetaMask
      const signature = await window.ethereum.request({
        method: "personal_sign",
        params: [message, walletAddress],
      });

      // Verify the signature
      const verifyResponse = await axios.post(
        `${process.env.REACT_APP_NFTVERSE_DEV_API}/user/blockchain/crypto/wallet/verify`,
        {
          blockchain,
          blockchainAddress: walletAddress,
          marketplaceAddress: "0", // Ensure this is included
          signedSecretKey: signature,
          userId: response.data.userId,
        },
        config
      );
      console.log("Verification Response:", verifyResponse.data);

      // Handle the verification response
      if (verifyResponse.data.role) {
        dispatch(appActions.setRole(verifyResponse.data.role));
      } else {
        dispatch(appActions.setRole("user"));
      }

      dispatch(appActions.updateAuthToken(verifyResponse.data.authToken));
      dispatch(
        appActions.updateUserDetails({
          ...verifyResponse.data,
          userId: verifyResponse.data.userId,
          firstName: verifyResponse.data.firstName,
          lastName: verifyResponse.data.lastName,
        })
      );

      // Store user details in localStorage
      window.localStorage.setItem("userId", verifyResponse.data.userId);
      window.localStorage.setItem("authToken", verifyResponse.data.authToken);
      window.localStorage.setItem("firstName", verifyResponse.data.firstName);
      window.localStorage.setItem("lastName", verifyResponse.data.lastName);
      if (verifyResponse.data.userName) {
        window.localStorage.setItem("userName", verifyResponse.data.userName);
      }

      dispatch(appActions.setBlockChain(blockchain));
      dispatch(appActions.setCustodial(false));

      // Check if essential user details are missing
      if (!verifyResponse.data.firstName || !verifyResponse.data.lastName) {
        // Show the additional details form
        setLoginMethod("metamask");
        setShowDetailsForm(true);
      } else {
        // Complete the login process
        dispatch(appActions.login(true));
        setSnackbar({
          open: true,
          message: "Wallet connected successfully",
          severity: "success",
        });
        console.log("MetaMask Login Response:", verifyResponse.data);
        navigate("/");
      }
    } catch (error) {
      console.error("Error connecting...", error);
      if (error.response) {
        console.error("API error response:", error.response.data);
        setSnackbar({
          open: true,
          message: `Error: ${
            error.response.data.message || "Failed to connect with MetaMask."
          }`,
          severity: "error",
        });
      } else {
        setSnackbar({
          open: true,
          message: "Failed to connect with MetaMask.",
          severity: "error",
        });
      }
    } finally {
      setNonCustodialLoader(false); // Hide loading spinner
      setIsMetaMaskRequesting(false);
      setMetamaskClicked(false);
    }
  };
  */