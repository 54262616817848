

import { NavLink } from "react-router-dom";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import "./Sidebar.css";
import { useDispatch, useSelector } from "react-redux";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import PaidIcon from "@mui/icons-material/Paid";
import TokenIcon from "@mui/icons-material/Token";
import WalletIcon from "@mui/icons-material/Wallet";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CameraIcon from "@mui/icons-material/Camera";
import ParaglidingIcon from "@mui/icons-material/Paragliding";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { appActions } from "../../context/app-slice";

const width = window.screen.width;
console.log("hello width--", width);

const SidebarMobile = ({ children }) => {
  const appCtx = useSelector((state) => state.app);
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  const [showTicker, setShowTicker] = useState(false);
  const makeRequest = useAuthorizedHttp();
  const dispatch = useDispatch();
  const sidebarVariants = {
    hidden: {
      x: "-100%",
    },
    visible: {
      x: "-5%",
    },
  };

  let isOpen = appCtx?.isSideBarOpen;

let routes = [
  {
    path: "/",
    name: "Home",
    icon: <DashboardIcon className={`w-[50px]`} />,
  },
  {
    path: "/wallet",
    name: "Wallet",
    icon: <WalletIcon className="w-[50px]" />,
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    icon: <AssignmentIndIcon className={`w-[50px]`} />,
  },
  {
    path: "/transactions",
    name: "Transactions",
    icon: <ReceiptIcon className="w-[50px]" />,
  },
  {
    path: "/crypto-basket",
    name: "Crypto Basket",
    icon: <ShoppingBasketIcon className="w-[50px]" />,
    subRoutes: ["/crypto-basket"],
  },
  {
    path: "/crypto-lens",
    name: "Cryptolens",
    icon: <CameraIcon className="w-[50px]" />,
  },
  {
    path: "/projects",
    name: "Projects",
    icon: <CameraIcon className="w-[50px]" />,
  },
  {
    path: "/gimmy-ai",
    name: "Gimmy AI",
    icon: <CameraIcon className="w-[50px]" />,
  },
  {
    path: "/financials",
    name: "Financials",
    icon: <CameraIcon className="w-[50px]" />,
  },
  {
    path: "/esg-pools",
    name: "ESG-Pools",
    icon: <CameraIcon className="w-[50px]" />,
  },
  {
    path: "/dgu-basket",
    name: "Crop Basket",
    icon: <CameraIcon className="w-[50px]" />,
  },
  {
    path: "/esg-report-maker",
    name: "ESG Report Maker",
    icon: <CameraIcon className="w-[50px]" />,
  },
  {
    path: "/esg-lens",
    name: "ESG Lens",
    icon: <CameraIcon className="w-[50px]" />,
  },
];
  return (
    <>
      <motion.nav
        className={isOpen ? "nav-mobile-menu  active" : "nav-mobile-menu"}
        variants={sidebarVariants}
        initial="hidden"
        animate="visible"
        exit="visible"
        style={{ zIndex: 10 }}
      >
        <ul className="">
          <section className="routes pt-4">
            {routes.map((route, index) => {
              return (
                <NavLink
                  onClick={() => {
                    dispatch(
                      appActions.setIsSideBarOpen(!appCtx.isSideBarOpen)
                    );
                  }}
                  to={route.path}
                  key={index}
                  className="link"
                  activeClassName="active d-flex gap-2 mt-4"
                >
                  {/* <div className="icon">{route.icon}</div> */}
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        initial="hidden"
                        exit="hidden"
                        className="d-flex align-items-center ms-2 "
                      >
                        <div className="icon">{route.icon}</div>
                        <p className="mb-0">{route.name}</p>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            })}
          </section>
        </ul>
      </motion.nav>
    </>
  );
};

export default SidebarMobile;
