import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { CircularProgress, Skeleton } from "@mui/material";
import { useWalletView } from "../../hooks/useWalletView";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { usehandleBuyTaleCoin } from "../../hooks/handleBuyTaleCoin";
import { appActions } from "../../context/app-slice";
import MainWrapper from "../../components/wrapper/MainWrapper";
import TokenTablesCard from "./TokenTablesCard";

const Tokens = () => {
  const [pausePriceApiCall, setPausePriceApiCall] = useState(false);
  const [openAddTokenModal, setOpenAddTokenModal] = useState(false);

  const makeRequest = useAuthorizedHttp();
  const {
    token,
    accountAsset,
    setAccountAsset,
    amount,
    setAmount,
    assetUrl,
    setAssetUrl,
    handleOptIn,
    showAssets,
    appCtx,
    optedIn,
    taleAmount,
    showTaleData,
    optInSuccessfull,
    setOptInSuccessfull,
    handleCustodialOptIn,
    minBalance,
    getAssetsById,
    assetLoader,
    allCoin,
    allCoinPrice,
    usersToken,
    isAssetLoading,
    setIsAssetLOading,
    isCoinLoading,
    isCoinPriceLOading,
    icon,
    otheraccountAsset,
    otherassetLoader,
    openSwapModal,
    setopenSwapModal,
    openSellModal,
    setopenSellModal,
    getAllCoin,
    getAllCoinPrice,
    getUsersToken,
    totalpage,
    setAllcoinPrice,
    listedToken,
    listedTokenLoading,
    getListedTokens,
  } = useWalletView();

  useEffect(() => {
    // getAllCoin(0, 100);
    // getAllCoinPrice();
    // getUsersToken();
    getListedTokens();
  }, [appCtx.blockchain, openAddTokenModal === false]);

  // calling additional price api

  useEffect(() => {
    if (
      allCoin.length !== 0 &&
      Object.keys(allCoinPrice).length !== 0 &&
      allCoin.length !== Object.keys(allCoinPrice).length &&
      !isCoinLoading &&
      !isCoinPriceLOading &&
      !pausePriceApiCall
    ) {
      const tokenSymbolsWithNoPrice = allCoin
        ?.filter((coin) => !allCoinPrice[coin.symbol?.toUpperCase()]?.price)
        ?.map((coin) => coin?.symbol);

      makeRequest(
        {
          url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/price`,
          data: tokenSymbolsWithNoPrice,
          method: "post",
          headers: {
            "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
            "Content-Type": "application/json",
          },
        },
        (data) => {
          setAllcoinPrice((prev) => ({ ...prev, ...data }));
          setPausePriceApiCall(true);
        },
        (error) => {}
      );
    }
  }, [allCoin, allCoinPrice, appCtx.blockchain]);

  return (
    <>
      <MainWrapper>
        {/* <FutureHeader /> */}
        <div class="">
          {/* <!-- row --> */}
          <div class="container-fluid">
            <div class="col-12 my-order-ile">
              <div class="card">
                <div class="card-header border-0 pb-3">
                  <h4 class="card-title">Tokens</h4>
                </div>
                <div
                  class="card-body px-4 pt-0 "
                  style={{ minHeight: "800px" }}
                >
                  {listedTokenLoading ? (
                    <div
                      class="d-flex justify-content-center align-items-center "
                      style={{ marginTop: "100px" }}
                    >
                      <CircularProgress
                        style={{ color: "#BBFF00", fontSize: "10px" }}
                        className="py-10"
                        fontSize="small"
                        style={{ width: "25px", height: "25px" }}
                      />
                    </div>
                  ) : (
                    <>
                      <div class="col-lg-12">
                        <div class="table-responsive dataTabletrade">
                          <table
                            id="example-history"
                            class="table shadow-hover display  orderbookTable"
                          >
                            <thead>
                              <tr>
                                <th>Symbol</th>
                                <th>Token Name</th>
                                <th>Price($)</th>
                                <th>Marketcap($)</th>
                                <th>1 day</th>
                                <th>Last 7 days</th>
                              </tr>
                            </thead>
                            <tbody>
                              {listedToken?.map((token, index) => {
                                return (
                                  <TokenTablesCard key={index} token={token} />
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default Tokens;
