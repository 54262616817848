import { createTheme } from '@mui/material/styles';

const white= "#FFFFFF"
const black = "#000000"
const grey = "#aaaaaa"
const dark_grey="#212121"
const orange="#e36c09"
const dark_orange="#cc5e08"
const lightGrey= "#2E2E2E"
const theme = createTheme({
  typography: {
    fontFamily: '"DM Sans", sans-serif', // Google Font
    logo: {
      fontSize: '20px',
      fontWeight: 'light',
      color: white, 
      textTransform: 'lowercase',
      backgroundColor: '#000000', // Black background
      padding: '2px 22px',
      borderRadius: '8px',
      display: 'inline-block',
    },
    social_logo: {
        fontSize: '15px',
        fontWeight: 'light',
        color: white, // White text
        textTransform: 'lowercase',
        backgroundColor: '#000000', // Black background
        padding: '2px 22px',
        borderRadius: '8px',
        display: 'inline-block',
      },
    h1: { fontSize: '3rem', fontWeight: 700, color: white, lineHeight: 1.2 },
    h2: { fontSize: '2.5rem', fontWeight: 600, color: white, lineHeight: 1.3 },
    h3: { fontSize: '2rem', fontWeight: 500, color:  white, lineHeight: 1.4 },
    h4: { fontSize: '1.75rem', fontWeight: 500, color:  white, lineHeight: 1.5 },
    h5: { fontSize: '1.5rem', fontWeight: 400, color:  white, lineHeight: 1.6 },
    h6: { fontSize: '1.25rem', fontWeight: 400, color:  white, lineHeight: 1.6 },
    h7: { fontSize: '1rem', fontWeight: 300, color:  white, lineHeight: 1.6 },
    h8: { fontSize: '0.75rem', fontWeight: 200, color:  white, lineHeight: 1.6 },
    t1: { fontSize: '1rem', fontWeight: 200, color:  white, lineHeight: 1.8 },
    t2: { fontSize: '0.875rem', fontWeight: 400, color:  white, lineHeight: 1.7 },
    t3: { fontSize: '0.75rem', fontWeight: 300, color:  white, lineHeight: 1.6 },
    h1_grey: { fontSize: '3rem', fontWeight: 700, color: grey, lineHeight: 1.2 },
    h2_grey: { fontSize: '2.5rem', fontWeight: 600, color: grey, lineHeight: 1.3 },
    h3_grey: { fontSize: '2rem', fontWeight: 500, color: grey, lineHeight: 1.4 },
    h4_grey: { fontSize: '1.75rem', fontWeight: 500, color:grey, lineHeight: 1.5 },
    h5_grey: { fontSize: '1.5rem', fontWeight: 400, color: grey, lineHeight: 1.6 },
    h6_grey: { fontSize: '1.25rem', fontWeight: 400, color:grey, lineHeight: 1.6 },
    t1_grey: { fontSize: '1rem', fontWeight: 400, color: grey, lineHeight: 1.8 },
    t2_grey: { fontSize: '0.875rem', fontWeight: 400, color: grey, lineHeight: 1.7 },
    t3_grey: { fontSize: '0.75rem', fontWeight: 300, color: grey, lineHeight: 1.6 },
    t4_grey: { fontSize: '0.75rem', fontWeight: 100, color: grey, lineHeight: 1.6 },

  },
  snackbar: {
    // Predefined snackbar messages
    messages: {
      emailEmpty: "Email cannot be empty!",
      emailInvalid: "Invalid email address!",
      otpEmpty: "Please enter the OTP!",
      otpSent: "OTP Sent Successfully!",
      otpFailed: "Failed to send OTP. Please try again.",
      otpVerified: "Login Successful!",
      otpVerificationFailed: "OTP verification failed. Please try again.",
      blockchainSuccess: "Wallet connected successfully",
      blockchainFailed: "Blockchain login failed. Please try again.",
      googleInvalid: "Invalid Google credentials.",
      googleSignupFailed: "Google signup failed. Please try again.",
      profileUpdated: "Profile updated successfully",
      profileUpdateFailed: "Failed to update profile. Please try again.",
      metamaskInProgress: "MetaMask request is already in progress. Please complete the current request.",
      metamaskPending: "A MetaMask request is already pending. Please check your MetaMask extension.",
      metamaskError: "An error occurred while connecting to MetaMask.",
      metamaskNotDetected: "MetaMask not detected. Please install MetaMask extension.",
      // Add more messages as needed
    },
  },
  palette: {
    background: {
      gradient: 'radial-gradient(circle, rgb(39, 39, 39) 50%, rgb(0, 0, 0) 100%)', // Background gradient
      grey:dark_grey,
      black:black,
      default:dark_grey,
      
    },
    primary: {
      main: "#fff", // Example primary color
    },
    secondary: {
      main: "#e36c09", // Example secondary color
    },
    text: {
      primary: white,
      secondary: dark_grey,
    },
    success: {
      main: "#28a745",
    },
    warning: {
      main: "#ffc107",
    },
    error: {
      main: '#f44336',
      light: '#e57373',
      dark: '#d32f2f',
      contrastText: '#fff'
    },
    grey: {
      400: '#bdbdbd',
    },
    selection: {
      background: grey, // Custom selection background
      color: white, // Custom selection text color
    },
    sidebar: {
      background: black,
      hoverBackground: dark_grey,
      activeBackground: orange,
      text: white,
      activeText: black,
      accountSectionText: white,
      borderColor: dark_grey,
    },
  },
  cssTransitions: { // Renamed from 'transitions'
    create: (properties, options) => {
      return `all 0.3s ease-in-out`;
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
        },
      },
      variants: [
        {
          props: { variant: 'orange' }, // Orange Button
          style: {
            backgroundColor: '#e36c09',
            color: '#000000',
            '&:hover': { backgroundColor: '#cc5e08' },
            '&:disabled': {
              backgroundColor: '#444444',
              color: '#888888',
            },
          },
        },
        {
          props: { variant: 'transparent' }, // Transparent Button
          style: {
            backgroundColor: 'transparent',
            color: '#FFFFFF',
            border: '1px solid rgba(255, 255, 255, 0.5)',
            '&:hover': {
              backgroundColor: 'rgba(68, 68, 68, 0.8)', // Greyish background on hover
            },
            '&:disabled': {
              backgroundColor: '#444444',
              color: '#888888',
            },
          },
        },

        {
            props: { variant: 'dynamic' }, // New dynamic button variant
            style: {
              backgroundColor: 'transparent', // Transparent by default
              color: white, // White text by default
              border: '1px solid rgba(255, 255, 255, 1)', // Semi-transparent white border
              borderRadius: '8px', // Rounded corners
              textTransform: 'none', // Disable uppercase transformation
              transition: 'background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease', // Smooth transitions
          
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 1)', // Subtle white overlay on hover
                color: '#FFFFFF', // Ensure white text remains on hover
                borderColor: 'rgba(255, 255, 255, 0.8)', // Brighter border on hover
              },
          
              '&.filled': {
                backgroundColor:orange, // Orange background when filled
                color: '#000000', // Black text when filled
                border: 'none', // No border when filled
                '&:hover': {
                  backgroundColor: '#cc5e08', // Darker orange on hover when filled
                  color: black, // Ensure text stays black when filled
                },
              },
          
              '&:disabled': {
                backgroundColor: 'transparent', // Transparent background when disabled
                color: 'rgba(255, 255, 255, 1)', // Faded white text when disabled
                border: '1px solid rgba(255, 255, 255, 1)', // Subtle greyish border when disabled
              },
            },
          },
          
          {
            props: { variant: "custom" },
            style: {
              borderRadius: "8px",
              textTransform: "none",
              border: `1px solid ${white}`, // Explicit border definition
              color: white, // Primary color for text
              backgroundColor: "transparent",
              "&:hover": {
                borderColor: orange, // Secondary color for border on hover
                backgroundColor: "transparent", // Transparent background on hover
                color: orange, // Secondary color for text on hover
              },
            },
          },
          
          {
            props: { variant: 'actionButton' }, // Custom variant for ActionButton
            style: {
              backgroundColor: orange, // Replace with theme.palette.success.main
              color: '#000', // Replace with theme.palette.success.contrastText
              fontWeight: 700,
              padding: '8px 24px', // Equivalent to theme.spacing(1, 3)
              borderRadius: '8px', // Equivalent to theme.shape.borderRadius
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#1e7e34', // Replace with theme.palette.success.dark
              },
            },
          },

          {
            props: { variant: 'contained' },
            style: {
              backgroundColor: orange,
              color: white,
              '&:hover': {
                backgroundColor: dark_orange,
              },
            },
          },
         
      ],
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: 'blackBox' },
          style: {
            backgroundColor: '#000000',
            color: '#ffffff',
            borderRadius: '16px',
            padding: '16px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.5)',
          },
        },
        {
          props: { variant: 'default' },
          style: {
            backgroundColor: '#000000',
            color: '#ffffff',
            borderRadius: '16px',
            padding: '16px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.5)',
          },
        },
      ],
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#1a1a1a', // Input background
          color: '#ffffff', // Default text color
          borderRadius: '16px', // Rounded corners
          transition: 'border-color 0.3s ease, box-shadow 0.3s ease', // Smooth transitions
          '& fieldset': {
            borderColor: '#444444', // Default border color
            transition: 'border-color 0.3s ease', // Smooth border transition
          },
          '&:hover fieldset': {
            borderColor: '#e36c09', // Orange on hover
          },
          '&.Mui-focused fieldset': {
            borderColor: '#e36c09 !important', // Enforced orange on focus
            boxShadow: '0 0 4px rgba(227, 108, 9, 0.5)', // Optional glowing effect
          },
          '&.Mui-error fieldset': {
            borderColor: '#f44336', // Red for error state
          },
          '&.Mui-disabled': {
            // Style for disabled TextField
            color:lightGrey, // Change the text color
            backgroundColor: dark_grey, // Change the background color
            borderColor: white, // Change the border color
          },
        },
        
        input: {
          padding: '12px 16px', // Input padding
          color: '#ffffff', // Text color
          '&::placeholder': {
            color: '#888888', // Placeholder color
            opacity: 1, // Ensure full opacity
          },
          // Handle autofill styles
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0px 1000px #1a1a1a inset', // Ensure background stays consistent
            WebkitTextFillColor: '#ffffff', // Ensure text color remains white
            transition: 'background-color 0s ease-in-out 0s', // Prevent autofill animations
          },
          '&:-webkit-autofill:focus': {
            WebkitBoxShadow: '0 0 0px 1000px #1a1a1a inset', // Keep background consistent on focus
            WebkitTextFillColor: '#ffffff', // Ensure text color remains white
          },
        },
      },
    },
    
      
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: grey,
          '&.Mui-focused': { color: orange },
          '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.4)', // Label color for disabled
          },
        },
      },
    },

    MuiBox: {
      variants: [
        {
          props: { variant: 'tag' },
          style: {
            fontSize: '8px',
            fontWeight: 300,
            color: '#fff',
            backgroundColor: '#444',
            padding: '2px 8px',
            borderRadius: '6px',
          },
        },
        {
          props: { variant: 'highlight' },
          style: {
            fontSize: '14px',
            fontWeight: 700,
            color: '#fff',
            backgroundColor: '#222',
            padding: '4px 10px',
            borderRadius: '8px',
          },
        },

        {
          props: { variant: 'glassHeader' }, // Custom variant for GlassHeader
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px', // Equivalent to theme.spacing(2)
            borderRadius: '8px', // Equivalent to theme.shape.borderRadius
            background: `linear-gradient(
              to right, 
              rgba(0, 0, 0, 0.8), 
              rgba(0, 0, 0, 0.4)
            ), url('/path/to/your/background/image.jpg')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            color: '#fff', // Replace with theme.palette.text.primary if needed
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)', // Equivalent to theme.shadows[2]
          },},

          {
            props: { variant: 'headerBox' },
            style: {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '16px',
              borderRadius: '8px',
              background: `linear-gradient(
                to right, 
                rgba(0, 0, 0, 0.8), 
                rgba(0, 0, 0, 0.4)
              ), url('/path/to/your/background/image.jpg')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              color: '#fff',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
            },
          },

          {
            props: { variant: 'imageUploadArea' },
            style: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '3px dashed #e36c09', // Orange border
              outline:'3px dashed #e36c09',
              borderRadius: '16px', // Rounded corners
              padding: '16px', // Add inner padding
              backgroundColor: 'rgba(227, 108, 9, 0.1)', // Light orange background
              cursor: 'pointer',
              transition: 'all 0.3s ease', // Smooth transitions
              '&:hover': {
                borderColor: '#cc5e08', // Darker orange on hover
                boxShadow: '0 0 10px rgba(227, 108, 9, 0.5)', // Add a glow effect on hover
              },
              '&.dragging': {
                borderColor: '#e36c09', // Strong orange during dragging
                boxShadow: '0 0 15px rgba(227, 108, 9, 0.8)', // Stronger glow during dragging
                backgroundColor: 'rgba(227, 108, 9, 0.2)', // Slightly darker background during dragging
              },
            },
          },
          

          {
            props: { variant: 'outlined' }, // Custom variant for outlined box
            style: {
              display: 'inline-flex', // Ensure flexibility and alignment
              justifyContent: 'center',
              alignItems: 'center',
              padding: '4px 12px', // Add padding
              borderRadius: '16px', // Rounded corners
              border: '1px solid rgba(255, 255, 255, 0.5)', // White border with semi-opacity
              color: '#FFFFFF', // White text
              
              fontSize: '0.875rem', // Equivalent to 14px
              fontWeight: 400, // Normal weight
              textTransform: 'none', // Preserve original case
              backgroundColor: 'transparent', // Transparent background
              transition: 'all 0.3s ease', // Smooth transitions
              '&:hover': {
                borderColor: '#FFFFFF', // Bright white border on hover
                backgroundColor: 'rgba(255, 255, 255, 0.1)', // Subtle white overlay
              },
            },
          },

          {
            props: { variant: 'messageBubble' },
            style: ({ theme, role }) => ({
              display: 'flex',
              padding: '16px',
              borderRadius: '16px',
              backgroundColor: role === 'user' ? theme.palette.secondary.main : theme.palette.background.grey,
              color: role === 'user' ? theme.palette.primary.contrastText : theme.palette.primary.main,
              boxShadow: theme.shadows[2],
              transition: theme.transitions.create(['transform', 'box-shadow'], {
                duration: 300,
              }),
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: theme.shadows[4],
              },
            }),
          },
              
          
      ],
    },
    
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: dark_grey, // Background of the menu
          color: white, // Text color
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.5)', // Menu shadow
          borderRadius: '8px', // Rounded corners
          border: `1px solid ${dark_grey}`, // Optional border
        },
        list: {
          padding: '8px 0', // Padding inside the menu
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: white, // Default text color
          padding: '12px 16px', // Padding for each menu item
          '&:hover': {
            backgroundColor: orange, // Hover background color
            color: black, // Hover text color
          },
          '&.Mui-selected': {
            backgroundColor: orange, // Selected background color
            color: black, // Selected text color
            '&:hover': {
              backgroundColor: dark_orange, // Darker orange on hover when selected
            },
          },
        },
      },},

      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: lightGrey,
            '&.Mui-checked': {
              color: orange,
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: grey, // Light grey color for dropdown icon
          },
        },
      },

      MuiTable: {
        styleOverrides: {
          root: {
            backgroundColor: black,
            borderRadius: "8px",
            overflow: "hidden",
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: black, // Black background for the table header
            "& th": {
              backgroundColor: black, // Ensure header cells also have a black background
              color: white,
              fontWeight: 600,
              textAlign: "left",
              padding: "12px 16px",
              fontSize: "0.875rem",
              borderBottom: `2px solid ${grey}`,
            },
          },
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            "& td": {
              backgroundColor: black, // Black background for table cells
              color: grey,
              fontSize: "0.875rem",
              padding: "12px 16px",
              borderBottom: `1px solid ${dark_grey}`,
            },
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:hover": {
              backgroundColor: "#1c1c1c", // Slightly lighter black on hover
            },
          },
        },
      },
      MuiBadge: {
        styleOverrides: {
          root: {
            borderRadius: "4px",
            textTransform: "capitalize",
            padding: "4px 8px",
            fontSize: "0.75rem",
            "& .MuiBadge-colorSuccess": {
              backgroundColor: "#28a745",
              color: white,
            },
            "& .MuiBadge-colorWarning": {
              backgroundColor: "#ffc107",
              color: black,
            },
          },
        },
      },

      MuiListItem: {
        styleOverrides: {
          root: {
            paddingTop: '8px',
            paddingBottom: '8px',
          },
        },
      },

      MuiListItemText: {
        styleOverrides: {
          primary: {
            // Ensure the text uses the "t1" variant from typography
            ...createTheme().typography.t1,
          },
        },
      },

      MuiChip: {
        variants: [
          {
            props: { variant: 'tag' }, // Custom variant for tags
            style: {
              display: 'inline-flex', // Ensure flexibility and alignment
              justifyContent: 'center',
              alignItems: 'center',
              padding: '4px 12px', // Add padding
              borderRadius: '16px', // Rounded corners
              border: '1px solid rgba(255, 255, 255, 0.5)', // White border with semi-opacity
              color: '#FFFFFF', // White text
              fontSize: '0.875rem', // Equivalent to 14px
              fontWeight: 400, // Normal weight
              textTransform: 'none', // Preserve original case
              backgroundColor: 'transparent', // Transparent background
              transition: 'all 0.3s ease', // Smooth transitions
              '&:hover': {
                borderColor: '#FFFFFF', // Bright white border on hover
                backgroundColor: 'rgba(255, 255, 255, 0.1)', // Subtle white overlay
              },
            },
          },
        ],
      },

      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundColor: black,
            color: white,
            borderRadius: '16px',
          },
        },
      },

      MuiDialogTitle: {
        styleOverrides: {
          root: {
            backgroundColor: black,
            color: white,
          },
        },
      },

      MuiTextField: {
        styleOverrides: {
          root: {
            backgroundColor: dark_grey,
            color: white,
            borderRadius: '8px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: grey,
              },
              '&:hover fieldset': {
                borderColor: orange,
              },
              '&.Mui-focused fieldset': {
                borderColor: orange,
              },
            },
          },
        },
      },

      MuiSelect: {
        styleOverrides: {
          icon: {
            color: grey,
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            backgroundColor: dark_grey,
            color: white,
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
          },
        },
      },

      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            borderRadius: '8px', // Overall border radius
            border: `1px solid ${grey}`, // Border color
            padding: '4px', // Padding around the group
            background:black
             
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            color: grey,
            border: 'none',
            '&.Mui-selected': {
              color: white,
              backgroundColor: orange,
              '&:hover': {
                backgroundColor: dark_orange,
              },
            },
            '&:not(:last-of-type)': {
              borderRight: `1px solid ${grey}`,
            },
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
          },
        },
      },

  },
});

export default theme;
