// src/components/profileDrawer/ProfileDrawer.jsx

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "../../context/app-slice";
import { persistor } from "../../context/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBusinessTime,
  faHeadset,
  faReceipt,
  faRightFromBracket,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Divider,
  Typography,
  Box,
} from "@mui/material";

const ProfileDrawer = ({
  setOpenSupportModal,
  setOpenAccountDetailsModal,
  setOpenPrivateKeyLoginModal,
  setOpenPrivateKeyPasswordModal,
  triggerElement, // Accept the trigger element as a prop
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appCtx = useSelector((state) => state.app);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleLogout = async () => {
    try {
      // Dispatch logout actions
      dispatch(appActions.logout(undefined));
      dispatch(appActions.updateAuthToken(undefined));
      // Uncomment and modify if you need to reset wallet address
      // dispatch(appActions.setWalletAddress([{ address: "" }]));
      dispatch(
        appActions.updateUserDetails({
          userId: "",
          email: "",
          name: "",
        })
      );

      // Purge persisted store
      await persistor.purge();

      // Navigate to the login page after successful purge
      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
      // Optionally, display an error message to the user
    }
  };

  const themedColor = appCtx?.isDarkMode ? "black" : "black";

  const menuItems = [
    {
      icon: faHeadset,
      label: "Support",
      onClick: () => {
        setOpenSupportModal(true);
        setIsDrawerOpen(false);
      },
    },
    {
      icon: faBusinessTime,
      label: "Profile",
      onClick: () => {
        navigate("/business-details");
        setIsDrawerOpen(false);
      },
    },
    {
      icon: faReceipt,
      label: "Terms and Compliance",
      onClick: () => {
        navigate("/terms-and-compliance");
        setIsDrawerOpen(false);
      },
    },
    {
      icon: faRightFromBracket,
      label: "Logout",
      onClick: () => {
        handleLogout();
        setIsDrawerOpen(false);
      },
    },
  ];

  return (
    <>
      {/* Trigger Element */}
      <Box onClick={() => setIsDrawerOpen(true)}>{triggerElement}</Box>

      {/* Drawer Component */}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        PaperProps={{
          sx: {
            width: 300,
            backgroundColor: "#000000",
            color: themedColor,
            display: "flex",
            flexDirection: "column",
            padding: 2,
          },
        }}
      >
        {/* Drawer Header */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6"></Typography>
          <IconButton
            onClick={() => setIsDrawerOpen(false)}
            sx={{
              color: "#212121",
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </Box>

        <Divider sx={{ borderColor: "#ccc", mb: 2 }} />

        {/* Drawer Content */}
        <List sx={{ flex: 1, overflowY: "auto" }}>
          {menuItems.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem
                button
                onClick={item.onClick}
                sx={{
                  "&:hover": {
                    backgroundColor: appCtx?.isDarkMode
                      ? "#f0f0f0"
                      : "#e36c09",
                  },
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={item.icon}
                    style={{
                      color: "#fff",
                      width: "20px",
                      marginRight: "10px",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{
                    variant: "t1",
                  }}
                />
              </ListItem>
              {index < menuItems.length - 1 && (
                <Divider sx={{ borderColor: "#ccc" }} />
              )}
            </React.Fragment>
          ))}
        </List>

        {/* Drawer Footer */}
        <Box
          sx={{
            textAlign: "center",
            pt: 2,
            borderTop: "1px solid #212121",
          }}
        >
          <Typography variant="t3_grey">© 2025 Vortx.ai</Typography>
        </Box>
      </Drawer>
    </>
  );
};

export default ProfileDrawer;
