import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_NFTVERSE_DEV_API;
const X_APP_TOKEN = process.env.REACT_APP_X_APP_TOKEN;
const X_INTERNAL_TOKEN = process.env.REACT_APP_X_INTERNAL_TOKEN;

// Axios instance
const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor for auth token
apiClient.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      config.headers["X-Auth-Token"] = authToken;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// API Calls
export const sendOtpApi = (email) => apiClient.post(`/otp/send?type=login`, { email },{headers: { "X-App-Token": X_APP_TOKEN },});

export const verifyOtpApi = (email, otp) => apiClient.post(`/otp/verify?type=login`, { email, otp },{headers: { "X-App-Token": X_APP_TOKEN },});

export const blockchainLoginApi = (payload) =>
  apiClient.post(`/user/blockchain/crypto/account/login`, payload, {
    headers: { "X-App-Token": X_APP_TOKEN },
  });

export const blockchainWalletVerifyApi = (payload) =>
  apiClient.post(`/user/blockchain/crypto/wallet/verify`, payload, {
    headers: { "X-App-Token": X_APP_TOKEN },
  });

export const googleSignupApi = (payload) =>
  apiClient.post(`/user/create/`, payload, {
    headers: {
      "X-Internal-Token": X_INTERNAL_TOKEN,
      "X-App-Token": X_APP_TOKEN,
    },
  });

  export const googleSigninApi = (payload) =>
    apiClient.post(`/googlesignup/`, payload, {
      headers: {
        "X-Internal-Token": X_INTERNAL_TOKEN,
        "X-App-Token": X_APP_TOKEN,
      },
    });

export const updateUserProfileApi = (payload) => apiClient.put(`/user/profile/update`, payload,{headers: { "X-App-Token": X_APP_TOKEN },});
