// ScheduleTab.js - Updated Component for the Schedule Tab

import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Tooltip,
  CircularProgress,
  Paper,
  Button,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  FormControlLabel,
  Checkbox,
  LinearProgress,
  Fab,
} from '@mui/material';
import {
  Add as AddIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
  Sync as SyncIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  ViewWeek as ViewWeekIcon,
  ViewModule as ViewModuleIcon,
  ViewAgenda as ViewAgendaIcon,
  Today as TodayIcon,
  Search as SearchIcon,
  Settings as SettingsIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';
import { styled, alpha, useTheme } from '@mui/material/styles';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import addDays from 'date-fns/addDays';
import { addHours } from 'date-fns';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import enUS from 'date-fns/locale/en-US';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import { DataGrid } from '@mui/x-data-grid';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

// Set up locales
const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse: (str, formatString) => parseISO(str),
  startOfWeek,
  getDay,
  locales,
});

// Set up DnD Calendar
const DragAndDropCalendar = withDragAndDrop(Calendar);

// Styled Calendar Wrapper using theme
const CalendarWrapper = styled('div')(({ theme }) => ({
  '& .rbc-toolbar': {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.black,
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  '& .rbc-toolbar-label': {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: theme.palette.secondary.main, // Orange color
  },
  '& .rbc-button-link': {
    color: theme.palette.text.primary, // White text
    fontSize: '1rem',
    margin: theme.spacing(0, 0.5),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      borderRadius: theme.shape.borderRadius,
    },
  },
  '& .rbc-event': {
    backgroundColor: theme.palette.primary.main, // Blue color
    borderRadius: '8px',
    padding: theme.spacing(0.5),
    color: '#fff',
    border: 'none',
    boxShadow: theme.shadows[2],
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  '& .rbc-selected': {
    backgroundColor: theme.palette.primary.dark,
  },
  '& .rbc-today': {
    backgroundColor: theme.palette.action.selected,
  },
  '& .rbc-off-range-bg': {
    backgroundColor: theme.palette.action.disabledBackground,
  },
  '& .rbc-time-view': {
    '& .rbc-time-header': {
      '& .rbc-time-header-cell': {
        textAlign: 'center',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      },
    },
  },
  '& .rbc-date-cell': {
    color: theme.palette.text.primary,
  },
  '& .rbc-month-view, & .rbc-week-view, & .rbc-day-view, & .rbc-agenda-view': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
}));

const CalendarTab = () => {
  const [projectData, setProjectData] = useState({
    startDate: null,
    endDate: null,
    deliveryFrequency: 'Weekly',
  });
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openEventDialog, setOpenEventDialog] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [dateFormat, setDateFormat] = useState('MMMM do yyyy, h:mm a');
  const [pageSize, setPageSize] = useState(5); // For dynamic pagination
  const [page, setPage] = useState(0); // For dynamic pagination

  const theme = useTheme(); // Access the theme

  useEffect(() => {
    // Fetch project data from dummy API
    const fetchData = async () => {
      try {
        setLoading(true);
        // Simulate API call with dummy data
        setTimeout(() => {
          setProjectData({
            startDate: '2024-10-01',
            endDate: '2024-12-31',
            deliveryFrequency: 'Weekly',
          });
          setLoading(false);
        }, 1000);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (projectData.startDate && projectData.endDate) {
      generateEvents();
    }
  }, [projectData]);

  const generateEvents = () => {
    const { startDate, endDate, deliveryFrequency } = projectData;
    let events = [];
    let currentDate = parseISO(startDate);
    const end = parseISO(endDate);
    let freq = 7;

    if (deliveryFrequency === 'Daily') {
      freq = 1;
    } else if (deliveryFrequency === 'Weekly') {
      freq = 7;
    } else if (deliveryFrequency === 'Biweekly') {
      freq = 14;
    } else if (deliveryFrequency === 'Monthly') {
      freq = 30;
    }

    while (isBefore(currentDate, end) || isSameDay(currentDate, end)) {
      events.push({
        id: events.length + 1,
        title: 'Data Synthesis',
        start: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          9,
          0,
          0
        ),
        end: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          10,
          0,
          0
        ),
        allDay: false,
        type: 'Synthesis',
      });
      currentDate = addDays(currentDate, freq);
    }
    setEvents(events);
  };

  const handleEventSelect = (event) => {
    setCurrentEvent(event);
    setOpenEventDialog(true);
  };

  const handleEventSave = () => {
    try {
      if (!currentEvent.id) {
        currentEvent.id = events.length + 1;
        setEvents((prevEvents) => [...prevEvents, currentEvent]);
      } else {
        setEvents((prevEvents) =>
          prevEvents.map((evt) => (evt.id === currentEvent.id ? currentEvent : evt))
        );
      }
      setOpenEventDialog(false);
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to save event:', error);
    }
  };

  const handleEventDelete = () => {
    try {
      const updatedEvents = events.filter((evt) => evt.id !== currentEvent.id);
      setEvents(updatedEvents);
      setOpenEventDialog(false);
      setConfirmDeleteOpen(false);
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to delete event:', error);
    }
  };

  const handleAddEvent = () => {
    setCurrentEvent({
      id: null,
      title: '',
      start: new Date(),
      end: addHours(new Date(), 1),
      allDay: false,
      type: 'Synthesis',
    });
    setOpenEventDialog(true);
  };

  const handleEventDialogClose = () => {
    setOpenEventDialog(false);
    setCurrentEvent(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSyncCalendar = async () => {
    try {
      // Implement Google Calendar Sync
      const event = {
        summary: 'Sample Event',
        start: {
          dateTime: new Date().toISOString(),
          timeZone: timezone,
        },
        end: {
          dateTime: new Date(new Date().getTime() + 60 * 60 * 1000).toISOString(),
          timeZone: timezone,
        },
      };

      // Replace with actual Google Calendar API integration
      // This is a placeholder for demonstration purposes
      console.log('Syncing with Google Calendar:', event);

      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to sync with calendars:', error);
    }
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor;
    if (event.type === 'Synthesis') {
      backgroundColor = theme.palette.primary.main; // Blue color
    } else if (event.type === 'Analysis') {
      backgroundColor = theme.palette.secondary.main; // Orange color
    } else {
      backgroundColor = theme.palette.error.main; // Red color
    }

    const style = {
      backgroundColor: isSelected ? theme.palette.primary.dark : backgroundColor,
      borderRadius: '8px',
      opacity: 0.9,
      color: 'white',
      border: 'none',
      display: 'block',
      padding: '4px',
      boxShadow: theme.shadows[2],
    };
    return {
      style: style,
    };
  };

  const CustomToolbar = (toolbar) => {
    const goToBack = () => {
      toolbar.onNavigate('PREV');
    };

    const goToNext = () => {
      toolbar.onNavigate('NEXT');
    };

    const goToToday = () => {
      toolbar.onNavigate('TODAY');
    };

    const handleViewChange = (view) => {
      toolbar.onView(view);
    };

    return (
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Box>
          <Button onClick={goToBack} startIcon={<ChevronLeftIcon />} sx={{ mr: 1 }}>
            Back
          </Button>
          <Button onClick={goToNext} endIcon={<ChevronRightIcon />} sx={{ mr: 1 }}>
            Next
          </Button>
          <Button onClick={goToToday} startIcon={<TodayIcon />}>
            Today
          </Button>
        </Box>
        <Typography variant="h5" color="secondary">
          {toolbar.label}
        </Typography>
        <Box>
          <Tooltip title="Month View">
            <IconButton onClick={() => handleViewChange('month')}>
              <ViewModuleIcon color="secondary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Week View">
            <IconButton onClick={() => handleViewChange('week')}>
              <ViewWeekIcon color="secondary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Day View">
            <IconButton onClick={() => handleViewChange('day')}>
              <TodayIcon color="secondary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Agenda View">
            <IconButton onClick={() => handleViewChange('agenda')}>
              <ViewAgendaIcon color="secondary" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    );
  };

  const handleEventResize = ({ event, start, end }) => {
    const nextEvents = events.map((existingEvent) => {
      return existingEvent.id === event.id
        ? { ...existingEvent, start, end }
        : existingEvent;
    });
    setEvents(nextEvents);
  };

  const handleEventDrop = ({ event, start, end }) => {
    const nextEvents = events.map((existingEvent) => {
      return existingEvent.id === event.id
        ? { ...existingEvent, start, end }
        : existingEvent;
    });
    setEvents(nextEvents);
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredEvents = events.filter((event) => {
    return (
      event.title.toLowerCase().includes(searchText.toLowerCase()) ||
      format(event.start, dateFormat)
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );
  });

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'title',
      headerName: 'Event Title',
      flex: 1,
      editable: true,
    },
    {
      field: 'start',
      headerName: 'Start Date and Time',
      flex: 1,
      valueGetter: (params) => {
        const date = new Date(params.value);
        return isNaN(date) ? '' : format(date, dateFormat);
      },
    },
    {
      field: 'end',
      headerName: 'End Date and Time',
      flex: 1,
      valueGetter: (params) => {
        const date = new Date(params.value);
        return isNaN(date) ? '' : format(date, dateFormat);
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 120,
      editable: true,
    },
  ];

  const handleDownloadCSV = () => {
    const csvData = events.map((event) => ({
      ID: event.id,
      Title: event.title,
      'Start Date': format(event.start, dateFormat),
      'End Date': format(event.end, dateFormat),
      Type: event.type,
    }));

    const worksheet = XLSX.utils.json_to_sheet(csvData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Events');
    const excelBuffer = XLSX.write(workbook, { bookType: 'csv', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'text/csv' });
    saveAs(data, 'events.csv');
  };

  const handleSettingsOpen = () => {
    setSettingsOpen(true);
  };

  const handleSettingsClose = () => {
    setSettingsOpen(false);
  };

  return (
    <Box sx={{ width: '100%', mt: 4 }} variant="blackBox">
      {loading ? (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <CircularProgress />
          <Typography variant="body1" sx={{ mt: 2 }}>
            Loading schedule...
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={3} sx={{ mt: 2 }} variant="blackBox">
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 2, height: '100%' }} variant="blackBox">
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Event List
                </Typography>
                <Tooltip title="Settings">
                  <IconButton onClick={handleSettingsOpen}>
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }} variant="blackBox">
                <TextField
                  variant="outlined"
                  placeholder="Search..."
                  size="small"
                  value={searchText}
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: <SearchIcon />,
                  }}
                  sx={{ flexGrow: 1, mr: 1 }}
                />
                <Tooltip title="Export">
                  <IconButton onClick={handleDownloadCSV}>
                    <FileDownloadIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <div style={{ height: 400, width: '100%' }} variant="blackBox">
                <DataGrid
                  rows={filteredEvents}
                  columns={columns}
                  pageSize={pageSize}
                  page={page}
                  pagination
                  paginationMode="client"
                  onPageChange={(newPage) => setPage(newPage)}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  checkboxSelection
                  disableSelectionOnClick
                  onCellEditCommit={(params) => {
                    const updatedEvents = events.map((event) => {
                      if (event.id === params.id) {
                        return { ...event, [params.field]: params.value };
                      }
                      return event;
                    });
                    setEvents(updatedEvents);
                  }}
                  componentsProps={{
                    row: {
                      onMouseEnter: (event) => {
                        event.currentTarget.style.backgroundColor = alpha(
                          theme.palette.primary.light,
                          0.25
                        );
                      },
                      onMouseLeave: (event) => {
                        event.currentTarget.style.backgroundColor = '';
                      },
                    },
                  }}
                />
              </div>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Fab color="primary" aria-label="add" onClick={handleAddEvent}>
                  <AddIcon />
                </Fab>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<SyncIcon />}
                  onClick={handleSyncCalendar}
                >
                  Sync with Calendar
                </Button>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={8}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <CalendarWrapper>
                <DragAndDropCalendar
                  localizer={localizer}
                  events={events}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: 600 }}
                  eventPropGetter={eventStyleGetter}
                  onEventDrop={handleEventDrop}
                  resizable
                  onEventResize={handleEventResize}
                  onSelectEvent={handleEventSelect}
                  selectable
                  onSelectSlot={(slotInfo) => {
                    setCurrentEvent({
                      id: null,
                      title: '',
                      start: slotInfo.start,
                      end: slotInfo.end,
                      allDay: slotInfo.action === 'doubleClick',
                      type: 'Synthesis',
                    });
                    setOpenEventDialog(true);
                  }}
                  components={{
                    toolbar: CustomToolbar,
                  }}
                  tooltipAccessor={(event) =>
                    `${event.title}\n${format(event.start, dateFormat)}`
                  }
                  timeZone={timezone}
                />
              </CalendarWrapper>
            </Paper>
          </Grid>
        </Grid>
      )}

      {/* Event Dialog */}
      <Dialog
        open={openEventDialog}
        onClose={handleEventDialogClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{currentEvent?.title ? 'Edit Event' : 'Add Event'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Event Title"
            fullWidth
            margin="normal"
            value={currentEvent?.title || ''}
            onChange={(e) =>
              setCurrentEvent({ ...currentEvent, title: e.target.value })
            }
          />
          <TextField
            label="Start Date and Time"
            type="datetime-local"
            fullWidth
            margin="normal"
            value={
              currentEvent?.start
                ? format(currentEvent.start, "yyyy-MM-dd'T'HH:mm")
                : ''
            }
            onChange={(e) =>
              setCurrentEvent({
                ...currentEvent,
                start: parseISO(e.target.value),
              })
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="End Date and Time"
            type="datetime-local"
            fullWidth
            margin="normal"
            value={
              currentEvent?.end ? format(currentEvent.end, "yyyy-MM-dd'T'HH:mm") : ''
            }
            onChange={(e) =>
              setCurrentEvent({
                ...currentEvent,
                end: parseISO(e.target.value),
              })
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={currentEvent?.allDay || false}
                onChange={(e) =>
                  setCurrentEvent({ ...currentEvent, allDay: e.target.checked })
                }
              />
            }
            label="All Day Event"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Event Type</InputLabel>
            <Select
              value={currentEvent?.type || ''}
              onChange={(e) =>
                setCurrentEvent({ ...currentEvent, type: e.target.value })
              }
            >
              <MenuItem value="Synthesis">Synthesis</MenuItem>
              <MenuItem value="Analysis">Analysis</MenuItem>
              <MenuItem value="Meeting">Meeting</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          {currentEvent?.id && (
            <Button
              onClick={() => setConfirmDeleteOpen(true)}
              color="secondary"
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          )}
          <Button
            onClick={handleEventDialogClose}
            color="secondary"
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleEventSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm Delete Dialog */}
      <Dialog
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this event?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmDeleteOpen(false)}
            color="secondary"
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
          <Button
            onClick={handleEventDelete}
            color="secondary"
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Settings Dialog */}
      <Dialog open={settingsOpen} onClose={handleSettingsClose}>
        <DialogTitle>Settings</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Timezone</InputLabel>
            <Select
              value={timezone}
              onChange={(e) => setTimezone(e.target.value)}
            >
              {Intl.supportedValuesOf('timeZone').map((tz) => (
                <MenuItem key={tz} value={tz}>
                  {tz}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Date Format</InputLabel>
            <Select
              value={dateFormat}
              onChange={(e) => setDateFormat(e.target.value)}
            >
              <MenuItem value="MMMM do yyyy, h:mm a">
                {format(new Date(), 'MMMM do yyyy, h:mm a')}
              </MenuItem>
              <MenuItem value="yyyy-MM-dd HH:mm">
                {format(new Date(), 'yyyy-MM-dd HH:mm')}
              </MenuItem>
              <MenuItem value="MM/dd/yyyy h:mm a">
                {format(new Date(), 'MM/dd/yyyy h:mm a')}
              </MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSettingsClose}
            color="secondary"
            startIcon={<CloseIcon />}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="Changes saved successfully"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            onClick={handleSnackbarClose}
            color="inherit"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        ContentProps={{
          sx: {
            backgroundColor: theme.palette.success.main,
            color: '#fff',
          },
        }}
      />
    </Box>
  );
};

export default CalendarTab;
