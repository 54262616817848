import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  Box,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { ProtocolList } from "../ProtocolList"; // Ensure this path is correct

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    maxHeight: "90vh",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  position: "relative",
  backgroundColor: theme.palette.background.grey,
  color: theme.palette.text.primary,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.text.primary,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1, 3),
}));

const SupportModal = ({ openModal, setOpenModal }) => {
  const navigate = useNavigate();
  const makeRequest = useAuthorizedHttp();
  const [loading, setLoading] = useState(false);

  const [selectedProject, setSelectedProject] = useState("");
  const [selectedSupport, setSelectedSupport] = useState("");
  const [selectedPriority, setSelectedPriority] = useState("");
  const [formData, setFormData] = useState({
    callback: "",
    contactMail: "",
    description: "",
    userFirstName: "",
    userLastName: "",
    userId: "12345",
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const authToken = localStorage.getItem("authToken");

  const handleClose = () => {
    setOpenModal(false);
    setSelectedSupport("");
    setSelectedPriority("");
    setSelectedProject("");
    setFormData({
      callback: "",
      contactMail: "",
      description: "",
      userFirstName: "",
      userLastName: "",
      userId: "12345",
    });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const project = ProtocolList.find(
      (protocol) => protocol.name === selectedProject
    );
    const projectId = project ? project.id : null;

    if (!projectId || !selectedSupport || !selectedPriority) {
      setSnackbar({
        open: true,
        message: "Please fill in all required fields.",
        severity: "error",
      });
      setLoading(false);
      return;
    }

    const requestData = {
      projectId,
      supportType: selectedSupport,
      priority: selectedPriority,
      userId: formData.userId,
      userFirstName: formData.userFirstName,
      userLastName: formData.userLastName,
      number: formData.callback,
      email: formData.contactMail,
      description: formData.description,
    };

    try {
      const response = await makeRequest({
        url: `${process.env.REACT_APP_VORTX_URL1}/supports`,
        method: "POST",
        data: requestData,
        headers: {
          "X-App-Token": authToken,
          "Content-Type": "application/json",
        },
      });

      setSnackbar({
        open: true,
        message: "Support request created successfully!",
        severity: "success",
      });
      setLoading(false);
      handleClose();
      navigate("/");
    } catch (error) {
      setSnackbar({
        open: true,
        message:
          error.response?.data?.message || "Failed to create support request",
        severity: "error",
      });
      setLoading(false);
    }
  };

  return (
    <>
      <StyledDialog
        open={openModal}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="support-modal-title"
      >
        <StyledDialogTitle id="support-modal-title">
          <Typography variant="h6">vortx.ai Support</Typography>
          <StyledIconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </StyledIconButton>
        </StyledDialogTitle>
        <DialogContent dividers>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="select-dgu-label">Select DGU</InputLabel>
              <Select
                labelId="select-dgu-label"
                id="select-dgu"
                value={selectedProject}
                onChange={(e) => setSelectedProject(e.target.value)}
                required
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {ProtocolList.map((protocol) => (
                  <MenuItem key={protocol.id} value={protocol.name}>
                    {protocol.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl component="fieldset">
              <Typography variant="h7" gutterBottom>
                Select Support Type
              </Typography>
              <ToggleButtonGroup
                value={selectedSupport}
                exclusive
                onChange={(e, newValue) => {
                  if (newValue !== null) {
                    setSelectedSupport(newValue);
                  }
                }}
                aria-label="support type"
              >
                {["Sales", "Bugs", "Compliances", "Pricing"].map((type) => (
                  <ToggleButton key={type} value={type} aria-label={type}>
                    {type}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </FormControl>

            <FormControl component="fieldset">
              <Typography variant="subtitle1" gutterBottom>
                Select Priority
              </Typography>
              <ToggleButtonGroup
                value={selectedPriority}
                exclusive
                onChange={(e, newValue) => {
                  if (newValue !== null) {
                    setSelectedPriority(newValue);
                  }
                }}
                aria-label="priority"
              >
                {["Urgent", "Normal", "Lite"].map((priority) => (
                  <ToggleButton key={priority} value={priority} aria-label={priority}>
                    {priority}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </FormControl>

            <TextField
              label="First Name"
              name="userFirstName"
              value={formData.userFirstName}
              onChange={handleChange}
              fullWidth
              required
            />

            <TextField
              label="Last Name"
              name="userLastName"
              value={formData.userLastName}
              onChange={handleChange}
              fullWidth
              required
            />

            <TextField
              label="Callback Number"
              name="callback"
              value={formData.callback}
              onChange={handleChange}
              fullWidth
            />

            <TextField
              label="Contact Email"
              name="contactMail"
              type="email"
              value={formData.contactMail}
              onChange={handleChange}
              fullWidth
              required
            />

            <TextField
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
              required
            />

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <StyledButton
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
                startIcon={
                  loading ? <CircularProgress size={20} color="inherit" /> : null
                }
              >
                {loading ? "Sending..." : "Submit"}
              </StyledButton>
            </Box>
          </Box>
        </DialogContent>
      </StyledDialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SupportModal;
