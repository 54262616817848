import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useCryptolens } from "./useCryptolens";
import Banner from "./Banner";
import WeOffer from "./WeOffer";
import ContactUs from "./ContactUs";
import Update from "./Update";
import LensModal from "./LensModal";
import MainWrapper from "../../components/wrapper/MainWrapper";

const CryptolensComponet = () => {
  const appCtx = useSelector((state) => state.app);
  const {
    openlensModal,
    setopenlensModal,
    subscribed,
    setSubscribed,
    success,
    setSuccess,
    closeClicked,
    setCloseClicked,
    loading,
    setLoading,
    postloading,
    setPostLoading,
    content,
    setContent,
    isSubscribed,
    handleUnSubscribe,
    fetchPostContent,
  } = useCryptolens();

  useEffect(() => {
    fetchPostContent();
  }, []);

  return (
    <MainWrapper>
      <div className="container-fluid">
        <Banner
          openlensModal={openlensModal}
          setopenlensModal={setopenlensModal}
        />
        {/* <NewsDisplay /> */}
        <Update />
        {!appCtx.isLoggedIn && (
          <>
            <WeOffer />
            {/* <Reviews /> */}
            <ContactUs />
          </>
        )}
      </div>

      <LensModal
        openModal={openlensModal}
        setOpenModal={setopenlensModal}
        success={success}
        setSuccess={setSuccess}
        closeClicked={closeClicked}
        setCloseClicked={setCloseClicked}
      />
    </MainWrapper>
  );
};

export default CryptolensComponet;
