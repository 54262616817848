import React from "react";
import { Container, Typography, Box } from "@mui/material";
import MainWrapper from "../../components/wrapper/MainWrapper";
import theme from "../../components/theme/theme";
const TermsAndCompliance = () => {
  return (
    <MainWrapper>
      <Box 
        sx={{
          background: theme.palette.background.grey,
         // bgcolor:  theme.palette.background.default,
          p: 3,
          //mt: { xs: 8, sm: 10 }, // Add top margin to avoid clipping
        }}
      >
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom>
            Terms and Policies 
          </Typography>
          <Typography variant="t2_grey" gutterBottom>
            By using our VortX LBVM, you agree to our terms and policies. Please review the following information carefully.
          </Typography>

          <Box variant="blackBox"
            
          >
            <Typography variant="h6" gutterBottom>
              Terms of Use
            </Typography>
            <Typography variant="t2_grey" paragraph>
              Updated: August 20, 2024
            </Typography>
            <Typography variant="t3_grey" paragraph>
              These Terms of Use govern your utilization of LBVM and other Vortx.AI services tailored for individuals, as well as any related software applications and websites (collectively referred to as “Services”). These Terms create a binding agreement between you and Vortx AI Private Limited, CIN: U72200JH2024PTC023101, a company duly registered under the Companies Act 2013 (18 of 2013), India.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Who We Are
            </Typography>
            <Typography variant="t2_grey" paragraph>
              Welcome to Vortx AI Private Limited. We are a dedicated AI research and deployment company, committed to the development of artificial general intelligence (AGI) with a mission to ensure its benefits reach all corners of humanity. At Vortx AI, we are driven by the potential of AI to transform lives and industries, aiming to pioneer advancements that are both innovative and ethical.
            </Typography>
            <Typography variant="t3_grey" paragraph>
              For more detailed information about who we are and what we do, please visit our website:{" "}
              <a href="https://vortx.ai" target="_blank" rel="noopener noreferrer">
                https://vortx.ai
              </a>
              .
            </Typography>

            <Typography variant="h6" gutterBottom>
              Registration and Access
            </Typography>
            <Typography variant="t2_grey" paragraph>
              To use our Services, you must be at least 13 years old, or the minimum age required in your country to consent to use the Services. For individuals under 18, parental or legal guardian consent is required to engage with our Services.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Using Our Services
            </Typography>
            <ul>
              <li>
                <Typography variant="t2_grey">
                  You are granted access to our services provided you adhere strictly to these Terms.
                </Typography>
              </li>
              <li>
                <Typography variant="t2_grey">
                  Do not use our Services in a way that infringes, misappropriates, or violates anyone's rights.
                </Typography>
              </li>
              <li>
                <Typography variant="t2_grey">
                  Reverse engineering or attempting to discover the source code or underlying algorithms of our Services is prohibited.
                </Typography>
              </li>
            </ul>

            <Typography variant="h6" gutterBottom>
              Content Ownership
            </Typography>
            <Typography variant="t2_grey" paragraph>
              You retain ownership of your input. For output, we assign to you all our rights, title, and interest, if any, in and to the output. Please note, given the nature of artificial intelligence, the output provided by our Services may not be unique, and similar outputs may be generated for other users.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Security
            </Typography>
            <Typography variant="t2_grey" paragraph>
              We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure, we have put in place suitable measures.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Changes to Terms
            </Typography>
            <Typography variant="t2_grey" paragraph>
              Vortx AI is dedicated to continuously improving our Services. We may update these Terms or modify our Services in response to various factors, including changes in legal requirements, security, or safety reasons.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Governing Law
            </Typography>
            <Typography variant="t2_grey" paragraph>
              These Terms are governed by the laws of the state of Jharkhand, India, excluding its conflicts of law principles.
            </Typography>
            <Typography variant="t2_grey" paragraph>
              For further inquiries, please contact us at{" "}
              <a href="mailto:avijeet@vortx.ai">avijeet@vortx.ai</a>.
            </Typography>
          </Box>
        </Container>
      </Box>
    </MainWrapper>
  );
};

export default TermsAndCompliance;
