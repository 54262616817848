import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import {
  faCheck,
  faChevronDown,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BiChevronDown } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import { useWalletView } from "../../hooks/useWalletView";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { nativeToken } from "../../config";
import { Card } from "react-bootstrap";
import { usehandleBuyTaleCoin } from "../../hooks/handleBuyTaleCoin";
import ConfirmSendPaymentModal from "../../components/BuyAndSellAlgos/sendAlgos/ConfirmSendPayment";
import { useAccount, useChainId } from "wagmi";
import web3 from "web3";
import { ethers } from "ethers";
import SuccessModal from "../../components/BuyAndSellAlgos/sendAlgos/SuccessModal";
import { erc20Abi } from "../../components/Abi/abi";

const PayusingTokenModal = ({
  openModal,
  setOpenModal,

  symbol,
  name,
  fundId,

  truncatedAddress,
  fundWalletAddress,
  setOpenTalewalletOnrampModal,
  allCoin,
}) => {
  const appCtx = useSelector((state) => state.app);

  const hardCodeGasFee = {
    POLYGON: 10,
    BSC: 30,
    ETHEREUM: 300,
    ALGORAND: 0,
    SOLANA: 10,
  };
  let minInvest = Math.round(5000 / appCtx?.usdPrice);

  const [sendAmount, setSendAmount] = useState(minInvest);

  const [openPayment, setOpenPayment] = useState(false);

  const { handleBuyAlgos } = usehandleBuyTaleCoin(appCtx);

  const [networkFee, setNetworkFee] = useState(
    hardCodeGasFee[appCtx?.blockchain] / appCtx?.usdPrice
  );
  const [isBasketDetailsPage, setIsBasketDetailsPage] = useState(false);

  const [isAddressCopied, setIsAddressCopied] = useState(false);
  const [expanded, setExpanded] = useState(null);

  const [isMetaMaskInstall, setIsMetaMaskInstall] = useState();
  const [isMetaMaskConnected, setIsMetaMaskConnected] = useState(true);
  const [networkVersion, setNetworkVersion] = useState();
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [metamaskTransactionHash, setMetamaskTransactionHash] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [
    openSelfCustodySendTransferSuccessModal,
    setOpenSelfCustodySendTransferSuccessModal,
  ] = useState(false);

  const [option, setOption] = useState(nativeToken[appCtx?.blockchain]);
  const [metamaskTransferLoading, setMetamaskTransferLoading] = useState(false);
  const [sendTokenPriceLoading, setsendTokenPriceLoading] = useState(false);

  const [sendTokenData, setSendTokenData] = useState({});

  const { getSingleTokenBalance } = useWalletView();

  function closeModal() {
    setOpenModal(false);
  }

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9992,
    },
    content: {
      top: "53%",
      backgroundColor: "#000000",
      border: "1px solid  #000000",
      color: "black",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
      zIndex: 9993,
    },
  };

  const themedColor = {
    color: "black",
  };

  const handleCopyAddress = (value) => {
    setIsAddressCopied(!isAddressCopied);
    navigator.clipboard.writeText(value);
  };

  let platformFee;
  let totalFee;

  if (sendAmount !== null && sendAmount !== undefined) {
    platformFee = (sendAmount * 0.4) / 100;
  } else {
    // Handle the case when sendAmount is null or undefined
    // For example:
    platformFee = "0.00";
  }

  if (networkFee !== null && networkFee !== undefined) {
    totalFee = parseFloat(platformFee) + parseFloat(networkFee);
  } else {
    // Handle the case when networkFee is null or undefined
    // For example:
    totalFee = platformFee;
  }
  const usersWalletAddress = appCtx?.walletAddress?.find(
    (addr) => addr?.blockchain === appCtx?.blockchain
  )?.address;

  const sendTokenObject = allCoin.find((token) => token?.symbol === "USDT");
  useEffect(() => {
    if (sendTokenObject) {
      getSingleTokenBalance(
        usersWalletAddress,
        sendTokenObject?.tokenId,
        setSendTokenData,
        setsendTokenPriceLoading
      ); //usdt
    }
  }, [allCoin]);

  const handleSendAmountChange = (event) => {
    setSendAmount(event.target.value);
  };

  useEffect(() => {});

  const getWeb3 = () => {
    if (window.ethereum) {
      let provider = new ethers.BrowserProvider(window.ethereum);
      return provider;
    } else {
      return false;
    }
  };

  const provider = getWeb3();

  // const setAccountDetails = async () => {
  //   const accounts = await provider.listAccounts();
  //   if (accounts.length === 0) {
  //     setIsMetaMaskConnected(false);
  //   } else {
  //     setIsMetaMaskConnected(true);
  //   }
  // };
  // // subscription for account change detection
  // const accountChangeDetection = async () => {
  //   window.ethereum.on("accountsChanged", async function () {
  //     setIsDataLoading(true);
  //     await setAccountDetails().finally(() => setIsDataLoading(false));
  //   });
  // };

  useEffect(() => {
    if (!appCtx?.custodial) {
      const init = async () => {
        try {
          await provider.send("eth_requestAccounts", []);
          setIsMetaMaskInstall(true);
          // first time set current network version Id.
          const { chainId } = await provider.getNetwork();
          setNetworkVersion(chainId);
          // first time call for load initial details
          // await setAccountDetails();
          // subscription for account change detection
          // await accountChangeDetection();
        } catch (e) {
          setIsMetaMaskInstall(false);
          setIsDataLoading(false);
          setIsMetaMaskConnected(false);
          const eMessage = e.message.split("{")[0] || "";
          console.log(eMessage);
        }
      };
      init().then((r) => setIsDataLoading(false));
    }
  }, []);

  useEffect(() => {
    if (!appCtx?.custodial) {
      const networkVersionChangeDetection = async () => {
        window.ethereum.on("chainChanged", function (networkId) {
          setNetworkVersion(Number(networkId));
        });
      };

      // Call the async function
      networkVersionChangeDetection();

      // Ensure proper cleanup to avoid memory leaks
      return () => {
        // Remove the listener if it's needed; replace with the appropriate cleanup code
        // window.ethereum.removeListener("chainChanged", networkVersionChangeDetection);
      };
    }
  }, [networkVersion]);

  const handleERC20Transfer = async () => {
    setMetamaskTransferLoading(true);
    //Calling the metamask plugin
    const Web3 = new web3(window.ethereum);

    let sendTokensDecimal = sendTokenObject?.decimals; //this is for usdt. need to change if we use other token
    let contractAddress = sendTokenObject?.contractAddress; //this is for usdt. need to change if we use other token

    const ethValue = ethers.parseUnits(
      sendAmount.toString(),
      sendTokensDecimal
    );

    //Intialize the contract
    var tokenContract = new Web3.eth.Contract(erc20Abi, contractAddress, {
      from: usersWalletAddress,
    });

    // Call the contract method transfer to send token to recipient id
    const transaction = tokenContract.methods.transfer(
      fundWalletAddress, //receiver address
      ethValue
    );
    const gas = await transaction.estimateGas({
      from: usersWalletAddress,
    });
    const gasPrice = await Web3.eth.getGasPrice();

    transaction
      .send({
        from: usersWalletAddress,
        gas,
        gasPrice,
      })
      .on("transactionHash", (hash) => {
        setStatus(`Transaction sent! Hash: ${hash}`);
        setMetamaskTransactionHash(hash);
        setOpenSelfCustodySendTransferSuccessModal(true);
        setMetamaskTransferLoading(false);
      })
      .on("receipt", (receipt) => {
        setStatus("Transfer successful");
        setMetamaskTransferLoading(false);
      })
      .on("error", (error) => {
        if (error.message.includes("User denied transaction signature")) {
          // User denied transaction signature
          setStatus("User denied the transfer.");
        } else {
          console.log("Error:", error);
          setStatus("Error during the transfer");
        }
        setMetamaskTransferLoading(false);
      });
  };

  let InsufficientBalance = sendAmount > sendTokenData?.balance;

  return (
    <>
      <Modal
        isOpen={openModal}
        style={customStyles}
        contentLabel="Example Modal"
        onRequestClose={closeModal}
        ariaHideApp={false}
      >
        <div
          class=" d-flex justify-content-between align-items-center  py-2 position-relative px-4 rounded-top-lg"
          style={{
            backgroundColor: "#9376B1",
            borderRadius: "5px 5px 0px 0px",
          }}
        >
          <div class="w-15%">
            <img
              src="https://tale-invest-assets.s3.ap-southeast-1.amazonaws.com/assets/images/tale-invest-logo-sm.png"
              alt=""
              style={{ width: "35px", height: "35px" }}
            />
          </div>

          <h5 class="mb-0">Tale Invest</h5>
          <div class="">
            <div
              className="btn btn-link"
              onClick={() => {
                closeModal();
              }}
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-times" style={{ fontSize: "20px" }}></i>
            </div>
          </div>
        </div>
        <div
          class="overflow-auto bg-EEEEEE rounded-xl"
          style={{ maxHeight: "70vh" }}
        >
          <div>
            <div class="px-4 py-2">
              {
                <p className="mb-1 mt-1 fs-5">
                  My balance:{" "}
                  <span className="font-w600">
                    {sendTokenData?.balance?.toFixed(2)} {symbol}
                  </span>
                </p>
              }
              {InsufficientBalance && (
                <p className="text-danger">
                  You don't have enough {symbol},{" "}
                  <span
                    className="text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setOpenTalewalletOnrampModal(true);
                      closeModal();
                    }}
                  >
                    Purchase {symbol} using FIAT
                  </span>
                </p>
              )}

              <p class="text-md my-2">Enter the amount</p>

              <div
                className="mb-3 d-flex justify-content-between align-items-center py-2 border"
                style={{ border: "#ced4da", borderRadius: "5px" }}
              >
                <div className="">
                  <input
                    id="sendAmount"
                    placeholder="Enter amount"
                    name="sendAmount"
                    type="number"
                    min="0"
                    value={sendAmount}
                    onChange={handleSendAmountChange}
                    className="ms-3"
                    style={{
                      backgroundColor:  "white"
                      ,
                      color: "black",
                      width: "100px",
                      border: "none",
                    }}
                  />
                </div>
                <div class="w-2 h-12 d-flex justify-content-center align-items-center">
                  <div
                    class="border border-end"
                    style={{
                      width: "2px",
                      height: "30px",
                      border: "#ced4da",
                    }}
                  ></div>
                </div>

                <div
                  id="sendToken"
                  name="sendToken"
                  class="d-flex align-items-center gap-2 ms-2 "
                  style={{ width: "150px" }}
                >
                  <img
                    src="https://talewallet-assets.s3.ap-southeast-1.amazonaws.com/token-icon/USDT.png"
                    alt=""
                    class=""
                    style={{ width: "25px", height: "25px" }}
                  />
                  <div class="">
                    <p class="m-0 p-0">{symbol}</p>
                  </div>
                </div>
              </div>

              <p class="text-md mt-2 mb-2">
                Minimum investment is {minInvest} {symbol}
              </p>
              <div className="">
                <Accordion className="border">
                  <AccordionSummary
                    as={Card.Header}
                    eventKey="0"
                    className="d-flex justify-content-between align-items-center"
                    style={{
                      backgroundColor:  "white"
                      ,
                      color: "black",
                    }}
                    onClick={() => setExpanded(!expanded)}
                  >
                    <p className="text-[#3B0076] font-medium text-md mb-0">
                      You Pay {symbol} {sendAmount}
                    </p>
                    <p className="ms-4 mb-0">View Breakup</p>
                    <div className="d-flex align-items-center ms-2">
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        rotation={expanded ? 180 : 0}
                        className="ms-1"
                        style={themedColor}
                      />
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    eventKey="0"
                    style={{
                      backgroundColor:  "white"
                      ,
                      color: "black",
                    }}
                  >
                    <>
                      <div className=" text-sm font-medium">
                        <div className="d-flex justify-content-between align-items-center ">
                          <p className="mb-1">Platform Fee (0.4%)</p>
                          <p className="mb-1">
                            {symbol} {platformFee?.toFixed(2) || 0}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="mb-1">Network Fee</p>
                          <p className="mb-1">
                            {" "}
                            {symbol} {networkFee?.toFixed(2) || 0}
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex justify-content-between align-items-center text-sm font-medium">
                        <p className="mb-1">Total Fee</p>
                        <p className="mb-1">
                          {symbol} {totalFee?.toFixed(2) || 0}
                        </p>
                      </div>
                    </>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div class="d-flex mt-2">
                <button
                  onClick={() => {
                    if (InsufficientBalance) {
                      toast.error("Insufficient balance");
                    } else if (sendAmount < minInvest) {
                      toast.error(`Minimum amount is ${minInvest}`);
                    } else {
                      if (appCtx?.custodial) {
                        setOpenPayment(true);
                      } else {
                        handleERC20Transfer();
                      }
                    }
                  }}
                  disabled={!sendAmount || metamaskTransferLoading}
                  class="w-100 mb-3 py-2 text-md font-weight-bold text-white bg-primary border border-primary rounded active:text-secondary hover:bg-transparent hover:text-primary focus:outline-none focus:ring"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
          <div
            className="d-flex flex-column align-items-center mx-auto px-4 pb-2"
            style={{ width: "400px" }}
          >
            <p className="text-sm mb-0">
              {sendAmount} {symbol} will be transferred to {name}'s wallet.
            </p>
            <div className="d-flex flex-row align-items-center gap-2 my-1">
              <span className="font-weight-medium text-small">
                Fund's Wallet Address
              </span>
              <span className="text-primary font-weight-medium text-small">
                {truncatedAddress}
              </span>

              <div
                onClick={() => handleCopyAddress(fundWalletAddress)}
                className="text-secondary px-3"
                style={{
                  fontSize: "12px",
                  cursor: "pointer",
                }}
              >
                {isAddressCopied ? (
                  <FontAwesomeIcon icon={faCheck} className="" />
                ) : (
                  <FontAwesomeIcon icon={faCopy} className="" />
                )}
              </div>
            </div>
          </div>
          <div
            className="d-flex flex-column align-items-center mx-auto px-4 pb-2"
            style={{ width: "400px" }}
          >
            <p
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpenTalewalletOnrampModal(true);
                closeModal();
              }}
            >
              Purchase {symbol} using FIAT
            </p>
          </div>
        </div>
      </Modal>

      <ConfirmSendPaymentModal
        openModal={openPayment}
        setOpenModal={setOpenPayment}
        setPreviousOpen={setOpenModal}
        address={fundWalletAddress}
        algos={sendAmount}
        // amount={amount1}
        // setAlgos={setAlgos}
        // showAssets={showAssets}
        // setInfo={setInfo}
        option={symbol}
        allCoin={allCoin}
        fundId={fundId}
        isCryptoFundTransfer={true}
      />

      {openSelfCustodySendTransferSuccessModal && (
        <SuccessModal
          openModal={openSelfCustodySendTransferSuccessModal}
          setOpenModal={setOpenSelfCustodySendTransferSuccessModal}
          amount={sendAmount}
          option={option}
          address={fundWalletAddress}
          setPreviousOpen={setOpenModal}
          setConfirmOpen={setConfirmOpen} // no work
          transactionHash={metamaskTransactionHash}
          isCryptoFundTransfer={true}
          fundId={fundId}
        />
      )}
    </>
  );
};

export default PayusingTokenModal;
