// CropBasket.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProtocolList } from "../../components/ProtocolList";
import MainWrapper from "../../components/wrapper/MainWrapper";
import CreateBasketModal from "./CreateBasketModal";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
  Chip,
  Paper,
  Autocomplete,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const CropBasket = () => {
  const theme = useTheme();
const white = theme.palette.common.white;
const grey = theme.palette.grey[500]; // Adjust the shade if needed
const orange = theme.palette.secondary.main;
  const navigate = useNavigate();

  const [openCreateBasketModal, setOpenCreateBasketModal] = useState(false);
  const [closeClicked, setCloseClicked] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterType, setFilterType] = useState("");
  const [noCodeConsoleOnly, setNoCodeConsoleOnly] = useState(false);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  // Extract all unique model types initially from ProtocolList
  const allModelTypes = Array.from(new Set(ProtocolList.map((item) => item.model)));

  // Handler for level checkbox changes
  const handleLevelChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedLevels((prevLevels) => [...prevLevels, value]);
    } else {
      setSelectedLevels((prevLevels) => prevLevels.filter((level) => level !== value));
    }
  };

  // Filter protocol list based on search term (tag), selected model type, no-code filter, and selected levels
  const filteredProtocols = ProtocolList.filter((basket) => {
    const matchesSearchTerm = searchTerm
      ? basket.tags.some((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase()))
      : true;
    const matchesModelType = filterType ? basket.model === filterType : true;
    const matchesNoCode = noCodeConsoleOnly ? basket.no_code === "yes" : true;
    const matchesLevel =
      selectedLevels.length > 0 ? selectedLevels.includes(basket.level) : true;
    return matchesSearchTerm && matchesModelType && matchesNoCode && matchesLevel;
  });

  const filteredModelTypes = searchTerm
    ? Array.from(
        new Set(
          ProtocolList.filter((basket) =>
            basket.tags.some((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase()))
          ).map((basket) => basket.model)
        )
      )
    : allModelTypes;

  useEffect(() => {
    if (searchTerm) {
      const tagSuggestions = ProtocolList.filter((basket) => {
        return basket.tags.some((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase()));
      })
        .flatMap((basket) => basket.tags)
        .filter((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase()))
        .slice(0, 5); // Limit the number of suggestions
      setSuggestions(tagSuggestions);
    } else {
      setSuggestions([]);
    }
  }, [searchTerm]);

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion);
    setSuggestions([]); // Close the suggestion dropdown after selection
  };

  return (
    <MainWrapper>
      <Box p={3} sx={{ color: theme.palette.text.primary }}>
        <Typography variant="h4" gutterBottom>
          Protocols
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "16px",
            marginBottom: "24px",
            position: "relative",
          }}
        >
          {/* Search by Tags */}
          <Autocomplete
            freeSolo
            options={suggestions}
            inputValue={searchTerm}
            onInputChange={(event, newInputValue) => {
              setSearchTerm(newInputValue);
            }}
            onChange={(event, newValue) => {
              if (newValue) {
                handleSuggestionClick(newValue);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search by tags"
                variant="outlined"
                sx={{
                  width: 300,
                  [`& .MuiOutlinedInput-root`]: {
                    backgroundColor: '#1a1a1a',
                    color: white,
                  },
                  [`& .MuiInputLabel-root`]: {
                    color: grey,
                  },
                  [`& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline`]: {
                    borderColor: grey,
                  },
                  [`&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline`]: {
                    borderColor: orange,
                  },
                  [`& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline`]: {
                    borderColor: orange,
                    boxShadow: '0 0 4px rgba(227, 108, 9, 0.5)',
                  },
                }}
              />
            )}
            sx={{
              position: "relative",
              "& .MuiAutocomplete-popper": {
                backgroundColor: theme.palette.background.grey,
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: "8px",
              },
            }}
          />

          {/* Model Type Select */}
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel>Model Type</InputLabel>
            <Select
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
              label="Model Type"
              sx={{
                backgroundColor: '#1a1a1a',
                color: white,
                '& .MuiSelect-icon': {
                  color: grey, // Light grey color for dropdown icon
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: grey,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: orange,
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: orange,
                  boxShadow: '0 0 4px rgba(227, 108, 9, 0.5)',
                },
              }}
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {filteredModelTypes.map((model, index) => (
                <MenuItem key={index} value={model}>
                  {model}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Active Models Checkbox */}
          <FormControlLabel
            control={
              <Checkbox
                checked={noCodeConsoleOnly}
                onChange={(e) => setNoCodeConsoleOnly(e.target.checked)}
                color="secondary"
              />
            }
            label="Active Models"
          />

          {/* Level Filters */}
          <FormGroup row>
            {["image&video", "Audio", "Text"].map((level) => (
              <FormControlLabel
                key={level}
                control={
                  <Checkbox
                    value={level}
                    checked={selectedLevels.includes(level)}
                    onChange={handleLevelChange}
                    color="secondary"
                  />
                }
                label={level}
              />
            ))}
          </FormGroup>
        </Box>

        {/* Protocol Cards */}
        <Grid container spacing={3}>
          {filteredProtocols.length > 0 ? (
            filteredProtocols.map((basket) => (
              <Grid item xs={12} sm={6} md={4} key={basket.id}>
                <Card variant="blackBox"
                  sx={{
                    //backgroundColor: theme.palette.background.grey,
                    borderRadius: "8px",
                    boxShadow: theme.shadows[3],
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    {/* Header */}
                    <Box 
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                        marginBottom: "16px",
                      }}
                    >
                      <img
                        src={basket.image}
                        alt={basket.name}
                        style={{ height: "40px", width: "40px", borderRadius: "50%" }}
                      />
                      <Typography variant="h6" >
                        {basket.name}
                      </Typography>
                    </Box>

                    {/* Description */}
                    <Typography variant="t2_grey"  gutterBottom>
                      {basket.description}
                    </Typography>

                    {/* Tags */}
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", marginTop: "8px" }}>
                      {basket.tags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag}
                          size="small"
                          sx={{
                            backgroundColor: theme.palette.action.hover,
                            color: theme.palette.text.primary,
                          }}
                        />
                      ))}
                    </Box>

                    {/* Level Indicator */}
                    <Box sx={{ marginTop: "12px" }}>
                      <Chip
                        label={basket.level}
                        size="small"
                        sx={{
                          backgroundColor:
                            basket.level === "image&video"
                              ? "#d1e7dd"
                              : basket.level === "Audio"
                              ? "#cff4fc"
                              : "#f8d7da",
                          color:
                            basket.level === "image&video"
                              ? "#0f5132"
                              : basket.level === "Audio"
                              ? "#055160"
                              : "#842029",
                        }}
                      />
                    </Box>
                  </CardContent>

                  {/* Select Button */}
                  {/*<Box sx={{ p: 2 }}>
                    <Button
                      variant="custom"
                      fullWidth
                      onClick={() => navigate(basket.link)}
                      
                    >
                      Select
                    </Button>
                  </Box>*/}
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography variant="body1" align="center" color="text.secondary">
                No protocols found matching the selected filters.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>

      {/* Create Basket Modal */}
      {openCreateBasketModal && (
        <CreateBasketModal
          openModal={openCreateBasketModal}
          setOpenModal={setOpenCreateBasketModal}
          closeClicked={closeClicked}
          setCloseClicked={setCloseClicked}
        />
      )}
    </MainWrapper>
  );
};

export default CropBasket;
