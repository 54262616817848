import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import styles from "./TaleWalletOffRampModal.module.css";
import { useSelector } from "react-redux";
import {
  faArrowRightFromBracket,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { toast } from "react-toastify";
import { BiChevronDown } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import SignupPageModal from "../CustodialWalletSetup/SignupPageModal";
import { useWalletView } from "../../hooks/useWalletView";
import { nativeToken } from "../../config";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import AddBankModal from "../Bank/AddBankModal";
import { Card } from "react-bootstrap";
import TokenSelectionDropdown from "../tokenSelectionDropdown/TokenSelectionDropdown";

const TaleWalletOffRampModal = ({
  openModal,
  setOpenModal,
  closeBuyModal,
  allCoin,
  allCoinPrice,
  amount,
  basketId,
  tokenPercentArray,
  tokenArray,
  basketBlockchain,
  isTokenDetailsPage,
  symbol,
}) => {
  const appCtx = useSelector((state) => state.app);
  const [receiveToken, setReceiveToken] = useState("INR");
  const [sendTokenPrice, setsendTokenPrice] = useState(null);
  const [sendAmount, setSendAmount] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [openAddBankModal, setOpenAddBankModal] = useState(false);
  const [openSignupPageModal, setOpenSignupPageModal] = useState(false);
  const [selectedtokenBalance, setSelectedtokenBalance] = useState(null);

  const [isBankDataCopied, setIsBankDataCopied] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const { getUsersToken, usersToken } = useWalletView();
  const symbolFromMobile = new URLSearchParams(window.location.search).get(
    "symbol"
  );
  const blockchainFromMobile = new URLSearchParams(window.location.search).get(
    "blockchain"
  );

  const [sendToken, setsendToken] = useState(
    isTokenDetailsPage ? symbol : nativeToken[appCtx.blockchain]
  );
  const [sendTokenName, setsendTokenName] = useState(null);

  let initialtokenName = allCoin
    ? allCoin.find((obj) => obj.symbol === sendToken)?.tokenName
    : "";

  const networks = {
    POLYGON: "matic20",
    BSC: "bep20",
    ETHEREUM: "erc20",
    ALGORAND: "algo",
    SOLANA: "sol",
  };

  var network;
  network =
    networks[blockchainFromMobile || basketBlockchain || appCtx.blockchain];

  const [receiveAmount, setReceiveAmount] = useState(null);
  const [currentComponent, setCurrentComponent] = useState(1);

  const [upiClicked, setUpiClicked] = useState(false);
  const [bankClicked, setBankClicked] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [fourDigit, setFourDigit] = useState(null);
  const [loader, setLoader] = useState(false);
  const [networkFee, setNetworkFee] = useState(20);
  const [isBasketDetailsPage, setIsBasketDetailsPage] = useState(false);
  const [tokenPrices, setTokenPrices] = useState({});
  const [bankDatas, setBankDatas] = useState([]);
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(null);

  const makeRequest = useAuthorizedHttp();
  const queryParams = new URLSearchParams(window.location.search);

  const componentRef = useRef(null);

  let isOpenedfromMobile = queryParams.get("address");

  const { getAllCoinPrice } = useWalletView();
  function closeModal() {
    setOpenModal(false);
    // closeBuyModal();
  }
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9991,
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: 9992,
      backgroundColor: "#000000",
      border: "1px solid  #000000",
      color: "black",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
      width: "400px",
    },
  };
  const width = window.screen.width;
  let isMobileDevice = width < 600;
  const formattedDate = moment().format("D MMM'YY, h:mm a");

  const handleCardClick = (accountNumber) => {
    if (selectedBankAccount === accountNumber) {
      setSelectedBankAccount(null);
    } else {
      setSelectedBankAccount(accountNumber);
    }
  };

  useEffect(() => {
    getUsersToken();
  }, []);

  useEffect(() => {
    const url = window.location.href;
    const searchStrings = ["subscribe-crypto-basket", "crypto-basket"];
    let isPageFound = false;

    for (const searchString of searchStrings) {
      if (url.includes(searchString)) {
        isPageFound = true;
        break;
      }
    }

    setIsBasketDetailsPage(isPageFound);
  }, []);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleBankDataCopy = (value, index) => {
    navigator.clipboard.writeText(value);
    const updatedCopiedState = [...isBankDataCopied];
    updatedCopiedState[index] = true;
    setIsBankDataCopied(updatedCopiedState);
  };

  // fetching receive token details

  useEffect(() => {
    setReceiveAmount(
      Math.ceil(sendAmount * allCoinPrice[sendToken]?.price * 84)
    );
  }, [sendAmount, sendToken]);

  useEffect(() => {
    let sendTokenPriceinUsd = allCoinPrice[sendToken]?.price;
    setsendTokenPrice(Math.ceil(sendTokenPriceinUsd * 84));
  }, [sendToken, sendAmount, allCoinPrice, receiveToken]);
  useEffect(() => {
    const min = 1000; // Minimum 4-digit number
    const max = 9999; // Maximum 4-digit number
    const fourDigit = Math.floor(Math.random() * (max - min + 1)) + min;
    setFourDigit(fourDigit);
  }, []);

  const handleSendAmountChange = (event) => {
    setSendAmount(event.target.value);
  };

  const availableTokens = allCoin?.map((token) => token);
  const filteredTokens = availableTokens.filter(
    (token) => token.tokenShortName !== receiveToken
  );
  console.log("filteredTokens", filteredTokens);

  let platformFee;
  let totalFee;

  if (sendAmount !== null && sendAmount !== undefined) {
    platformFee = Math.ceil((receiveAmount * 0.4) / 100);
  } else {
    // Handle the case when sendAmount is null or undefined
    // For example:
    platformFee = "0.00";
  }

  if (networkFee !== null && networkFee !== undefined) {
    totalFee = (parseFloat(platformFee) + parseFloat(networkFee)).toFixed(2);
  } else {
    // Handle the case when networkFee is null or undefined
    // For example:
    totalFee = platformFee;
  }

  let userAddressFromUrl = queryParams.get("address");
  const userAddress =
    appCtx?.walletAddress?.find(
      (addr) => addr?.blockchain === appCtx?.blockchain
    )?.address || userAddressFromUrl;

  // get clients bank account
  useEffect(() => {
    makeRequest(
      {
        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/fundMagnet/client/${appCtx.userDetails?.userId}/bank/account/list`,
      },
      (res) => {
        setBankDatas(res);
      }
    );
  }, [openAddBankModal]);

  useEffect(() => {
    const bal =
      usersToken &&
      usersToken.some((singleToken) => singleToken.token === sendToken)
        ? usersToken
            .filter((singleToken) => singleToken.token === sendToken)
            .map((singleToken) => Number(singleToken.balance).toFixed(5))
            .join("")
        : "0";

    setSelectedtokenBalance(bal);
  }, [usersToken, sendToken]);

  useEffect(() => {
    if (selectedBankAccount) {
      setOpenSignupPageModal(true);
      // setOpenModal(false);
    }
  }, [currentComponent]);

  let OffRampTransactionData = {
    fiatAmount: receiveAmount,

    fiatCurrency: receiveToken,

    cryptoToken: sendToken,

    cryptoAmount: sendAmount,

    blockchain: appCtx.blockchain,

    platformFee: platformFee,
    networkFee: networkFee,
    tokenPrice: allCoinPrice[sendToken]?.price,
    address: userAddress,
  };

  const themedColor = {
    color: "black",
  };

  const handleClick = (event) => {
    event.preventDefault();
    // Check if the clicked element has the 'button-id' ID
    if (event.target.id === "sendButton") {
      setOpen(true);
    } else if (
      event.target.id === "searchbar" ||
      event.target.id === "searchIcon"
    ) {
    } else if (event.target.id === "receiveButton") {
      // setOpen(true);
    } else {
      //  setSendOpen(false);
      setOpen(false);
    }
  };

  const handleMaxButtonClick = () => {
    setSendAmount(selectedtokenBalance);
  };

  return (
    <>
      <Modal
        isOpen={openModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        className={`${styles.popup} `}
      >
        <div
          class=" d-flex justify-content-between align-items-center  py-2 position-relative px-4 rounded-top-lg"
          style={{
            backgroundColor: "#9376B1",
            borderRadius: "5px 5px 0px 0px",
          }}
        >
          <div class="w-15%">
            <img
              src="https://tale-invest-assets.s3.ap-southeast-1.amazonaws.com/assets/images/tale-invest-logo-sm.png"
              alt=""
              style={{ width: "35px", height: "35px" }}
            />
          </div>

          <h5 class="mb-0">Talewallet</h5>
          <div class="">
            <div
              className="btn btn-link"
              onClick={() => {
                closeModal();
              }}
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-times" style={{ fontSize: "20px" }}></i>
            </div>
          </div>
        </div>
        <div
          className="w-[400px] max-h-[550px] overflow-x-hidden overflow-y-visible bg-[#EEEEEE] rounded-xl "
          ref={componentRef}
          onClick={handleClick}
        >
          {currentComponent === 1 && (
            <div>
              <div className="px-4 py-2">
                <p class="text-md mt-2 mb-2">
                  You have {selectedtokenBalance} {sendToken}
                </p>
                <p class="text-md mt-2 mb-2">You sell</p>
                <div
                  class="d-flex justify-content-between align-items-center  border"
                  style={{ border: "#ced4da", borderRadius: "5px" }}
                >
                  <div class="position-relative d-flex">
                    <div
                      class="position-relative d-flex align-items-center"
                      style={{ width: "160px" }}
                    >
                      <input
                        id="sendAmount"
                        placeholder="Enter amount"
                        name="sendAmount"
                        type="number"
                        min="0"
                        step="any"
                        value={sendAmount}
                        disabled={isBasketDetailsPage}
                        onChange={handleSendAmountChange}
                        class="form-control"
                        style={{
                          backgroundColor: `${
                            appCtx?.isDarkMode ? "white" : "white"
                          }`,
                          color: "black",

                          border: "none",
                        }}
                      />
                      <button
                        type="button"
                        class="position-absolute text-sm text-gray-700 bg-transparent border border-gray-400 rounded px-2 py-1"
                        onClick={handleMaxButtonClick}
                        style={{
                          top: "50%",
                          transform: "translateY(-50%)",
                          right: "5px",
                        }}
                      >
                        <h6 class="font-s12 font-w400 mb-0">max</h6>
                      </button>
                    </div>
                  </div>
                  <div class="w-2 h-12 d-flex justify-content-center align-items-center my-1">
                    <div
                      class="border border-end"
                      style={{
                        width: "2px",
                        height: "30px",
                        border: "#ced4da",
                      }}
                    ></div>
                  </div>

                  <div
                    class="d-flex align-items-center gap-2 ms-2 "
                    style={{ width: "150px" }}
                  >
                    <img
                      src={
                        allCoin
                          ? allCoin.find((obj) => obj.symbol === sendToken)
                              ?.tokenIcon
                          : ""
                      }
                      alt=""
                      style={{ width: "25px", height: "25px" }}
                    />

                    {/* dropdown  */}
                    <div className="position-relative">
                      <div
                        className="mt-1"
                        onClick={() => !isTokenDetailsPage && setOpen(!open)}
                      >
                        <div className="" id="sendButton">
                          <div
                            className={` d-flex align-items-center justify-content-between rounded`}
                            id="sendButton"
                          >
                            {sendTokenName
                              ? sendTokenName?.length > 15
                                ? sendTokenName?.substring(0, 15) + "..."
                                : sendTokenName
                              : initialtokenName}
                            <BiChevronDown
                              size={20}
                              className={` ms-1`}
                              style={{
                                transform: open ? "rotate(180deg)" : "none",
                              }}
                              id="sendButton"
                            />
                          </div>
                          <p className="text-xs mb-0" id="sendButton">
                            On {network}
                          </p>
                        </div>
                      </div>
                      {open && (
                        <TokenSelectionDropdown
                          searchTerm={inputValue}
                          setSearchTerm={setInputValue}
                          allCoin={allCoin}
                          isDrodownOpen={open}
                          setIsDropdownOpen={setOpen}
                          setToken={setsendToken}
                          setTokenName={setsendTokenName}
                          additionalStyles={{ top: "-50px", right: "0px" }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <p class="text-md mt-2 mb-2">You receive</p>

                <div
                  class="d-flex justify-content-between align-items-center  border"
                  style={{ border: "#ced4da", borderRadius: "5px" }}
                >
                  <div class="">
                    <input
                      id="receiveAmount"
                      placeholder="you will receive"
                      name="receiveAmount"
                      type="number"
                      min="0"
                      step="any"
                      value={receiveAmount}
                      readOnly
                      className="ms-3"
                      style={{
                        backgroundColor: `${
                          appCtx?.isDarkMode ? "white" : "white"
                        }`,
                        color: "black",
                        width: "100px",
                        border: "none",
                      }}
                    />
                  </div>
                  <div class="w-2 h-12 d-flex justify-content-center align-items-center my-1">
                    <div
                      class="border border-end"
                      style={{
                        width: "2px",
                        height: "30px",
                        border: "#ced4da",
                      }}
                    ></div>
                  </div>

                  <div
                    id="receiveToken"
                    name="receiveToken"
                    class="d-flex align-items-center gap-2 ms-2 "
                    style={{ width: "150px" }}
                  >
                    <img
                      src="/images/indianflag.png"
                      alt=""
                      class=""
                      style={{ width: "25px", height: "25px" }}
                    />
                    <div class="">
                      <p class="mb-0">INR</p>
                      <p class="mb-0">Indian Rupee</p>
                    </div>
                  </div>
                </div>

                <p className=" text-sm mt-2  ">
                  {sendTokenPrice && (
                    <>
                      1 {sendToken} = ₹{sendTokenPrice}
                    </>
                  )}
                </p>

                <div className="">
                  <Accordion className="border">
                    <AccordionSummary
                      as={Card.Header}
                      eventKey="0"
                      className="d-flex justify-content-between align-items-center"
                      style={{
                        backgroundColor: `${
                          appCtx?.isDarkMode ? "white" : "white"
                        }`,
                        color: "black",
                      }}
                      onClick={() => setExpanded(!expanded)}
                    >
                      <p className="text-[#3B0076] font-medium text-md mb-0">
                        You Pay ₹{sendAmount}
                      </p>
                      <p className="ms-4 mb-0">View Breakup</p>
                      <div className="d-flex align-items-center ms-2">
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          rotation={expanded ? 180 : 0}
                          className="ms-1"
                          style={themedColor}
                        />
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      eventKey="0"
                      style={{
                        backgroundColor: `${
                          appCtx?.isDarkMode ? "white" : "white"
                        }`,
                        color: "black",
                      }}
                    >
                      <>
                        <div className=" text-sm font-medium">
                          <div className="d-flex justify-content-between align-items-center ">
                            <p className="mb-1">Platform Fee (0.4%)</p>
                            <p className="mb-1">₹{platformFee || 0}</p>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="mb-1">Network Fee</p>
                            <p className="mb-1"> ₹{networkFee || 0}</p>
                          </div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between align-items-center text-sm font-medium">
                          <p className="mb-1">Total Fee</p>
                          <p className="mb-1">₹{totalFee || 0}</p>
                        </div>
                        {isBasketDetailsPage && (
                          <p className="text-sm font-medium mt-1">
                            Network fee includes{" "}
                            {tokenArray?.map((token, index) => {
                              const isLastToken =
                                index === tokenArray.length - 1;
                              return (
                                <span
                                  key={index}
                                  className="text-sm font-medium ml-2"
                                >
                                  {nativeToken[basketBlockchain]} to {token}
                                  {!isLastToken &&
                                    index !== tokenArray.length - 2 &&
                                    ", "}
                                  {!isLastToken &&
                                    index === tokenArray.length - 2 &&
                                    " and "}
                                </span>
                              );
                            })}
                          </p>
                        )}
                      </>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>
          )}
          {currentComponent === 2 && (
            <div className="p-3">
              <p className="text-xl font-weight-bold text-center mb-1">
                Select the Bank
              </p>
              <p className="text-lg font-weight-medium text-center mb-3">
                We'll send funds to the selected account
              </p>
              <div className="d-flex flex-column gap-3">
                {bankDatas && bankDatas.length === 0 ? (
                  <p className="text-center my-5">
                    You haven't added any bank accounts. Please add an account
                    to proceed further.
                  </p>
                ) : (
                  bankDatas.length !== 0 &&
                  bankDatas.map((bankData, index) => (
                    <div
                      key={index}
                      className={`w-100 h-14 px-4 py-2 rounded-lg  d-flex justify-content-between align-items-center inline-flex cursor-pointer`}
                      style={{
                        border:
                          selectedBankAccount === bankData?.accountNumber
                            ? "1px solid #8B5CF6"
                            : "1px solid #718096",
                        color:
                          selectedBankAccount === bankData?.accountNumber
                            ? "#8B5CF6"
                            : "#718096",
                      }}
                      onClick={() => handleCardClick(bankData?.accountNumber)}
                    >
                      <div className="w-100 d-flex d-flex justify-content-between align-items-center">
                        <div className="h-5 d-flex justify-content-start align-items-center gap-3">
                          <div className="d-flex justify-content-start align-items-center gap-2">
                            <div className="text-base font-normal">
                              {bankData?.accountNumber}
                            </div>
                          </div>
                        </div>
                        <div className="">
                          {/* Replace with your FontAwesomeIcon */}
                          <FontAwesomeIcon icon={faArrowRightFromBracket} />
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
              <div className="d-flex justify-content-center mt-3 mb-2 w-100">
                <button
                  onClick={() => {
                    setOpenAddBankModal(true);
                  }}
                  className="btn btn-outline-secondary "
                >
                  <p className="mb-0"> + Add New Bank</p>
                </button>
              </div>
            </div>
          )}
          {currentComponent === 3 && (
            <div className="px-5 py-5">
              <>
                <p>
                  <span className="font-medium">Account No:</span>
                  <span className="">{selectedBankAccount}</span>
                </p>
                <p>
                  <span className="font-medium">You are sending:</span>
                  <span className="">
                    {sendAmount} {sendToken}
                  </span>
                </p>
                <p>
                  <span className="font-medium">You will receive:</span>
                  <span className="">
                    {receiveAmount - totalFee} {receiveToken}
                  </span>
                </p>
              </>
            </div>
          )}
          <div className="px-5 mb-10">
            <div className="d-flex justify-content-center mt-3 mb-5 w-100">
              <button
                onClick={() => {
                  if (sendAmount > selectedtokenBalance) {
                    toast.error("You don't have enough  coin !!");
                  } else {
                    setCurrentComponent(currentComponent + 1);
                  }
                }}
                disabled={
                  !sendAmount ||
                  (!selectedBankAccount && currentComponent === 2)
                }
                className="btn btn-primary px-5 d-flex align-items-center gap-2  "
              >
                <p className="mb-0"> Proceed</p>
                <div>
                  {loader && (
                    <CircularProgress size={20} className="ml-[5px]" />
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <AddBankModal
        openModal={openAddBankModal}
        setOpenModal={setOpenAddBankModal}
      />

      <SignupPageModal
        openModal={openSignupPageModal}
        setOpenModal={setOpenSignupPageModal}
        offRampSell={true}
        // recieverAddress={appCtx.walletAddress[0]?.address}
        // option={option}
        // amount={nftPrice}
        // nftId={nftId}
        // blockchainNftId={blockchainNftId}
        // setOpenModalPaymentFailure={setOpenModalPaymentFailure}
        // setOpenModalPaymentSuccess={setOpenModalPaymentSuccess}
        OffRampTransactionData={OffRampTransactionData}
      />
    </>
  );
};

export default TaleWalletOffRampModal;
