import { useSelector } from "react-redux";
import Modal from "react-modal";

import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
function TpSlModal({ openModal, setOpenModal }) {
  const appCtx = useSelector((state) => state.app);
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: 9999,
      backgroundColor: "#000000",
      border: "1px solid  #000000",
      color: "black",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
    },
  };
  function closeModal() {
    setOpenModal(false);
  }
  const themedColor = {
    color: "black",
  };

  return (
    <div>
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div
          className="d-flex justify-content-end m-4 position-relative"
          style={{ maxHeight: "600px", overflow: "auto" }}
        >
          <button
            onClick={closeModal}
            className="btn btn-link position-absolute top-0 right-2"
          >
            <i className="fa fa-times" style={{ fontSize: "20px" }}></i>
          </button>

          <div className="p-3">
            <div className="" style={{ width: "900px" }}>
              <h4 className="text-center">Take Profit / Stop Loss</h4>

              <div className="border border-bottom-2"></div>

              <h5 className="text-center mt-2">
                If order A is filled partially or fully, order B and C will be
                placed.
              </h5>
              <div className="d-flex flex-column align-items-center  justify-content-center w-100">
                {/* first table  */}
                <div className="border " style={{ width: "300px" }}>
                  <div
                    className="border px-2 py-1"
                    style={{ backgroundColor: "#C5C5C5" }}
                  >
                    <h6 className="mb-0">Order A</h6>
                  </div>
                  <div className="px-2">
                    <div className="d-flex justify-content-between">
                      <p className="mb-1">Limit</p>
                      <p className="mb-1 font-w600">New</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="mb-1">Side</p>
                      <p className="mb-1 font-w600">New</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="mb-1">Amount</p>
                      <p className="mb-1 font-w600">New</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="mb-1">Price</p>
                      <p className="mb-1 font-w600">New</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="mb-1">Reduce Only</p>
                      <p className="mb-1 font-w600">New</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="mb-1">Side</p>
                      <p className="mb-1 font-w600">New</p>
                    </div>
                  </div>
                </div>

                {/* line  */}
                <div className="d-flex flex-column align-items-center position-relative">
                  <div
                    className=""
                    style={{
                      width: "2px",
                      height: "30px",
                      backgroundColor: "#C5C5C5",
                    }}
                  ></div>
                  <div
                    className=""
                    style={{
                      width: "350px",
                      height: "2px",
                      backgroundColor: "#C5C5C5",
                    }}
                  ></div>
                  <div
                    className="d-flex justify-content-between"
                    style={{
                      width: "350px",
                      height: "2px",
                      backgroundColor: "#C5C5C5",
                    }}
                  >
                    <div
                      className=""
                      style={{
                        width: "2px",
                        height: "30px",
                        backgroundColor: "#C5C5C5",
                      }}
                    ></div>
                    <div
                      className=""
                      style={{
                        width: "2px",
                        height: "30px",
                        backgroundColor: "#C5C5C5",
                      }}
                    ></div>
                  </div>
                </div>

                {/* second and third table  */}
                <div className="d-flex gap-5 " style={{ marginTop: "30px" }}>
                  <div className="">
                    <h5 className="text-center mt-2">
                      If order B is filled fully, order C will be canceled.
                    </h5>
                    <div className="border " style={{ width: "300px" }}>
                      <div
                        className="border px-2 py-1"
                        style={{ backgroundColor: "#C5C5C5" }}
                      >
                        <h6 className="mb-0">Order B</h6>
                      </div>
                      <div className="px-2">
                        <div className="d-flex justify-content-between">
                          <p className="mb-1">Side</p>
                          <p className="mb-1 font-w600">New</p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="mb-1">Amount</p>
                          <p className="mb-1 font-w600">New</p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="mb-1">Stop Price</p>
                          <p className="mb-1 font-w600">New</p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="mb-1">Trigger</p>
                          <p className="mb-1 font-w600">New</p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="mb-1">Reduce Only</p>
                          <p className="mb-1 font-w600">New</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <h5 className="text-center mt-2">
                      If order C is filled fully, order B will be canceled.
                    </h5>
                    <div className="border " style={{ width: "300px" }}>
                      <div
                        className="border px-2 py-1"
                        style={{ backgroundColor: "#C5C5C5" }}
                      >
                        <h6 className="mb-0">Order C</h6>
                      </div>
                      <div className="px-2">
                        <div className="d-flex justify-content-between">
                          <p className="mb-1">Side</p>
                          <p className="mb-1 font-w600">New</p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="mb-1">Amount</p>
                          <p className="mb-1 font-w600">New</p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="mb-1">Stop Price</p>
                          <p className="mb-1 font-w600">New</p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="mb-1">Trigger</p>
                          <p className="mb-1 font-w600">New</p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="mb-1">Reduce Only</p>
                          <p className="mb-1 font-w600">New</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex gap-1">
                <span>
                  <FontAwesomeIcon
                    icon={faInfo}
                    className=""
                    style={themedColor}
                  />
                </span>
                <p className="text-center mb-0">
                  One-Triggers-a One Cancels the Other order(OTOCO) allows you
                  to place two orders - a primary order and two secondary orders
                  at the same time.
                </p>
              </div>
              <div className="d-flex justify-content-end w-100">
                <button
                  onClick={() => {
                    closeModal();
                  }}
                  className="btn btn-warning"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default TpSlModal;
