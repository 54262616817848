// src/components/sidebar/Sidebar.jsx
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "../../context/app-slice";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Import Custom SVG Icons as React Components
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as ProjectsIcon } from "../../assets/icons/project.svg";
import { ReactComponent as DGUBasketIcon } from "../../assets/icons/protocol.svg";
import { ReactComponent as TransactionsIcon } from "../../assets/icons/transactions.svg";
import { ReactComponent as BillingIcon } from "../../assets/icons/credit_card.svg";
import { ReactComponent as CollapseIcon } from "../../assets/icons/collapse.svg";
import { ReactComponent as ExpandIcon } from "../../assets/icons/expand.svg";

// Styled Components

// Main Sidebar Container
const SidebarContainer = styled(motion.div)(({ theme }) => ({
  backgroundColor: theme.palette.sidebar.background,
  position: "fixed",
  top: "5vh",
  left: "0",
  height: "100vh",
  overflowY: "auto",
  zIndex: 1000,
  borderRight: `1px solid ${theme.palette.sidebar.borderColor}`,
  boxShadow: theme.palette.sidebar.boxShadow,
  width: 250, // Expanded width
  transition: "width 0.3s ease-in-out",
}));

// Toggle Button to Expand/Collapse Sidebar
const ToggleButton = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  borderRadius: theme.shape.borderRadius,
  transition: "background-color 0.3s ease",
  padding: theme.spacing(1.25),
  margin: theme.spacing(2.5),
  color: theme.palette.sidebar.text,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    backgroundColor: theme.palette.sidebar.hoverBackground,
  },
}));

// Navigation Link
const NavLink = styled(Link)(({ theme, active }) => ({
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1.25),
  margin: theme.spacing(1.25, 2.5),
  color:
    active === "true"
      ? theme.palette.sidebar.activeText
      : theme.palette.sidebar.text,
  backgroundColor:
    active === "true"
      ? theme.palette.sidebar.activeBackground
      : "transparent",
  transition: "background-color 0.3s ease, color 0.3s ease",
  "&:hover": {
    backgroundColor: theme.palette.sidebar.hoverBackground,
  },
}));

// Container for Icons
const IconContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: theme.spacing(3.75), // 30px
  height: theme.spacing(3.75),
  color: "inherit", // Inherit color from NavLink
  "& svg": {
    width: "24px",
    height: "24px",
    fill: "currentColor", // Ensure SVG inherits color
  },
}));

// Text for Navigation Links
const LinkText = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1.25),
  // Removed color to allow inheritance
  fontWeight: "normal",
  whiteSpace: "nowrap",
  fontSize: theme.typography.body1.fontSize,
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

// Title for Account Section
const AccountSectionTitle = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2.5),
  fontWeight: "bold",
  fontSize: theme.typography.subtitle2.fontSize,
  marginTop: theme.spacing(1.25),
  color: theme.palette.sidebar.accountSectionText,
}));

const Sidebar = ({ children }) => {
  const dispatch = useDispatch();
  const appCtx = useSelector((state) => state.app);
  const isOpen = appCtx?.isSideBarOpen;
  const location = useLocation();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const toggleSidebar = () => {
    dispatch(appActions.setIsSideBarOpen(!isOpen));
  };

  // Update window width on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Automatically collapse sidebar on route change if it's open, except for specified routes
  useEffect(() => {
    const exceptionPaths = [
      "/",
      "/projects",
      "/dgu-basket",
      "/transactions",
      "/billing",
    ];
    if (!exceptionPaths.includes(location.pathname) && isOpen) {
      dispatch(appActions.setIsSideBarOpen(false));
    }
  }, [location.pathname, isOpen, dispatch]);

  // Define your routes here
  const routes = [
    {
      path: "/",
      name: "Home",
      icon: <DashboardIcon />,
    },
    {
      path: "/projects",
      name: "Projects",
      icon: <ProjectsIcon />,
    },
    {
      path: "/dgu-basket",
      name: "DGUs",
      icon: <DGUBasketIcon />,
    },
  ];

  // Define your account routes here
  const accountRoutes = [
    {
      path: "/transactions",
      name: "Transactions",
      icon: <TransactionsIcon />,
    },
    {
      path: "/billing",
      name: "Billing",
      icon: <BillingIcon />,
    },
  ];

  return (
    <div className="main-container" style={{ display: "flex" }}>
      {/* Desktop Sidebar */}
      <div className="d-none d-md-block">
        <SidebarContainer
          initial={{ width: isOpen ? 250 : 80 }}
          animate={{ width: isOpen ? 250 : 80 }}
          transition={{ duration: 0.3, ease: "easeInOut" }} // Corrected easing
        >
          {/* Main Section Routes */}
          <section className="routes">
            <div style={{ display: "flex", flexDirection: "column" }}>
              {/* Toggle Button */}
              <ToggleButton onClick={toggleSidebar} aria-label="Toggle Sidebar">
                <IconContainer>
                  {isOpen ? <CollapseIcon /> : <ExpandIcon />}
                </IconContainer>
              </ToggleButton>

              {/* Existing Routes */}
              {routes.map((route, index) => {
                const isActive = route.path === location.pathname;

                return (
                  <NavLink
                    to={route.path}
                    key={index}
                    active={isActive ? "true" : "false"}
                    aria-current={isActive ? "page" : undefined}
                  >
                    <IconContainer>{route.icon}</IconContainer>
                    {isOpen && <LinkText variant="body1">{route.name}</LinkText>}
                  </NavLink>
                );
              })}
            </div>
          </section>

          {/* Account Section */}
          <section className="routes" style={{ marginTop: "20px" }}>
            {isOpen && (
              <AccountSectionTitle variant="subtitle2">
                Accounts
              </AccountSectionTitle>
            )}
            <div style={{ display: "flex", flexDirection: "column" }}>
              {accountRoutes.map((route, index) => {
                const isActive = route.path === location.pathname;

                return (
                  <NavLink
                    to={route.path}
                    key={index}
                    active={isActive ? "true" : "false"}
                    aria-current={isActive ? "page" : undefined}
                  >
                    <IconContainer>{route.icon}</IconContainer>
                    {isOpen && <LinkText variant="body1">{route.name}</LinkText>}
                  </NavLink>
                );
              })}
            </div>
          </section>
        </SidebarContainer>
      </div>

      {/* Main Content */}
      <main
        style={{
          flex: 1,
          marginLeft: isOpen ? 250 : 80,
          paddingTop: "50px",
          paddingBottom: windowWidth < 768 ? "60px" : "0",
          overflowY: "auto",
          minHeight: "100vh",
          transition: "margin-left 0.3s ease-in-out",
        }}
      >
        {children}
      </main>
    </div>
  );
};

export default Sidebar;
